import axios from 'axios';

let AlgaAxiosApi = {
  createAlgaPoints: createAlgaPoints,
};

function createAlgaPoints(startDate) {
  return axios.create({
    baseURL: 'https://rajapinnat.ymparisto.fi/api/kansalaishavainnot/1.0/requests.json',
    timeout: 20000,
    params: {
      start_date: startDate,
      service_code: 'algaebloom_service_code_201808151546171',
      extension: 'true'
    }
  })
}

export default AlgaAxiosApi;