// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import './plugins/vuetify';
import Vuetify from 'vuetify';
import vuexI18n from 'vuex-i18n';
import App from './App';
import router from './router';
import colors from 'vuetify/es5/util/colors';
import {
  store
} from "./store/store";
import 'vuetify/dist/vuetify.min.css';
import 'babel-polyfill';
import translationFinnish from './assets/locales/fi.json';
import translationSwedish from './assets/locales/sv.json';
import translationEnglish from './assets/locales/en.json';
require('../node_modules/ol/ol.css');


Vue.use(Vuetify, {
  theme: {
    primary: colors.lightBlue.darken3,
    secondary: colors.shades.white,
    accent: colors.shades.black,
    error: colors.red.accent3,
    info: colors.blue.darken1
  }
})

Vue.use(vuexI18n.plugin, store);
Vue.i18n.set('fi');
Vue.i18n.add('fi', translationFinnish);
Vue.i18n.add('en', translationEnglish);
Vue.i18n.add('sv', translationSwedish);

export const eventBus = new Vue();

Vue.config.productionTip = false
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: {
    App
  },
  template: '<App/>'
})
