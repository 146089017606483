<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 17.223 20.637"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName}} icon
		</title>
        <g id="noun_1435310_cc" class="cls-1" transform="translate(0 0)">
            <g id="Group" transform="translate(0 0)">
                <path id="Compound_Path" data-name="Compound Path" class="cls-2" d="M19.8,31.337H31.824L32.9,15.321h1.523V13.746H29.3V10.7H22.32v3.046H17.2v1.575h1.523ZM30.38,29.761H21.27L20.3,15.321H31.325ZM23.9,12.275h3.833v1.47H23.9Z" transform="translate(-17.2 -10.7)"/>
                <rect id="Path" class="cls-2" width="10.213" height="1.575" transform="translate(9.583 16.796) rotate(-88.3)"/>
                <rect id="Path-2" data-name="Path" class="cls-2" width="1.575" height="10.216" transform="translate(5.736 6.644) rotate(-1.7)"/>
            </g>
        </g>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'box'
		},
		width: {
			type: [Number, String],
			default: 16
		},
		height: {
			type: [Number, String],
			default: 20
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

    svg > * {
        fill: white;
    }

</style>