import moment from 'moment';
import mapApi from '../maps-constructor/mapsInit';
import store from '../store/modules/maps-main.store.js';

let helperAPI = {
  createFeatureInfoBox: createFeatureInfoBox
};


// construct feature object
async function createFeatureInfoBox(mapInstance, feature) {
  let info = checkFeatureType(feature);
  console.log('info tyyppi: ' + info.type)
  let boxConvertedInfo;
  switch (info.type) {
    case 'AIS':
      let infoAIS = {};
      infoAIS.callSign = info.featureProperties.callSign;
      infoAIS.name = info.featureProperties.name;
      infoAIS.mmsi = info.featureProperties.mmsi;
      infoAIS.imo = info.featureProperties.imo;
      infoAIS.shipType = info.featureProperties.shipType;
      infoAIS.COG = info.featureProperties.cog;
      infoAIS.Speed = info.featureProperties.sog;
      infoAIS.destination = info.featureProperties.destination;
      infoAIS.draught = info.featureProperties.draught;
      infoAIS.eta = parseEta(info.featureProperties.eta);
      infoAIS.position = `${info.featureProperties.latitude}, ${info.featureProperties.longitude}`;
      infoAIS.lastReport = info.featureProperties.timestampExternal;
      boxConvertedInfo = featureBoxAISDescriber(infoAIS);
      mapApi.featureCentered(mapInstance, [parseFloat(info.featureProperties.longitude), parseFloat(info.featureProperties.latitude)]);
      break;
    case 'Wrecks':
      boxConvertedInfo = featureBoxWrecksDescriber(info.featureProperties);
      break;
    case 'Winds':
      boxConvertedInfo = featureBoxWindsDescriber(info.featureProperties);
      break;
    case 'Waves':
      boxConvertedInfo = featureBoxWavesDescriber(info.featureProperties);
      break;
    case 'Temperatures':
      boxConvertedInfo = featureBoxTemperaturesDescriber(info.featureProperties);
      break;
    case 'WaterTemperature':
      boxConvertedInfo = featureBoxWaterTemperatureDescriber(info.featureProperties);
      break;
    case 'WaterHeight':
      boxConvertedInfo = featureBoxWaterHeightDescriber(info.featureProperties);
      break;
    case 'POI':
      boxConvertedInfo = featureBoxPOIDescriber(info.featureProperties);
      break;
    case 'Videos':
      boxConvertedInfo = featureBoxVideosDescriber(info.featureProperties);
      break;
    case 'Boating':
      boxConvertedInfo = featureBoxBoatingDescriber(info.featureProperties);
      break;
    case 'Pictures':
      boxConvertedInfo = featureBoxPicturesDescriber(info.featureProperties);
      break;
    case 'SwimmingInfo':
      boxConvertedInfo = featureBoxSwimmingDescriber(info.featureProperties);
      break;
    case 'AlgaInfo':
      boxConvertedInfo = featureBoxAlgaDescriber(info.featureProperties);
      break;
  }

  return boxConvertedInfo;
}

// parse eta information 
function parseEta(eta) {
  let bits0to5 = eta & 0b111111;
  bits0to5 = bits0to5 < 10 ? '0' + bits0to5 : bits0to5;
  let bits6to10 = eta >>> 6 & 0b11111;
  let bits11to15 = eta >>> 11 & 0b11111;
  let bits16to19 = eta >>> 16 & 0b11111;
  return bits11to15 + '.' + bits16to19 + ' ' + bits6to10 + ':' + bits0to5;

}

// checking feature type
function checkFeatureType(feature) {
  console.log(feature)
  let featureMeta = {};
  if (feature.getProperties().features) {
    let properties = feature.getProperties().features[0].getProperties();
    featureMeta.featureProperties = properties;
    featureMeta.type = properties.type;
  } else {
    let properties = feature.getProperties();
    featureMeta.featureProperties = properties;
    featureMeta.type = properties.type;
  }
  return featureMeta;
}

function featureBoxAISDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'AIS';
  featureBox.name = data.name;
  featureBox.type = data.shipType;
  featureBox.position = data.position;
  featureBox.COG = data.COG;
  featureBox.SOG = data.Speed;
  featureBox.destination = data.destination;
  featureBox.lastReport = data.lastReport;
  return featureBox;
}

function featureBoxWrecksDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'Wrecks';
  featureBox.FornID = data.FornID;
  featureBox.Lagrum = data.Lagrum;
  featureBox.creationDate = data.CreationDate;
  featureBox.destinationName = data.DestinationName;
  featureBox.muncipality = data.Municipality;
  featureBox.positioning = data.Positioning;
  featureBox.species = data.SpeciesLaji;
  featureBox.subtype = data.Subtype;
  featureBox.url = data.Url;
  return featureBox;
}

function featureBoxVideosDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'Videos';
  featureBox.name = data.KohteenNim;
  featureBox.Youtube = data.youTubeLink;
  return featureBox;
}

function featureBoxBoatingDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'Boating';
  featureBox.Nimi = data.properties.Nimi;
  featureBox.Koordinaat = data.properties.Koordinaat;
  featureBox.Kategoria = data.properties.Kategoria;
  featureBox.URL = data.properties.URL;
  return featureBox;
}


function featureBoxSwimmingDescriber(data) {
  let activeLanguage = store.state.currentActiveLanguage.name ? store.state.currentActiveLanguage.name : 'fi';
  let featureBox = {};
  featureBox.Info = 'SwimmingInfo';
  featureBox.name = data.name;
  if (data.observations && data.observations.length > 0) {
    for (const key in data.observations) {
      if (data.observations.hasOwnProperty(key)) {
        if (data.observations[key].property === 'swimming_water_cyanobacteria') {
          featureBox[data.observations[key].property] = data.observations[key].name[activeLanguage];
          featureBox['time' + key] = moment(data.observations[key].time).format("DD.MM.YYYY HH:mm");
        }
      }
    }
  }
  return featureBox;
}

function featureBoxPicturesDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'Pictures';
  featureBox.name = data.name;
  featureBox.species = data.species;
  featureBox.speciesEng = data.speciesEng;
  featureBox.speciesSve = data.speciesSve;
  featureBox.Luontoportti = data.Luontoport;
  featureBox.date = moment(data.date).format("DD.MM.YYYY HH:mm");
  featureBox['Picture link'] = data.link;
  return featureBox;
}

function featureBoxWindsDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'Winds';
  featureBox.place = data.windPlace;
  featureBox.time = moment(data.windTime).format("DD.MM HH:mm");
  if (data.windSpeed !== 'NaN' && data.windDirection !== 'NaN') {
    featureBox.direction = data.windDirection !== 'NaN' ? data.windDirection + '°' : "Not available";
    featureBox.speed = data.windSpeed !== 'NaN' ? data.windSpeed + 'm/s' : "Not available";
    featureBox.gust = data.windGust !== 'NaN' ? data.windGust + 'm/s' : "Not available";
  }
  featureBox.temperature = data.temperature + "°";
  return featureBox;
}

function featureBoxWavesDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'Waves';
  featureBox.place = data.wavePlace;
  featureBox.time = moment(data.waveTime).format("DD.MM HH:mm");
  if (data.waveHeight !== 'NaN' && data.waveDirection !== 'NaN') {
    featureBox.direction = data.waveDirection !== 'NaN' ? data.waveDirection + "°" : "Not available";
    featureBox.waveHeight = data.waveHeight !== 'NaN' ? data.waveHeight + 'm' : "Not available";
  }
  return featureBox;
}

function featureBoxTemperaturesDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'Temperatures';
  featureBox.place = data.temperaturePlace;
  featureBox.time = moment(data.temperatureTime).format("DD.MM HH:mm");
  featureBox.waterTemperature = data.waterTemperature + "°";
  return featureBox;
}

function featureBoxWaterTemperatureDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'WaterTemperature';
  featureBox.time = moment(data.waterTemperatureTime).format("DD.MM HH:mm");
  featureBox.waterTemperature = data.waterTemperatureValue + "°";
  return featureBox;
}

function featureBoxWaterHeightDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'WaterHeight';
  featureBox.waterHeight = data.waterHeightValue + ' cm';
  featureBox.time = moment(data.waterHeightTime).format("DD.MM HH:mm");
  return featureBox;
}

function featureBoxPOIDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'POI';
  featureBox.place = data.title;
  //featureBox.wikiLink = data.wikiURL;
  //featureBox.summary = data.summary;
  featureBox.position = `${Math.round(data.lat * 1000) / 1000}, ${Math.round(data.lon * 1000) / 1000}`;
  return featureBox;
}

function featureBoxAlgaDescriber(data) {
  let featureBox = {};
  featureBox.Info = 'AlgaInfo';
  featureBox.algaInfo = 'algae' + data.observation;
  featureBox.time = moment(data.time).format("DD.MM HH:mm");
  return featureBox;
}

export default helperAPI;
