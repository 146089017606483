<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :width="width"
    :height="height" viewBox="0 0 12.989 7.872" :aria-labelledby="iconName" role="presentation">
    <title :id="iconName" lang="en">
      {{iconName}}
    </title>
    <g id="Group_152" transform="translate(-141.756 -186.064)" style="opacity:0.6;" :fill=iconColor>
      <path id="Subtraction_2" d="M145.371,192.884l1.667-0.962c0.335,0.261,0.748,0.402,1.173,0.4c1.055-0.001,1.91-0.856,1.911-1.911
		c0-0.088-0.006-0.175-0.018-0.262l2.65-1.53c0.51,0.379,0.866,0.931,1,1.553C152.131,194.064,146.248,193.218,145.371,192.884z
		 M144.296,192.35L144.296,192.35c-0.123-0.076-0.244-0.157-0.359-0.24c-0.726-0.423-1.251-1.12-1.459-1.934
		c0.202-0.812,1.965-3.2,5.638-3.2c1.322-0.022,2.623,0.324,3.759,1l-2.17,1.253c-0.362-0.459-0.915-0.726-1.5-0.723
		c-1.055,0.001-1.91,0.856-1.911,1.911c0,0.24,0.045,0.477,0.133,0.7l-2.136,1.233L144.296,192.35z M153.235,187.564l-9.973,5.759
		l-0.521-0.903l9.973-5.759L153.235,187.564z" />
    </g>
  </svg>
</template>
<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: ''
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
      iconColor: {
        type: String,
        default: 'currentColor'
      }
    }
  }

</script>

<style scoped>
  svg {
    display: inline-block;
    vertical-align: middle;
  }

</style>
