import VectorSource from 'ol/source/vector';
import wfsAxiosApi from '../../axios-wms-conf/axiosWFSConf'
import spatialApi from './spatialInfoLayers';
import mapApi from '../mapsInit';
// Features
import Feature from 'ol/feature';
// Point
import Point from 'ol/geom/point';
// Styling Point
import Style from 'ol/style/style';
import Icon from 'ol/style/icon';
// set the projections
import proj from 'ol/proj';
import proj4 from 'proj4';

proj.setProj4(proj4);
proj4.defs("EPSG:5048", "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
let proj5048_ = proj.get("EPSG:5048");
// spatial configuration
import spatialConfiguration from './spatial-config';
let Videos_API = {
  addLocations: addLocations,
};
let VIDEOSFeatureSize;

async function addLocations(map) {
  let VideoVectorLayerSource = new VectorSource({});
  let videosInstance = new spatialApi.videoInitializer(map, wfsAxiosApi.wfsCreateWFSVideoSpatilInfo);
  let spatielGroup = mapApi.getPlannerGroup(map, spatialConfiguration.groupName);
  let videoVector = getVideoVector(spatielGroup, spatialConfiguration.VIDEO_NAME);
  videoVector.setZIndex(2);
  let videosPoints = await videosInstance.getSourceVectorObjects();
  await settingVideoFeatures(videosPoints, VideoVectorLayerSource);
  videoVector.setSource(VideoVectorLayerSource);
  videoVector.setStyle(function (feature) {
    let styleCache = {};
    let featuresSize = VIDEOSFeatureSize;
    let style = styleCache[featuresSize];

    if (!style) {
      style = new Style({
        image: new Icon({
          src: 'https://meriopas.ymparisto.fi/meriopas/static/videosIcons/video_map.png',
          size: [54, 72],
          anchor: [0.5, 1],
          scale: 0.5,
        })
      })
      styleCache[featuresSize] = style
    }
    return style;
  })
}

function getVideoVector(collection, name) {
  let vector;
  collection.forEach((layer) => {
    if (layer.get("name") === name) {
      vector = layer;
    }
  })
  return vector;
}

async function settingVideoFeatures(locations, source) {
  let features = [];
  for (let i = 0; i < locations.length; ++i) {
    features[i] = new Feature({
      geometry: new Point(proj.transform([locations[i].geometry.coordinates[0], locations[i].geometry.coordinates[1]], proj5048_, 'EPSG:3857')),
      type: 'Videos',
      youTubeLink: locations[i].properties.Youtube,
      KohteenNim: locations[i].properties.KohteenNim,
      Videon_ID: locations[i].properties.Videon_ID
    });
    features[i].set('id', i);
  }
  source.clear();
  source.addFeatures(features);
  VIDEOSFeatureSize = features.length;
}

export default Videos_API;
