import baseMapsConfig from './basemap-layers.configs/config'

import TileLayer from 'ol/layer/tile';
import OSM from 'ol/source/osm';
import TileWMS from 'ol/source/tilewms';
import extent from 'ol/extent';
import proj from 'ol/proj';
import proj4 from 'proj4';
import WMTS_Source from 'ol/source/wmts';
import WMTSTILE from 'ol/tilegrid/wmts';

proj.setProj4(proj4);
proj4.defs("EPSG:3067", "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

let proj3067_ = proj.get("EPSG:3067");
proj3067_.setExtent([-3669433.90, 4601644.86, 642319.78, 9362767.00]);


var projection = proj.get('EPSG:3857');
// console.log(' 3857 projection >>> ',projection);
var projectionExtent = projection.getExtent();
var size = extent.getWidth(projectionExtent) / 256;
var resolutions = new Array(18);
var matrixIds = new Array(18);
for (var z = 0; z < 18; ++z) {
  // generate resolutions and matrixIds arrays for this WMTS
  resolutions[z] = size / Math.pow(2, z);
  matrixIds[z] = z;
}



let ortokuvaProjection = proj3067_;
let ortokuvaProjectionExtent = ortokuvaProjection.getExtent();
let ortokuvaSize = extent.getWidth(ortokuvaProjectionExtent) / 256;

let ortokuvaResolutions = new Array(18);
let ortokuvaMatrixIds = new Array(18)

for (let z = 0; z < 18; z++) {
  ortokuvaResolutions[z] = ortokuvaSize / Math.pow(2, z);
  ortokuvaMatrixIds[z] = z;
}

let baseMapLayers = [

  new TileLayer({
    id: 0,
    name: baseMapsConfig.plainMapName,
    finnishName: baseMapsConfig.plainMapName,
    default: false,
    visible: false,
    source: new OSM({
      attributions: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors. <a href="https://www.openstreetmap.org/fixthemap">https://www.openstreetmap.org/fixthemap</a>'
    }),      
    type: 'base'
  }),

  new TileLayer({
    id: 1,
    name: baseMapsConfig.seaMapName,
    finnishName: baseMapsConfig.seaMapName,
    visible: false,
    default: false,
    source: new TileWMS({
      url: baseMapsConfig.seaMapURL,
      params: {
        'VERSION': '1.3.0',
        'LAYERS': "cells",
        'CRS': 'CRS:84',
        'TILED': true,

      },
      crossOrigin: 'anonymous',
      transparent: 'true',
      attributions: '© Liikenne- ja viestintävirasto Traficom',
    }),
    type: 'base'
  }),

  new TileLayer({
    id: 2,
    name: baseMapsConfig.backgroundMapName,
    finnishName: baseMapsConfig.backgroundMapName,
    visible: true,
    projection: projection,
    default: true,
    source: new WMTS_Source({
      url: baseMapsConfig.backgroundMapURL,
      matrixSet: 'WGS84_Pseudo-Mercator',
      format: 'image/png',
      layer: 'taustakartta',
      crossOrigin: 'anonymous',
      tileGrid: new WMTSTILE({
        origin: extent.getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds
      }),
      style: 'default',
      transparent: 'true',
      attributions: '© Maanmittauslaitos',
    }),
    type: 'base'
  }),

  new TileLayer({
    id: 3,
    name: baseMapsConfig.topographicMapName,
    finnishName: baseMapsConfig.topographicMapName,
    visible: false,
    default: false,
    projection: projection,
    source: new WMTS_Source({
      url: baseMapsConfig.topographicMapURL,
      matrixSet: 'WGS84_Pseudo-Mercator',
      format: 'image/png',
      layer: 'maastokartta',
      crossOrigin: 'anonymous',
      tileGrid: new WMTSTILE({
        origin: extent.getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds
      }),
      style: 'default',
      attributions: '© Maanmittauslaitos',
    }),
    type: 'base'
  }),

  new TileLayer({
    id: 4,
    finnishName: baseMapsConfig.depthModelMapName,
    name: baseMapsConfig.depthModelMapName,
    dataType: 'GeoJSON',
    visible: false,
    default: false,
    layerLegendURL: baseMapsConfig.depthModelMapLegendURL,
    source: new TileWMS({
      url: baseMapsConfig.depthModelMapURL,
      params: {
        'TILED': true,
        'LAYERS': '1'
      },
      transparent: 'true',
      projection: "EPSG:3857",
      crossOrigin: 'anonymous',
      attributions: '© Suomen ympäristökeskus',
    }),
    type: 'base'
  }),

  new TileLayer({
    id: 5,
    name: baseMapsConfig.aerialMapName,
    finnishName: baseMapsConfig.aerialMapName,
    visible: false,
    default: false,
    projection: ortokuvaProjection,
    source: new WMTS_Source({
      url: baseMapsConfig.aerialMapURL,
      matrixSet: 'ETRS-TM35FIN',
      format: 'image/jpeg',
      layer: 'ortokuva',
      tileGrid: new WMTSTILE({
        origin: extent.getTopLeft(ortokuvaProjectionExtent),
        resolutions: ortokuvaResolutions,
        matrixIds: ortokuvaMatrixIds
      }),
      style: 'default',
      crossOrigin: 'anonymous'
    }),
    type: 'base',
    finProjection: baseMapsConfig.projectionFin
  }),

  new TileLayer({
    id: 6,
    name: baseMapsConfig.hillShadeMap,
    finnishName: baseMapsConfig.hillShadeMap,
    visible: false,
    default: false,
    projection: ortokuvaProjection,
    source: new WMTS_Source({
      url: baseMapsConfig.hillSahedMapURL,
      matrixSet: 'ETRS-TM35FIN',
      format: 'image/png',
      layer: 'korkeusmalli_vinovalo',
      tileGrid: new WMTSTILE({
        origin: extent.getTopLeft(ortokuvaProjectionExtent),
        resolutions: ortokuvaResolutions,
        matrixIds: ortokuvaMatrixIds
      }),
      style: 'default',
      crossOrigin: 'anonymous'
    }),
    type: 'base',
    finProjection: baseMapsConfig.projectionFin
  }),

];

export default baseMapLayers;
