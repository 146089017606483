// only constants should be changed in order to provide different names

const WRECKS_NAME = "wrecks";
//const WRECKS_NAME_AHV = "wrecks_ahv";
const AIS_NAME = "ships";
const POI_NAME = "placesOfInterest";

const VIDEO_NAME = "videos";
const PICTURE_NAME = "pictures";

const SPATIAL_GROUP_NAME = "Spatial info";
const NATIONAL_PARKS_NAME = "nationalParks";
const SEAL_Protected_AreaS_NAME = "sealProtectedAreas";
const NAVIGATIIONAL_RESTRICTIONS_NAME = "navigationalRestrictions";
const REED_AREAS_NAME = "reedAreas";

const ALGAEBLOOM_NAME = "algaeBloom";
const SWIMMINGINFO_NAME = "swimmingInfo";
const BOATINGINFO_NAME = "boatingInfo";
const ALGAINFO_NAME = "algaInfo";

let spatialConfiguration = {
  wrecksName: WRECKS_NAME,
  //wrecksName_AHV: WRECKS_NAME_AHV,
  AISName: AIS_NAME,
  poiName: POI_NAME,
  NATIONAL_PARKS_NAME,
  SEAL_Protected_AreaS_NAME,
  NAVIGATIIONAL_RESTRICTIONS_NAME,
  REED_AREAS_NAME,
  VIDEO_NAME,
  PICTURE_NAME,
  ALGAEBLOOM_NAME,
  SWIMMINGINFO_NAME,
  BOATINGINFO_NAME,
  groupName: SPATIAL_GROUP_NAME,
  ALGAINFO_NAME,
};


export default spatialConfiguration;
