<template>
  <div class="buttons-container">
    <control-buttons v-if="this.map" v-bind:map="map">
    </control-buttons>
    <control-buttons-bottom v-if="this.map" v-bind:map="map">
    </control-buttons-bottom>
  </div>
</template>

<script>
  import ControlButtons from './ControlButtons.vue';
  import ControlButtonsBottom from './ControlButtonsBottom.vue';
  import ControlMenuButton from './ControlMenuButton.vue';

  export default {

    props: ['map'],

    components: {
      ControlButtons,
      ControlButtonsBottom
    }

  }

</script>

<style scoped lang="scss">
  .buttons-container {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

</style>
