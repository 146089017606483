/**
 *
 * @description Layers for spatial info : parent class
 *
 * @class SpatialLayer
 */

let g_algae_data = 'null';

class SpatialLayer {
  constructor(mapInstance) {
    this.mapInstance = mapInstance;
  }
}

class wrecksSpatialLayer extends SpatialLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVectorObjects() {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig;
      axios
        .get()
        .then((data) => {
          resolve(data.data.features)
        })
    })
  }
}

class videoSpatialLayer extends SpatialLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVectorObjects() {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig;
      axios
        .get()
        .then((data) => {
          resolve(data.data.features)
        })
    })
  }
}

class ExtendedSpatialLayer extends SpatialLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVectorObjects() {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig;
      axios
        .get()
        .then((data) => {
          resolve(data.data)
        })
    })
  }
}

class AISSpatialLayer extends SpatialLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVector(url, from) {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig(url, from);
      axios
        .get()
        .then((data) => {
          resolve(data.data.features);
        })
    })
  }

  getAllAISMetadata(url, from, metadataAxiosConf) {
    return new Promise((resolve, reject) => {
      let axios = metadataAxiosConf(url, from);
      axios
        .get()
        .then((data) => {
          resolve(data.data);
        })
    })
  }
}

class AISSingleInfo extends SpatialLayer {
  constructor(mapInstance, axiosConfig, MMSI) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
    this.MMSI = MMSI;
  }

  getSingleShipInfo(url, MMSI) {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig(url, MMSI);
      axios
        .get()
        .then((data) => {
          resolve(data.data);
        })
    })
  }
}

class POIInfo extends SpatialLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVector(url, poi_bbox) {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig(url, poi_bbox);
      axios
        .get()
        .then((data) => {
          resolve(data.data.geonames);
        })
    })
  }
}

class AlgaInfo extends SpatialLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVector(startDate) {
    if (g_algae_data === 'null') {
      console.log("getSuorceVector start - get algae_data")
      return new Promise((resolve, reject) => {
        let axios = this.axiosConfig(startDate);
        axios
          .get()
          .then((data) => {
            // global var
            g_algae_data = data.data
            resolve(g_algae_data);
          })
      })
    }
    else {
      console.log("getSuorceVector loaded - get algae_data")
      return g_algae_data;
    }
  }
}

let spatialApi = {
  wrecksInitializer: wrecksSpatialLayer,
  videoInitializer: videoSpatialLayer,
  AISSpatialLayer: AISSpatialLayer,
  AISSingleInfo: AISSingleInfo,
  POIInfo: POIInfo,
  AlgaInfo: AlgaInfo,
  initializer: ExtendedSpatialLayer
}


export default spatialApi;
