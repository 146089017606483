let convert = require('xml-js');
/**
 *
 * @description Weather Info layer - parent layer
 * @class WeatherLayer
 */
class WeatherLayer {
  constructor(mapInstance) {
    this.mapInstance = mapInstance;
  }
}

// Observations and forecasts layer
class forecastlLayer extends WeatherLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVectorObjects() {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig;
      axios
        .get()
        .then((data) => {
          let convertWFS = convert.xml2json(data.data, {
            compact: true,
            ignoreDeclaration: true
          });
          resolve(convertWFS)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}


class WaveForecastLayer extends WeatherLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVectorObjects() {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig;
      axios
        .get()
        .then((data) => {
          let convertWFS = convert.xml2json(data.data, {
            compact: true,
            ignoreDeclaration: true
          });
          resolve(convertWFS)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

class TemperatureForecastLayer extends WeatherLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVectorObjects() {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig;
      axios
        .get()
        .then((data) => {
          let convertWFS = convert.xml2json(data.data, {
            compact: true,
            ignoreDeclaration: true
          });
          resolve(convertWFS)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

class WaterHeightForecastLayer extends WeatherLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVectorObjects() {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig;
      axios
        .get()
        .then((data) => {
          let convertWFS = convert.xml2json(data.data, {
            compact: true,
            ignoreDeclaration: true
          });
          resolve(convertWFS)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

class WaterTemperatureForecastLayer extends WeatherLayer {
  constructor(mapInstance, axiosConfig) {
    super(mapInstance);
    this.axiosConfig = axiosConfig;
  }

  getSourceVectorObjects() {
    return new Promise((resolve, reject) => {
      let axios = this.axiosConfig;
      axios
        .get()
        .then((data) => {
          let convertWFS = convert.xml2json(data.data, {
            compact: true,
            ignoreDeclaration: true
          });
          resolve(convertWFS)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

let weatherApi = {
  forecastlLayer: forecastlLayer,
  WaveForecastLayer: WaveForecastLayer,
  TemperatureForecastLayer: TemperatureForecastLayer,  
  WaterHeightForecastLayer: WaterHeightForecastLayer,
  WaterTemperatureForecastLayer: WaterTemperatureForecastLayer
}

export default weatherApi;
