import satelliteLayersConfig from '../../../maps-constructor/satellite-layers.configs/config';

let layersMenu = [
  /*{
    name: satelliteLayersConfig.satelliteConfigs.satellites,
    id: 0,
    selected: false,
    icon: "/static/satelliteIcons/True_color_pictures.png",
    type: satelliteLayersConfig.satelliteConfigs.type
  },*/
  {
    name: satelliteLayersConfig.landsatConfigs.satellites,
    id: 0,
    selected: false,
    icon: "https://meriopas.ymparisto.fi/meriopas/static/satelliteIcons/Landsat-8.jpg",
    type: satelliteLayersConfig.landsatConfigs.type
  },
  {
    name: satelliteLayersConfig.sentinel2Configs.satellites,
    id: 1,
    selected: false,
    icon: "https://meriopas.ymparisto.fi/meriopas/static/satelliteIcons/Sentinel-2.jpg",
    type: satelliteLayersConfig.sentinel2Configs.type
  },
  {
    name: satelliteLayersConfig.sentinel3Configs.satellites,
    id: 2,
    selected: false,
    icon: "https://meriopas.ymparisto.fi/meriopas/static/satelliteIcons/Sentinel-3.png",
    type: satelliteLayersConfig.sentinel3Configs.type
  },
  {
    name: satelliteLayersConfig.waterSurfaceConfigs.surfaceTemperature,
    id: 3,
    selected: false,
    icon: "https://meriopas.ymparisto.fi/meriopas/static/satelliteIcons/Surface_temperature.png",
    type: satelliteLayersConfig.waterSurfaceConfigs.type
  },
  {
    name: satelliteLayersConfig.waterSurfaceConfigs.algalBlooms,
    id: 4,
    selected: false,
    icon: "https://meriopas.ymparisto.fi/meriopas/static/satelliteIcons/Algal_blooms.png",
    type: satelliteLayersConfig.waterSurfaceConfigs.type
  },
  /*{
    name: satelliteLayersConfig.waterSurfaceConfigs.chlrophyll,
    id: 6,
    selected: false,
    icon: "/static/satelliteIcons/Chlrophyll-a.png",
    type: satelliteLayersConfig.waterSurfaceConfigs.type
  },*/
  {
    name: satelliteLayersConfig.waterQualityConfigs.turbidity,
    id: 5,
    selected: false,
    icon: "https://meriopas.ymparisto.fi/meriopas/static/satelliteIcons/Turbidity.png",
    type: satelliteLayersConfig.waterQualityConfigs.type
  },
  /*{
    name: satelliteLayersConfig.waterQualityConfigs.cdom,
    id: 8,
    selected: false,
    icon: "/static/satelliteIcons/CDOM.png",
    type: satelliteLayersConfig.waterQualityConfigs.type
  },
  {
    name: satelliteLayersConfig.waterQualityConfigs.secchiDepth,
    id: 9,
    selected: false,
    icon: "/static/satelliteIcons/Secchi_depth.png",
    type: satelliteLayersConfig.waterQualityConfigs.type
  },*/

]

export default {
  layersMenu
};
