import Polygon from 'ol/geom/polygon';
import LineString from 'ol/geom/linestring';
import Draw from 'ol/interaction/draw';
import Sphere from 'ol/sphere';
// for styling vector layer
import Stroke from 'ol/style/stroke';
import Fill from 'ol/style/fill';
import Text from 'ol/style/text';
import Circle from 'ol/style/circle';
import Observable from 'ol/observable';
// general vector sty;ing
import Style from 'ol/style/style';
import mapApi from './mapsInit';
import Overlay from 'ol/overlay';

var sketch;
var helpTooltipElement;
var helpTooltip;
var measureTooltipElement;
var measureTooltip;
var continuePolygonMsg = 'Click to continue drawing the polygon';
var continueLineMsg = 'Click to continue drawing the line';
var draw; // global so we can remove it later
var helpMsg;
let coordinatesForHelpTools;
let drawnFeatures = [];

// handle mouse pointer events
function pointerMoveHandler(evt) {
  if (evt.dragging) {
    return;
  }
  /** @type {string} */
  helpMsg = 'Click to start drawing';

  if (sketch) {
    var geom = (sketch.getGeometry());
    if (geom instanceof Polygon) {
      helpMsg = continuePolygonMsg;
    } else if (geom instanceof LineString) {
      helpMsg = continueLineMsg;
    }
  }
  coordinatesForHelpTools = evt.coordinate;
}

function addInteraction(map, drawingVectorSource, typeOfLine) {
  createMeasureTooltip(map);
  createHelpTooltip(map);
  helpTooltipElement.innerHTML = helpMsg;
  var type = typeOfLine;

  draw = new Draw({
    source: drawingVectorSource,
    type: /** @type {ol.geom.GeometryType} */ (type),
    style: new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2
      }),
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.7)'
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        })
      })
    })
  });
  map.addInteraction(draw);

  var listener;
  draw.on('drawstart',
    function (evt) {
      // set sketch
      sketch = evt.feature;
      var tooltipCoord = evt.coordinate;

      listener = sketch.getGeometry().on('change', function (evt) {
        var geom = evt.target;
        var output;
        if (geom instanceof Polygon) {
          output = formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();

        }
        let nauticalMilesValue = output.nauticalMiles ? output.nauticalMiles : "";
        measureTooltipElement.innerHTML = output.simple + "</br>" + nauticalMilesValue;
        measureTooltip.setPosition(tooltipCoord);
      });
    }, this);

  draw.on('drawend',
    function (evt) {
      let coordsOfFeature;
      let featureDraw;
      measureTooltipElement.className = 'tooltip tooltip-static';
      let deleteButton = document.createElement('button');
      deleteButton.addEventListener('click', (e) => {
        mapApi.deleteMeasureOverlay(measureTooltipElement, map, featureDraw);
        let element = e.target.parentNode;
        element.parentNode.removeChild(element);
      })
      deleteButton.className = 'tooltip-static--delete';
      measureTooltipElement.appendChild(deleteButton);
      measureTooltip.setOffset([0, -7]);
      // unset sketch
      sketch = null;
      // unset tooltip so that a new one can be created
      measureTooltipElement = null;
      createMeasureTooltip(map);
      Observable.unByKey(listener);
      drawnFeatures.push(evt.feature);
      coordsOfFeature = evt.feature.getGeometry().getCoordinates();
      evt.feature.setId(new Date().getTime());
      featureDraw = evt.feature;
      map.removeInteraction(draw);
    }, this
  );
  return draw;
}

function formatLength(line) {
  var length = Sphere.getLength(line);
  var output = {};
  if (length > 100) {
    output.simple = (Math.round(length / 1000 * 100) / 100) +
      ' ' + 'km';
    output.nauticalMiles = Math.round((length / 1000 * 100) / 100 / 1.852 * 100) / 100 + ' NM';
  } else {
    output.simple = (Math.round(length * 100) / 100) +
      ' ' + 'm';
  }
  return output;
}

function formatArea(polygon) {
  var area = Sphere.getArea(polygon);
  var output = {};
  if (area > 10000) {
    output.simple = (Math.round(area / 1000000 * 100) / 100) +
      ' ' + 'km<sup>2</sup>';
  } else {
    output.simple = (Math.round(area * 100) / 100) +
      ' ' + 'm<sup>2</sup>';
  }
  return output;
}


function createHelpTooltip(map) {
  if (helpTooltipElement) {
    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
  }
  helpTooltipElement = document.createElement('div');
  helpTooltipElement.className = 'tooltip hidden';
  helpTooltip = new Overlay({
    element: helpTooltipElement,
    offset: [15, 0],
    positioning: 'center-left'
  });
  map.addOverlay(helpTooltip);
}


function createMeasureTooltip(map) {
  measureTooltipElement = undefined;
  if (measureTooltipElement && measureTooltipElement !== null) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'tooltip tooltip-measure';
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center'
  });
  map.addOverlay(measureTooltip);
}

function checkInteraction(map) {
  let interactionState = false;
  map.getInteractions().forEach(function (interaction) {
    if (interaction instanceof Draw) {
      interactionState = true
    }
  })
  return interactionState;
}


let drawingToolsBehaviorApi = {
  addInteraction: addInteraction,
  pointerMoveHandler: pointerMoveHandler,
  checkInteraction: checkInteraction
}

export default drawingToolsBehaviorApi;
