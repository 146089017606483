import spatialConfiguration from '../../../maps-constructor/spatial-constructor/spatial-config';
import keyStoneSpeciesConfigs from '../../../maps-constructor/keystoneSpecies-layers.configs/configs';
// sublayer menu for
let sublayersMenu = [
  {
    name: spatialConfiguration.NATIONAL_PARKS_NAME,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 0,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/kansallispuisto.svg'
  },
  {
    name: spatialConfiguration.NAVIGATIIONAL_RESTRICTIONS_NAME,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 1,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/laiva.svg'
  },
  {
    name: spatialConfiguration.wrecksName,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 2,
    type: "Vector",
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/shipsPoiWrecksPictures/Wrecks_menu.svg'
  },
  // {
  //   name: spatialConfiguration.VIDEO_NAME,
  //   containsSubmenu: false,
  //   content: [],
  //   selected: false,
  //   id: 4,
  //   type: "Vector",
  //   icon: '/static/videosIcons/video_menu.png'
  // },
  {
    name: spatialConfiguration.PICTURE_NAME,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 3,
    type: "Vector",
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/picturesIcons/picture_menu.png'
  },
  {
    name: spatialConfiguration.AISName,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 4,
    type: "Vector",
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/shipsPoiWrecksPictures/Ship_for_menu.svg'
  },
  {
    name: spatialConfiguration.REED_AREAS_NAME,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 5,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/ruovikko.svg'
  },
  {
    name: spatialConfiguration.ALGAEBLOOM_NAME,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 6,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/swimming_menu.png'
  },
  {
    name: spatialConfiguration.SWIMMINGINFO_NAME,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 7,
    type: "Vector",
    hiddenFromMenu: true,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/swimming_menu.png'
  },
  {
    name: spatialConfiguration.BOATINGINFO_NAME,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 8,
    type: "Vector",
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/anchor_menu.png'
  },
  {
    name: spatialConfiguration.poiName,
    containsSubmenu: false,
    content: [],
    selected: false,
    id: 9,
    type: "Vector",
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/shipsPoiWrecksPictures/POI_menu.svg'
  },
  {
    name: keyStoneSpeciesConfigs.keyStoneGroupName,
    containsSubmenu: true,
    visibleSubmenu: false,
    selectedLayersAmount: 0,
    id: 10,
    submenu: [{
      complex: true,
      content: []
    }]
  },
  {
    name: spatialConfiguration.SEAL_Protected_AreaS_NAME,
    containsSubmenu: false,
    content: [],
    selected: false,
    hiddenFromMenu: true,
    id: 11,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/kansallispuisto.svg'
  },
  // {
  //   name: spatialConfiguration.wrecksName,
  //   containsSubmenu: false,
  //   content: [],
  //   selected: false,
  //   hiddenFromMenu: true,
  //   id: 12,
  //   type: "Vector",
  //   icon: 'https://meriopas.ymparisto.fi/meriopas/static/shipsPoiWrecksPictures/Wrecks_menu.svg'
  // },
];

let allSelectedSublayers = {
  keyStoneSpecies: 0,
  other: 0
};

let keystoneSpeciesInfo = [{
  id: 0,
  systemName: keyStoneSpeciesConfigs.mytilus,
  systemText: keyStoneSpeciesConfigs.mytilus + 'Details',
  systemURL: keyStoneSpeciesConfigs.mytilus + "Wiki",
  pictureUrl: "https://upload.wikimedia.org/wikipedia/commons/0/0c/Miesmuscheln_Mytilus_1.jpg"
},
{
  id: 1,
  systemName: keyStoneSpeciesConfigs.perfoliate,
  systemText: keyStoneSpeciesConfigs.perfoliate + "Details",
  systemURL: keyStoneSpeciesConfigs.perfoliate + "Wiki",
  pictureUrl: "https://upload.wikimedia.org/wikipedia/commons/3/3f/PotamogetonPerfoliatus.jpg"

},
{
  id: 2,
  systemName: keyStoneSpeciesConfigs.fucus,
  systemText: keyStoneSpeciesConfigs.fucus + "Details",
  systemURL: keyStoneSpeciesConfigs.fucus + "Wiki",
  pictureUrl: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Fucus_vesiculosus.JPG",
},
{
  id: 3,
  systemName: keyStoneSpeciesConfigs.zostera,
  systemText: keyStoneSpeciesConfigs.zostera + "Details",
  systemURL: keyStoneSpeciesConfigs.zostera + "Wiki",
  pictureUrl: "https://upload.wikimedia.org/wikipedia/commons/8/88/Zostera_marina_nf_clean.jpg",
},
{
  id: 4,
  systemName: keyStoneSpeciesConfigs.potamogeton,
  systemText: keyStoneSpeciesConfigs.potamogeton + "Details",
  systemURL: keyStoneSpeciesConfigs.potamogeton + "Wiki",
  pictureUrl: "https://upload.wikimedia.org/wikipedia/commons/3/3f/PotamogetonPerfoliatus.jpg",

},
{
  id: 5,
  systemName: keyStoneSpeciesConfigs.ceratophyllum,
  systemText: keyStoneSpeciesConfigs.ceratophyllum + "Details",
  systemURL: keyStoneSpeciesConfigs.ceratophyllum + "Wiki",
  pictureUrl: "https://upload.wikimedia.org/wikipedia/commons/1/1f/Ceratophyllum_demersum_240907.jpg",
},
{
  id: 6,
  systemName: keyStoneSpeciesConfigs.myriophyllum,
  systemText: keyStoneSpeciesConfigs.myriophyllum + "Details",
  systemURL: keyStoneSpeciesConfigs.myriophyllum + "Wiki",
  pictureUrl: "https://upload.wikimedia.org/wikipedia/commons/6/68/Illustration_Myriophyllum_spicatum0.jpg",
},
];

function closeCalendar(calendar) {
  let closedCalendar = {};
  closedCalendar = JSON.parse(JSON.stringify(calendar));
  closedCalendar.visible = false;
  return closeCalendar;
}


function submenuClosingOpening(submenuItem, submenuElement) {
  let submenuElementHeight =
    Array.isArray(submenuItem.submenu[0].content) ?
      submenuItem.submenu[0].content.length * 35 :
      submenuItem.submenu.length * 35;

  submenuElement.style.height = !submenuItem.visibleSubmenu ?
    0 + 'px' :
    submenuElementHeight + 'px';
}

function setKeyStoneImage(keyStoneLayers) {
  keyStoneLayers.map((layer) => {
    switch (layer.name) {
      case keyStoneSpeciesConfigs.mytilus:
        var alayer = 'velmupohjaelainhavainnot:Sinisimpukka_Mytilus_trossulus'
        layer.IconSource = 'https://paikkatiedot.ymparisto.fi/geoserver/velmupohjaelainhavainnot/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&transparent=true&LAYER=' + alayer;
        break;
      case keyStoneSpeciesConfigs.perfoliate:
        var alayer = 'velmulajidata:Potamogeton_perfoliatus'
        layer.IconSource = 'https://paikkatiedot.ymparisto.fi/geoserver/velmulajidata/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&transparent=true&LAYER=' + alayer;
        break;
      case keyStoneSpeciesConfigs.fucus:
        var alayer = 'velmulajidata:Fucus_spp'
        layer.IconSource = 'https://paikkatiedot.ymparisto.fi/geoserver/velmulajidata/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&transparent=true&LAYER=' + alayer;
        break;
      case keyStoneSpeciesConfigs.zostera:
        var alayer = 'velmulajidata:Zostera_marina'
        layer.IconSource = 'https://paikkatiedot.ymparisto.fi/geoserver/velmulajidata/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&transparent=true&LAYER=' + alayer;
        break;
      case keyStoneSpeciesConfigs.potamogeton:
        var alayer = 'velmulajidata:Stuckenia_pectinata'
        layer.IconSource = 'https://paikkatiedot.ymparisto.fi/geoserver/velmulajidata/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&transparent=true&LAYER=' + alayer;
        break;
      case keyStoneSpeciesConfigs.ceratophyllum:
        var alayer = 'velmulajidata:Ceratophyllum_demersum'
        layer.IconSource = 'https://paikkatiedot.ymparisto.fi/geoserver/velmulajidata/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&transparent=true&LAYER=' + alayer;
        break;
      case keyStoneSpeciesConfigs.myriophyllum:
        var alayer = 'velmulajidata:Myriophyllum_spp'
        layer.IconSource = 'https://paikkatiedot.ymparisto.fi/geoserver/velmulajidata/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&transparent=true&LAYER=' + alayer;
        break;
    }
  })
}

let menuLayerUtils = {
  sublayersMenu: sublayersMenu,
  allSelectedSublayers: allSelectedSublayers,
  closeCalendar: closeCalendar,
  submenuClosingOpening: submenuClosingOpening,
  keystoneSpeciesInfo: keystoneSpeciesInfo,
  setKeyStoneImage: setKeyStoneImage
};

export default menuLayerUtils;
