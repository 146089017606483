var moment = require('moment');
// imports for creating Vectors layers and clusters
//import Vector from 'ol/layer/vector';
import VectorSource from 'ol/source/vector';
import SourceCluster from 'ol/source/cluster';
import wfsAxiosApi from '../../axios-wms-conf/axiosWFSConf';
import weatherApi from './weatherInfoLayers';
// weather configuration details
import weatherConfigs from './weather-config';
import mapApi from '../mapsInit';
// Features
import Feature from 'ol/feature';
// Point
import Point from 'ol/geom/point';
// Styling Point
import Style from 'ol/style/style';
import Icon from 'ol/style/icon';
// set the projections
import proj from 'ol/proj';
// styling Feature and Clusters
import Stroke from 'ol/style/stroke';
import Fill from 'ol/style/fill';
import Text from 'ol/style/text';
import Circle from 'ol/style/circle';

// AIS API
// water temperatures: getTemperaturefeatures: settingTemperatureFeatures and settingWaterTemperatureFeatures
// change: 21.5.2021
let windWaveAPI = {
  getWindsFeatures: getWindsFeatures,
  getWavesFeatures: getWavesFeatures,
  getTemperaturefeatures: getTemperaturefeatures,
  //getWaterHeightTempFeatures_pois: getWaterHeightTempFeatures_pois,
  getWaterHeightFeatures: getWaterHeightFeatures,
  getWaterTemperaturefeatures: getWaterTemperaturefeatures
};

// winds feature size
let windsFeatureSize;
let temperaturesFeatureSize;
let wavesFeatureSize;
let waterHeightFeatureSize;

/********************************************/
/*    Create weather - wind source layer    */
/********************************************/
async function getWindsFeatures(map) {
  let windVectorSource = new VectorSource({});
  let forecatsInstance = new weatherApi.forecastlLayer(map, wfsAxiosApi.wfsCreateWFSWeatherINfo(true));
  let weatherGroup = mapApi.getPlannerGroup(map, weatherConfigs.weatherGroupName);
  let weathers = await forecatsInstance.getSourceVectorObjects(map, wfsAxiosApi.wfsCreateWFSWeatherINfo(true));
  let windsCollection = JSON.parse(weathers);
  console.log(" getWindFeatures to map")
  let GridSeriesObservation = windsCollection["wfs:FeatureCollection"]["wfs:member"]["omso:GridSeriesObservation"];
  await settingWindsFeatures(GridSeriesObservation, windVectorSource);
  // let clusterSource = new SourceCluster({
  // 	distance 	: 50,
  // 	source		: windVectorSource
  // })
  let vector = getWeatherVector(weatherGroup, weatherConfigs.weatherWindName);
  vector.setZIndex(2);
  let styleCache = {};
  let textStyleCache = {};
  let providedStyle = [];
  // vector.setSource(clusterSource);
  vector.setSource(windVectorSource);
  vector.setStyle(function (feature) {
    let featuresSize = windsFeatureSize;
    let style = styleCache[feature.getProperties().id];
    let textStyle = textStyleCache[feature.getProperties().id];

    if (style && textStyle) return [style, textStyle]
    else {
      let svg = `<svg
			width="50" height="50"
			xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 629.5 627.5">
			<defs>
				<style>
					.cls-1{fill:#ff931e;}.cls-2{font-size:200px;font-family:SFUIText-Bold, SF UI Text;font-weight:700;}
				</style>
			</defs>
				<title>
					Asset 1
				</title>
				<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
				<circle class="cls-1" cx="173" cy="454.5" r="169.5"/>
				<path d="M550.22,33.28l31.88,8.46L293.48,330.36a172.54,172.54,0,1,0,5.62,5.7L587.76,47.4l8.45,31.87L629.5,0ZM339,454.5A166,166,0,0,1,55.62,571.88,166,166,0,0,1,290.38,337.12,164.92,164.92,0,0,1,339,454.5Z"/>
				<text class="cls-2" transform="translate(115.07 528.46)">
				
				</text>
				</g>
				</g>
			</svg>`;
      let windDirection = parseFloat(feature.getProperties().windDirection);
      let radianWindDirection = !isNaN(windDirection) ? windDirection * (Math.PI / 180) : 0;
      // style = new Style({
      function windIcon(speed) {
        if (speed < 7) {
          return 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wind_3.0_g.png'; // 'punainen_alas.svg';
        } else if (speed < 14) {
          return 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wind_3.0_y.png';
        } else {
          return 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wind_3.0_r.png';
        }
      }
      styleCache[feature.getProperties().id] = new Style({
        image: new Icon({
          src: radianWindDirection !== 0 ? windIcon(parseFloat(feature.getProperties().windSpeed)) : 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/temp.png',
          rotation: radianWindDirection,
        }),

      });

      textStyleCache[feature.getProperties().id] = new Style({
        text: new Text({
          font: !isNaN(feature.getProperties().windSpeed) ? ' bold 14px Calibri' : ' bold 12px Calibri',
          overflow: false,
          textAlign: "center",
          text: !isNaN(feature.getProperties().windSpeed) ? feature.getProperties().windSpeed : ' ' + Math.round(feature.getProperties().temperature) + '°',

        })
      })
      // Set z-index so the icon text won't overlap
      textStyleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);
      styleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);

      return [textStyleCache[feature.getProperties().id], styleCache[feature.getProperties().id]]
    }
  });
}


async function settingWindsFeatures(data, source) {
  let features = [];
  let featureId = 0;
  let pointMember = data["om:featureOfInterest"]["sams:SF_SpatialSamplingFeature"]["sams:shape"]["gml:MultiPoint"]['gml:pointMember'];
  let positions = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:domainSet"]["gmlcov:SimpleMultiPoint"]["gmlcov:positions"];
  positions = positions._text.trim().split('\n').reverse();
  let doubleOrNilReasonTupleList = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:rangeSet"]["gml:DataBlock"]["gml:doubleOrNilReasonTupleList"]
  doubleOrNilReasonTupleList = doubleOrNilReasonTupleList._text.trim().split('\n').reverse();

  for (const point of pointMember) {
    let coords
    let place
    let windTime;
    let windDirection;
    let windSpeed;
    let windGust;
    let temperature;

    // Save coords and place name
    coords = point["gml:Point"]["gml:pos"]._text.split(" ", 2);
    place = point["gml:Point"]["gml:name"]._text;

    // find matching time and value pairs for this station
    for (const key in positions) {
      if (positions.hasOwnProperty(key)) {
        let position = positions[key].trim().split(' ');
        let values = doubleOrNilReasonTupleList[key].trim().split(' ');
        if (coords[0] === position[0] && coords[1] === position[1]) { // station found
          if ((values[0] !== 'NaN' && values[1] !== 'NaN') || values[3] !== 'NaN') { // wind or temperature available
            windTime = moment.unix(position[3]).format();
            windDirection = Math.round(values[0]).toString();
            windSpeed = Math.round(values[1]).toString();
            windGust = Math.round(values[2]).toString();
            temperature = values[3]
            break; // break loop since value found!
          }
        }
      }
    }

    // setting the features
    features[featureId] = new Feature({
      geometry: new Point(proj.transform([parseFloat(coords[1]), parseFloat(coords[0])], 'EPSG:4326', 'EPSG:3857')),
      type: 'Winds',
      windPlace: place,
      windTime: windTime,
      windDirection: windDirection,
      windSpeed: windSpeed,
      windGust: windGust,
      temperature: temperature
    });

    features[featureId].set('id', featureId);
    ++featureId;
  }
  windsFeatureSize = features.length;
  source.clear();
  source.addFeatures(features);
}

/********************************************/
/*    Create weather - waves source layer   */
/********************************************/
async function getWavesFeatures(map) {
  let waveVectorSource = new VectorSource({});
  let forecatsInstance = new weatherApi.WaveForecastLayer(map, wfsAxiosApi.WFSWeatherWaveQuery);
  let weatherGroup = mapApi.getPlannerGroup(map, weatherConfigs.weatherGroupName);
  let weathers = await forecatsInstance.getSourceVectorObjects(map, wfsAxiosApi.WFSWeatherWaveQuery);
  let wavesCollection = JSON.parse(weathers);
  console.log(" getWavesFeatures to map")
  let GridSeriesObservation = wavesCollection["wfs:FeatureCollection"]["wfs:member"]["omso:GridSeriesObservation"];
  try {
    await settingWavesFeatures(GridSeriesObservation, waveVectorSource);
  } catch (error) {
    throw new Error("Sorry, wave locations unavailable, try again later");
  }
  let vector = getWeatherVector(weatherGroup, weatherConfigs.weatherWaveName);
  vector.setSource(waveVectorSource);
  vector.setZIndex(2);
  let styleCache = {};
  let styleCacheArrow = {};
  let textStyleCache = {};
  vector.setStyle(function (feature) {
    let featuresSize = wavesFeatureSize;
    let style = styleCache[feature.getProperties().id];
    let arrowStyle = styleCacheArrow[feature.getProperties().id];
    let textStyle = textStyleCache[feature.getProperties().id];

    if (style && arrowStyle && textStyle) {
      return [arrowStyle, style, textStyle]
    } else {
      let waveDirection = parseFloat(feature.getProperties().waveDirection);
      let radianWaveDirection = !isNaN(waveDirection) ? waveDirection * (Math.PI / 180) : 0;
      styleCache[feature.getProperties().id] = new Style({
        image: new Icon({
          src: radianWaveDirection !== 0 ? 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wave_3.0.png' : 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/watertemp.png',
          size: [80, 80]
        })
      })

      if (radianWaveDirection !== 0) {
        styleCacheArrow[feature.getProperties().id] = new Style({
          image: new Icon({
            src: 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wave_3.0_arrow.png',
            size: [80, 80],
            rotation: radianWaveDirection
          })
        })
      }

      textStyleCache[feature.getProperties().id] = new Style({
        text: new Text({
          font: !isNaN(feature.getProperties().waveHeight) ? ' bold 13px Calibri' : ' bold 12px Calibri',
          overflow: false,
          fill: new Fill({
            color: 'white'
          }),
          textAlign: "center",
          text: !isNaN(feature.getProperties().waveHeight) ? feature.getProperties().waveHeight : ' ' + Math.round(feature.getProperties().waterTemperature) + '°',
        })
      })
      // Set z-index so the icon text won't overlap
      textStyleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);
      if (radianWaveDirection !== 0) {
        styleCacheArrow[feature.getProperties().id].setZIndex(feature.getProperties().id);
      }
      styleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);

      if (radianWaveDirection !== 0) {
        return [textStyleCache[feature.getProperties().id], styleCacheArrow[feature.getProperties().id], styleCache[feature.getProperties().id]]
      } else {
        return [textStyleCache[feature.getProperties().id], styleCache[feature.getProperties().id]]
      }
    }
  });
}


async function settingWavesFeatures(data, source) {
  let features = [];
  let featureId = 0;
  let pointMember = data["om:featureOfInterest"]["sams:SF_SpatialSamplingFeature"]["sams:shape"]["gml:MultiPoint"]['gml:pointMember'];
  let positions = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:domainSet"]["gmlcov:SimpleMultiPoint"]["gmlcov:positions"];
  positions = positions._text.trim().split('\n').reverse();
  let doubleOrNilReasonTupleList = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:rangeSet"]["gml:DataBlock"]["gml:doubleOrNilReasonTupleList"]
  doubleOrNilReasonTupleList = doubleOrNilReasonTupleList._text.trim().split('\n').reverse();

  let pointList = [];
  if (Array.isArray(pointMember)) { // several locations
    pointList = pointMember;
  }
  else { // only one location
    pointList.push(pointMember);
  }

  for (const point of pointList) {
    let coords
    let place
    let waveTime;
    let waveDirection;
    let waveHeight;
    let WTP;
    let waterTemperature;
    // Save coords and place name
    coords = point["gml:Point"]["gml:pos"]._text.split(" ", 2);
    place = point["gml:Point"]["gml:name"]._text;
    // find matching time and value pairs for this station
    for (const key in positions) {
      if (positions.hasOwnProperty(key)) {
        let position = positions[key].trim().split(' ');
        let values = doubleOrNilReasonTupleList[key].trim().split(' '); // WaveHs,ModalWDi,TWATER,WTP
        if (coords[0] === position[0] && coords[1] === position[1]) { // station found
          if ((place.indexOf('aaltopoiju') !== -1 && values[0] !== 'NaN' && values[1] !== 'NaN') || (place.indexOf('aaltopoiju') === -1 && values[2] !== 'NaN')) { // only places named 'aaltopoiju' contains wave data
            waveTime = moment.unix(position[3]).format();
            waveHeight = values[0];
            waveDirection = Math.round(values[1]).toString();
            waterTemperature = values[2]
            WTP = values[3];
            break; // break loop since value found!
          }
        }
      }
    }

    // setting the features
    features[featureId] = new Feature({
      geometry: new Point(proj.transform([parseFloat(coords[1]), parseFloat(coords[0])], 'EPSG:4326', 'EPSG:3857')),
      type: 'Waves',
      wavePlace: place,
      waveTime: waveTime,
      waveHeight: waveHeight,
      waveDirection: waveDirection,
      waterTemperature: waterTemperature,
      WTP: WTP
    });


    features[featureId].set('id', featureId);
    ++featureId;
  }
  wavesFeatureSize = features.length;
  source.clear();
  source.addFeatures(features);
}

/**************************************************/
/*    Create weather - temperature source layer   */
/**************************************************/
async function getTemperaturefeatures(map) {
  // changes 21.5.2021
  // Get the water temperatures from two different source
  // 
  // 1: define TemperatureFeatures
  //
  let temperatureVectorSource = new VectorSource({});
  let forecatsInstance = new weatherApi.TemperatureForecastLayer(map, wfsAxiosApi.WFSWeatherTemperatureQuery);
  let weatherGroup = mapApi.getPlannerGroup(map, weatherConfigs.weatherGroupName);
  // change 16.9.20 WFSWeatherTemperaturQuery => WFSWeatherTemperatureQuery
  let weathers = await forecatsInstance.getSourceVectorObjects(map, wfsAxiosApi.WFSWeatherTemperatureQuery);
  let temperaturesCollection = JSON.parse(weathers);
  console.log(" WFSWeatherTemperatureQuery to map")
  let GridSeriesObservation = temperaturesCollection["wfs:FeatureCollection"]["wfs:member"]["omso:GridSeriesObservation"];

  // 2: define WaterTemperatureFeatures(WaterHeight stations)
  //
  let forecastInstance_temperature = new weatherApi.WaterTemperatureForecastLayer(map, wfsAxiosApi.WFSWeatherWaterTemperatureQuery); // 
  let weathers_temperature = await forecastInstance_temperature.getSourceVectorObjects(map, wfsAxiosApi.WFSWeatherWaterTemperatureQuery); // WFSWeatherWaterTemperatureQuery
  let waterHeightCollection_temperature = JSON.parse(weathers_temperature);
  console.log(" WFSWeatherWaterTemperatureQuery (WaterHeight stations) to map")
  let wfsMember_temperature = waterHeightCollection_temperature["wfs:FeatureCollection"]['wfs:member'];

  // 1: set WaterTemperatureFeatures to temperatureVectorSource
  // obs! clear source first (new data), and adds features to the source
  try {
    await settingTemperatureFeatures(GridSeriesObservation, temperatureVectorSource);
  } catch (error) {
    throw new Error("Sorry, temperature locations unavailable, try again later");
  }

  // 2: set WaterTemperatureFeatures to temperatureVectorSource
  // obs! add features to the existing source
  try {
    await settingWaterTemperatureFeatures(wfsMember_temperature, temperatureVectorSource);
  } catch (error) {
    throw new Error("Sorry, water height locations unavailable, try again later");
  }

  let vector = getWeatherVector(weatherGroup, weatherConfigs.weatherTemperatureName);
  vector.setSource(temperatureVectorSource);
  vector.setZIndex(2);
  let styleCache = {};
  let styleCacheArrow = {};
  let textStyleCache = {};
  vector.setStyle(function (feature) {
    let featuresSize = temperaturesFeatureSize;
    let style = styleCache[feature.getProperties().id];
    let arrowStyle = styleCacheArrow[feature.getProperties().id];
    let textStyle = textStyleCache[feature.getProperties().id];

    if (style && arrowStyle && textStyle) {
      return [arrowStyle, style, textStyle]
    }
    else {
      let waveDirection = parseFloat(feature.getProperties().waveDirection);
      let radianWaveDirection = !isNaN(waveDirection) ? waveDirection * (Math.PI / 180) : 0;
      styleCache[feature.getProperties().id] = new Style({
        image: new Icon({
          src: radianWaveDirection !== 0 ? 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wave_3.0.png' : 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/watertemp.png',
          size: [80, 80]
        })
      })

      if (radianWaveDirection !== 0) {
        styleCacheArrow[feature.getProperties().id] = new Style({
          image: new Icon({
            src: 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wave_3.0_arrow.png',
            size: [80, 80],
            rotation: radianWaveDirection
          })
        })
      }

      textStyleCache[feature.getProperties().id] = new Style({
        text: new Text({
          font: !isNaN(feature.getProperties().waveHeight) ? ' bold 13px Calibri' : ' bold 12px Calibri',
          overflow: false,
          fill: new Fill({
            color: 'white'
          }),
          textAlign: "center",
          text: !isNaN(feature.getProperties().waveHeight) ? feature.getProperties().waveHeight : ' ' + Math.round(feature.getProperties().waterTemperature) + '°',
        })
      })
      // Set z-index so the icon text won't overlap
      textStyleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);
      if (radianWaveDirection !== 0) {
        styleCacheArrow[feature.getProperties().id].setZIndex(feature.getProperties().id);
      }
      styleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);
      if (radianWaveDirection !== 0) {
        return [textStyleCache[feature.getProperties().id], styleCacheArrow[feature.getProperties().id], styleCache[feature.getProperties().id]]
      } else {
        return [textStyleCache[feature.getProperties().id], styleCache[feature.getProperties().id]]
      }
    }
  });
}

async function settingTemperatureFeatures(data, source) {
  let features = [];
  let featureId = 0;
  let pointMember = data["om:featureOfInterest"]["sams:SF_SpatialSamplingFeature"]["sams:shape"]["gml:MultiPoint"]['gml:pointMember'];
  let positions = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:domainSet"]["gmlcov:SimpleMultiPoint"]["gmlcov:positions"];
  positions = positions._text.trim().split('\n').reverse();
  let doubleOrNilReasonTupleList = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:rangeSet"]["gml:DataBlock"]["gml:doubleOrNilReasonTupleList"]
  doubleOrNilReasonTupleList = doubleOrNilReasonTupleList._text.trim().split('\n').reverse();

  let pointList = [];
  if (Array.isArray(pointMember)) { // several locations
    pointList = pointMember;
  }
  else { // only one location
    pointList.push(pointMember);
  }

  for (const point of pointList) {
    let coords
    let place
    let temperatureTime;
    let waterTemperature;
    // Save coords and place name
    coords = point["gml:Point"]["gml:pos"]._text.split(" ", 2);
    place = point["gml:Point"]["gml:name"]._text;
    // find matching time and value pairs for this station
    for (const key in positions) {
      if (positions.hasOwnProperty(key)) {
        let position = positions[key].trim().split(' ');
        let values = doubleOrNilReasonTupleList[key].trim().split(' '); // TWATER
        if (coords[0] === position[0] && coords[1] === position[1]) { // station found
          if ((place.indexOf('aaltopoiju') !== -1 && values[0] !== 'NaN' && values[1] !== 'NaN') || (place.indexOf('aaltopoiju') === -1 && values[2] !== 'NaN')) { // only places named 'aaltopoiju' contains wave data
            temperatureTime = moment.unix(position[3]).format();
            waterTemperature = values[0]
            break; // break loop since value found!
          }
        }
      }
    }

    // setting the features
    features[featureId] = new Feature({
      geometry: new Point(proj.transform([parseFloat(coords[1]), parseFloat(coords[0])], 'EPSG:4326', 'EPSG:3857')),
      type: 'Temperatures',
      temperaturePlace: place,
      waterTemperature: waterTemperature,
    });

    features[featureId].set('id', featureId);
    //console.log(' waterTemperature: ', waterTemperature)
    ++featureId;
  }
  temperaturesFeatureSize = features.length;
  source.clear();
  source.addFeatures(features);
}

async function settingWaterTemperatureFeatures(data, source) {
  let features = [];
  let featureId = 0;
  // console.log(' settingWaterTemperatureFeatures', data)
  for (const PointTimeSeriesObservation of data) {
    let pos = PointTimeSeriesObservation['omso:PointTimeSeriesObservation']['om:featureOfInterest']['sams:SF_SpatialSamplingFeature']['sams:shape']['gml:Point']['gml:pos'];
    let coords = pos._text.trim().split(' ');
    let MeasurementTVP = PointTimeSeriesObservation['omso:PointTimeSeriesObservation']['om:result']['wml2:MeasurementTimeseries']['wml2:point']['wml2:MeasurementTVP'];
    // temperature 
    let wTempValue = parseFloat(MeasurementTVP['wml2:value']._text);
    // place name 
    let place = PointTimeSeriesObservation['omso:PointTimeSeriesObservation']['om:featureOfInterest']['sams:SF_SpatialSamplingFeature']['sam:sampledFeature']['target:LocationCollection']['target:member']['target:Location']['gml:name'][0]._text;
    features[featureId] = new Feature({
      geometry: new Point([coords[0], coords[1]]),
      type: 'Temperatures',
      temperaturePlace: place,
      waterTemperature: wTempValue.toFixed(1)
    });

    features[featureId].set('id', featureId);
    //console.log(' featureId: ', place, wHeightValueCm, coords[0], coords[1])
    //console.log(' featureId: ', nimi)
    ++featureId;
  }

  //add features to existing source - water temperatures from two different source
  source.addFeatures(features);
}

/**************************************************/
/*    Create weather - temperature (WaterHeight stations) source layer   */
/**************************************************/
async function getWaterTemperaturefeatures(map) {
  let waterTemperatureVectorSource = new VectorSource({});
  let forecastInstance_temperature = new weatherApi.WaterTemperatureForecastLayer(map, wfsAxiosApi.WFSWeatherWaterTemperatureQuery); // 
  let weatherGroup = mapApi.getPlannerGroup(map, weatherConfigs.weatherGroupName);
  let weathers_temperature = await forecastInstance_temperature.getSourceVectorObjects(map, wfsAxiosApi.WFSWeatherWaterTemperatureQuery); // WFSWeatherWaterTemperatureQuery
  let waterHeightCollection_temperature = JSON.parse(weathers_temperature);
  console.log(" getWaterTemperatureFeatures(WaterHeight stations) to map")
  let wfsMember_temperature = waterHeightCollection_temperature["wfs:FeatureCollection"]['wfs:member'];
  try {
    await settingWaterTemperatureFeatures(wfsMember_temperature, waterTemperatureVectorSource);
  } catch (error) {
    throw new Error("Sorry, water height locations unavailable, try again later");
  }
  let vector = getWeatherVector(weatherGroup, weatherConfigs.weatherWaterTemperatureName); // weatherWaterHeightName weatherWaterTemperatureName
  vector.setSource(waterTemperatureVectorSource);
  vector.setZIndex(2);
  let styleCache = {};
  let textStyleCache = {};
  vector.setStyle(function (feature) {
    let featuresSize = waterTemperatureFeatureSize;
    let style = styleCache[feature.getProperties().id];
    let textStyle = textStyleCache[feature.getProperties().id];

    if (style && textStyle) {
      return [style, textStyle]
    } else {
      styleCache[feature.getProperties().id] = new Style({
        image: new Icon({
          src: 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/watertemp.png',
          size: [80, 80]
        })
      })

      textStyleCache[feature.getProperties().id] = new Style({
        text: new Text({
          font: 'bold 12px Calibri',
          //offsetX: -7,
          //offsetY: -5,
          overflow: false,
          fill: new Fill({
            color: 'white'
          }),
          textAlign: "center",
          text: feature.getProperties().waterHeightValue
        })
      })

      textStyleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);
      styleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);

      return [textStyleCache[feature.getProperties().id], styleCache[feature.getProperties().id]]
    }
  });
}


async function settingWaterHeightTempFeatures(data, source) {
  let features = [];
  let featureId = 0;
  let pointMember = data["om:featureOfInterest"]["sams:SF_SpatialSamplingFeature"]["sams:shape"]["gml:MultiPoint"]['gml:pointMember'];
  let positions = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:domainSet"]["gmlcov:SimpleMultiPoint"]["gmlcov:positions"];
  positions = positions._text.trim().split('\n').reverse();
  let doubleOrNilReasonTupleList = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:rangeSet"]["gml:DataBlock"]["gml:doubleOrNilReasonTupleList"]
  doubleOrNilReasonTupleList = doubleOrNilReasonTupleList._text.trim().split('\n').reverse();

  console.log('doubleOrNilReasonTupleList')

  let pointList = [];
  if (Array.isArray(pointMember)) { // several locations
    pointList = pointMember;
  }
  else { // only one location
    pointList.push(pointMember);
  }

  for (const point of pointList) {
    let coords
    let place
    let waterHeightTime;
    let waterHeightValue;
    // Save coords and place name
    coords = point["gml:Point"]["gml:pos"]._text.split(" ", 2);
    place = point["gml:Point"]["gml:name"]._text;

    // find matching time and value pairs for this station
    for (const key in positions) {
      if (positions.hasOwnProperty(key)) {
        let position = positions[key].trim().split(' ');
        let values = doubleOrNilReasonTupleList[key].trim().split(' '); // TWATER
        if (coords[0] === position[0] && coords[1] === position[1]) { // station found
          if ((place.indexOf('aaltopoiju') !== -1 && values[0] !== 'NaN' && values[1] !== 'NaN') || (place.indexOf('aaltopoiju') === -1 && values[2] !== 'NaN')) { // only places named 'aaltopoiju' contains wave data
            waterHeightTime = moment.unix(position[3]).format();
            waterHeightValue = (values[0]) * 0.1;
            waterHeightValue = Math.round(waterHeightValue * 10) // / 10
            console.log('WaterHeightTempValue:', waterHeightValue)
            break; // break loop since value found!
          }
        }
      }
    }

    // setting the features
    features[featureId] = new Feature({
      geometry: new Point(proj.transform([parseFloat(coords[1]), parseFloat(coords[0])], 'EPSG:4326', 'EPSG:3857')),
      type: 'Temperatures',
      waterHeightTime: waterHeightTime,
      waterHeightValue: waterHeightValue,
    });

    features[featureId].set('id', featureId);
    ++featureId;
  }

  waterHeightFeatureSize = features.length;
  console.log('features ready', features.length)
  source.clear();
  source.addFeatures(features);
}

/***************************************************/
/*    Create weather - water height source layer   */
/***************************************************/
// changed whole part 23.9.2020 by MH
//
async function getWaterHeightFeatures(map) {
  let waterHeightVectorSource = new VectorSource({});
  let forecatsInstance = new weatherApi.WaterHeightForecastLayer(map, wfsAxiosApi.WFSWeatherWaterHeightQuery);
  let weatherGroup = mapApi.getPlannerGroup(map, weatherConfigs.weatherGroupName);
  let weathers = await forecatsInstance.getSourceVectorObjects(map, wfsAxiosApi.WFSWeatherWaterHeightQuery);
  let waterHeightsCollection = JSON.parse(weathers);
  console.log(" getWaterHightFeatures to map")
  let GridSeriesObservation = waterHeightsCollection["wfs:FeatureCollection"]["wfs:member"]["omso:GridSeriesObservation"];
  try {
    await settingWaterHeightFeatures(GridSeriesObservation, waterHeightVectorSource);
  } catch (error) {
    throw new Error("Sorry, waterHeight locations unavailable, try again later");
  }

  let vector = getWeatherVector(weatherGroup, weatherConfigs.weatherWaterHeightName);
  vector.setSource(waterHeightVectorSource);
  vector.setZIndex(2);
  let styleCache = {};
  let textStyleCache = {};
  vector.setStyle(function (feature) {
    let style = styleCache[feature.getProperties().id];
    let textStyle = textStyleCache[feature.getProperties().id];

    if (style && textStyle) {
      return [style, textStyle]
    } else {
      styleCache[feature.getProperties().id] = new Style({
        image: new Icon({
          src: 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/waterheight_map_icon.png',
          size: [80, 80]
        })
      })

      textStyleCache[feature.getProperties().id] = new Style({
        text: new Text({
          font: 'bold 14px Calibri',
          offsetX: -7,
          offsetY: -5,
          overflow: false,
          fill: new Fill({
            color: 'white'
          }),
          textAlign: "center",
          text: feature.getProperties().waterHeightValue.toFixed(1)
        })
      })
      textStyleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);
      styleCache[feature.getProperties().id].setZIndex(feature.getProperties().id);
      return [textStyleCache[feature.getProperties().id], styleCache[feature.getProperties().id]]
    }
  });
}


async function settingWaterHeightFeatures(data, source) {
  let features = [];
  let featureId = 0;
  let pointMember = data["om:featureOfInterest"]["sams:SF_SpatialSamplingFeature"]["sams:shape"]["gml:MultiPoint"]['gml:pointMember'];
  let positions = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:domainSet"]["gmlcov:SimpleMultiPoint"]["gmlcov:positions"];
  positions = positions._text.trim().split('\n').reverse();
  let doubleOrNilReasonTupleList = data["om:result"]["gmlcov:MultiPointCoverage"]["gml:rangeSet"]["gml:DataBlock"]["gml:doubleOrNilReasonTupleList"]
  doubleOrNilReasonTupleList = doubleOrNilReasonTupleList._text.trim().split('\n').reverse();

  let pointList = [];
  if (Array.isArray(pointMember)) { // several locations
    pointList = pointMember;
  }
  else { // only one location
    pointList.push(pointMember);
  }

  for (const point of pointList) {
    let coords
    let place
    let waterHeightTime;
    let waterHeightValue;
    // Save coords and place name
    coords = point["gml:Point"]["gml:pos"]._text.split(" ", 2);
    place = point["gml:Point"]["gml:name"]._text;
    // find matching time and value pairs for this station
    for (const key in positions) {
      if (positions.hasOwnProperty(key)) {
        let position = positions[key].trim().split(' ');
        let values = doubleOrNilReasonTupleList[key].trim().split(' '); // TWATER
        if (coords[0] === position[0] && coords[1] === position[1]) { // station found
          if ((place.indexOf('aaltopoiju') !== -1 && values[0] !== 'NaN' && values[1] !== 'NaN') || (place.indexOf('aaltopoiju') === -1 && values[2] !== 'NaN')) { // only places named 'aaltopoiju' contains wave data
            waterHeightTime = moment.unix(position[3]).format();
            waterHeightValue = (values[0]) * 0.1;
            waterHeightValue = Math.round(waterHeightValue * 10) / 10
            break; // break loop since value found!
          }
        }
      }
    }

    // setting the features
    features[featureId] = new Feature({
      geometry: new Point(proj.transform([parseFloat(coords[1]), parseFloat(coords[0])], 'EPSG:4326', 'EPSG:3857')),
      type: 'WaterHeight',
      waterHeightTime: waterHeightTime,
      waterHeightValue: waterHeightValue,
    });

    features[featureId].set('id', featureId);
    ++featureId;
  }

  waterHeightFeatureSize = features.length;
  console.log('features ready', features.length)
  source.clear();
  source.addFeatures(features);
}

function getWeatherVector(collection, name) {
  let vector;
  collection.forEach((layer) => {
    if (layer.get("name") === name) {
      vector = layer;
    }
  })
  return vector;
}
export default windWaveAPI;
