// open layers imports
import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import spatialConfiguration from './spatial-constructor/spatial-config';

// // // configs for retrieving layer
// const National_parksURL_ahv = 'https://kartor.regeringen.ax/arcgis/services/Miljo/Skyddsomraden/MapServer/WMSServer?';
// const National_parks_layers_ahv = [
//   "Naturreservat"
// ];

const National_parksURL = 'https://paikkatiedot.ymparisto.fi/geoserver/inspire_ps/wms?';
// // "Yksityiset suojelualueet"
const National_parks_layers = [
  "PS.ProtectedSitesValtionOmistamaLuonnonsuojelualue"
];
//   "Valtion maiden luonnonsuojelualueet"
let nationalModels = [
  new TileLayer({
    id: 0,
    zIndex: 1,
    finnishName: spatialConfiguration.NATIONAL_PARKS_NAME,
    name: spatialConfiguration.NATIONAL_PARKS_NAME,
    dataType: 'JSON',
    visible: false,
    source: new TileWMS({
      url: National_parksURL,
      params: {
        'TILED': true,
        'LAYERS': National_parks_layers,
        'STYLES': 'PS.ValtionMaidenSuojelualueet.Luokiteltu',
        'buffer': 400
      },
      transparent: 'true',
      projection: "EPSG:3857",
      crossOrigin: 'anonymous'
    }),
    opacity: 0.6
  }),
];

export default nationalModels;
