<template>
  <div class="header-wrapper">

    <div class="header-wrapper__menu-logo">
      <control-menu-button>
      </control-menu-button>
      <p class="menu-logo__text" @click="reload" :class="{'menu-logo__text--fin' : currentLanguage.name === 'fi'}">
        <!-- Site logo -->
        <img style="width: 120px;vertical-align: middle;" :src="'https://meriopas.ymparisto.fi/meriopas/static/logos/meriopas_logo_' + currentLanguage.name + '.svg'" />
        <!-- Site name -->
        <!-- {{ 'appName' | translate}} -->
        <span class="menu-logo__version"> 2.1 </span>
      </p>
    </div> 
    <div class="tools__header-wrapper">
      <img v-if="!languageMenu" class="layers-clear" :class="{'tools-icon--invisible' : activeToolsMenu}" :title="'clearLayers' | translate"
        @click="clear" src="https://meriopas.ymparisto.fi/meriopas/static/misc/layers_clear.svg" />
      <div v-if="!languageMenu" class="header-wrapper__tools-icon" :class="{'tools-icon--invisible' : activeToolsMenu}"
        @click="activateToolsMenu()">
        <icon-tools-base :icon-name="toolsButtonsnames.drawingTools | translate" iconColor="#fff" :class="{'tools-projection3067' : map.getView().getProjection().getCode() == 'EPSG:3067'}">
        </icon-tools-base>
      </div>

      <div v-if="!activeToolsMenu && !languageMenu" @click="shareLink" class="header-wrapper__share-button">
        <icon-share-base :icon-name="toolsButtonsnames.shareTool | translate" icon-color="#fff">
        </icon-share-base>
      </div>

      <!-- share dialog -->
      <v-dialog content-class="share__dialog" v-model="shareTooltip" scrollable max-width="500px">
        <v-card>
          <v-card-title class="headline headline--text">{{ shareText | translate }}</v-card-title>
          <v-card-text class="share__dialog-text">
            <span>
              {{ shareURL }}
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click.native="shareTooltip = false">Ok</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>

      <div @click="activateLanguageMenu" class="header-wrapper__languages-button" :class="{'header-wrapper__languages-button--invisible' : languageMenu  || activeToolsMenu }">
	<span>
	  {{ currentLanguage.name }}
	</span>
      </div>

      <tools v-bind:map="map" class="tools-wrapper" :class="{'tools-wrapper--visible' : activeToolsMenu}">
      </tools>

      <language-tools v-bind:map="map" class="languages-wrapper" :class="{'languages-wrapper--visible' : languageMenu}">
      </language-tools>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex';
  import Tools from './header-tools/Tools';
  import LanguageTools from './header-tools/LanguageTools'
  import ControlMenuButton from '../map-control-buttons/ControlMenuButton';
  import IconToolsBase from '../svg-system-components/IconToolsBase';
  import IconShareBase from '../svg-system-components/icons-tools/IconShareBase';
  import mapApi from '../../maps-constructor/mapsInit';
  export default {
    props: ['map'],
    components: {
      ControlMenuButton,
      IconToolsBase,
      Tools,
      LanguageTools,
      IconShareBase
    },
    data() {
      return {
        toolsComponentsHash: {
          "Drawing tools": "DrawingTools",
          "Language tools": "LanguageTools",
          "Share tools": "ShareTools"
        },
        appName: "appName",
        shareTooltip: false,
        shareURL: "",
        shareText: "shareToolsText",
        toolsButtonsnames: {
          drawingTools: "drawingTools",
          shareTool: "shareTool",
          languageTools: "languageTools"
        },
        popObject: null,
        WMSPopObject: null,
        WMSComplexPopObject: null,
      }
    },
    computed: {
      ...mapGetters({
        activeToolsMenu: 'getActiveToolsMenu',
        tools: 'getToolsTypes',
        languageMenu: 'getLanguageToolsMenu',
        currentLanguage: 'getCurrentLanguage',
        language: 'translationsStore/language',
        layerLegend: 'mapsMainLegendStore/getLayersLegendInfo'
      }),
    },
    methods: {
      ...mapMutations({
        setActiveToolsMenu: 'setActiveToolsMenu',
        setActivateLanguageMenu: 'setActivateLanguageMenu',
        setLayerLegendInfo: 'mapsMainLegendStore/setLayerLegendInfo',
        setSelecteSubLayers: 'setSelecteSubLayers',
        setSelectedWeatherLayers: 'setSelectedWeatherLayers',
        clearLayers: 'clearLayers',
        clearSatelliteLayers: 'clearSatelliteLayers',
        setSelectedType: 'setSelectedType',
        setCurrentLayerInfoSatellite: 'setCurrentLayerInfoSatellite',
        setCurrentLayerInfoSpatial: 'setCurrentLayerInfoSpatial',
        setCurrentLayerInfoWeather: 'setCurrentLayerInfoWeather',
        setCalendar: 'setCalendar',
        setKeyStoneSelectedAmount: 'setKeyStoneSelectedAmount',
        setLoadingStatus: 'setLoadingStatus',
        setAlgaeBloomSelected: 'setAlgaeBloomSelected'
      }),
      activateToolsMenu() {
        this.setActiveToolsMenu();
      },
      activateLanguageMenu() {
        this.setActivateLanguageMenu();
      },
      reload: function () {
        this.$router.push('/');
        this.$router.go();
      },
      shareLink() {
        let text = window.location.href;
        let tempoparyInput = document.createElement('input');
        document.body.appendChild(tempoparyInput);
        tempoparyInput.value = text;
        tempoparyInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempoparyInput);
        this.shareTooltip = true;
        this.shareURL = text;
      },
      closeLegend() {
        this.layerLegend.isActive = false;
      },
      clear() {
        mapApi.clearAllLayers(this.map);
        this.closeLegend();
        // this.setLayerLegendInfo({
        //   layerLegendURL: '',
        //   visible: false
        // });
        this.setSelecteSubLayers({
          keyStoneSpecies: 0,
          other: 0
        });
        this.setSelectedWeatherLayers(0);
        this.clearLayers();
        this.clearSatelliteLayers();
        this.setSelectedType("");
        this.setCurrentLayerInfoSatellite("");
        this.setCurrentLayerInfoSpatial("");
        this.setCurrentLayerInfoWeather("");
        this.setCalendar("");
        this.setKeyStoneSelectedAmount(0);
        mapApi.asyncVectorRefresh(this.map);
        this.map.getOverlays().forEach(function(overlay) {
          overlay.setPosition(undefined);
        });
        this.setAlgaeBloomSelected(false);
        this.setLoadingStatus(false);
      }
    },
  }

</script>

<style scoped lang="scss">
  .header-wrapper {
    display: flex;
    justify-content: space-between;

    .header-wrapper__menu-logo {
      display: flex;
      align-items: center;
      width: 320px;

      .menu-logo__text {
        margin: 0px;
        font-size: 18px;
        word-break: break-word;
        color: #fff;
        cursor: text;
        font-weight: bold;
        cursor: pointer;
        align-self: center;
        text-align: left;
        text-transform: uppercase;
      }

      .menu-logo__text--fin {
        line-height: 1rem;
      }

      .menu-logo__version {
        color: white;
        font-size: 0.8rem;
        vertical-align: text-top;
      }
    }

    .tools__header-wrapper {
      background-color: #074679;
      display: flex;
      padding-left: 10px;
    }

    .header-wrapper__tools-icon {
      background-color: #074679;
      padding: 0rem 1rem;
      opacity: 1;
      display: flex;
      top: 0;
      right: 0;
      height: 100%;
      transition: all .25s cubic-bezier(0.2, 0, 0.2, 1);

      svg {
        margin: 0 !important;
        cursor: pointer;
        height: 100%;
      }
    }

    .layers-clear {
      width: 23px;
      height: 23px;
      margin-top: 12px;
      cursor: pointer;
    }

    .tools-icon--invisible {
      display: none;
      padding: 0;
      opacity: 0;
      width: 0;
      height: 0;
      transition: all .25s cubic-bezier(0.2, 0, 0.2, 1);
    }

    .tools-wrapper,
    .languages-wrapper {
      overflow: hidden;
      width: 0%;
      opacity: 0;
      transition: all .2s linear;
      display: flex;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }

    .tools-wrapper--visible {
      display: flex;
      position: absolute;
      right: 0;
      width: 20%;
      opacity: 1;
      transition: all .2s linear;


      @media screen and (max-width: 1440px) {
        width: 30%;
      }


      @media screen and (max-width: 1366px) {
        width: 30%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        background-color: #3d8ac7;
      }

      @media screen and (max-width: 414px) {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #3d8ac7;

      }
    }

    .languages-wrapper--visible {
      display: flex;
      position: absolute;
      right: 0;
      width: 20%;
      opacity: 1;
      transition: all .2s linear;


      @media screen and (max-width: 1440px) {
        width: 30%;
      }


      @media screen and (max-width: 1366px) {
        width: 40%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        background-color: #3d8ac7;
      }

      @media screen and (max-width: 414px) {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #3d8ac7;

      }
    }

    .header-wrapper__share-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 1rem;
      cursor: pointer;
    }

    .header-wrapper__languages-button {
      color: #ffffff;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 1rem;
      width: auto;
      overflow: hidden;
      cursor: pointer;
    }

    .header-wrapper__languages-button--invisible {
      display: none;
    }
  }

  .headline--text {
    text-align: left;
  }

  .share__dialog-text {
    text-align: left;
  }

  .tools-projection3067 {
    display: none;
  }

</style>
