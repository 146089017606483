import Vector from 'ol/layer/vector';
// spatial configuration
import spatialConfiguration from './spatial-config';

let spatialLayers = [
  new Vector({
    id: 0,
    finnishName: spatialConfiguration.wrecksName,
    name: spatialConfiguration.wrecksName,
    visible: false,
    updateWhileInteracting: true,
  }),
  new Vector({
    id: 1,
    finnishName: spatialConfiguration.AISName,
    name: spatialConfiguration.AISName,
    visible: false,
    updateWhileInteracting: true
  }),
  new Vector({
    id: 2,
    finnishName: spatialConfiguration.poiName,
    name: spatialConfiguration.poiName,
    visible: false,
    updateWhileInteracting: true
  }),
  new Vector({
    id: 3,
    finnishName: spatialConfiguration.VIDEO_NAME,
    name: spatialConfiguration.VIDEO_NAME,
    visible: false,
    updateWhileInteracting: true
  }),
  new Vector({
    id: 4,
    finnishName: spatialConfiguration.PICTURE_NAME,
    name: spatialConfiguration.PICTURE_NAME,
    visible: false,
    updateWhileInteracting: true
  }),
  new Vector({
    id: 5,
    finnishName: spatialConfiguration.SWIMMINGINFO_NAME,
    name: spatialConfiguration.SWIMMINGINFO_NAME,
    visible: false,
    updateWhileInteracting: true
  }),
  new Vector({
    id: 6,
    finnishName: spatialConfiguration.BOATINGINFO_NAME,
    name: spatialConfiguration.BOATINGINFO_NAME,
    visible: false,
    updateWhileInteracting: true
  }),
  new Vector({
    id: 7,
    finnishName: spatialConfiguration.ALGAINFO_NAME,
    name: spatialConfiguration.ALGAINFO_NAME,
    visible: false,
    updateWhileInteracting: true
  }), 
  new Vector({
    id: 8,
    finnishName: spatialConfiguration.wrecksName_AHV,
    name: spatialConfiguration.wrecksName_AHV,
    visible: false,
    updateWhileInteracting: true,
  }),
];

export default spatialLayers;
