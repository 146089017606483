import VectorSource from 'ol/source/vector';
import wfsAxiosApi from '../../axios-wms-conf/axiosWFSConf'
import spatialApi from './spatialInfoLayers';
import mapApi from '../mapsInit';
import spatialConfiguration from './spatial-config';
import Feature from 'ol/feature';
import Point from 'ol/geom/point';
import Style from 'ol/style/style';
import Icon from 'ol/style/icon';
import proj from 'ol/proj';

let featureSize;

async function addLocations(map) {
  let vSource = new VectorSource({});
  let instance = new spatialApi.initializer(map, wfsAxiosApi.boatingSpatialInfo);
  let spatialGroup = mapApi.getPlannerGroup(map, spatialConfiguration.groupName);
  let vector = getVector(spatialGroup, spatialConfiguration.BOATINGINFO_NAME);
  vector.setZIndex(2);
  let locations = await instance.getSourceVectorObjects();
  await setFeatures(locations.features, vSource);
  vector.setSource(vSource);
  vector.setStyle(function (feature) {
    let styleCache = {};
    let featuresSize = featureSize;
    let style = styleCache[featuresSize];
    let icon = 'retkisatama_map.png';
    let props = feature.getProperties();
    if (props.properties && props.properties.Pntyypp === 'Vierasvenesatama') {
      icon = 'vierasvenesatama_map.png';
    } else if (props.properties && props.properties.Pntyypp === 'Palvelusatama') {
      icon = 'palvelusatama_map.png';
    }

    if (!style) {
      style = new Style({
        image: new Icon({
          src: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/' + icon,
          size: [53, 71],
          anchor: [0.5, 1],
          scale: 0.5,
        })
      })
      styleCache[featuresSize] = style
    }
    return style;
  })
}

function getVector(collection, name) {
  let vector;
  collection.forEach((layer) => {
    if (layer.get("name") === name) {
      vector = layer;
    }
  })
  return vector;
}

async function setFeatures(locations, source) {
  let features = [];
  for (let i = 0; i < locations.length; ++i) {
    features[i] = new Feature({
      geometry: new Point(proj.transform([parseFloat(locations[i].geometry.coordinates[0]), parseFloat(locations[i].geometry.coordinates[1])], 'EPSG:4326', 'EPSG:3857')),
      type: 'Boating',
      properties: locations[i].properties
    });
    features[i].set('id', locations[i].id);
  }
  source.clear();
  source.addFeatures(features);
  featureSize = features.length;
}

export default {
  addLocations
};
