import mapApi from '../../maps-constructor/mapsInit';

const state = {
  openLayerMap: null,
  centerCoordinates: null,
  layerLegendInfo: {},
  currentZoom: null,
  sateliteXMl: null,
  satelitesLayersNames: null,
  loadingStatus: null,
  admin: {
    1: "",
    2: "",
    3: ""
  },
  // available tools
  toolsTypes: [{
    name: "Drawing tools",
    id: 0,
    isActive: false
  },
  {
    name: "Language tools",
    id: 1,
    isActive: false
  },
  {
    name: "Share tools",
    id: 2,
    isActive: false
  }
  ],
  //languages types
  languages: [{
    name: "en",
    icon: "https://meriopas.ymparisto.fi/meriopas/static/flags/england.svg"
  },
  {
    name: "fi",
    icon: "https://meriopas.ymparisto.fi/meriopas/static/flags/fin.svg"
  },
  {
    name: "sv",
    icon: "https://meriopas.ymparisto.fi/meriopas/static/flags/sweden.svg"
  }
  ],
  currentActiveLanguage: {
    name: "fi",
    icon: "https://meriopas.ymparisto.fi/meriopas/static/flags/fin.svg"
  },
  // toolsMenuActivation
  activeToolsMenu: false,
  // activeLanguage Menu
  activeLanguageMenu: false,
  // counter for spateils layers
  selectedSpatialLayers: 0,
  // counter for layers in layers menu
  selecteSubLayers: 0,
  // counter for weathers layers
  selectedWeatherLayers: 0,
  // water temperature, quality. satellites layers
  selectedWaterTemperatureLayers: 0,
  selectedWaterQualityLayers: 0,
  selectedSatellitesLayers: 0,
  // includes final result of sum of water temperature, quality. satellites layers
  selectedLayersWithCalendar: 0,
  layerInterval: {
    wind: false,
    wave: false,
    waterHeight: false,
    ais: false
  },
  aisMetaData: []
}

const getters = {
  getMap(state) {
    return state.openLayerMap;
  },
  getCenterCoordinates(state) {
    //console.log("-state.centerCoordinates: " + state.centerCoordinates)
    //console.log("maps-main.store.js window.location.href: " + window.location.href)
    return state.centerCoordinates;
  },
  getCurrentZoom(state) {
    //console.log("-state.currentZoom: " + state.currentZoom)
    return state.currentZoom;
  },
  getLoadingStatus(state) {
    return state.loadingStatus;
  },
  getSatelliteXML(state) {
    return state.sateliteXMl;
  },
  getSatelliteLayersNames(state) {
    return state.satelitesLayersNames;
  },
  getSelectedSpatialLayers(state) {
    return state.selectedSpatialLayers;
  },
  getSelecteSubLayers(state) {
    return state.selecteSubLayers;
  },
  getSelectedWeatherLayers(state) {
    return state.selectedWeatherLayers;
  },
  getSelectedWaterTemperatureLayers(state) {
    return state.selectedWaterTemperatureLayers;
  },
  getSelectedWaterQualityLayers(state) {
    return state.selectedWaterQualityLayers;
  },
  getSelectedSatellitesLayers(state) {
    return state.selectedSatellitesLayers;
  },
  getSelectedLayersWithCalendar(state) {
    return state.selectedLayersWithCalendar;
  },
  getActiveToolsMenu(state) {
    return state.activeToolsMenu;
  },
  getLanguageToolsMenu(state) {
    return state.activeLanguageMenu;
  },
  getLanguages(state) {
    return state.languages;
  },
  getCurrentLanguage(state) {
    return state.currentActiveLanguage;
  },
  getToolsTypes(state) {
    return state.toolsTypes;
  },
  // get layers legend info
  getLayersLegendInfo(state) {
    return state.layerLegendInfo;
  },
  getLayerInterval(state) {
    return state.layerInterval;
  },
  getAisMetaData(state) {
    return state.aisMetaData;
  }
};

const mutations = {
  setMap(state, payload) {
    state.openLayerMap = payload;
  },
  setLayerInterval(state, payload) {
    state.layerInterval = Object.assign({}, state.layerInterval, payload);
  },
  setCeneterCoordinates(state, payload) {
    state.centerCoordinates = payload;
  },
  setCurrentZoom(state, payload) {
    state.currentZoom = payload;
  },
  setLoadingStatus(state, payload) {
    state.loadingStatus = payload;
  },
  setSatelliteXML(state, payload) {
    state.sateliteXMl = payload;
  },
  setSatelliteLayersNames(state, payload) {
    state.satelitesLayersNames = payload;
  },
  setSelectedSpatialLayers(state, payload) {
    state.selectedSpatialLayers = payload;
  },
  setSelecteSubLayers(state, payload) {
    state.selecteSubLayers = 0;
    for (let sublayer in payload) {
      state.selecteSubLayers = state.selecteSubLayers + payload[sublayer];
    }
  },
  setSelectedLayersWithCalendar(state, payload) {
    state.selectedLayersWithCalendar = payload;
  },
  setSelectedWeatherLayers(state, payload) {
    state.selectedWeatherLayers = payload;
  },
  setSelectedWaterTemperatureLayers(state, payload) {
    state.selectedWaterTemperatureLayers = payload;
  },
  setSelectedWaterQualityLayers(state, payload) {
    state.selectedWaterQualityLayers = payload;
  },
  setSatellitesLayers(state, payload) {
    state.selectedSatellitesLayers = payload;
  },
  clearSatelliteLayers(state) {
    state.selectedSatellitesLayers = 0;
  },
  setActiveToolsMenu(state) {
    state.activeToolsMenu = !state.activeToolsMenu;
  },
  setActivateLanguageMenu(state) {
    state.activeLanguageMenu = !state.activeLanguageMenu;
  },
  setLanguages(state, payload) {
    state.languages = payload;
  },
  setLayerLegendInfo(state, payload) {
    state.layerLegendInfo = payload;
  },
  setCurrentLanguage(state, payload) {
    state.currentActiveLanguage = payload;
  },
  setAisMetaData(state, payload) {
    state.aisMetaData = payload;
  }
};

const actions = {
  async initMap({
    commit
  }, payload) {
    try {
      let mapInstance = await mapApi.initMap('map', payload.center, payload.zoom);
      commit('setMap', mapInstance);
    } catch (err) {
      console.log('my error >>> ', err);
      alert(err);
    }
  },

  async parseSatellitePictures({
    commit
  }, payload) {
    commit('setSatelliteXML', await mapApi.parseSatelliteWMS(payload));
  },
  async fetchSatelliteLayersNames({
    dispatch,
    commit
  }, payload) {
    await dispatch('parseSatellitePictures', payload);
    commit('setSatelliteLayersNames', await mapApi.getLastSatelliteLayersName(state.sateliteXMl));
  },
  setSelectedWaterTemperatureLayersInSubLayers({
    commit
  }, payload) {
    commit("setSelectedWaterTemperatureLayers", payload)
  },
  setsetSelectedWaterQualityLayersInSubLayers({
    commit
  }, payload) {
    commit("setSelectedWaterQualityLayers", payload)
  }
};

export default {
  state,
  getters,
  mutations,
  actions
}
