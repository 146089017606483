<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 23.508 22.622"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName | translate}}
		</title>
        <g id="Group_170_1_" transform="translate(-225 -924)">
            <g id="Group_167_1_" transform="translate(-39.629 -30.629)">
                <path id="Path_373_1_" d="M279.732,964.711v1.5h-9v-1.5H279.732z"/>
                <path id="Path_374_1_" d="M275.932,969.854h-1.5v-9h1.5V969.854z"/>
            </g>
            <g id="Group_166_1_" transform="translate(-30 1)">
                <path id="Path_375_1_" d="M278.508,944.37l-1.004,1.115l-4.956-4.462l1.004-1.115L278.508,944.37z"/>
                <g id="Ellipse_429_1_" transform="translate(255 923)">
                    <circle style="fill:none;stroke:#ffffff;stroke-width:1.5;" cx="10.603" cy="10.59" r="9.75"/>
                </g>
            </g>
        </g>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'zoomIn'
		},
		width: {
			type: [Number, String],
			default: 23
		},
		height: {
			type: [Number, String],
			default: 19
		},
		iconColor: {
			type: String,
			default: '#FFFFFF'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

    svg  > * {
        fill : white;
        
    }

</style>