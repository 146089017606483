<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 4.621 7.888"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName}}
		</title>
	<g 
		:fill = iconColor
	>
	<path id="Path_370" style="fill:#FFFFFF;" d="M4.581,3.939c0,0.068-0.028,0.133-0.078,0.18l-3.64,3.64
        c-0.046,0.05-0.112,0.079-0.18,0.08c-0.068-0.001-0.134-0.03-0.18-0.08l-0.39-0.39c-0.05-0.046-0.079-0.112-0.08-0.18
        c0.001-0.068,0.03-0.134,0.08-0.18l3.07-3.07l-3.07-3.07c-0.05-0.046-0.079-0.112-0.08-0.18c0.001-0.068,0.03-0.134,0.08-0.18
        l0.39-0.39c0.046-0.05,0.112-0.079,0.18-0.08c0.068,0,0.133,0.028,0.18,0.078l3.64,3.642C4.553,3.806,4.581,3.871,4.581,3.939z"
    />
	</g>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: ''
		},
		width: {
			type: [Number, String],
			default: 24
		},
		height: {
			type: [Number, String],
			default: 24
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

</style>