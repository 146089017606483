<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width              = "width"
    :height             = "height"
    viewBox             = "0 0 28.88 22.667"
    :aria-labelledby    = "iconName"
    role                = "presentation"
  >
    <title
        :id     = "iconName"
        lang    = "en"
    >
        {{iconName}}
    </title>
    <g>
	<path style="fill:#FFFFFF;" d="M0.751,10.333c0.356,0,0.644-0.203,0.644-0.453V1.12c0-0.253-0.288-0.453-0.644-0.453
		S0.107,0.87,0.107,1.12v8.76C0.107,10.133,0.395,10.333,0.751,10.333z"/>
	<path style="fill:#FFFFFF;" d="M28.236,0.667c-0.356,0-0.644,0.203-0.644,0.453v8.76c0,0.253,0.288,0.453,0.644,0.453
		S28.88,10.13,28.88,9.88V1.12C28.88,0.867,28.592,0.667,28.236,0.667z"/>
	<path style="fill:#FFFFFF;" d="M5.63,10.16c0.5,0.5,1.32,0.5,1.82,0c0.24-0.24,0.38-0.57,0.38-0.91V6.178h13.32V9.25
		c0,0.34,0.13,0.67,0.37,0.91c0.51,0.5,1.32,0.5,1.83,0l3.71-3.72c0.5-0.5,0.5-1.31,0-1.82l-3.71-3.71c-0.51-0.5-1.32-0.5-1.82,0
		c-0.25,0.24-0.38,0.57-0.38,0.91v3.07H7.83V1.82c0-0.34-0.14-0.67-0.38-0.91c-0.5-0.5-1.32-0.5-1.82,0L1.91,4.62
		c-0.5,0.51-0.5,1.32,0,1.82L5.63,10.16z M26.15,5.53l-3.71,3.72V5.552c0-0.006,0.002-0.012,0.002-0.018
		c0-0.007-0.002-0.012-0.002-0.019V1.82L26.15,5.53z M6.54,1.82v7.43L2.83,5.53L6.54,1.82z"/>
	<path style="fill:#FFFFFF;" d="M27.27,12.58H1.5c-0.83,0-1.5,0.67-1.5,1.5v7.08c0,0.01,0,0.01,0,0.01c0,0.83,0.67,1.5,1.5,1.5
		h25.77c0.83,0,1.5-0.68,1.5-1.51v-7.08C28.77,13.25,28.1,12.58,27.27,12.58z M27.49,21.17c0,0.11-0.1,0.21-0.22,0.21H1.5
		c-0.12,0-0.22-0.1-0.22-0.22v-7.08c0-0.11,0.1-0.21,0.22-0.21h3.843v4.398c0,0.356,0.288,0.644,0.644,0.644
		s0.644-0.288,0.644-0.644V13.87h3.025c-0.006,0.036-0.021,0.068-0.021,0.106v2.575c0,0.356,0.288,0.644,0.644,0.644
		s0.644-0.288,0.644-0.644v-2.575c0-0.038-0.015-0.07-0.021-0.106h3.025v4.183c0,0.356,0.288,0.644,0.644,0.644
		s0.644-0.288,0.644-0.644V13.87h3.025c-0.006,0.036-0.021,0.068-0.021,0.106v2.575c0,0.356,0.288,0.644,0.644,0.644
		s0.644-0.288,0.644-0.644v-2.575c0-0.038-0.015-0.07-0.021-0.106h3.025v4.183c0,0.356,0.288,0.644,0.644,0.644
		s0.644-0.288,0.644-0.644V13.87h3.471c0.12,0,0.22,0.09,0.22,0.21V21.17z"/>
</g>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 24.88
    },
    height: {
      type: [Number, String],
      default: 22.667
    },
    iconColor: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>

<style scoped>

  svg {
    display           : inline-block;
    vertical-align    : bottom;
    margin-bottom     : -5px;
    margin-left       : 10px;
  }

  svg > * {
    fill: white;
  }

</style>
