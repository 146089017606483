import { render, staticRenderFns } from "./MenuSatellite.vue?vue&type=template&id=3d162878&scoped=true"
import script from "./MenuSatellite.vue?vue&type=script&lang=js"
export * from "./MenuSatellite.vue?vue&type=script&lang=js"
import style0 from "./MenuSatellite.vue?vue&type=style&index=0&id=3d162878&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d162878",
  null
  
)

export default component.exports