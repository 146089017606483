// open layers imports
import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import spatialConfiguration from './spatial-constructor/spatial-config';

// // configs for retrieving layer
const NaturreservatURL_ahv = 'https://kartor.regeringen.ax/arcgis/services/Miljo/Skyddsomraden/MapServer/WMSServer?';
const Naturreservat_layers_ahv = [
  "Naturreservat"
];

let naturreservatModels = [
  new TileLayer({
    id: 0,
    zIndex: 1,
    finnishName: spatialConfiguration.Naturreservat_NAME,
    name: spatialConfiguration.Naturreservat_NAME,
    dataType: 'text/xml',
    visible: false,
    source: new TileWMS({
      url: NaturreservatURL_ahv,
      params: {
        'TILED': true,
        'LAYERS': Naturreservat_layers_ahv,
        'buffer': 400
      },
      transparent: 'true',
      projection: "EPSG:3857",
      crossOrigin: 'anonymous'
    }),
    opacity: 0.6
  })
];

export default naturreservatModels;
