export default {
	inserted (elem, binding, vnode) {
        let menuIconElem = document.querySelector('.calendar-button__wrapper');
        // To close side menu when component changes
        // binding.value(event);

        elem.__vueClickOutside__ = function (event) {
            let calendarList = document.querySelector('.calendar-widget-wrapper');
            let isClickedOutside = !elem.contains(event.target)
                && elem !== event.target
                && !menuIconElem.contains(event.target)
                && !calendarList.contains(event.target)
                && event.target.tagName !== 'path'
                && event.target.tagName !== 'svg';

            if (isClickedOutside) {
                binding.value(event);
            }
        };

        document.addEventListener('click', elem.__vueClickOutside__)
    },
    unbind (elem, binding) {
        document.removeEventListener('click', elem.__vueClickOutside__);
    }
};