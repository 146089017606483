<template>
	<svg
		xmlns               = "http://www.w3.org/2000/svg"
		xmlns:xlink         = "http://www.w3.org/1999/xlink"
		x                   = "0px"
		y                   = "0px"
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 48 48"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName}}
		</title>
<g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8"><g id="icon-share"><path class="cls-1" d="M38,28A10,10,0,0,1,48,38,10,10,0,0,1,38,48,10,10,0,0,1,28,38c0-.25,0-.6.06-1.06L16.81,31.31A9.62,9.62,0,0,1,10,34a9.64,9.64,0,0,1-7.08-2.92A9.68,9.68,0,0,1,0,24a9.68,9.68,0,0,1,2.92-7.08A9.64,9.64,0,0,1,10,14a9.62,9.62,0,0,1,6.81,2.69l11.25-5.63C28,10.6,28,10.25,28,10a9.64,9.64,0,0,1,2.92-7.08,10,10,0,0,1,14.16,0A9.68,9.68,0,0,1,48,10a9.68,9.68,0,0,1-2.92,7.08,10,10,0,0,1-13.89.23L19.94,22.94c0,.46.06.81.06,1.06s0,.6-.06,1.06l11.25,5.63A9.64,9.64,0,0,1,38,28Z"/></g></g></g>
	</svg>
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'box'
		},
		width: {
			type: [Number, String],
			default: 17.27
		},
		height: {
			type: [Number, String],
			default: 19.47
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

	svg > * {
        fill: white;
    }

</style>
