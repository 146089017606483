import VectorSource from 'ol/source/vector';
import wfsAxiosApi from '../../axios-wms-conf/axiosWFSConf'
import algaAxiosConf from '../../alga-axios-conf/axios.conf.js'
import store from '../../store/modules/maps-main.store.js';
import spatialApi from './spatialInfoLayers';
import mapApi from '../mapsInit';
import spatialConfiguration from './spatial-config';
import Feature from 'ol/feature';
import Point from 'ol/geom/point';
import Style from 'ol/style/style';
import Icon from 'ol/style/icon';
import proj from 'ol/proj';
import moment from 'moment';
import { scale } from 'ol/size';
import Group from 'ol/layer/group';

let featureSize;
let algaFeatureSize;

async function addLocations(map) {
  await addSwimmingLocations(map);
  await addAlgaLocations(map);
}

async function addAlgaLocations(map) {
  let vSource = new VectorSource({});
  let algaInstance = new spatialApi.AlgaInfo(map, algaAxiosConf.createAlgaPoints);
  let spatialGroup = mapApi.getPlannerGroup(map, spatialConfiguration.groupName);
  let vector = getVector(spatialGroup, spatialConfiguration.ALGAINFO_NAME);
  vector.setZIndex(2);
  // change 7 => 3
  // 26.8.2020 MH
  let dateLimit = moment().subtract(3, "days").format('YYYY-MM-DD');
  console.log(dateLimit + " = datelimit")
  let locations = await algaInstance.getSourceVector(dateLimit + 'T00:00:00Z');
  console.log(locations.length + " = locations")
  await setAlgaFeatures(locations, vSource);
  vector.setSource(vSource);
  vector.setStyle(function (feature) {
    let styleCache = {};
    let featuresSize = algaFeatureSize;
    let style = styleCache[featuresSize];
    let icon = '';
    let properties = feature.getProperties();
    if (properties) {
      if (properties.observation) {
        if (properties.observation === '1') {
          icon = 'alga_none.png'
        } else if (properties.observation === '2') {
          icon = 'alga_little.png'
        } else if (properties.observation === '3') {
          icon = 'alga_high.png'
        } else if (properties.observation === '4') {
          icon = 'alga_high.png'
        }
      }
      if (!style) {
        style = new Style({
          image: new Icon({
            src: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/' + icon,
            //src: '/static/spatialInfoIcons/' + icon,
          })
        })
        styleCache[featuresSize] = style
      }
    }
    return style;
  })
}

async function setAlgaFeatures(locations, source) {
  let features = [];
  console.log("locations : " + locations.length)
  for (let i = 0; i < locations.length; ++i) {
    features[i] = new Feature({
      geometry: new Point(proj.transform([parseFloat(locations[i].long.replace(',', '.')), parseFloat(locations[i].lat.replace(',', '.'))], 'EPSG:4326', 'EPSG:3857')),
      type: 'AlgaInfo',
      observation: locations[i].attributes.algaebloom_singlevaluelist_201808151546174,
      time: locations[i].saved_datetime
    });
    features[i].set('id', locations[i].service_request_id);
  }
  source.clear();
  source.addFeatures(features);
  console.log("features : " + features.length)
  algaFeatureSize = features.length;
}

async function addSwimmingLocations(map) {

  let vSource = new VectorSource({});
  let instance = new spatialApi.initializer(map, wfsAxiosApi.swimmingSpatialInfo);
  let spatialGroup = mapApi.getPlannerGroup(map, spatialConfiguration.groupName);
  let vector = getVector(spatialGroup, spatialConfiguration.SWIMMINGINFO_NAME);
  vector.setZIndex(2);
  let locations = await instance.getSourceVectorObjects();
  await setFeatures(locations.results, vSource);
  vector.setSource(vSource);

  vector.setStyle(function (feature) {
    let styleCache = {};
    let featuresSize = featureSize;
    let style = styleCache[featuresSize];
    let style_nonvisible = styleCache[featuresSize];
    let icon = '';
    let properties = feature.getProperties();
    if (properties && properties.observations && properties.observations.length > 0) {
      for (const observation of properties.observations) {
        if (observation.property === 'swimming_water_cyanobacteria') {
          if (observation.value === '2') {
            icon = 'alga_high.png';
          } else if (observation.value === '1') {
            icon = 'alga_little.png';
          } else if (observation.value === '0') {
            icon = 'alga_none.png';
          }
        }
      }
    }

    if (!style) {
      style = new Style({
        image: new Icon({
          src: 'https://meriopas.ymparisto.fi/meriopas/static/spatialInfoIcons/' + icon,
          size: [20, 20],
          scale: 1,
        })
      })
      // var ankkuri = 'anchor_menu.png';
      style_nonvisible = new Style({
        image: new Icon({
          src: '/static/spatialInfoIcons/' + icon,
          size: [0, 0],
          scale: 0,
        })
      })
      // kansalaishavaintoaineiston MaxScaleDenominator arvo: 944941.421131
      // oikeampi tapa olisi hakea ohjelmallisesti...
      // The “standardized rendering pixel size” is defined to be 0.28mm × 0.28mm (millimeters).
      // 0.28mm ÷ 0.254mm = 1.102362205 (multiplier for scale conversion) 
      //
      var maxscaleden = 944941 / 1.102362205
      // if (mapScale(map) > maxscaleden) {
      //   //console.log("ei näkyviin - scale: " + mapScale(map) + ' - ' + maxscaleden)
      //   styleCache = {};
      //   featuresSize = 0;
      //   styleCache[featuresSize] = style_nonvisible
      //   return style_nonvisible
      // }
      // else{
      //console.log("näkyviin- scale: " + mapScale(map))
      styleCache[featuresSize] = style
      return style;
      //}
    }

  })
}

function getVector(collection, name) {
  let vector;
  collection.forEach((layer) => {
    if (layer.get("name") === name) {
      vector = layer;
    }
  })
  return vector;
}

function mapScale(map) {
  var unit = map.getView().getProjection().getUnits();
  var resolution = map.getView().getResolution();
  var inchesPerMetre = 39.37;
  var dpi = 72;
  // dpi =  window.devicePixelRatio;
  return resolution * proj.METERS_PER_UNIT[unit] * inchesPerMetre * dpi;
}

async function setFeatures(locations, source) {
  let features = [];
  for (let i = 0; i < locations.length; ++i) {
    for (const observation of locations[i].observations) {
      if (observation.property === 'swimming_water_cyanobacteria') {
        if (moment.utc().unix() - moment.utc(observation.time).unix() <= 3 * 24 * 60 * 60) { // kelpuutetaan vain rannat, joilta alle 3 vrk vanha lev�havainto
          let feat = new Feature({
            geometry: new Point(proj.transform([parseFloat(locations[i].geometry.coordinates[0]), parseFloat(locations[i].geometry.coordinates[1])], 'EPSG:4326', 'EPSG:3857')),
            type: 'SwimmingInfo',
            name: locations[i].name.fi,
            location: locations[i].location.fi,
            municipality: locations[i].municipality.fi,
            observations: locations[i].observations
          });
          feat.set('id', locations[i].id);
          features.push(feat);
        }
      }
    }
  }
  source.clear();
  source.addFeatures(features);
  featureSize = features.length;
}

export default {
  addLocations
};
