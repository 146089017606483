<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :width="width"
    :height="height" viewBox="0 0 11.501 6.563" :aria-labelledby="iconName" role="presentation">
    <title :id="iconName" lang="en">
      {{iconName}}
    </title>
    <g :fill=iconColor>
      <path id="Subtraction_40" :fill=iconColor d="M5.743,6.486C2,6.486,0.312,4.098,0.104,3.284c0.203-0.812,1.901-3.2,5.638-3.2
        s5.427,2.398,5.638,3.201C11.164,4.089,9.486,6.486,5.743,6.486z M5.852,1.374C4.797,1.375,3.942,2.23,3.941,3.285
        c0.001,1.055,0.856,1.91,1.911,1.911c1.055-0.001,1.91-0.856,1.911-1.911C7.762,2.23,6.907,1.375,5.852,1.374L5.852,1.374z" />
    </g>
  </svg>
</template>
<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: ''
      },
      width: {
        type: [Number, String],
        default: 24
      },
      height: {
        type: [Number, String],
        default: 24
      },
      iconColor: {
        type: String,
        default: '#FFFFFF'
      }
    }
  }

</script>

<style scoped>
  svg {
    display: inline-block;
    vertical-align: middle;
  }

</style>
