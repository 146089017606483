<template>
	<svg
		xmlns               = "http://www.w3.org/2000/svg"
		xmlns:xlink         = "http://www.w3.org/1999/xlink"
		x                   = "0px"
		y                   = "0px"
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 114.315 127.581"
		:aria-labelledby    = "iconName | translate"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName | translate}}
		</title>
     <g id="Group_814" transform="translate(-2612.866 1116.89)" >
    <path id="Path_652" :fill=iconColor d="M2676.301-1084.292l20.449,23.256c1.613,1.669,1.568,4.33-0.101,5.943l-20.628,22.55
      c-1.669,1.613-4.33,1.568-5.943-0.101l-20.975-23.256c-1.613-1.669-1.568-4.33,0.101-5.943l21.154-22.55
      C2672.027-1086.007,2674.688-1085.961,2676.301-1084.292z"/>

    <path id="Path_653" :fill=iconColor d="M2619.147-1101.219l14.236-15.333l27.454,30.093c0,0,2.885,5.099,2.075,6.969
      c-0.809,1.87-8.591,9.403-9.952,10.136c-1.361,0.733-5.596-1.756-5.596-1.756L2619.147-1101.219z"/>

    <path id="Path_654" :fill=iconColor d="M2727.18-1014.964l-14.236,15.333l-27.454-30.093c0,0-2.885-5.099-2.075-6.969
      c0.809-1.87,8.591-9.403,9.952-10.136c1.361-0.733,5.596,1.756,5.596,1.756L2727.18-1014.964z"/>

    <path id="Path_655" :fill=iconColor d="M2630.612-1042.59l8.546-0.004c0,0,0.179,9.686,7.608,16.861
      c4.577,4.356,10.178,7.488,16.285,9.107l0.004,8.714c-8.809-1.154-16.943-5.335-23.01-11.827
      C2629.982-1030.587,2630.612-1042.59,2630.612-1042.59z"/>

    <path id="Path_656" :fill=iconColor d="M2612.867-1042.708l9.128-0.006c0,0-0.5,15.7,12.335,29.682
      c12.834,13.982,28.432,14.589,28.432,14.589l0.339,9.134c0,0-20.324-0.26-35.5-17.362S2612.867-1042.708,2612.867-1042.708z"/>
  </g>
	</svg>
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'satellitePictures'
		},
		width: {
			type: [Number, String],
			default: 24
		},
		height: {
			type: [Number, String],
			default: 24
		},
		iconColor: {
			type: String,
			default: '#FFFFFF'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

</style>
