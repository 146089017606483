<template>
  <div v-if="map.getView().getProjection().getCode() !== 'EPSG:3067'" class="buttons-wrapper">
    <div v-for="(button, index) in buttons" :key="index" class="buttons-wrapper__buttons">
      <v-btn @click="openMapMenu(button, button.name)" color="secondary" :title="button.name | translate" medium flat
        icon>
        <component ref="component" :is="iconsHash[button.name]" :iconColor="button.id === setActiveButton ? '#FFFFFF' : '#FFFFFF'"
          :iconStroke="button.id === setActiveButton ? '#FFFFFF' : '#FFFFFF'">
        </component>
        <div v-if="button.name == menuTitles.spatialMenu && selecteSubLayers > 0" class="button-amount-circle">
          <span>
            {{ selecteSubLayers }}
          </span>
        </div>
        <div v-else-if="button.name == menuTitles.weatherMenu && selectedWeatherLayers > 0" class="button-amount-circle">
          <span>
            {{ selectedWeatherLayers }}
          </span>
        </div>
        <div v-else-if="button.name == menuTitles.satelliteMenu && satelliteLayersNumber > 0" class="button-amount-circle">
          <span>
            {{ satelliteLayersNumber  }} 
          </span>
        </div>
      </v-btn>
    </div>
  </div>
  <div v-else>
    <div class="buttons-wrapper__buttons">
      <v-btn @click="openMapMenu(mapsMenuOnly, mapsMenuOnly.name)" color="secondary" :title="mapsMenuOnly.name | translate"
        medium flat icon>

        <icon-map-base :iconColor="mapsMenuOnly.id === setActiveButton ? '#FFFFFF' : '#FFFFFF'" :iconStroke="mapsMenuOnly.id === setActiveButton ? '#FFFFFF' : '#FFFFFF'">
        </icon-map-base>

      </v-btn>
    </div>
  </div>
</template>

<script>
  import IconMapBase from '../svg-system-components/IconMapBase';
  import IconLayerBase from '../svg-system-components/IconLayerBase';
  import IconSpatielBase from '../svg-system-components/IconSpatielBase';
  import IconWeatherBaseMap from '../svg-system-components/IconWeatherBaseMap';
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import menuTitlesConfig from '../../maps-constructor/menu-titles.configs/configs';
  export default {
    components: {
      IconMapBase,
      IconLayerBase,
      IconSpatielBase,
      IconWeatherBaseMap
    },
    data() {
      return {
        iconsHash: {
          'maps': "IconMapBase",
          'satellitePictures': "IconLayerBase",
          'spatialInfo': "IconSpatielBase",
          'weather': "IconWeatherBaseMap"
        },
        activeButton: "",
        menuTitles: menuTitlesConfig,
        mapsMenuOnly: {}
      }
    },
    computed: {
      ...mapGetters({
        map: "getMap",
        buttons: 'getButtons',
        sidemenu: 'getshowBaseMapMenu',
        defaultButton: 'getDefaultButton',
        activeButtons: 'getButtonsActivity',
        selecteSubLayers: 'getSelecteSubLayers',
        selectedSpatialLayers: 'getSelectedSpatialLayers',
        selectedWeatherLayers: 'getSelectedWeatherLayers',
        satelliteLayersNumber: "getSelectedSatellitesLayers"
      }),
      setActiveButton() {
        let active = this.activeButtons.filter(button => button.isActive);
        this.activeButton = active[0].id;
        return active[0].id;
      },

    },
    methods: {
      ...mapActions(['setMenuIcon']),
      ...mapMutations(['closeBaseMap']),
      openMapMenu(button, name) {
        if (
          this.activeButton == button.id &&
          this.sidemenu === true
        ) {
          this.closeBaseMap();
        } else {
          this.$router.push({
            name: "menu",
            params: {
              name: name
            }
          });
          this.setMenuIcon(button);
          let active = this.activeButtons.filter(button => button.isActive);
          this.activeButton = active[0].id;
        }
      }
    },
    mounted() {
      if (this.$route.params.name) {
        let openedButton = this.$route.params.name == this.defaultButton.name ?
          this.defaultButton :
          this.buttons.filter(button => this.$route.params.name === button.name);
        if (Array.isArray(openedButton)) {
          this.setMenuIcon(openedButton[0]);
        } else {
          this.setMenuIcon(openedButton);
        }
      }

      let active = this.activeButtons.filter(button => button.isActive);
      this.activeButton = active[0].id;


      let mapsMenu = this.buttons.filter(button => button.name == menuTitlesConfig.mapsMenu)[0];
      this.mapsMenuOnly = mapsMenu;

    }
  }

</script>

<style scoped lang="scss">
  .buttons-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, minmax(1rem, auto));
    border-bottom: solid 1px #127dc1;
    padding: .5rem 0rem;

    .buttons-wrapper__buttons {
      box-sizing: border-box;
    }

    .button-amount-circle {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #C81740;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .buttons-wrapper__map--3067Projection {
    display: none;
  }

</style>
