import Vue from 'vue'

const state = {
  fallback: 'en',
  locale: 'en'
};

const mutations = {
  setCulture(state, payload) {
    state.locale = payload
    Vue.i18n.set(state.locale);
  }
};

const getters = {
  language(state) {
    return state.locale
  }
};

const actions = {
  changeCulture({
    commit
  }, payload) {
    commit('setCulture', payload)
  }
};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
