<template>
  <div class="menu-list__wrapper">
    <ul class="sidemenu__list">
      <li class="list__item" v-for="(item, index) in menu" :key="index" @click="initializeSatelliteType(item.name, item);infoIndex=index">
        <!-- wrapper for weather element -->
        <div class="list__item--general">
          <img :src="item.icon" width="24px" height="24px">
          <!-- names of weather menu elements -->
          <span :class="{'invisible-layer-text' : !item.selected}">
            {{ item.name | translate }}
          </span>

          <!-- container for visibility status -->
          <div class="item-eyes-container">
            <icon-close-eye-base v-if="item.name !== getSelectedType" icon-name="invisible" icon-color="#fff" width="18"
              height="10">
            </icon-close-eye-base>

            <icon-open-eye-base v-if="item.name == getSelectedType" icon-name="visible" icon-color="#fff" width="18"
              height="9">
            </icon-open-eye-base>
          </div>
        </div>
        <div v-if="infoIndex===index && currentLayerInfo" class="menu-info">
          {{ currentLayerInfo !== "" ? currentLayerInfo + "Details" : "" | translate }}
        </div>
      </li>
    </ul>
    <div class="portal-link">
		  <a :href="'portalLink' | translate">
        <img style="width: 120px;vertical-align: middle;" :src="'https://meriopas.ymparisto.fi/meriopas/static/logos/itamerilogo ' + currentLanguage.name + ' nega.svg'" alt="">
      </a>
    </div>  
  </div>
</template>

<script>
  // refactoring part
  import mapApi from '../../maps-constructor/mapsInit';
  import utils from './menu-satellites-utils.js/utils';
  import satelliteLayersConf from '../../maps-constructor/satellite-layers.configs/config';
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex';
  import IconCloseEyeBase from '../svg-system-components/IconCloseEyeBase';
  import IconOpenEyeBase from '../svg-system-components/IconOpenEyeBase';

  export default {
    components: {
      IconCloseEyeBase,
      IconOpenEyeBase,
    },
    props: ['map'],
    data() {
      return {
        menu: utils.layersMenu,
        infoIndex: null
      }
    },
    computed: {
      ...mapGetters({
        getMap: 'getMap',
        satelliteLayersNumber: 'getSelectedSatellitesLayers',
        getLoadingStatus: 'getLoadingStatus',
        getSelectedType: 'getSelectedType',
        currentLayerInfo: 'getCurrentLayerInfoSatellite',
        currentLanguage: 'getCurrentLanguage'
      })
    },
    methods: {
      ...mapActions(
        [
          'setVisibleLayer',
          'loadWaterQualityTurbidityLayers',
          'loadWaterSurfaceTempLayers',
          'loadSatellitesLayers',
          'hideSatelliteLayersGroups'
        ]
      ),
      ...mapMutations({
        setSelectedLayersWithCalendar: 'setSelectedLayersWithCalendar', 
        setSatellitesLayers: 'setSatellitesLayers', 
        setLoadingStatus: 'setLoadingStatus',
        setLayerLegendInfo: 'mapsMainLegendStore/setLayerLegendInfo',
        setSelectedType: 'setSelectedType',
        setCurrentLayerInfo: 'setCurrentLayerInfoSatellite'
      }),

      showLayerInfo(item) {
        console.log('satellite item >>> ', item);
        if (!this.currentLayerInfo && item.selected) {
          this.setCurrentLayerInfo(item.name);
        } else if (this.currentLayerInfo && item.selected) {
          this.setCurrentLayerInfo(item.name);
        } else {
          this.setCurrentLayerInfo("");
        }
      },

      initializeSatelliteType(name, item) {
        this.setLoadingStatus(true);
        console.log(item)
        if (item.type === satelliteLayersConf.waterQualityConfigs.type) {
          this.loadWaterQualityType(this.map, name, item)
        } else if (item.type === satelliteLayersConf.waterSurfaceConfigs.type) {
          this.loadWaterSurfaceType(this.map, name, item)
        } else if (item.type === satelliteLayersConf.satelliteConfigs.type) {
          this.loadSatellites(this.map, name, item);
          //set empty legend, since satellite layers don't have legend
          // this.closeLegend();
        } 
        setTimeout(() => {
          let satellitesAmount = this.getSelectedType !== "" ? 1 : 0;
          this.setSatellitesLayers(satellitesAmount);
          item.selected = !item.selected;
          this.showLayerInfo(item)
        }, 1000)
      },

      async loadWaterQualityType(map, name) {
        if (this.getSelectedType === "") {
          await this.loadWaterQualityTurbidityLayers({
            mapInstance: map,
            name: name
          });
          this.setSelectedType(name);
          let firstSelected = this.menu.filter(menuItem => menuItem.name === this.getSelectedType).length;
          this.setSelectedLayersWithCalendar(firstSelected)
        }
        // destroy already selected layer
        else if (this.getSelectedType === name) {
          this.hideSatelliteLayersGroups({
            map: this.map,
            name: name
          })
          mapApi.asyncVectorRefresh(this.map);
          this.setSelectedType("");
        } else {
          await this.loadWaterQualityTurbidityLayers({
            waterQualityType: this.getSelectedType,
            mapInstance: map,
            name: name
          });
          this.setSelectedType(name);
          let firstSelected = this.menu.filter(menuItem => menuItem.name === this.getSelectedType).length;
          this.setSelectedLayersWithCalendar(firstSelected)
        }
        this.setLoadingStatus(false);
      },

      async loadWaterSurfaceType(map, name) {
        if (this.getSelectedType === "") {
          console.log('name of layer >>> 11 ', name);
          await this.loadWaterSurfaceTempLayers({
            mapInstance: map,
            name: name
          });
          this.setSelectedType(name);
          let firstSelected = this.menu.filter(menuItem => menuItem.name === this.getSelectedType).length;
          this.setSelectedLayersWithCalendar(firstSelected)
        }
        // destroy already selected layer
        else if (this.getSelectedType === name) {
          this.hideSatelliteLayersGroups({
            map: this.map,
            name: name
          })
          mapApi.asyncVectorRefresh(this.map);
          this.setSelectedType("");
        } else {
          await this.loadWaterSurfaceTempLayers({
            waterSurfaceType: this.getSelectedType,
            mapInstance: map,
            name: name
          });
          this.setSelectedType(name);
          let firstSelected = this.menu.filter(menuItem => menuItem.name === this.getSelectedType).length;
          this.setSelectedLayersWithCalendar(firstSelected)
        }
        this.setLoadingStatus(false);
      },

      async loadSatellites(map, name, item) {
        console.log('satellites loading >>>> ', item);
        if (this.getSelectedType === "") {
          console.log('name of layer >>> ', name);
          await this.loadSatellitesLayers({
            mapInstance: map,
            name: name,
            item: item
          });
          this.setSelectedType(name);
          let firstSelected = this.menu.filter(menuItem => menuItem.name === this.getSelectedType).length;
          this.setSelectedLayersWithCalendar(firstSelected);
        }
        // here we have to destroy already selected layer
        else if (this.getSelectedType === name) {
          this.hideSatelliteLayersGroups({
            map: this.map,
            name: name
          })
          mapApi.asyncVectorRefresh(this.map);
          this.setSelectedType("");
        } else {
          await this.loadSatellitesLayers({
            satelliteType: this.getSelectedType,
            mapInstance: map,
            name: name,
            item: item
          });
          this.setSelectedType(name);
          let firstSelected = this.menu.filter(menuItem => menuItem.name === this.getSelectedType).length;
          this.setSelectedLayersWithCalendar(firstSelected);
        }
        this.setLoadingStatus(false);
      }
    }
  }

</script>

<style scoped lang="scss">
  .menu-list__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 47px);
  }

  .sidemenu__list {
    @include list;

    .list__item {
      .list__item--general {
        padding: 0 10px;
        height: 46px;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        justify-content: space-between;

        span {
          width: 80%;
          margin-left: 9px;
        }

        .invisible-layer-text {
          opacity: 0.8;
        }

        .item-eyes-container {
          margin-right: 0px;
        }
      }
    }

    .list__item--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .menu-info {
    background-color: #074679;
    max-height: 250px;
    overflow-y: auto;
    margin: 0;
    padding: 1rem;
    word-break: break-word;
    color: white;
    text-align: left;
    overflow-x: hidden;
  }
	.portal-link-description {
    color: white;
  }
  
  .portal-link-image {
    height: 100px;
    width: 162px;
  }
</style>
