<template>
  <transition name="fadeHeight" mode="in-out">
    <div v-if="layerLegend.isActive" class="layer-legend-wrapper" :class="{'layer-legend-wrapper--menu-close' : !baseMenuVisibility}">
      <div class="legend-frame">
        <img :src="layerLegend.legendURL" class="legend-frame_img" alt="">
        <v-btn @click="closeLegend()" class="legend-button--close" flat>
          x
      	</v-btn>
      </div>
    </div>
  </transition>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex';
  export default {
    computed: {
      ...mapGetters({
        layerLegend: 'mapsMainLegendStore/getLayersLegendInfo',
        baseMenuVisibility: 'getshowBaseMapMenu'
      }),
    },
    methods: {
      closeLegend() {
        this.layerLegend.isActive = false;
      }
    }
  }

</script>

<style scoped lang="scss">
  .layer-legend-wrapper {
    position: absolute;
    bottom: 0;
    left: 19rem;
    margin-left: 10px;
    transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 92;
  }

  .layer-legend-wrapper--menu-close {
    position: absolute;
    bottom: 0;
    left: 0rem;
    margin-left: 10px;
    transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .fadeHeight-enter-active,
  .fadeHeight-leave-active {
    transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
  }

  .fadeHeight-enter,
  .fadeHeight-leave-to {
    transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  .legend-frame
  {
    background-color: white;
    border: 3px solid #052D4D;
    border-radius: 5px;
  }
    .legend-frame_img
  {
    border-radius: 5px;
  }
  .legend-button--close {
    color: rgb(5, 45, 77) !important;
    font-weight: bold;
    margin-right: 0;
    margin-top: 0;
    min-width: 30px;
    padding: 0;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .legend-button--close:hover {
    background-color: rgba(255, 255, 255, 255);
    position: absolute;
  }

</style>