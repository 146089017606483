<template>
  <div v-show="calendar.visible" class="calendar-wrapper">
    <div id="calendar-widget-wrapper" v-show="openCalendarWidget" class="calendar-widget-wrapper" :class="openCalendarWidget
									&& calendar.groupName == 'Water surface - algal blooms'
									? 'calendar-widget-wrapper-algal--visible'
									: 'calendar-widget-wrapper--visible'
									">
      <div class="calendar-header">
	<p>
        <icon-calendar-base icon-name="header" icon-color="#ffffff" width="28" height="30">
        </icon-calendar-base>
	{{ 'calendar' | translate }}
	</p>
      </div>
      <v-list v-if="activeList.length > 0">
        <v-list-tile v-for="(item, index) in activeList" :key="index" @click="setVisible(item)" v-ripple :class="{'layers-menu__visible-item' : item.visible}">
          <v-list-tile-title>
            {{ item.finnishName | translate }}
          </v-list-tile-title>
          <div class="item-eyes-container">
            <icon-close-eye-base v-if="!item.visible" icon-name="invisible" icon-color="#696969" width="18" height="10">
            </icon-close-eye-base>
            <icon-open-eye-base v-if="item.visible" icon-name="visible" icon-color="#000000" width="18" height="9">
            </icon-open-eye-base>
          </div>
        </v-list-tile>
      </v-list>
    </div>
    <div class="calendar-button__wrapper" v-click-outside-calendar="closeCalendarWidget">
      <v-btn slot="activator" color="secondary" @click="selectFirstLayer(calendar)" medium flat icon>
        <icon-calendar-high-light></icon-calendar-high-light>
        <div class="button-amount-circle" v-if="selectedLayersWithCalendar > 0">
          <span>
            {{ selectedLayersWithCalendar }}
          </span>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import mapApi from "../../maps-constructor/mapsInit";
  import satelliteLayersConf from '../../maps-constructor/satellite-layers.configs/config';

  import IconCloseEyeBase from "../svg-system-components/IconCloseEyeBase";
  import IconOpenEyeBase from "../svg-system-components/IconOpenEyeBase";
  import IconCalendarBase from "../svg-system-components/IconCalendarBase";
  import IconCalendarHighLight from "../svg-system-components/IconCalendarHighLight";
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from "vuex";
  //directives
  import clickOutsideCalendarDirective from "./directives/clickOutsideCalendarDirective";
  export default {
    components: {
      IconCalendarHighLight,
      IconCloseEyeBase,
      IconOpenEyeBase,
      IconCalendarBase
    },
    props: ["map"],
    data() {
      return {
        openCalendarWidget: false,
        activeList: "",
        allSelectedLayersWithDates: [{
            name: "Water quality",
            id: 0,
            amount: 0
          },
          {
            name: "Water temperature",
            id: 1,
            amount: 0
          },
          {
            name: "Satellites",
            id: 2,
            amount: 0
          }
        ]
      };
    },
    computed: {
      ...mapGetters({
        calendar: "getCalendar",
        turbidityLayers: "getWaterQualityTurbidityLayers",
        watersurfaceLayers: "getWaterSurfaceTempLayers",
        selectedWaterTemperatureLayers: "getSelectedWaterTemperatureLayers",
        selectedWaterQualityLayers: "getSelectedWaterQualityLayers",
        satellitesLayers: "getSatelliteLayers",
        selectedLayersWithCalendar: "getSelectedLayersWithCalendar"
      }),
    },
    watch: {
      calendar(newV, oldV) {
        if (oldV !== newV) {
          if (newV.name && newV.type == satelliteLayersConf.waterQualityConfigs.type) {
            this.activeList = this.turbidityLayers;
          } else if (newV.name && newV.type == satelliteLayersConf.waterSurfaceConfigs.type) {
            this.activeList = this.watersurfaceLayers;
          } else if (newV.name && newV.type == satelliteLayersConf.satelliteConfigs.type) {
            this.activeList = this.satellitesLayers;
          }
          if (newV.visible) {
            this.selectFirstLayer(this.calendar);
          }
        }
      }
    },
    methods: {

      ...mapActions([
        "fetchWaterQualityTurbidityLayers",
        "fetchWaterSurfaceTempLayers",
        "setVisibleLayer",
        "setSelectedWaterTemperatureLayersInSubLayers",
        "setsetSelectedWaterQualityLayersInSubLayers"
      ]),
      ...mapActions({
        setLegendToLayersVisibility: 'mapsMainLegendStore/setLegendToLayersVisibility'
      }),

      ...mapMutations(["closeBaseMap", "setSelectedLayersWithCalendar", "setSatellitesLayers"]),

      async changeVisibility(name) {
        await this.setVisibleLayer({
          mapInstance: this.map,
          name: name,
          layerGroupName: this.calendar.name
        });
      },

      setVisible(item) {
        this.activeList.forEach((layer) => {
	  if (item.name === layer.name) {
	    this.changeVisibility(item.name);
	  }
	  else if (item.name !== layer.name && layer.visible == true)  { // only one date can be active
	    this.changeVisibility(layer.name);
	  }
	});
        let selectedSatellitesLayerAmount = this.activeList.filter(layer => layer.visible).length;
        this.setSelectedLayersWithCalendar(selectedSatellitesLayerAmount);
        mapApi.asyncVectorRefresh(this.map);
      },

      selectFirstLayer(calendar) {
        this.openCalendarWidget = !this.openCalendarWidget;
      },

      closeCalendarWidget() {
        this.openCalendarWidget = false;
      }
    },

    directives: {
      "click-outside-calendar": clickOutsideCalendarDirective
    }
  };

</script>

<style scoped lang="scss">
  // initial state of a calendar
  .calendar-widget-wrapper {
    width: auto;
    height: auto;
    max-height: 0;
    position: absolute;
    bottom: calc(100% + 10px);
    left: right;
    right: 0%;
    overflow-y: auto;
    border-radius: 5px;
    opacity: 1 !important;

    .calendar-header {
      p {
        background-color: #052D4D;
        border-bottom: 2px solid #074679;
        color: #FFFFFF;
        font-family: 'Montserrat-Semibold';
        font-size: 15px;
	margin-bottom: 0px;
	padding-bottom: 2px;
	padding-top: 2px;
      }
    }
  }

  // open state of calendr widget
  .calendar-widget-wrapper--visible {
    border: 2px solid #1884bf;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: auto;
    height: auto;
    max-height: 15rem;
    position: absolute;
    bottom: 0;
    left: right;
    right: 64px;
    overflow-y: auto;
    border-radius: 5px;
    opacity: 1 !important;

    .item-eyes-container {
      margin-left: 11.5px;
    }
  }

  .calendar-widget-wrapper-algal--visible {
    width: auto;
    height: auto;
    position: absolute;
    bottom: calc(100% + 10px);
    left: right;
    right: 0%;
    overflow-y: auto;
    border-radius: 5px;
    opacity: 1 !important;
  }

  // circle with amount of layers
  .button-amount-circle {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #C81740;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>
