import Vector 	            from 'ol/layer/vector';
import WMSAxiosAPI          from '../../axios-wms-conf/axiosWmsConf';
import featuresWindows      from '../mapFeatureLogic';

let mapInitUtils = {
    checkWMSLayers,
    getFeatureInfo,
    addingFeatureWindowLogic
};

function checkWMSLayers(layer) {
    if(!(layer instanceof Vector) && layer.values_.type !== "base") {
        return true;
    }
    else {
        return false;
    }
}

function getFeatureInfo(url) {
    return new Promise((resolve, reject) => {
        let axios = WMSAxiosAPI.createRequest(url);
        axios
            .get()
            .then((data)=> {
                resolve(data.data);
            })
    })
}

function addingFeatureWindowLogic(map, element){
    return featuresWindows.WMSFeaturesLogic(map, element)
}

export default mapInitUtils;
