<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
	export default {
		name: 'App'
	}
</script>

<style>
	@font-face {
		font-family: 'Montserrat';
		src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
		font-weight	: normal;
		font-style	: normal;
	}

	@font-face {
		font-family: 'Montserrat-Semibold';
		src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
		font-weight	: normal;
		font-style	: normal;
	}

	@font-face {
		font-family: 'Montserrat-Bold';
		src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
		font-weight	: normal;
		font-style	: normal;
	}

	#app {
		font-family: 'Montserrat', 'Montserrat-Semibold', 'Montserrat-Bold', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align	: center;
		color		: #2c3e50;
		height		: 100%;
		width		: 100%; 
	}
	.list__tile__title {
		text-overflow: initial;
	}
	.list__tile {
		overflow-x: hidden;
	}
	@-moz-document url-prefix() {
		.list__tile__title{
			min-width: 160px;
		}
	}
</style>
