<template>
  <div class="sponsors-wrapper">
    <img class="main-one" src="@/assets/sponsors/aland.png" alt="">
	  <img class="main-two" :src="'https://meriopas.ymparisto.fi/meriopas/static/sponsors/emkr_tunnus_' + currentLanguage.name + '.png'"  alt="">
    <img class="main-three" src="@/assets/sponsors/EUflag_yellow_high.jpg" alt="">

    <img class="general" src="@/assets/sponsors/SYKE-logo.jpg" alt="">
    <img class="general" :src="'https://meriopas.ymparisto.fi/meriopas/static/sponsors/luke_' + currentLanguage.name + '.png'"  alt="">
    <img class="general" src="@/assets/sponsors/GTK-logo_2019_fi_en_sv.png" alt="">

    <img class="general" :src="'https://meriopas.ymparisto.fi/meriopas/static/sponsors/Museovirasto_logo_' + currentLanguage.name + '.jpg'"  alt="">
    <img class="general" src="@/assets/sponsors/council.png" alt="">
    <img class="general" src="@//assets/sponsors/il-logo-fi-se-en-rgb.jpg" alt="">

    <img class="general" :src="'https://meriopas.ymparisto.fi/meriopas/static/sponsors/TurunYliopisto_' + currentLanguage.name + '.jpg'"  alt="">
    <img class="general" src="@/assets/sponsors/metsahallitus.jpg"  alt="">
    <img class="general" src="@/assets/sponsors/TMFG_logo_reduced_white_space.png" alt="">

  </div>
</template>

<script>
  import {
    mapGetters,
  } from 'vuex';
  export default {
    computed: {
      ...mapGetters({
      currentLanguage: 'getCurrentLanguage'
      }),
    }
  }
</script>

<style scoped lang="scss">
  .sponsors-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, minmax(3rem, auto));
    color: black;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    background-color: #fff;
    align-items: center;

    img {
      max-width: 100%;
    }

    .general {
      padding: 0 5px;
    }
  }

</style>
