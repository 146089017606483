<template>
  <div class="tools-wrapper">

    <div class="tools">
      <div v-for="(item, index) in languages" :key="index" :class="currentLanguage.name == item.name ? 'tools__single-wrapper-active' : 'tools__single-wrapper'">
        <v-btn @click="setNewLanguage(item)" class="languages-button" color="secondary" medium flat icon>
	  <span>{{ item.name }}</span>
        </v-btn>
      </div>
    </div>
    <div class="tools-close" @click="deactivateMenuTools()">
      <v-btn class="control-menu-button control-menu-button--close" color="secondary" large flat icon>
        <icon-tools-close-base>
        </icon-tools-close-base>
      </v-btn>
    </div>
  </div>
</template>

<script>
  // vue-loader@13.7.1
  import IconToolsCloseBase from '../../svg-system-components/icons-tools/IconToolsCloseBase';
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex';
  export default {
    components: {
      IconToolsCloseBase
    },
    computed: {
      ...mapGetters({
        languages: 'getLanguages',
        languageMenu: 'getLanguageToolsMenu',
	currentLanguage: 'getCurrentLanguage'
      }),
      ...mapGetters('translationsStore', {
        language: 'language'
      })
    },
    methods: {
      ...mapActions('translationsStore', [
        'changeCulture'
      ]),
      ...mapMutations(['setActivateLanguageMenu', 'setCurrentLanguage']),
      deactivateMenuTools() {
        this.setActivateLanguageMenu();
      },
      setNewLanguage(currentLanguage) {
        let languageRequest = {
          icon: currentLanguage.icon,
          name: currentLanguage.name
        }
        this.setCurrentLanguage(languageRequest);

        this.changeCulture(currentLanguage.name);
	this.deactivateMenuTools();
      }
    },
    mounted() {}
  }

</script>


<style scoped lang="scss">
  .tools-wrapper {
    .tools {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, minmax(1rem, auto));
      flex-basis: 70%;

      div {
        cursor: pointer;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-right: 1px solid #1884bf;

        &:first-of-type {
          border-left: 1px solid #1884bf;
        }
	}
    }

    .tools__single-wrapper {
      background-color: #074679;
    }

    .tools__single-wrapper-active {
      background-color: #052D4D;
    }
    
    .tools-close {
      flex-basis: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;

      .control-menu-button--close {
        margin: 0;
      }
    }
  }

</style>
