const state = {
  weatherPopUpWindow: {},
  WMSPopUpWindowData: {},
  WMSComplexPopUpWindowData: {},
};

const getters = {
  getWeatherPopUpWindow(state) {
    return state.weatherPopUpWindow
  },
  getWMSPopUpWindowData(state) {
    return state.WMSPopUpWindowData
  },
  getWMSComplexPopUpWindowData(state) {
    return state.WMSComplexPopUpWindowData;
  }
};

const mutations = {
  setWeatherPopUpWindow(state, payload) {
    state.weatherPopUpWindow = payload
  },
  setWMSPopUpWindowData(state, payload) {
    state.WMSPopUpWindowData = payload;
  },
  setWMSComplexPopUpWindowData(state, payload) {
    state.WMSComplexPopUpWindowData = payload;
  }
};

const actions = {
  createInfoWindows({
    commit
  }, payload) {
    commit('setWeatherPopUpWindow', payload);
  },
  createWMSInfoWindows({
    commit
  }, payload) {
    commit('setWMSPopUpWindowData', payload);
  },
  createWMSComplexWindows({
    commit
  }, payload) {
    if (payload) {
      let properties = payload.find((props) => {
        if (props && props.properties) {
          for (const key in props.properties) {
            if (props.properties.hasOwnProperty(key)) {
              // flatten object
              if (typeof props.properties[key] === 'object') {
                for (const key2 in props.properties[key]) {
                  if (props.properties[key] && props.properties[key].hasOwnProperty(key2)) {
                    props.properties[key2] = props.properties[key][key2];
                  }
                  delete props.properties[key];
                }
              }
            }
          }
          return props;
        }
      })
      commit('setWMSComplexPopUpWindowData', properties);
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
