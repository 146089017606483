// open layers imports
import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import spatialConfiguration from './spatial-constructor/spatial-config';

import moment from 'moment';

// configs for retrieving layer
const url = 'https://paikkatieto.ymparisto.fi/arcgis/services/Kansalaishavainnot/kansalaishavainnot_havaintotiedot_v2/MapServer/WMSServer?';
let model = [
  new TileLayer({
    id: 0,
    zIndex: 1,
    finnishName: spatialConfiguration.ALGAEBLOOM_NAME,
    name: spatialConfiguration.ALGAEBLOOM_NAME,
    dataType: 'GeoJSON',
    visible: false,
    source: new TileWMS({
      url: url,
      params: {
        'LAYERS': 'havaintotiedot_algae',
        'TILED': true,
        'STYLES': 'meriopas',
        'LAYERDEFS': '{\"havaintotiedot_algae\":\"ObservationBeginTimeDATE>=timestamp\'' + moment.utc().subtract(3, "days").format("DD-MM-YYYY") + ' 03:00:00\'\"}'
      },
      transparent: 'true',
      projection: "EPSG:3857",
      crossOrigin: 'anonymous',
      // reprojectionErrorThreshold: 100,
    }),
  })
];

export default model;
