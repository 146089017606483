<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 16 20"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName}} icon
		</title>
        <rect :fill=iconColor id="Rectangle_100" width="16" height="1.5"/>
        <path :fill=iconColor id="Path_152" d="M8.776,1.694l0.01,16.524H7.359L7.276,1.694H8.776z"/>
        <path :fill=iconColor id="Path_150" d="M8.549,2.447l-3,5.196L4.25,6.893l3-5.196L8.549,2.447z"/>
        <path :fill=iconColor id="Path_151" d="M11.799,13.02l-3,5.196L7.5,17.466l3-5.196L11.799,13.02z"/>
        <rect :fill=iconColor id="Rectangle_104" x="8.899" y="1.67" transform="matrix(0.866 -0.5 0.5 0.866 -1.0423 5.4504)" width="1.5" height="6"/>
        <rect :fill=iconColor id="Rectangle_105" x="5.75" y="12.243" transform="matrix(0.866 -0.5 0.5 0.866 -6.7506 5.2924)" width="1.5" height="6"/>
        <rect :fill=iconColor id="Rectangle_101" y="18.5" width="16" height="1.5"/>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'box'
		},
		width: {
			type: [Number, String],
			default: 16
		},
		height: {
			type: [Number, String],
			default: 20
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

</style>