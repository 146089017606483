var moment = require('moment');
import satelliteLayersConfig from './satellite-layers.configs/config';
import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import mapApi from './mapsInit';

let waterSurfaceCompabilitiesURL = satelliteLayersConfig.waterSurfaceConfigs.WMScapabilitiesURL;
let waterSurfaceURL = satelliteLayersConfig.waterSurfaceConfigs.layerRequestURL;
let surface = [];
let seaSurfaceTimeDimensions = [];
let lastSeaSurfaceTime = [];
let chlrophyllTimeDimensions = [];
let lastChlrophyllTime = [];
let algaeTimeDimensions = {};
let lastAlgaeTime = {};

function _buildersatelliteProductLayers(url, names, type) {
  return new Promise((resolve, reject) => {
    let satelliteProductLayers = [];

    // if layer type is Sea surface temperature  
    if (type === satelliteLayersConfig.waterSurfaceConfigs.surfaceTemperature) {
      let name = names[names.length - 1];
      lastSeaSurfaceTime.forEach((time, index) => {
        let timeDimension = lastSeaSurfaceTime[index];
        let visibleID = index;
        let LayerCounctructor = new TileLayer({
          id: index++,
          finnishName: layerTime(time),
          name: layerTime(time),
          originalName: layerTime(time),
          layerLegendURL: name.layerLegendURL,
          visible: visibleID === 0 ? true : false,
          source: new TileWMS({
            url: url,
            params: {
              'TILED': true,
              'LAYERS': `${name.layerOriginalName}`,
              'TIME': timeDimension,
            },
            transparent: 'false',
            projection: `EPSG:${name.layerEPSG}`,
            crossOrigin: 'anonymous'
          })
        });
        satelliteProductLayers.push(LayerCounctructor);
        })
        if (satelliteProductLayers.length == satelliteLayersConfig.waterSurfaceConfigs.layersPerGroup) {
          resolve(satelliteProductLayers)
        }
    } else if (type === satelliteLayersConfig.waterSurfaceConfigs.algalBlooms) {
      let times = Object.keys(lastAlgaeTime);
      times.forEach((time, index) => { // adding the most recent dates
	    let nIndex = 0;  
	    let layer = names[nIndex];
	    while (lastAlgaeTime[time][0] != layer.layerOriginalName) {
          nIndex++;
          layer = names[nIndex];
      }
      lastAlgaeTime[time].sort(); // wanted drawing order is in this case reversed alphabetical order
      lastAlgaeTime[time].reverse();  
      let layerStr = lastAlgaeTime[time][0];
      let stylesStr = 'eo_algae_index_fin_v2';
      lastAlgaeTime[time].forEach((layerName, index) => {
        //if (index > 0) {
        //layerStr = layerName; // testaus 29.9.20
        layerStr += ',' + layerName;
        stylesStr += ',' + stylesStr;
        //}
      })
  
      let visibleID = index;
	    let LayerCounctructor = new TileLayer({
          id: index++,
          finnishName: layerTime(time),
          name: layerTime(time),
          originalName: layerTime(time),
          visible: visibleID === 0 ? true : false,
          source: new TileWMS({
            url: url,
            params: {
              'TILED': true,
              'LAYERS': `${layerStr}`,
              'TIME': time,
              'STYLES': `${stylesStr}`
            },
            transparent: 'false',
            projection: `EPSG:${layer.layerEPSG}`,
            crossOrigin: 'anonymous'
          })
        });
        satelliteProductLayers.push(LayerCounctructor);
      })
      let index = satelliteProductLayers.length;
      let name = names[names.length - 1];
      names.forEach((name) => { // adding the composite dates
        if (name.layerOriginalName.indexOf('EO_OLCI_ALGAE_v1_season_composite') > -1) {
	        let visibleID = index;      
          let LayerCounctructor = new TileLayer({
            id: index++,
            finnishName: name.layerName,
            name: name.layerName,
            originalName: name.layerOriginalName,
            layerLegendURL: name.layerLegendURL,
            visible: visibleID === 0 ? true : false,
            source: new TileWMS({
              url: url,
              params: {
                'TILED': true,
                'LAYERS': `${name.layerOriginalName}`,
                'TRANSPARENT': 'TRUE',
                'FORMAT': 'image/png',
                'STYLES': 'eo_algae_index_fin_v2'
              },
              transparent: 'false',
              projection: `EPSG:${name.layerEPSG}`,
              crossOrigin: 'anonymous'
            })
          });
	        satelliteProductLayers.push(LayerCounctructor);
        }
      })
      resolve(satelliteProductLayers);
    } else if (type === satelliteLayersConfig.waterSurfaceConfigs.chlrophyll) {
      let name = names[names.length - 1];
      lastChlrophyllTime.forEach((time, index) => {
        let timeDimension = lastChlrophyllTime[index];
        let visibleID = index;
        let LayerCounctructor = new TileLayer({
          id: index++,
          finnishName: layerTime(time),
          name: layerTime(time),
          originalName: layerTime(time),
          layerLegendURL: name.layerLegendURL,
          visible: visibleID === 0 ? true : false,
          source: new TileWMS({
            url: url,
            params: {
              'TILED': true,
              'LAYERS': `${name.layerOriginalName}`,
              'TIME': timeDimension
            },
            transparent: 'true',
            projection: `EPSG:${name.layerEPSG}`,
            crossOrigin: 'anonymous'
          }),
          opacity: 0.6
        });
        satelliteProductLayers.push(LayerCounctructor);
        if (satelliteProductLayers.length == satelliteLayersConfig.waterSurfaceConfigs.layersPerGroup) {
          resolve(satelliteProductLayers)
        }
      })
    }

  })
}

function addWaterSurface(type) {
  return new Promise((resolve, reject) => {
    let typedLayers = [];
    mapApi.parseWaterQualityWMS({
        map: 'map',
        url: waterSurfaceCompabilitiesURL
      })
      .then((wms) => {
        let layers = wms.Capability.Layer.Layer;

        if (type === satelliteLayersConfig.waterSurfaceConfigs.algalBlooms) {
          algaeTimeDimensions = {};
          layers.forEach((layer) => {
            if (layer.Name === 'EO_HR_WQ_LC8_ALGAE') {
              typedLayers.push(layer);
              algaeTimeDimensions[layer.Name] = layer.Dimension[0].values.split(",");
            } else if (layer.Name === 'EO_HR_WQ_S2_ALGAE') {
              typedLayers.push(layer);
              algaeTimeDimensions[layer.Name] = layer.Dimension[0].values.split(",");
              //  'EO_OLCI_ALGAE_v1' => 'EO_MR_OLCI_ALGAE'
            } else if (layer.Name === 'EO_MR_OLCI_ALGAE') {
              typedLayers.push(layer);
              algaeTimeDimensions[layer.Name] = layer.Dimension[0].values.split(",");
            } else if (layer.Name.indexOf("EO_OLCI_ALGAE_v1_season_composite") > -1) {
              typedLayers.push(layer);
            }
          })
          lastAlgaeTime = mapApi.getLastTimesMap(algaeTimeDimensions); 
        } else if (type === satelliteLayersConfig.waterSurfaceConfigs.surfaceTemperature) {
          layers.forEach((layer) => {
            if (layer.Name.indexOf("EO_SST") > -1) {
              typedLayers.push(layer);
              seaSurfaceTimeDimensions = layer.Dimension[0].values.split(",");
            }
          })
          lastSeaSurfaceTime = mapApi.getLastTimeDimension(seaSurfaceTimeDimensions);
        } else if (type === satelliteLayersConfig.waterSurfaceConfigs.chlrophyll) {
          layers.forEach((layer) => {
            if (layer.Name.indexOf("MER_FSG_L3_rp2017_chla") > -1) {
              typedLayers.push(layer);
              chlrophyllTimeDimensions = layer.Dimension[0].values.split(",");
            }
          })
          lastChlrophyllTime = mapApi.getLastTimeDimension(chlrophyllTimeDimensions);
        }

        surface = typedLayers;

        let formatedNames = surface.map((layer) => {
          let newLayer = {
            layerName: layer.Name,
            layerOriginalName: layer.Name,
            layerDesc: layer.Abstract,
            layerLegendURL: layer.Style[0].LegendURL[0].OnlineResource.replace('https://geoserver2.ymparisto.fi', satelliteLayersConfig.waterSurfaceConfigs.replacePartURL),
            layerEPSG: layer.CRS[0]
          }
          return newLayer;
        })
        _buildersatelliteProductLayers(waterSurfaceURL, formatedNames, type)
          .then((buildedLayers) => {
            resolve(buildedLayers);
          })
      })
  })
}

function layerTime(time) {
  if (moment(time).format("DD-MM-YYYY hh:mm A", true)) {
    let date = moment(time).format("DD.MM.YYYY");
    return date;
  } else {
    return "without_time"
  }
}

export default addWaterSurface;
