import mapApi from '../../maps-constructor/mapsInit';

const state = {
  // layer legend component
  layerLegendInfo: {
    layerName: "",
    legendURL: "",
    isActive: false
  },
  legendHistory: []
};

const getters = {
  // get layers legend info
  getLayersLegendInfo(state) {
    return state.layerLegendInfo;
  }
}

const mutations = {
  setLayerLegendInfo(state, payload) {
    if (payload && payload.layerLegendURL && payload.visible) {
      let legendInfo = {};
      legendInfo.layerName = payload.layerName;
      legendInfo.legendURL = payload.layerLegendURL;
      legendInfo.isActive = true;
      state.layerLegendInfo = legendInfo;
      // if the history includes a satellite layer it is removed
      let legendIndex = 0;
      while (legendIndex < state.legendHistory.length) {
	let legend = state.legendHistory[legendIndex];
	if (legend.layerName === 'surfaceTemperature' ||
	    legend.layerName === 'algalBlooms' ||
	    legend.layerName === 'turbidity') {
	  state.legendHistory.splice(legendIndex, 1);
	  break;  
	}
	legendIndex++;  
      }	
      state.legendHistory.push(legendInfo);
    } else {
      // remove the legend from history
      let legendIndex = 0;
      while (legendIndex < state.legendHistory.length) {
	let legend = state.legendHistory[legendIndex];
	  if (payload.layerName === legend.layerName) {
	    state.legendHistory.splice(legendIndex, 1);
	  break;  
	}
	legendIndex++;  
      }
      // show the legend of the newest opened layer if any
      if (state.legendHistory.length) {
        state.layerLegendInfo = state.legendHistory[state.legendHistory.length-1];
      } else {
        state.layerLegendInfo.layerName = '';	
        state.layerLegendInfo.legendURL = '';
	state.layerLegendInfo.isActive = false;
      }
    }
  }
}

const actions = {
  setLegendToLayerName({
    commit
  }, payload) {
    let layers = getLayersNames(payload);
    let currentVisibleLayer;
    for (const layer of layers) {
      if (layer.visible === true) {
        currentVisibleLayer = layer;
        break;
      }
    }
    commit('setLayerLegendInfo', currentVisibleLayer);
  },
  setLegendToLayersVisibility({
    commit
  }, payload) {
    let layersCollection = mapApi.getPlannerGroup(payload.mapInstance, payload.groupName);
    let layers = getLayersNames(layersCollection);
    commit('setLayerLegendInfo', layers[payload.id]);
  }
}

function getLayersNames(layers) {
  let names = [];
  layers.forEach((layer) => {
    let obj = {};
    if (layer.values_.layerLegendURL !== undefined) {
      obj.layerLegendURL = layer.values_.layerLegendURL
    }
    obj.finnishName = layer.values_.finnishName;
    obj.visible = layer.values_.visible;
    obj.id = layer.values_.id;
    if (obj.finnishName) {
      names.push(obj)
    }
  })
  return names;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
