import Vector from 'ol/layer/vector';
import weatherConfigs from './weather-config';


let weatherLayers = [

  new Vector({
    id: 0,
    finnishName: weatherConfigs.weatherWindName,
    name: weatherConfigs.weatherWindName,
    visible: true,
    updateWhileInteracting: true,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/Wind_icon.png'
  }),

  new Vector({
    id: 1,
    finnishName: weatherConfigs.weatherWaveName,
    name: weatherConfigs.weatherWaveName,
    visible: true,
    updateWhileInteracting: true,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/Wave_icon.png'
  }),

  new Vector({
    id: 2,
    finnishName: weatherConfigs.weatherWaterHeightName,
    name: weatherConfigs.weatherWaterHeightName,
    visible: true,
    updateWhileInteracting: true,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/WaterHeight_icon.png'
  }),

  new Vector({
    id: 3,
    finnishName: weatherConfigs.weatherTemperatureName,
    name: weatherConfigs.weatherTemperatureName,
    visible: true,
    updateWhileInteracting: true,
    icon: 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/vesi_valikko.svg' //watertemperature_icon.png'
  }),

  // new Vector({
  //   id: 4,
  //   finnishName: weatherConfigs.weatherWaterTemperatureName,
  //   name: weatherConfigs.weatherWaterTemperatureName,
  //   visible: true,
  //   updateWhileInteracting: true,
  //   icon: '/static/weatherIcons/watertemperature_icon.png'
  // }),

];

export default weatherLayers;
