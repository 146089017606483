import Vue from 'vue'
import Router from 'vue-router'
import MapsMain from '@/components/MapsMain'
import MenuWrapper from '@/components/map-menus/MenuWrapper'

Vue.use(Router)

export default new Router({
  base: '/meriopas/',
  mode: 'history',
  routes: [{
    path: '/:location?/:zoom?',
    name: 'MapsMain',
    component: MapsMain,
    children: [
      {
        path: "menu/:name/:locationCoords?/:zoomLevel?",
        component: MenuWrapper,
        name: "menu",
        props: true
      }
    ]
  }]
})
