import { render, staticRenderFns } from "./ControlButtonsWrapper.vue?vue&type=template&id=6ca50381&scoped=true"
import script from "./ControlButtonsWrapper.vue?vue&type=script&lang=js"
export * from "./ControlButtonsWrapper.vue?vue&type=script&lang=js"
import style0 from "./ControlButtonsWrapper.vue?vue&type=style&index=0&id=6ca50381&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca50381",
  null
  
)

export default component.exports