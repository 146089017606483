<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 17.539 17.539"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName}} icon
		</title>
        <g id="plus1" transform="translate(-144.103 -925.686)">
            <g id="Group_167_1_" transform="translate(-42.629 -37.168)">
                <path id="Path_373_1_" style="fill:#2b77b2;" d="M204.271,971.008v2h-17.539v-2H204.271z"/>
                <path id="Path_374_1_" style="fill:#2b77b2;" d="M196.482,980.393h-2v-17.539h2V980.393z"/>
            </g>
        </g>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'box'
		},
		width: {
			type: [Number, String],
			default: 17.539
		},
		height: {
			type: [Number, String],
			default: 17.539
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

</style>