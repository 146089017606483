<template>
  <div class="tools-wrapper">
    <div class="tools">
      <div :class="drawMouseLine == true ? 'tools__single-wrapper-active' : 'tools__single-wrapper'" :title="'startDrawingLine' | translate" @click="startDrawingLine('LineString')">
        <v-btn class="control-menu-button" color="secondary" large flat icon>
          <icon-measure-ruller-base iconColor='#FFFFFF'>
          </icon-measure-ruller-base>
        </v-btn>
      </div>
      <div :class="drawMousePolygon == true ? 'tools__single-wrapper-active' : 'tools__single-wrapper'" :title="'startDrawingPolygon' | translate" @click="startDrawingPolygon('Polygon')">
        <v-btn class="control-menu-button" color="secondary" large flat icon>
          <icon-measure-square-base iconColor='#FFFFFF'>
          </icon-measure-square-base>
        </v-btn>
      </div>
    </div>
    <div class="tools-close" :title="'deactivateMenuTools' | translate" @click="deactivateMenuTools()">
      <v-btn class="control-menu-button control-menu-button--close" color="secondary" large flat icon>
        <icon-tools-close-base>
        </icon-tools-close-base>
      </v-btn>
    </div>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" max-width="290" content-class="custom-dialog-class">
        <v-card class="dialog-card">
          <v-card-title class="headline">Confirmation</v-card-title>
          <v-card-text>
            Delete all drawing features?
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" flat="flat" @click.native="dialog = false">Disagree</v-btn>
            <v-btn color="error" flat="flat" @click="removeAllDrawnFeatures">Agree</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="shareDialog" max-width="290" content-class="custom-dialog-class">
        <v-card class="dialog-card">
          <v-card-title class="headline">URL sharing</v-card-title>
          <v-card-text>
            Your Link - {{ path.href }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" flat="flat" @click.native="shareDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
  import mapApi from '../../../maps-constructor/mapsInit';
  import drawingToolsBehaviorApi from '../../../maps-constructor/drawingToolsBehavior';
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex';
  import IconMeasureRullerBase from '../../svg-system-components/icons-tools/IconMeasureRullerBase';
  import IconMeasureSquareBase from '../../svg-system-components/icons-tools/IconMeasureSquareBase';
  import IconShareBase from '../../svg-system-components/icons-tools/IconShareBase';
  import IconDeletedBase from '../../svg-system-components/icons-tools/IconDeletedBase';
  import IconToolsCloseBase from '../../svg-system-components/icons-tools/IconToolsCloseBase';
  export default {
    data() {
      return {
        sourceForDrawing: null,
        drawMouseLine: false,
        drawMousePolygon: false,
        lineDraw: null,
        polygonDraw: null,
        dialog: false,
        shareDialog: false,
        drawLine: null
      }
    },
    props: ['map'],
    components: {
      IconMeasureRullerBase,
      IconMeasureSquareBase,
      IconShareBase,
      IconDeletedBase,
      IconToolsCloseBase
    },
    computed: {
      ...mapGetters({
        drawingTools: 'getDrawingTools'
      }),
      path() {
        return window.location;
      }
    },
    methods: {
      ...mapMutations(['setActiveToolsMenu']),
      ...mapActions(['fetchDrawingTools', 'clearDrawingTools']),
      deactivateMenuTools() {
        this.setActiveToolsMenu();
      },
      startDrawingLine(type) {
        if (this.drawMouseLine == true) {
          this.drawMouseLine = false;
          this.map.removeInteraction(this.drawLine)
        } else {

          if (this.drawMousePolygon) {
            this.map.removeInteraction(this.polygonDraw)
            this.drawMousePolygon = false;
          }

          this.drawMouseLine = !this.drawMouseLine;

          this.drawLine = drawingToolsBehaviorApi.addInteraction(this.map, this.sourceForDrawing, type);

          this.drawLine.on('drawend', () => {
            this.drawMouseLine = !this.drawMouseLine;
          })
        }
      },
      startDrawingPolygon(type) {
        if (this.drawMousePolygon == true) {
          this.drawMousePolygon = false;
          this.map.removeInteraction(this.polygonDraw)
        } else {
          if (this.drawMouseLine) {
            this.map.removeInteraction(this.drawLine)
            this.drawMouseLine = false;
          }

          this.drawMousePolygon = !this.drawMousePolygon;

          this.polygonDraw = drawingToolsBehaviorApi.addInteraction(this.map, this.sourceForDrawing, type);

          this.polygonDraw.on('drawend', () => {
            this.drawMousePolygon = !this.drawMousePolygon;
          })
        }
      },
      removeAllDrawnFeatures() {
        this.clearDrawingTools({
          mapInstance: this.map,
          name: "Drawing tools"
        });
        let elements = document.querySelectorAll('.tooltip-static');
        elements.forEach((elem) => {
          elem.remove()
        });
        this.dialog = false;
      },
      shareLink() {
        console.log('link');
      }

    },
    async mounted() {
      await this.fetchDrawingTools({
        mapInstance: this.map,
        name: "Drawing tools"
      });
      this.map.on('pointermove', drawingToolsBehaviorApi.pointerMoveHandler);
      let drawingGroup = mapApi.getPlannerGroup(this.map, 'Drawing tools');
      let drawingSource = drawingGroup[0].getSource();
      this.sourceForDrawing = drawingSource;
    }
  }

</script>

<style scoped lang="scss">
  .tools-wrapper {
    .tools {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, minmax(1rem, auto));
      flex-basis: 70%;

      div {
        cursor: pointer;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-right: 1px solid #1884bf;

        &:first-of-type {
          border-left: 1px solid #1884bf;
        }
      }
    }

    .tools__single-wrapper {
      background-color: #074679;
    }

    .tools__single-wrapper-active {
      background-color: #052D4D;
    }

    .tools-close {
      flex-basis: 30%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;


      .control-menu-button--close {
        margin: 0;
      }
    }
  }

  .dialog-card {
    background-color: white;

    .card__title {
      justify-content: flex-start;
      ;
    }

    .card__text {
      text-align: left;
      word-break: break-word;
    }

    .card__actions {
      justify-content: space-between;
    }
  }

</style>
