import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import keyStoneSpeciesConfigs from './keystoneSpecies-layers.configs/configs';

const keyStoneSpeciesvelmulajiUrl = 'https://paikkatiedot.ymparisto.fi/geoserver/velmulajidata/wms';
const keyStoneSpeciesvelmupohjaelainUrl = 'https://paikkatiedot.ymparisto.fi/geoserver/velmupohjaelainhavainnot/wms';

let keyStoneSpeciesLayers = [

  // Layer: Mytilus trossulus x edulis (velmupohjaelainhavainnot:Sinisimpukka_Mytilus_trossulus)
  // https://paikkatiedot.ymparisto.fi/geoserver/velmupohjaelainhavainnot/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=velmupohjaelainhavainnot:Sinisimpukka_Mytilus_trossulus
  new TileLayer({
    id: 0,
    finnishName: keyStoneSpeciesConfigs.mytilus,
    name: keyStoneSpeciesConfigs.mytilus,
    dataType: 'GeoJSON',
    visible: false,
    zIndex: 1,
    source: new TileWMS({
      url: keyStoneSpeciesvelmupohjaelainUrl,
      params: {
        'TILED': true,
        'LAYERS': 'velmupohjaelainhavainnot:Sinisimpukka_Mytilus_trossulus'
      },
      transparent: 'true',
      crossOrigin: 'anonymous'
    })
  }),
  // Layer: Potamogeton perfoliatus (velmulajidata:Potamogeton_perfoliatus)
  new TileLayer({
    id: 1,
    finnishName: keyStoneSpeciesConfigs.perfoliate,
    name: keyStoneSpeciesConfigs.perfoliate,
    dataType: 'GeoJSON',
    visible: false,
    zIndex: 1,
    source: new TileWMS({
      url: keyStoneSpeciesvelmulajiUrl,
      params: {
        'TILED': true,
        'LAYERS': 'velmulajidata:Potamogeton_perfoliatus'
      },
      transparent: 'true',
      crossOrigin: 'anonymous'
    })
  }),

  // Layer: Fucus spp. (velmulajidata:Fucus_spp)
  new TileLayer({
    id: 2,
    finnishName: keyStoneSpeciesConfigs.fucus,
    name: keyStoneSpeciesConfigs.fucus,
    dataType: 'GeoJSON',
    visible: false,
    zIndex: 1,
    source: new TileWMS({
      url: keyStoneSpeciesvelmulajiUrl,
      params: {
        'TILED': true,
        'LAYERS': 'velmulajidata:Fucus_spp'
      },
      transparent: 'true',
      crossOrigin: 'anonymous'
    })
  }),

  // Layer: Zostera marina (velmulajidata:Zostera_marina)
  new TileLayer({
    id: 3,
    finnishName: keyStoneSpeciesConfigs.zostera,
    name: keyStoneSpeciesConfigs.zostera,
    dataType: 'GeoJSON',
    visible: false,
    zIndex: 1,
    source: new TileWMS({
      url: keyStoneSpeciesvelmulajiUrl,
      params: {
        'TILED': true,
        'LAYERS': 'velmulajidata:Zostera_marina'
      },
      transparent: 'true',
      crossOrigin: 'anonymous'
    })
  }),

  // Layer: Stuckenia pectinata (velmulajidata:Stuckenia_pectinata)
  new TileLayer({
    id: 4,
    finnishName: keyStoneSpeciesConfigs.potamogeton,
    name: keyStoneSpeciesConfigs.potamogeton,
    dataType: 'GeoJSON',
    visible: false,
    zIndex: 1,
    source: new TileWMS({
      url: keyStoneSpeciesvelmulajiUrl,
      params: {
        'TILED': true,
        'LAYERS': 'velmulajidata:Stuckenia_pectinata'
      },
      transparent: 'true',
      crossOrigin: 'anonymous'
    })
  }),

  // Layer: Ceratophyllum demersum (velmulajidata:Ceratophyllum_demersum)
  new TileLayer({
    id: 5,
    finnishName: keyStoneSpeciesConfigs.ceratophyllum,
    name: keyStoneSpeciesConfigs.ceratophyllum,
    dataType: 'GeoJSON',
    visible: false,
    zIndex: 1,
    source: new TileWMS({
      url: keyStoneSpeciesvelmulajiUrl,
      params: {
        'TILED': true,
        'LAYERS': 'velmulajidata:Ceratophyllum_demersum'
      },
      transparent: 'true',
      crossOrigin: 'anonymous'
    })
  }),

  // Layer: Myriophyllum spp. (velmulajidata:Myriophyllum_spp)
  new TileLayer({
    id: 6,
    finnishName: keyStoneSpeciesConfigs.myriophyllum,
    name: keyStoneSpeciesConfigs.myriophyllum,
    dataType: 'GeoJSON',
    zIndex: 1,
    visible: false,
    source: new TileWMS({
      url: keyStoneSpeciesvelmulajiUrl,
      params: {
        'TILED': true,
        'LAYERS': 'velmulajidata:Myriophyllum_spp'
      },
      transparent: 'true',
      crossOrigin: 'anonymous'
    })
  }),
]

export default keyStoneSpeciesLayers;
