let keyStoneSpeciesConfigs = {

  keyStoneGroupName : "keyStoneGroup",
  mytilus           : "keyStoneMytilus",
  perfoliate        : "keyStonePerfoliate",
  fucus             : "keyStoneFucus",
  zostera           : "keyStoneZostera",
  potamogeton       : "keyStonePotamogeton",
  ceratophyllum     : "keyStoneCeratophyllum",
  myriophyllum      : "keyStoneMyriophyllum",
};

export default keyStoneSpeciesConfigs;
