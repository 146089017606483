import mapApi from '../../maps-constructor/mapsInit';
import satelliteLayersConf from '../../maps-constructor/satellite-layers.configs/config';
import menuTitlesConfig from "@/maps-constructor/menu-titles.configs/configs";
import spatialConfiguration from "@/maps-constructor/spatial-constructor/spatial-config";
import keyStoneSpeciesConfigs from '../../maps-constructor/keystoneSpecies-layers.configs/configs';
// router for translations
import router from '../../router'
import store from './maps-main.store.js';

const state = {
  mapIntanceErrorPopUp: "",
  buttonsActivity: [{
    isActive: true,
    id: 0,
    name: menuTitlesConfig.menu,
    type: 'mainMenu'
  },
  {
    isActive: false,
    id: 1,
    name: menuTitlesConfig.weatherMenu,
    type: 'rightMenu'
  },
  {
    isActive: false,
    id: 2,
    name: menuTitlesConfig.spatialMenu,
    type: 'rightMenu'
  },
  {
    isActive: false,
    id: 3,
    name: menuTitlesConfig.satelliteMenu,
    type: 'rightMenu'
  },
  {
    isActive: false,
    id: 4,
    name: menuTitlesConfig.mapsMenu,
    type: 'rightMenu'
  },
  ],
  defaultButton: {
    id: 0,
    name: menuTitlesConfig.menu,
    type: 'mainMenu'
  },
  buttons: [{
    id: 1,
    name: menuTitlesConfig.weatherMenu
  },
  {
    id: 2,
    name: menuTitlesConfig.spatialMenu
  },
  {
    id: 3,
    name: menuTitlesConfig.satelliteMenu
  },
  {
    id: 4,
    name: menuTitlesConfig.mapsMenu
  }
  ],
  mapsMenu: [{
    name: 'Background map'
  },
  {
    name: 'Plain map'
  },
  {
    name: 'Topographic map'
  },
  {
    name: 'Aerial image'
  },
  {
    name: 'Hillshade'
  },
  {
    name: 'Sea map'
  },
  {
    text: "Description of this area was set in order to provide a test"
  }
  ],
  nationalParksLayers: [],
  navigationalRestrictionsLayers: [],
  reedAreasLayers: [],
  algaeBloomLayers: [],
  sealProtectedLayers: [],
  keyStoneSpecies: [],
  baseMapLayers: [],
  depthModels: [],
  satelliteLayers: [],
  spatielInfoLayers: [],
  AIS: [],
  weatherInfoLayers: [],
  // turbidity layers
  waterQualityTurbidityLayers: [],
  // water surface layers
  waterSurfaceTempLayers: [],
  // drawing tools layers
  drawingTools: [],
  // map
  showBaseMapMenu: false,
  //loaders
  spatielLoader: false,
  // calendar checker
  calendar: {
    visible: false,
    groupName: "",
    type: "",
    id: "'"
  },
  baseMapWarning: "",
  selectedType: "",
  currentLayerInfoSatellite: "",
  currentLayerInfoSpatial: "",
  currentLayerInfoWeather: "",
  keyStoneSelectedAmount: 0,
  algeaBloomSelected: false
}

const getters = {
  getMapInstanceErrorPopUp(state) {
    return state.mapIntanceErrorPopUp;
  },
  getBaseMapWarning(state) {
    return state.baseMapWarning;
  },
  getButtonsActivity(state) {
    return state.buttonsActivity;
  },
  getButtons(state) {
    return state.buttons;
  },
  getMenu(state) {
    return state.mapsMenu;
  },
  getDefaultButton(state) {
    return state.defaultButton;
  },
  getshowBaseMapMenu(state) {
    return state.showBaseMapMenu;
  },
  getKeyStoneSpecies(state) {
    return state.keyStoneSpecies;
  },
  getBaseMapLayers(state) {
    return state.baseMapLayers;
  },
  getDepthModelsLayers(state) {
    return state.depthModels;
  },
  getReedAreasLayers(state) {
    return state.reedAreasLayers;
  },
  getSealProtectedLayers(state) {
    return state.sealProtectedLayers;
  },
  getSatelliteLayers(state) {
    return state.satelliteLayers;
  },
  getSpatielInfoLayers(state) {
    return state.spatielInfoLayers;
  },
  getWeatherInfoLayer(state) {
    return state.weatherInfoLayers;
  },
  getAIS(state) {
    return state.AIS;
  },
  getSpatielLoader(state) {
    return state.spatielLoader;
  },
  getCalendar(state) {
    return state.calendar;
  },
  getWaterQualityTurbidityLayers(state) {
    return state.waterQualityTurbidityLayers;
  },
  getWaterSurfaceTempLayers(state) {
    return state.waterSurfaceTempLayers;
  },
  getNavigationalRestrictionsLayers(state) {
    return state.navigationalRestrictionsLayers;
  },
  getNationalParksLayers(state) {
    return state.nationalParksLayers;
  },
  getAlgaeBloomLayers(state) {
    return state.algaeBloomLayers;
  },
  getDrawingTools(state) {
    return state.drawingTools;
  },
  getSelectedType(state) {
    return state.selectedType;
  },
  getCurrentLayerInfoSatellite(state) {
    return state.currentLayerInfoSatellite;
  },
  getCurrentLayerInfoSpatial(state) {
    return state.currentLayerInfoSpatial;
  },
  getCurrentLayerInfoWeather(state) {
    return state.currentLayerInfoWeather;
  },
  getKeyStoneSelectedAmount(state) {
    return state.keyStoneSelectedAmount;
  },
  getAlgaeBloomSelected(state) {
    return state.algeaBloomSelected;
  }
}

const mutations = {
  // catch all errors with map instance
  mapIntanceError(state, payload) {
    state.mapIntanceErrorPopUp = payload;
  },
  setBaseMapWarning(state, payload) {
    state.baseMapWarning = payload;
    console.log('warning >>> ', state.baseMapWarning);
  },
  changeMenuIcon(state, payload) {
    if (payload) {
      state.buttonsActivity.map((button) => {
        let previosActiveButton = button.isActive == true ? button : '';
        if (previosActiveButton !== payload.id && previosActiveButton !== '') {
          previosActiveButton.isActive = false;
          state.buttonsActivity[payload.id].isActive = true;
        } else return;
      })
      return state.buttonsActivity;
    } else return;
  },
  changeBaseMapVisibility(state, payload) {
    state.showBaseMapMenu = true;
  },
  closeBaseMap(state) {
    state.showBaseMapMenu = false;
  },
  readLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.keyStoneSpecies = layerNames;
  },
  readBaseMapLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.baseMapLayers = layerNames;
  },
  readDepthModelLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.depthModels = layerNames;
  },
  readReedAreasModelLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.reedAreasLayers = layerNames;
  },
  readSealProtectedLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.sealProtectedLayers = layerNames;
  },
  readSatelliteLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.satelliteLayers = layerNames;
  },
  readSpatialLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.spatielInfoLayers = layerNames;
  },
  readWeatherLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.weatherInfoLayers = layerNames;
  },
  clearLayers(state) {

    for (const key in state.weatherInfoLayers) {
      if (state.weatherInfoLayers.hasOwnProperty(key) && state.weatherInfoLayers[key].visible) {
        state.weatherInfoLayers[key].visible = false
      }
    }
    for (const key in state.spatielInfoLayers) {
      if (state.spatielInfoLayers.hasOwnProperty(key) && state.spatielInfoLayers[key].visible) {
        state.spatielInfoLayers[key].visible = false
      }
    }
    for (const key in state.keyStoneSpecies) {
      if (state.keyStoneSpecies.hasOwnProperty(key) && state.keyStoneSpecies[key].visible) {
        state.keyStoneSpecies[key].visible = false
      }
    }
    for (const key in state.reedAreasLayers) {
      if (state.reedAreasLayers.hasOwnProperty(key) && state.reedAreasLayers[key].visible) {
        state.reedAreasLayers[key].visible = false
      }
    }
    for (const key in state.nationalParksLayers) {
      if (state.nationalParksLayers.hasOwnProperty(key) && state.nationalParksLayers[key].visible) {
        state.nationalParksLayers[key].visible = false
        //state.sealProtectedLayers[key].visible = false
      }
    }
    for (const key in state.algaeBloomLayers) {
      if (state.algaeBloomLayers.hasOwnProperty(key) && state.algaeBloomLayers[key].visible) {
        state.algaeBloomLayers[key].visible = false
      }
    }
    for (const key in state.navigationalRestrictionsLayers) {
      if (state.navigationalRestrictionsLayers.hasOwnProperty(key) && state.navigationalRestrictionsLayers[key].visible) {
        state.navigationalRestrictionsLayers[key].visible = false
      }
    }
  },
  setSelectedType(state, payload) {
    state.selectedType = payload;
  },
  setCurrentLayerInfoSatellite(state, payload) {
    state.currentLayerInfoSatellite = payload;
  },
  setCurrentLayerInfoSpatial(state, payload) {
    state.currentLayerInfoSpatial = payload;
  },
  setCurrentLayerInfoWeather(state, payload) {
    state.currentLayerInfoWeather = payload;
  },
  setKeyStoneSelectedAmount(state, payload) {
    state.keyStoneSelectedAmount = payload;
  },
  setAlgaeBloomSelected(state, payload) {
    state.algeaBloomSelected = payload;
  },
  readWaterQualityTurbidityLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.waterQualityTurbidityLayers = layerNames;
  },
  readSpatialLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.spatielInfoLayers = layerNames;
  },
  readWaterSurfaceTempLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.waterSurfaceTempLayers = layerNames;
  },
  readNavigationalRestrictionsLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.navigationalRestrictionsLayers = layerNames;
  },
  readNationalParksLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.nationalParksLayers = layerNames;
  },
  readAlgaeBloomLayers(state, payload) {
    let layerNames = getLayersNames(payload);
    state.algaeBloomLayers = layerNames;
  },
  readDrawingTools(state, payload) {
    let layerNames = getLayersNames(payload);
    state.drawingTools = layerNames;
  },
  updateBaseMapLayers(state, {
    baseMapLayers
  }) {
    // console.log('payload >>> ', baseMapLayers);
  },

  updateLayer(state, {
    updatedLayers,
    stateToUpdate
  }) {
    switch (stateToUpdate) {
      case 'Base maps':
        _visibilityHelper(state.baseMapLayers, updatedLayers)
        break;
      case keyStoneSpeciesConfigs.keyStoneGroupName:
        _visibilityHelper(state.keyStoneSpecies, updatedLayers)
        break;
      case 'Depth models':
        _visibilityHelper(state.depthModels, updatedLayers)
        break;
      case satelliteLayersConf.satelliteConfigs.satellites:
        _visibilityHelper(state.satelliteLayers, updatedLayers)
        break;
      case satelliteLayersConf.landsatConfigs.satellites:
        _visibilityHelper(state.satelliteLayers, updatedLayers)
        break;
      case satelliteLayersConf.sentinel2Configs.satellites:
        _visibilityHelper(state.satelliteLayers, updatedLayers)
        break;
      case satelliteLayersConf.sentinel3Configs.satellites:
        _visibilityHelper(state.satelliteLayers, updatedLayers)
        break;
      case 'Spatial info':
        _visibilityHelper(state.spatielInfoLayers, updatedLayers)
        break;
      case 'Weather info':
        _visibilityHelper(state.weatherInfoLayers, updatedLayers)
        break;
      case satelliteLayersConf.waterQualityConfigs.turbidity:
        _visibilityHelper(state.waterQualityTurbidityLayers, updatedLayers)
        break;
      case satelliteLayersConf.waterQualityConfigs.cdom:
        _visibilityHelper(state.waterQualityTurbidityLayers, updatedLayers)
        break;
      case satelliteLayersConf.waterQualityConfigs.secchiDepth:
        _visibilityHelper(state.waterQualityTurbidityLayers, updatedLayers)
        break;
      case satelliteLayersConf.waterSurfaceConfigs.surfaceTemperature:
        _visibilityHelper(state.waterSurfaceTempLayers, updatedLayers)
        break;
      case satelliteLayersConf.waterSurfaceConfigs.algalBlooms:
        _visibilityHelper(state.waterSurfaceTempLayers, updatedLayers)
        break;
      case satelliteLayersConf.waterSurfaceConfigs.chlrophyll:
        _visibilityHelper(state.waterSurfaceTempLayers, updatedLayers)
        break;
      case spatialConfiguration.NAVIGATIIONAL_RESTRICTIONS_NAME:
        _visibilityHelper(state.navigationalRestrictionsLayers, updatedLayers)
        break;
      case spatialConfiguration.NATIONAL_PARKS_NAME:
        _visibilityHelper(state.nationalParksLayers, updatedLayers)
        break;
      case spatialConfiguration.REED_AREAS_NAME:
        _visibilityHelper(state.reedAreasLayers, updatedLayers)
        break;
      case spatialConfiguration.ALGAEBLOOM_NAME:
        _visibilityHelper(state.algaeBloomLayers, updatedLayers)
        break;
      case spatialConfiguration.SEAL_Protected_AreaS_NAME:
        _visibilityHelper(state.sealProtectedLayers, updatedLayers)
        break;
    }
  },

  updateBaseMapLayer(state, payload) {
    let layerUpdated = [payload]
    layerUpdated = getLayersNames(layerUpdated);
    const layerUpdateId = layerUpdated[0].id;
    const layer = layerUpdated[0];
    state.baseMapLayers.splice(layerUpdateId, 1, layer);
  },
  updateKeyStoneLayer(state, payload) {
    let layerUpdated = [payload]
    layerUpdated = getLayersNames(layerUpdated);
    const layerUpdateId = layerUpdated[0].id;
    const layer = layerUpdated[0];
    state.keyStoneSpecies.splice(layerUpdateId, 1, layer);
  },
  updateDepthModelLayer(state, payload) {
    let layerUpdated = [payload]
    layerUpdated = getLayersNames(layerUpdated);
    const layerUpdateId = layerUpdated[0].id;
    const layer = layerUpdated[0];
    state.depthModels.splice(layerUpdateId, 1, layer);
  },
  updateSatelliteLayer(state, payload) {
    let layerUpdated = [payload]
    layerUpdated = getLayersNames(layerUpdated);
    const layerUpdateId = layerUpdated[0].id;
    const layer = layerUpdated[0];
    state.satelliteLayers.splice(layerUpdateId, 1, layer);
  },

  readAIS(state, payload) {
    state.AIS.push(payload);
  },

  setloaderOnclick(state) {
    state.spatielLoader = true;
  },

  setLoader(state) {
    state.spatielLoader = !state.spatielLoader;
  },

  setCalendar(state, payload) {
    console.log('calendar payload >>> ', payload);
    state.calendar = payload;
  }
}

const actions = {
  fetchLayers({
    commit
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name)
    commit('readLayers', res);
  },
  fetchBaseMapLayers({
    commit
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name)
    commit('readBaseMapLayers', res);
  },
  fetchDepthMapLayers({
    commit
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name)
    commit('readDepthModelLayers', res);
  },
  fetchNavigationalRestrictionsLayers({
    commit
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name)
    commit('readNavigationalRestrictionsLayers', res);
  },
  fetchNationalParksLayers({
    commit
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name)
    commit('readNationalParksLayers', res);
  },
  fetchAlgaeBloomLayers({
    commit
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name)
    commit('readAlgaeBloomLayers', res);
  },
  fetchreedAreasModelLayers({
    commit
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name)
    commit('readReedAreasModelLayers', res);
  },
  fetchSealProtectedLayers({
    commit
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name)
    commit('readSealProtectedLayers', res);
  },


  hideSatelliteLayersGroups({
    commit,
    dispatch
  }, payload) {
    mapApi.removeSatelliteLayers(payload.map, payload.name);
    commit('setCalendar', {
      id: "",
      visible: false,
      name: ""
    });
    dispatch('fetchWaterQualityTurbidityLayers', {
      mapInstance: payload.map,
      name: payload.name
    });
  },

  // water quality layers loading and fetching
  loadWaterQualityTurbidityLayers({
    commit,
    dispatch
  }, payload) {
    if (!payload.mapInstance) {
      let apiError = router.app.$t('waterQualityTurbidityLoadingError');
      commit('mapIntanceError', apiError);
    }

    return new Promise((resolve, reject) => {
      let pay = payload;
      if (payload.waterQualityType && payload.waterQualityType !== "") {
        let previousWeatherGroup = mapApi.getPlannerGroup(payload.mapInstance, payload.waterQualityType);
        previousWeatherGroup.splice(0, previousWeatherGroup.length);
      }
      mapApi.addWaterTurbidity(payload.mapInstance, payload.name)
        .then(() => {
          let calendarRequest = {
            name: payload.name,
            visible: true,
            type: satelliteLayersConf.waterQualityConfigs.type
          };
          commit('setCalendar', calendarRequest);
          resolve(dispatch('fetchWaterQualityTurbidityLayers', {
            mapInstance: pay.mapInstance,
            name: pay.name
          }));
        })
    })
  },


  fetchWaterQualityTurbidityLayers({
    commit,
    dispatch
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name);
    commit('readWaterQualityTurbidityLayers', res);
    if (payload.name === 'turbidity') {
      // check if there is at least one visible turbidity layer
      let turbidityVisible = false;
      let layerIndex = 0;
      while (!turbidityVisible && layerIndex < res.length) {
        turbidityVisible = res[layerIndex].values_.visible;
        layerIndex++;
      }
      let turbidityLegendURL = 'https://geoserver2.ymparisto.fi/geoserver/eo/wms?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=EO_HR_WQ_S2_TURB';

      commit('mapsMainLegendStore/setLayerLegendInfo', {
        layerName: payload.name,
        layerLegendURL: turbidityLegendURL,
        visible: turbidityVisible
      });
    } else {
      commit('mapsMainLegendStore/setLayerLegendInfo', {
        layerName: payload.name,
        layerLegendURL: '',
        visible: false
      });
    }
  },



  /******************************************************************************/
  /* Initialize Water temperature */
  /*  Check if water surface type has previous Group (payload.waterSurfaceType) */
  /*  Add new layer Group to store                                              */
  /******************************************************************************/
  loadWaterSurfaceTempLayers({
    commit,
    dispatch
  }, payload) {

    if (!payload.mapInstance) {
      let apiError = router.app.$t('waterTempLoadingError');
      commit('mapIntanceError', apiError);
    }

    return new Promise((resolve, reject) => {
      let pay = payload;
      // check if payload has previous water type
      if (payload.waterSurfaceType && payload.waterSurfaceType !== "") {
        let previousWeatherGroup = mapApi.getPlannerGroup(payload.mapInstance, payload.waterSurfaceType);
        // if has, than delete all layers from group //
        previousWeatherGroup.splice(0, previousWeatherGroup.length);
      }
      // add new layers to group
      mapApi.addWaterSurfaceLayers(payload.mapInstance, payload.name)
        .then(() => {
          let calendarRequest = {
            name: payload.name,
            visible: true,
            type: satelliteLayersConf.waterSurfaceConfigs.type
          };
          commit('setCalendar', calendarRequest);
          resolve(dispatch('fetchWaterSurfaceTempLayers', {
            mapInstance: pay.mapInstance,
            name: pay.name
          }));
        })
    })
  },

  loadSatellitesLayers({
    commit,
    dispatch
  }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.satelliteType && payload.satelliteType !== "") {
        let previousWeatherGroup = mapApi.getPlannerGroup(payload.mapInstance, payload.satelliteType);
        // if has, than delete all layers from group //
        previousWeatherGroup.splice(0, previousWeatherGroup.length);
      }
      if (!payload) {
        let apiError = router.app.$t('satelliteError');
        reject(commit('mapIntanceError', apiError));
      }
      mapApi.addSatellitesLayers(payload.mapInstance, payload.item)
        .then(() => {
          let calendarRequest = {
            name: payload.name,
            visible: true,
            type: satelliteLayersConf.satelliteConfigs.type
          };
          console.log(calendarRequest)
          commit('setCalendar', calendarRequest);
          resolve(dispatch('fetchSatelliteLayers', {
            mapInstance: payload.mapInstance,
            name: payload.name
          }))
        })
    })

  },

  fetchSatelliteLayers({
    commit
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name)
    commit('readSatelliteLayers', res);
  },

  fetchWaterSurfaceTempLayers({
    commit,
    dispatch
  }, payload) {
    let res = mapApi.followLayers(payload.mapInstance, payload.name);
    commit('readWaterSurfaceTempLayers', res);
    let legendURL;
    if (payload.name === 'surfaceTemperature') {
      legendURL = 'https://geoserver2.ymparisto.fi/geoserver/eo/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=EO_SST';
    }
    else if (payload.name === 'algalBlooms') {
      let activeLanguage = store.state.currentActiveLanguage.name ? store.state.currentActiveLanguage.name : 'fi';
      if (activeLanguage === 'fi') {
        activeLanguage = 'fin';
      }
      // change eo_algae_index_fin_v1 => eo_algae_index_fin_v2
      console.log("activeLanguage: " + activeLanguage)
      legendURL = 'https://geoserver2.ymparisto.fi/geoserver/eo/wms?service=WMS&REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=EO_MR_OLCI_ALGAE&STYLE=eo_algae_index_' + activeLanguage + '_v2&width=20&height=25';
    }
    commit('mapsMainLegendStore/setLayerLegendInfo', {
      layerName: payload.name,
      layerLegendURL: legendURL,
      visible: true
    });
  },

  async fetchSpatialLayers({
    commit
  }, payload) {
    let allSpatialLayers = mapApi.followLayers(payload.mapInstance, payload.name);
    commit('readSpatialLayers', allSpatialLayers);
  },

  async fetchAIS({
    commit
  }, payload) {
    commit('setLoader');
    await mapApi.addAISPonts(payload.map, payload.params);
    let activeLanguage = store.state.currentActiveLanguage.name ? store.state.currentActiveLanguage.name : 'fi';
    commit('mapsMainLegendStore/setLayerLegendInfo', {
      layerName: spatialConfiguration.AISName,
      layerLegendURL: 'https://meriopas.ymparisto.fi/meriopas/static/legends/ais_legend_' + activeLanguage + '.png',
      visible: true
    });
    commit('setLoader');
  },

  async fetchWrecks({
    commit
  }, payload) {
    commit('setLoader');
    await mapApi.addWrecksLocations(payload);
    commit('setLoader');
  },

  // async fetchWrecks_AHV ({
  //   commit
  // }, payload) {
  //   commit('setLoader');
  //   await mapApi.addWrecksLocations_AHV(payload);
  //   commit('setLoader');
  // },

  async fetchAlgaeBloom({
    commit
  }, payload) {
    commit('setLoader');
    await mapApi.addAlgaeBloomLocations(payload);
    commit('setLoader');
  },

  async fetchSwimmingInfo({
    commit
  }, payload) {
    commit('setLoader');
    await mapApi.addSwimmingInfoLocations(payload);
    let activeLanguage = store.state.currentActiveLanguage.name ? store.state.currentActiveLanguage.name : 'fi';
    commit('mapsMainLegendStore/setLayerLegendInfo', {
      layerName: spatialConfiguration.SWIMMINGINFO_NAME,
      layerLegendURL: 'https://meriopas.ymparisto.fi/meriopas/static/legends/swimming_legend_' + activeLanguage + '.png',
      visible: true
    });
    commit('setLoader');
  },

  async fetchBoatingInfo({
    commit
  }, payload) {
    commit('setLoader');
    await mapApi.addBoatingInfoLocations(payload);
    let activeLanguage = store.state.currentActiveLanguage.name ? store.state.currentActiveLanguage.name : 'fi';
    commit('mapsMainLegendStore/setLayerLegendInfo', {
      layerName: spatialConfiguration.BOATINGINFO_NAME,
      layerLegendURL: 'https://meriopas.ymparisto.fi/meriopas/static/legends/boating_legend_' + activeLanguage + '.svg',
      visible: true
    });
    commit('setLoader');
  },

  async fetchVideos({
    commit
  }, payload) {
    commit('setLoader');
    await mapApi.addVideosLocations(payload);
    commit('setLoader');
  },

  async fetchPictures({
    commit
  }, payload) {
    commit('setLoader');
    await mapApi.addPicturesLocations(payload);
    commit('setLoader');
  },

  async fetchPOILayers({
    commit
  }, payload) {
    commit('setLoader');
    await mapApi.addPOIPoints(payload);
    commit('setLoader');
  },

  //fetching winds for weather layers
  async fetchWinds({
    commit
  }, payload) {
    try {
      await mapApi.addWindsPoints(payload);
    } catch (error) {
      let apiError = router.app.$t('windLoadingError');
      console.log(apiError);
    }
  },

  async fetchWaves({
    commit
  }, payload) {
    try {
      await mapApi.addWavesPoints(payload);
    } catch (error) {
      let apiError = router.app.$t('waveLoadingError');
      console.log(apiError);
    }
  },

  async fetchTemperatures({
    commit
  }, payload) {
    try {
      //await mapApi.addWaterHeightPoints(payload);
      await mapApi.addTemperaturesPoints(payload);
    } catch (error) {
      let apiError = router.app.$t('temperatureLoadingError');
      console.log(apiError);
    }
  },

  async fetchWaterTemperature({
    commit
  }, payload) {
    try {
      await mapApi.addWaterTemperaturePoints(payload);
    } catch (error) {
      let apiError = router.app.$t('waterTemperatureLoadingError');
      console.log(apiError);
    }
  },

  async fetchWaterHeight({
    commit
  }, payload) {
    try {
      await mapApi.addWaterHeightPoints(payload);
    } catch (error) {
      let apiError = router.app.$t('waterHeightLoadingError');
      console.log(apiError);
    }
  },

  fetchDrawingTools({
    commit
  }, payload) {
    mapApi.addDrawingLayer(payload.mapInstance);
    let drawingLayers = mapApi.followLayers(payload.mapInstance, payload.name);
    commit('readDrawingTools', drawingLayers);
  },

  clearDrawingTools({
    commit,
    dispatch
  }, payload) {
    mapApi.clearDrawingVectorSource(payload.mapInstance);
    let drawingLayers = mapApi.followLayers(payload.mapInstance, payload.name);
    commit('readDrawingTools', drawingLayers);
  },

  async setVisibleLayer({
    commit, dispatch
  }, payload) {
    if (payload.layerGroupName === "Base maps") {
      try {
        let res = await mapApi.setVisibility(payload.mapInstance, payload.name, payload.layerGroupName);
        commit('readBaseMapLayers', res);
        dispatch('mapsMainLegendStore/setLegendToLayerName', res, {
          root: true
        });
      } catch (error) {
        commit('setBaseMapWarning', error);
      }
    } else {
      commit('updateLayer', {
        updatedLayers: await mapApi.setVisibility(payload.mapInstance, payload.name, payload.layerGroupName),
        stateToUpdate: payload.layerGroupName
      });
    }
  },

  async setVisibileBaseLayer({
    commit
  }, payload) {
    commit('updateLayer', {
      updatedLayers: await mapApi.setVisibility(payload.mapInstance, payload.name),
      stateToUpdate: payload.layerGroupName
    });
  },

  // it's irrelevant pattern
  async setVisibileKeyStoneLayer({
    commit
  }, payload) {
    commit('updateKeyStoneLayer', await mapApi.setVisibility(payload.mapInstance, payload.name));
  },
  async setVisibleDepthModelLayer({
    commit
  }, payload) {
    commit('updateDepthModelLayer', await mapApi.setVisibility(payload.mapInstance, payload.name));
  },
  async setVisiblSatteliteLayer({
    commit
  }, payload) {
    commit('updateSatelliteLayer', await mapApi.setVisibility(payload.mapInstance, payload.name))
  },
  activateMenu({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      if (payload) {
        resolve(commit('changeMenuIcon', payload))
      }
    })
  },
  async setMenuIcon({
    dispatch,
    commit
  }, payload) {
    await dispatch('activateMenu', payload);
    commit('changeBaseMapVisibility', payload);
  }
}

function getLayersNames(layers) {
  let names = [];
  layers.forEach((layer) => {
    let obj = {};
    if (layer.values_.layerLegendURL !== undefined) {
      obj.layerLegendURL = layer.values_.layerLegendURL
    }
    if (layer.values_.name !== undefined) {
      obj.name = layer.values_.name;
    }
    if (layer.values_.icon !== undefined) {
      obj.icon = layer.values_.icon;
    }
    obj.finnishName = layer.values_.finnishName;
    obj.visible = layer.values_.visible;
    obj.id = layer.values_.id;
    if (obj.finnishName) {
      names.push(obj)
    }
  })
  // console.log('names >>> ', names);
  return names;
}

function _visibilityHelper(currentState, data) {
  let layerUpdated = [data];
  layerUpdated = getLayersNames(layerUpdated);
  const layerUpdateId = layerUpdated[0].id;
  const layer = layerUpdated[0];
  currentState.splice(layerUpdateId, 1, layer);
  // if (currentState.length === 2) {
  //   layer = layerUpdated[1];
  //   currentState.splice(layerUpdateId, 1, layer);
  // }
}

export default {
  state,
  getters,
  mutations,
  actions
}
