import { render, staticRenderFns } from "./LanguageTools.vue?vue&type=template&id=7a4a84ec&scoped=true"
import script from "./LanguageTools.vue?vue&type=script&lang=js"
export * from "./LanguageTools.vue?vue&type=script&lang=js"
import style0 from "./LanguageTools.vue?vue&type=style&index=0&id=7a4a84ec&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a4a84ec",
  null
  
)

export default component.exports