import mapApi from '../../../maps-constructor/mapsInit';

const moment = require('moment');

function yesterdayDateFormat() {
  let today = new Date();
  let dayLast = new Date(today);
  dayLast.setMinutes(dayLast.getMinutes() - 20);
  console.log('dayLats >>> ', dayLast);
  let test_2 = new Date(new Date(new Date().setMinutes(-200)).toUTCString()).getTime();
  return test_2;
}


function sourceExistence(map, layerGroupName, layerName) {
  let sourceExist;
  let layerCollection = mapApi.getPlannerGroup(map, layerGroupName);
  layerCollection.forEach((layer) => {
    if (layer.get("name") === layerName && layer.getSource() !== null) {
      sourceExist = true;
    }
  })
  return sourceExist;
}

function RefreshSourceMenu(menuItems, spatialLayers, id, nameOfLayer) {
  let spatialLayer = spatialLayers.filter((elem => elem.name === nameOfLayer));
  menuItems[id].content = spatialLayer;
  return menuItems;
}

let spatialUtils = {
  yesterdayDateFormat: yesterdayDateFormat,
  sourceExistence: sourceExistence,
  RefreshSourceMenu: RefreshSourceMenu,
};

export default spatialUtils;
