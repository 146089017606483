const state = {
  //info-window for keystone species
  keystoneSpeciesInfoWindow: {
    singleSpecies: {},
    active: false
  }
};

//getters
const getters = {
  getKeystoneSpeciesInfoWindow(state) {
    return state.keystoneSpeciesInfoWindow;
  }
};

// mutations
const mutations = {
  setKeyStoneSpeciesInfoWindow(state, payload) {
    if (payload.id == state.keystoneSpeciesInfoWindow.singleSpecies.id && state.keystoneSpeciesInfoWindow.active) {
      state.keystoneSpeciesInfoWindow.active = !state.keystoneSpeciesInfoWindow.active
    } else {
      let species = payload.allSpecies.filter((element) => element.id == payload.id);
      state.keystoneSpeciesInfoWindow.singleSpecies = species[0];
      state.keystoneSpeciesInfoWindow.active = true;
    }
  },
  deactivateKeyStoneSpeciesInfoWindow(state) {
    state.keystoneSpeciesInfoWindow.active = false;
  }
};


export default {
  state,
  getters,
  mutations,
}
