import axios from 'axios';

let aisAxiosApi = {
  createLatestPointsRequest: createLatestPointsRequest,
  createSingleShipRequest: createSingleShipRequest,
  allAISMetadata: allAISMetadata
};

function createLatestPointsRequest(url, from, to) {
  return axios.create({
    baseURL: url,
    timeout: 20000,
    params: {
      from: from,
      to: to
    }
  })
}

function allAISMetadata(url, from) {
  return axios.create({
    baseURL: url,
    timeout: 20000,
    params: {
      from: from
    }
  })
}

function createSingleShipRequest(url) {
  return axios.create({
    baseURL: url,
    timeout: 20000,
  })
}

export default aisAxiosApi;
