import axios from 'axios';

let wmsAxiosApi = {
  wmsCompabilitiesRequest: createWmsAxiosCompabilities,
  createRequest: createRequest
};

function createWmsAxiosCompabilities(wmsCompabilitiesURL, wmsURL) {
  return axios.create({
    baseURL: wmsCompabilitiesURL || wmsURL,
    timeout: 10000
  })
}

function createRequest(url) {
  return axios.create({
    baseURL: url,
    timeout: 10000
  })
}

export default wmsAxiosApi;
