<template>
  <div v-if="popObjectContent !== {} ">
    <div class="infobox">
      <v-icon class="window-info--close" @click="closePopUp(popObject)" size="1.5rem">
        clear
      </v-icon>
      <!-- if feature is AIS -->
      <div v-if="popObjectContent.MMSI" class="infobox__ais">
        <!-- One click on AIS shows small description with expandable panel -->
        <!--<div class="ais__single-infobox" :class="{'ais__single-infobox--expandable' : expand}">
          <div class="single-infobox__short-info" v-if="!expand">
            <span> {{ popObjectContent.name ? popObjectContent.name : "Unknown" }}</span>
            <span>- {{ popObjectContent.destination ? popObjectContent.destination : "Unknown" }}</span>
            <span>- {{ popObjectContent.SOG }}</span>
          </div>

          <v-btn icon @click.native="expand = !expand">
            <v-icon size="1.5rem">{{ !expand ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
          </v-btn>-->
        <div class="ais__single-infobox">
          <div v-if="expand" class="single-infobox__full-info">
            <p v-for="(value, key, index) in popObjectContent" :key="index" class="infobox__text">
              <span class="property-headings" :class="{'propperty-headings--type' : key == 'Info'}">
                {{ key | translate }}
              </span>
              <span :class="{'property-values--type' : key == 'Info'}">
                {{ value ? value : "Unknown" }}
              </span>
            </p>
          </div>

        </div>
      </div>
      <div v-if="!popObjectContent.MMSI" class="infobox__default">
        <div class="single-infobox__full-info">
          <p v-for="(value, key, index) in popObjectContent" :key="index" class="infobox__text" v-if="value && value !== 'NaN' && value !== 'null'">
            <span v-if="key !== 'Picture link' && key !== 'Youtube'" class="property-headings" :class="{'propperty-headings--type' : key == 'Info'}">
              {{ key | translate }}
	      <p v-if="key === 'time0'"></p> <!-- empty line after swimming info observation -->
	      <p v-if="key === 'time1'"></p> <!-- empty line after swimming info observation -->
            </span>
	    <span v-if="key === 'wikiLink'" class="property-link">
              <a :href='"https://" + value' target="_blank">
                Description
              </a>
            </span>
            <span v-else-if="key === 'Luontoportti' && value" class="property-link">
              <a :href='"" + value' target="_blank">
                Luontoportti.com
              </a>
            </span>
            <span v-else-if="key === 'url' && value" class="property-link">
              <a :href='"https://" + value' target="_blank">
                {{ 'link' | translate}}
              </a>
            </span>
            <span v-else-if="key === 'www_url' && value" class="property-link">
              <a :href='value' target="_blank">
                {{ stripUrl(value)}}
              </a>
            </span>
            <span v-else-if="key === 'URL' && value" class="property-link">
              <a :href='value' target="_blank">
                vierassatamat.fi
              </a>
            </span>
            <span :class="{'property-values--type' : key == 'Info'}" v-else-if="key !== 'Picture link' && key !== 'Youtube'">
              {{ value && value !=="NaN" ? value : "notAvailable" | translate}}
            </span>
            <a v-if="key === 'Picture link'" :href=value target="_blank">
              <img :src="value" style="margin-top: 10px;width: 105%"/>
            </a>
            <iframe v-if="key === 'Youtube'" ref="videoFrame" style="margin-top: 10px;width: 105%" :src="getYoutubeEmbedUrl(value)" frameborder="0" allowfullscreen></iframe>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex';
  export default {
    data() {
      return {
        expand: false
      }
    },
    props: ['popObjectContent', 'popObject', 'mapClickType'],
    methods: {
      closePopUp(overlay) {
        overlay.popup.setPosition(undefined);
        this.expand = false;
	if (typeof(this.$refs.videoFrame)!=='undefined' && this.$refs.videoFrame.length>0) {
	  this.$refs.videoFrame[0].setAttribute('src', '');
	}
        return false;
      },
      getYoutubeEmbedUrl(url) {
        let baseUrl = 'https://www.youtube.com/embed/';
        let fullUrl;
        let parts;
        if (url.indexOf('youtu.be') !== -1) {
          parts = url.split('/');
        } else {
          parts = url.split('=');
        }
        fullUrl = baseUrl + parts[parts.length-1];
        return fullUrl;
      },
      stripUrl(url) {
        url = url.replace('https://', '');
        url = url.replace('http://', '');
        url = url.replace('www.', '');
        return url.split('/')[0];
      }
    }
  }

</script>

<style scoped lang="scss">
  .window-info--close {
    position: absolute;
    right: 2px;
    cursor: pointer;
    top: 2px;
  }

  .window-info--actions {
    justify-content: center;
    height: 1rem;
    padding: 0;
  }

  .infobox {
    background-color: white;
    border: 2px solid #052D4D;
    border-radius: 5px;
    width: auto;
    height: auto;
    max-width: 320px;
    min-width:280px;
    
    .ais__single-infobox {
      padding: 2rem 1rem 0rem 1rem;
      color: white;
      font-family: "Montserrat-Semibold";
      font-size: 12px;
    }

    .ais__single-infobox--expandable,
    .infobox__default {
      padding: 1rem 2rem 1rem 1rem;
    }

    button {
      margin: 0;
      color: white;
    }

    .single-infobox__full-info {
      font-family: "Montserrat";

      .infobox__text {
        color: black;
        text-align: left;
        display: flex;
        justify-content: space-between;
        margin: 0;

        .property-headings {
          font-family: "Montserrat-Semibold";
          font-size: 11px;
          text-transform: uppercase;
          max-width: 40%;
          white-space: nowrap;
        }

        .propperty-headings--type {
          display: none;
        }

        .property-link {
          a {
            text-decoration: none;
            color: black;
          }
        }

        span {
          &:last-of-type {
            width: 50%;
            font-size: 11px;
            box-sizing: border-box;
            padding-left: 1rem;
          }
        }

        .property-values--type {
          display: none;
        }

      }
    }
  }

</style>
