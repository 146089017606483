<template>
  <div class="menu-list__wrapper">
    <ul class="sidemenu__list" :class="{'sidemenu__list--visible' : baseMenuVisibility}">
      <li class="list__item" v-for="(item, index) in menu" :key="index" v-if="item.name">
        <div class="list__item--general" :class="{'list__item--general-visible' : item.visibleSubmenu}" @click="showSubmenuItems(item, index)" v-if="item.name!=='mainMenuFeedback'">
          <div class="item-content-arrow" v-if="item.submenu">
            <icon-base-arrow-right v-if="!item.visibleSubmenu" icon-name="" icon-color="#fff" width="12" height="12">
            </icon-base-arrow-right>

            <icon-base-arrow-down v-if="item.visibleSubmenu" icon-name="" icon-color="#fff" width="12" height="12">
            </icon-base-arrow-down>
          </div>
          <span>
            {{ item.name | translate }}
          </span>
        </div>
  	<div class="list__item--general" v-if="item.name==='mainMenuFeedback'">
          <span>
            <a href="mailto:meriopas@syke.fi">{{ item.name | translate }}</a>
          </span>
        </div>
        <ul ref="listSubmenu" class="sidemenu__list--submenu" :class="{'sidemenu__list--submenu--visible' : item.visibleSubmenu}">

          <li class="list__item--submenu-item" v-for="(subItem, index) in item.submenu" :key="index" :class="{'list__item--submenu-item--visible' : item.visibleSubmenu}"
            @click="someEvent()">

            <div class="submenu-water">
              <span>
                {{ subItem.name | translate }}
              </span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <div class="portal-link-description">
      <span>
        {{ 'mainMenuPortalLinkDescription' | translate }}
      </span>
    </div>
    <div class="portal-link">
      <a :href="'portalLink' | translate">
      <img style="width: 120px;vertical-align: middle;" :src="'https://meriopas.ymparisto.fi/meriopas/static/logos/itamerilogo ' + currentLanguage.name + ' nega.svg'" alt="">
      </a>
    </div>
    <div class="menu-info">
      <menu-sponsors>
      </menu-sponsors>
    </div>
  </div>
</template>
<script>
  import {
    mapGetters,
    mapMutations
  } from 'vuex';
  import MenuSponsors from './menu-sponsors-languages/MenuSponsors';
  import IconBaseArrowDown from '../svg-system-components/IconBaseArrowDown';
  import IconBaseArrowRight from '../svg-system-components/IconBaseArrowRight';
  export default {
    data() {
      return {
        menu: [{       
            name: 'mainMenuInstructions',
            visibleSubmenu: false,
            submenu: [{
              name: 'instructionsDescription'
            }]
          },
          {
            name: 'mainMenuContents',
            visibleSubmenu: false,
            submenu: [{
              name: 'contentsDescription'
            }]
          },

          {
            name: "mainMenuTermsOfUse",
            visibleSubmenu: false,
            submenu: [{
              name: "termsOfUseDescription"
            }]
          },
          {
            name: 'mainMenuFeedback',
            visibleSubmenu: false
          },	  
        ],
      }
    },
    components: {
      MenuSponsors,
      IconBaseArrowDown,
      IconBaseArrowRight
    },
    computed: {
      ...mapGetters({
        baseMenuVisibility: 'getshowBaseMapMenu',
        currentLanguage: 'getCurrentLanguage'
      })
    },
    methods: {
      showSubmenuItems(submenuItem) {
        if (submenuItem) {
          submenuItem.visibleSubmenu = !submenuItem.visibleSubmenu
        }
      },
      someEvent() {},
      showSubmenuItems(submenuItem, index) {
        if (submenuItem) {
          submenuItem.visibleSubmenu = !submenuItem.visibleSubmenu;
          let submenuElement = this.$refs.listSubmenu[index];
          let submenuElementHeight =
            Array.isArray(submenuItem.submenu[0].content) ?
            submenuItem.submenu[0].content.length * 135 :
            'auto';
          submenuElement.style.height = !submenuItem.visibleSubmenu ?
            0 + 'px' :
            submenuElementHeight;
        }
      },
    }
  }

</script>

<style scoped lang="scss">
  .menu-list__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 47px);
  }

  .sidemenu__list {
    @include list;
    height: 100%;
    overflow-y: auto;

    .list__item--general {
      padding: 0 10px;
      height: 46px;
      display: flex;
      align-items: center;
      position: relative;

      .item-content-arrow {
        margin-right: 10px;
        line-height: 0;
      }
    }

    .list__item--general-visible {
      background-color: #1c629a;
      display: flex;
      align-items: center;
      position: relative;

      &:span:first-of-type {
        margin-left: 10px;
      }
    }

    .sidemenu__list--submenu {
      height: 0;
      opacity: 0;
      margin: 0;
      list-style: none;
      pointer-events: none;
      min-width: 15rem;
      transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);

      .list__item--submenu-item {
        height: 0;
        opacity: 0;
        cursor: pointer;
        text-align: left;
        color: white;
        display: flex;

        .submenu-water {
          display: flex;
          height: auto;
          width: 100%;
          padding: 0 10px;
          align-items: center;

          &:hover {
            background-color: #2B77B2;
          }
        }

        span {
          width: 100%;
          padding: 1rem 0rem;
        }
      }

      .list__item--submenu-item--visible {
        opacity: 1;
        height: auto;
      }
    }

    .sidemenu__list--submenu--visible {
      opacity: 1;
      pointer-events: initial;
    }

  }

  .sidemenu__list--visible {
    opacity: 1;
    transition: opacity .1s ease-in;
  }

  .portal-link-description {
    color: white;
  }
  
  .portal-link-image {
    height: 100px;
    width: 162px;
  }

  .menu-info p {
    margin: 0;
    padding: 1rem;
    word-break: break-word;
    color: white;
  }

</style>
