<template>
  <div class="menu-list__wrapper">
    <ul class="sidemenu__list" :class="{'sidemenu__list--visible' : baseMenuVisibility}">
      <li class="list__item" :class="{'list__item--open' : sublayer.visibleSubmenu}" v-for="(sublayer, index) in sublayersMenu"
        :key="index">
        <!-- if menu contains only one level layer ( for example: National parks/Reed areas )-->
        <div v-if="!sublayer.submenu && !sublayer.hiddenFromMenu" class="list__item--general list__item--single" @click="setVisible(sublayer.content[0].finnishName, sublayer.name, sublayer);infoIndex=index">
          <img :src="sublayer.icon" width="24px" height="24px">
          <span v-if="sublayer.content[0]" :class="{'unvisibleLayerSelected' : sublayer.content[0].visible == false}">
            {{ sublayer.name | translate }}
          </span>
          <div class="list__item-button" v-if="sublayer.content[0]">
            <icon-close-eye-base v-if="sublayer.content[0].visible == false" icon-name="" icon-color="#fff" width="18"
              height="10">
            </icon-close-eye-base>
            <icon-open-eye-base v-if="sublayer.content[0].visible == true" icon-name="" icon-color="#fff" width="18"
              height="9">
            </icon-open-eye-base>
          </div>
        </div>
        <div v-if="infoIndex===index && currentLayerInfo" class="menu-info">
          {{ currentLayerInfo !== "" ? currentLayerInfo + "Details" : "" | translate }}
        </div>
        <!-- if menu contains multi level structure -->
        <div v-if="sublayer.submenu" class="list__item--general item-content" :class="{'list__item--general-visible' : sublayer.visibleSubmenu}"
          @click="showSubmenuItems(sublayer, index)">

          <img src="https://meriopas.ymparisto.fi/meriopas/static/keyStoneIcons/Avainlajit.png" class="keyStone_branch" alt="" width="24" height="24">

          <span v-if="sublayer.submenu">
            {{ sublayer.name | translate }}
          </span>

          <div class="item-content-arrow" v-if="sublayer.submenu">
            <icon-base-arrow-right v-if="!sublayer.visibleSubmenu" icon-name="" icon-color="#fff" width="12" height="12">
            </icon-base-arrow-right>

            <icon-base-arrow-down v-if="sublayer.visibleSubmenu" icon-name="" icon-color="#fff" width="12" height="12">
            </icon-base-arrow-down>
          </div>

          <!--container for quality of selected layers -->
          <div v-if="sublayer.submenu && selectedLayersAmount !== 0" class="submenu-numbers" :class="{'submenu-numbers--big' : selectedLayersAmount > 9}">
            <div>
              <span>
                {{ selectedLayersAmount }}
              </span>
            </div>
          </div>
        </div>
        <ul ref="listSubmenu" class="sidemenu__list--submenu" v-show="sublayer.visibleSubmenu" :class="{'sidemenu__list--submenu--visible' : sublayer.visibleSubmenu}">
          <li class="list__item--submenu-item" :class="{'list__item--submenu-item--visible' : sublayer.visibleSubmenu}"
            v-for="(submenu, index) in sublayer.submenu" :key="index">
            <div v-if="submenu.complex" class="submenu-complex">
              <div class="submenu-complex__item" v-for="(item, index) in submenu.content" :key="index" @click="setVisible(item.finnishName, keyStoneGroupName, sublayer, item)">
                <img :src="item.IconSource" alt="" width="18px" height="18px">
                <span :class="{'unvisibleLayerSelected' : !item.visible}">
                  {{ item.finnishName | translate }}
                </span>
                <div class="list__item-info-button" @click="activateKeystoneInfo(item.id, $event)">
                  <key-stone-species-tooltip v-bind:name="item.finnishName | translate" />
                </div>
                <div class="list__item-button">
                  <icon-close-eye-base v-if="!item.visible" icon-name="" icon-color="#fff" width="18" height="10">
                  </icon-close-eye-base>
                  <icon-open-eye-base v-if="item.visible" icon-name="" icon-color="#fff" width="18" height="9">
                  </icon-open-eye-base>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <div class="portal-link">
		  <a :href="'portalLink' | translate">
        <img style="width: 120px;vertical-align: middle;" :src="'https://meriopas.ymparisto.fi/meriopas/static/logos/itamerilogo ' + currentLanguage.name + ' nega.svg'" alt="">
      </a>
    </div> 
  </div>
</template>

<script>
  import mapApi from '../../maps-constructor/mapsInit';
  import keyStoneSpeciesConfigs from '../../maps-constructor/keystoneSpecies-layers.configs/configs';
  import KeyStoneSpeciesTooltip from './menu-layers-menu-composer/KeyStoneSpeciesTooltip';
  import menuLayerUtils from './menu-layers-map-utils/utils';
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex';
  // import MenuDepthModel from './MenuDepthModel.vue';
  import IconCloseEyeBase from '../svg-system-components/IconCloseEyeBase';
  import IconOpenEyeBase from '../svg-system-components/IconOpenEyeBase';
  import IconBaseArrowDown from '../svg-system-components/IconBaseArrowDown';
  import IconBaseArrowRight from '../svg-system-components/IconBaseArrowRight';
  import spatialConfiguration from '../../maps-constructor/spatial-constructor/spatial-config';
  import spatialUtils from './menu-spatial-layers-utils/utils';
  import configs from './menu-spatial-layers-utils/config.js';
  import spatialApi from '../../maps-constructor/spatial-constructor/spatialInfoLayers';
  import algaAxiosConf from '../../alga-axios-conf/axios.conf.js';
  import moment from 'moment';
  export default {
    props: ['map'],
    data() {
      return {
        visibleKeyStoneLayers: [],
        previosSubmenu: null,
        allSelectedSublayers: menuLayerUtils.allSelectedSublayers,
        sublayersMenu: menuLayerUtils.sublayersMenu,
        keystoneSpeciesInfo: menuLayerUtils.keystoneSpeciesInfo,
        keyStoneGroupName: keyStoneSpeciesConfigs.keyStoneGroupName,
        infoIndex: null,
        aisInterval: 0
      }
    },
    components: {
      KeyStoneSpeciesTooltip,
      IconCloseEyeBase,
      IconOpenEyeBase,
      IconBaseArrowDown,
      IconBaseArrowRight
    },
    computed: {
      ...mapGetters({
        keyStoneSpecies: 'getKeyStoneSpecies',
        baseMenuVisibility: 'getshowBaseMapMenu',
        spatielLoader: 'getSpatielLoader',
        navigationalRestrictions: 'getNavigationalRestrictionsLayers',
        nationalParks: 'getNationalParksLayers',
        algaeBloom: 'getAlgaeBloomLayers',
        spatialLayers: 'getSpatielInfoLayers',
        calendar: 'getCalendar',
        depthModels: 'getDepthModelsLayers',
        reedAreas: 'getReedAreasLayers',
        sealProtectedAreas: 'getSealProtectedLayers',
        turbidityLayers: 'getWaterQualityTurbidityLayers',
        getLoadingStatus: 'getLoadingStatus',
        getLayersLegendInfo: 'getLayersLegendInfo',
        currentLayerInfo: 'getCurrentLayerInfoSpatial',
        selectedLayersAmount: 'getKeyStoneSelectedAmount',
        algaeBloomSelected: 'getAlgaeBloomSelected',
        currentLanguage: 'getCurrentLanguage',
        layerLegend: 'mapsMainLegendStore/getLayersLegendInfo'
      }),
    },
    beforeDestroy() {
      clearInterval(this.aisInterval);
    },
    methods: {
      ...mapActions(
        [
          'fetchLayers',
          'setVisibileKeyStoneLayer',
          'setVisibleLayer',
          'fetchNavigationalRestrictionsLayers',
          'fetchNationalParksLayers',
          'fetchAlgaeBloomLayers',
          'fetchDepthMapLayers',
          'fetchreedAreasModelLayers',
          'fetchSealProtectedLayers',
          'fetchSpatialLayers',
          'fetchWrecks',
          //'fetchWrecks_AHV',
          'fetchAIS',
          'fetchPOILayers',
          'fetchVideos',
          'fetchPictures',
          'fetchBoatingInfo',
          'fetchSwimmingInfo',
          'loadWaterSurfaceTempLayers',
          'hideSatelliteLayersGroups'
        ]
      ),
      ...mapMutations({
        setloaderOnclick: 'setloaderOnclick',
        setLoader: 'setLoader',
        setSelecteSubLayers: 'setSelecteSubLayers',
        setCalendar: 'setCalendar',
        setKeyStoneSpeciesInfoWindow: 'setKeyStoneSpeciesInfoWindow',
        setLoadingStatus: 'setLoadingStatus',
        setLayerLegendInfo: 'mapsMainLegendStore/setLayerLegendInfo',
        setCurrentLayerInfo: 'setCurrentLayerInfoSpatial',
        setKeyStoneSelectedAmount: 'setKeyStoneSelectedAmount',
        setAlgaeBloomSelected: 'setAlgaeBloomSelected'
      }),
      closeLegend() {
        this.layerLegend.isActive = false;
      },
      async setVisible(name, group, sublayer) {
        this.setLoadingStatus(true);

        if (sublayer.type === "Vector") {
          await this.setVisibleVectorLayers(name, sublayer);
        } else {
          await this.setVisibleLayer({
            mapInstance: this.map,
            name: name,
            layerGroupName: group
          });
          if (name === 'nationalParks') {
            // sealProtectedLayers
            let sublayer = this.sublayersMenu.filter(sel => sel.name === 'nationalParks')[0];
            await this.setVisibleVectorLayers(spatialConfiguration.SEAL_Protected_AreaS_NAME, sublayer);
          }
          if ( name === 'sealProtectedAreas') {
            await this.setVisibleVectorLayers(spatialConfiguration.NATIONAL_PARKS_NAME, sublayer);
          }
          // sublayer = ParksnationalParks
          mapApi.asyncVectorRefresh(this.map);
          if (name === 'nationalParks' || name === 'sealProtectedAreas') {
            let nationalParksLegendURL = 'https://paikkatiedot.ymparisto.fi/geoserver/inspire_ps/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=PS.ProtectedSitesValtionOmistamaLuonnonsuojelualue&style=PS.ValtionMaidenSuojelualueet.Luokiteltu';
            if (this.isSelected(sublayer)) {
              this.setLayerLegendInfo({
                layerName: name,
                layerLegendURL: nationalParksLegendURL,
                      visible: true
                    });
            } else {
              this.closeLegend();
            }
          }
          if (name === 'algaeBloom') {
            // Show swimming info also
            let sublayer = this.sublayersMenu.filter(sel => sel.name === 'swimmingInfo')[0];
            await this.setVisibleVectorLayers(spatialConfiguration.SWIMMINGINFO_NAME, sublayer);
            await this.setVisibleVectorLayers(spatialConfiguration.ALGAINFO_NAME, sublayer);
          }
          if (sublayer && sublayer.containsSubmenu) {
            this.calculateSelectedLayers(sublayer);
            menuLayerUtils.setKeyStoneImage(this.keyStoneSpecies);
          }
          // jatkettava tasta joskus paremmalla ajalla
          // else if (name === 'algaeBloom' && sublayer.name === 'algaeBloom') {
          //   this.allSelectedSublayers.other = this.sublayersMenu.filter(layer => this.isSelected(layer)).length - 1;
          //   this.setSelecteSubLayers(this.allSelectedSublayers);
          // }
          else {
            //if (this.algaeBloom[0].visible === true) 
            this.allSelectedSublayers.other = this.sublayersMenu.filter(layer => this.isSelected(layer)).length;
            this.setSelecteSubLayers(this.allSelectedSublayers);
          }
        }
        this.showLayerInfo(sublayer);
        if (name === 'ships') 
        {
          if (this.isSelected(sublayer)) {
            this.layerLegend.isActive = true;
          }
          else
            this.layerLegend.isActive = false;
        }
        this.setLoadingStatus(false);
      },

      calculateSelectedLayers(sublayer) {
        if (Array.isArray(sublayer.submenu[0].content)) {
          this.setKeyStoneSelectedAmount(sublayer.submenu[0].content.filter(sel => sel.visible).length);
          this.allSelectedSublayers[sublayer.name] = this.selectedLayersAmount;
          this.setSelecteSubLayers(this.allSelectedSublayers);
        } else {
          return this.selectedLayersAmount;
        }
      },

      async setVisibleVectorLayers(name, sublayer) {
        let selected = this.isSelected(sublayer);
        if (name === spatialConfiguration.wrecksName && !selected) {
          //await this.fetchWrecks_AHV(this.map);
          await this.fetchWrecks(this.map);
        } else if (name === spatialConfiguration.NATIONAL_PARKS_NAME && !selected) {
          await this.fetchSealProtectedLayers(this.map);
        } else if (name === spatialConfiguration.SEAL_Protected_AreaS_NAME && !selected) {
          // case sealProtectedAreas
          // add: 2.9.2020 MH
          selected = true;
          //await this.fetchSealProtectedLayers(this.map); //fetchSwimmingInfo(this.map);
        } else if (name === spatialConfiguration.SWIMMINGINFO_NAME && !selected) {
          await this.fetchSwimmingInfo(this.map);
        } else if (name === spatialConfiguration.BOATINGINFO_NAME && !selected) {
          await this.fetchBoatingInfo(this.map);
        } else if (name === spatialConfiguration.PICTURE_NAME) {
          if (!selected) {
            await this.fetchVideos(this.map);
            await this.fetchPictures(this.map);
          }
          await this.setVisibleLayer({
            mapInstance: this.map,
            name: spatialConfiguration.VIDEO_NAME,
            layerGroupName: spatialConfiguration.groupName
          });
        } else if (name === spatialConfiguration.AISName) {
          if (this.aisInterval === 0) {
            let aisRequest = {
              map: this.map,
              params: {
                from: spatialUtils.yesterdayDateFormat()
              }
            };
            this.aisInterval = setInterval(() => { // Fetch ais data once per minute
              this.fetchAIS(aisRequest);
            }, 1 * 60 * 1000);
            await this.fetchAIS(aisRequest);
          } else {
            clearInterval(this.aisInterval);
            this.aisInterval = 0;
            this.closeLegend();
            // this.setLayerLegendInfo({
	          //   layerName: name,
            //   layerLegendURL: '',
            //   visible: false
            // });
          }
        } else {
          if (!selected) {
              // change 
              // 26.8.2020
              if (name !== spatialConfiguration.ALGAINFO_NAME) {
              let poiRequest = {
                map: this.map,
                POI: configs.POI_DEFAULT_STATE
              };
              await this.fetchPOILayers(poiRequest);
              }
          } else {
            // change - do not close legend
            // 26.8.2020
            if (name !== spatialConfiguration.ALGAINFO_NAME) {
                this.closeLegend();
            }
          }
        }
        await this.setVisibleLayer({
          mapInstance: this.map,
          name: name,
          layerGroupName: spatialConfiguration.groupName
        });
        this.allSelectedSublayers.other = this.sublayersMenu.filter(layer => {
          if (name === layer.name) {
            return !this.isSelected(layer);  
          } else {
            return this.isSelected(layer);
          }
        }).length;
        this.setSelecteSubLayers(this.allSelectedSublayers);
        mapApi.asyncVectorRefresh(this.map);
        this.sublayersMenu = spatialUtils.RefreshSourceMenu(this.sublayersMenu, this.spatialLayers, sublayer.id,
          name);
      },
      showSubmenuItems(submenuItem, index) {
        let submenuElement = this.$refs.listSubmenu[index];
        if (submenuItem) {
          submenuItem.visibleSubmenu = !submenuItem.visibleSubmenu;
          // set styles or submenu to transition correctly
          menuLayerUtils.submenuClosingOpening(submenuItem, submenuElement);
          this.previosSubmenu = submenuItem;
        }
      },
      activateKeystoneInfo(id, event) {
        event.stopPropagation();
        this.setKeyStoneSpeciesInfoWindow({
          allSpecies: this.keystoneSpeciesInfo,
          id: id
        });
      },
      showLayerInfo(layer) {
        if (this.isSelected(layer)) {
          this.setCurrentLayerInfo(layer.name);
        } else {
          this.setCurrentLayerInfo("");
        }
      },
      isSelected(layer) {
        if (layer && layer.content && layer.content.length > 0) {
          for (const content of layer.content) {
            if (content.visible === true) {
              return true;
            }
          }
        }
        return false;
      }
    },
    async mounted() {
      this.fetchLayers({
        mapInstance: this.map,
        name: keyStoneSpeciesConfigs.keyStoneGroupName
      });
      this.fetchNavigationalRestrictionsLayers({
        mapInstance: this.map,
        name: spatialConfiguration.NAVIGATIIONAL_RESTRICTIONS_NAME
      });
      this.fetchNationalParksLayers({
        mapInstance: this.map,
        name: spatialConfiguration.NATIONAL_PARKS_NAME
      });
      this.fetchAlgaeBloomLayers({
        mapInstance: this.map,
        name: spatialConfiguration.ALGAEBLOOM_NAME
      });
      this.fetchreedAreasModelLayers({
        mapInstance: this.map,
        name: spatialConfiguration.REED_AREAS_NAME
      });
      this.fetchSealProtectedLayers({
        mapInstance: this.map,
        name: spatialConfiguration.SEAL_Protected_AreaS_NAME
      });
      this.fetchSpatialLayers({
        mapInstance: this.map,
        name: spatialConfiguration.groupName
      });
      this.sublayersMenu.forEach((menuItem) => {
        if (menuItem.containsSubmenu) {
          menuItem.submenu[0].content = this.keyStoneSpecies;
        } else if (menuItem.type === "Vector") {
          fullfillSpatialLayers(menuItem, this.spatialLayers);
        } else {
          switch (menuItem.name) {
            case spatialConfiguration.NAVIGATIIONAL_RESTRICTIONS_NAME:
              menuItem.content = this.navigationalRestrictions;
              break;
            case spatialConfiguration.NATIONAL_PARKS_NAME:
              menuItem.content = this.nationalParks
              break;
            case spatialConfiguration.ALGAEBLOOM_NAME:
              menuItem.content = this.algaeBloom
              break;
            case spatialConfiguration.REED_AREAS_NAME:
              menuItem.content = this.reedAreas
              break;
            case spatialConfiguration.SEAL_Protected_AreaS_NAME:
              menuItem.content = this.sealProtectedAreas
              break;
          }
        }
      })
      menuLayerUtils.setKeyStoneImage(this.keyStoneSpecies);
      // load algae data 
      // added: 28.8.2020
      getAlgaeDataLocations(map);
    }
  }

  function getAlgaeDataLocations(map) {
    //
    let algaInstance = new spatialApi.AlgaInfo(map, algaAxiosConf.createAlgaPoints);
    let dateLimit = moment().subtract(3, "days").format('YYYY-MM-DD');
    let locations =  algaInstance.getSourceVector(dateLimit + 'T00:00:00Z');
  }

  function fullfillSpatialLayers(menuItem, spatialLayers) {
    return spatialLayers.forEach((layer) => {
      if (menuItem.name === layer.finnishName) {
        menuItem.content[0] = layer;
      }
    })
  }

</script>

<style scoped lang="scss">

  .menu-list__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 47px);
  }

  .sidemenu__list {
    @include list;

    .list__item--general {
      padding: 0 10px;
      height: 46px;
      display: flex;
      align-items: center;
      position: relative;

      .keyStone_branch {
        margin-right: 10px;
      }


      .submenu-numbers {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 54px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #2b76b1;
      }

      .submenu-numbers--big {
        width: auto;
        height: 16px;
        border-radius: 25px;

        div {
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .list__item--single {
      display: flex;
      align-items: center;

      span {
        padding: 0 10px;
      }

      .list__item-button {
        margin-left: auto;
      }
    }

    .list__item--general-visible {
      background-color: #1c629a;
      display: flex;
      align-items: center;
      position: relative;

      &:span:first-of-type {
        margin-left: 10px;
      }
    }

    .item-content-arrow {
      position: absolute;
      /*margin-right : 10px;*/
      right: 15px;
      line-height: 0;
    }

    .sidemenu__list--submenu {
      height: 0;
      opacity: 0;
      margin: 0;
      list-style: none;
      pointer-events: none;
      min-width: 15rem;
      transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);

      .list__item--submenu-item {
        height: 0;
        opacity: 0;
        cursor: pointer;
        text-align: left;
        color: white;
        display: flex;

        .submenu-water {
          display: flex;
          height: 35px;
          width: 100%;
          padding: 0 10px;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background-color: #2B77B2;
          }
        }

        .submenu-complex {
          width: 100%;
          background-color: #2672AF;

          .submenu-complex__item {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            height: 35px;
            padding: 0 10px;

            span {
              padding: 0 10px;
            }

            &:hover {
              background-color: #2B77B2;
            }

            .list__item-info-button {
              height: 100%;
              display: flex;
              align-items: center;
              margin-right: 10px;
              width: 5rem;
              justify-content: center;

            }

            .list__item-info-button>* {
              padding: 0;
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: transparent;
              color: #000;
            }
          }
        }

        span {
          opacity: 1;
          width: 80%;
        }

        .unvisibleLayerSelected {
          opacity: .8
        }
      }

      .list__item--submenu-item--visible {
        opacity: 1;
        height: auto;
      }
    }

    .sidemenu__list--submenu--visible {
      opacity: 1;
      pointer-events: initial;
      background-color: #2672AF;
    }

  }

  .sidemenu__list--visible {
    opacity: 1;
    transition: opacity .1s ease-in;
    overflow-y: auto;
    height: 100%;
  }

  .menu-info {
    background-color: #074679;
    max-height: 250px;
    overflow-y: auto;
    margin: 0;
    padding: 1rem;
    word-break: break-word;
    color: white;
    text-align: left;
    overflow-x: hidden;
  }

  .list__item--active {
    background-color: rgb(11, 54, 68);
  }

  .unvisibleLayerSelected {
    opacity: .8
  }
	.portal-link-description {
    color: white;
  }
  
  .portal-link-image {
    height: 100px;
    width: 162px;
  }
</style>
