import VectorSource from 'ol/source/vector';

// main map API
import mapApi from './mapsInit';

let drawingToolsAPI = {
  createDrawingLayer: createDrawingLayer,
};

function createDrawingLayer(map) {
  let drawingSource = new VectorSource({});
  let drawingGroup = mapApi.getPlannerGroup(map, 'Drawing tools');
  let drawingVector = getDrawingVector(drawingGroup, "Tools");
  drawingVector.setSource(drawingSource);
}

function getDrawingVector(collection, name) {
  let vector;
  collection.forEach((layer) => {
    if (layer.get("name") === name) {
      vector = layer;
    }
  })
  return vector;
}

export default drawingToolsAPI;
