import axios from 'axios';
import moment, { now } from 'moment';

//const WRECKS_URL = 'https://paikkatieto.ymparisto.fi/proxy/proxy.ashx?http://kartta.nba.fi/arcgis/services/WFS/MV_HylytMeritietoportaali/MapServer/WFSServer?';
const WRECKS_URL = 'https://geoserver.museovirasto.fi:8443/geoserver/rajapinta_suojellut/wfs?&cql_filter=tyyppi%3D%27alusten%20hylyt,%20%20,%20%20,%20%20%27';

const WRECKS_URL_AHV = 'https://kartor.regeringen.ax/arcgis/services/Kulturarv/MaritimtKulturarv/MapServer/WFSServer?';
const VIDEO_URL = 'https://paikkatiedot.ymparisto.fi/geoserver/velmukartta/wfs?';
const WEATHER_URL = 'https://opendata.fmi.fi/wfs';
const SWIMMINGINFO_URL = 'https://api.hel.fi/servicemap/v2/unit/';
const BOATINGINFO_URL = 'https://geoserver2.ymparisto.fi/geoserver/meritietoportaali/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=meritietoportaali:satamat&outputFormat=application%2Fjson';
// velmukartta:Satama
// &srs=EPSG:3067, palvelusta puutuu kohteen Nimi ja url, ei oteta käyttöön 12.1.2024
//const BOATINGINFO_URL = 'https://paikkatiedot.ymparisto.fi/geoserver/velmukartta/wfs?service=WFS&version=1.0.0&request=GetFeature&srs=EPSG:4326&typeName=velmukartta:Satama&outputFormat=application%2Fjson';

let wfsAxiosApi = {
  wfsGetFeatureRequest: createWfsAxiosGetFeatureRequest(WRECKS_URL),
  wfsGetFeatureRequest_AHV: createWfsAxiosGetFeatureRequest_Ahv(WRECKS_URL_AHV),
  wfsCreateWFSVideoSpatilInfo: createWFSVideoSpatilInfo(VIDEO_URL),
  wfsCreateWFSPictureSpatilInfo: createWFSPictureSpatilInfo(VIDEO_URL),
  wfsCreateWFSWeatherINfo: createWFSWeatherINfo,
  WFSWeatherWaveQuery: WFSWeatherWaveQuery(WEATHER_URL),
  WFSWeatherTemperatureQuery: WFSWeatherTemperatureQuery(WEATHER_URL),
  WFSWeatherWaterHeightQuery: WFSWeatherWaterHeightQuery(WEATHER_URL),
  WFSWeatherWaterHeightTempQuery: WFSWeatherWaterHeightTempQuery(WEATHER_URL),
  WFSWeatherWaterTemperatureQuery: WFSWeatherWaterTemperatureQuery(WEATHER_URL),
  boatingSpatialInfo: receiveFeatures(BOATINGINFO_URL),
  swimmingSpatialInfo: createSwimmingInfo(SWIMMINGINFO_URL),
  receiveFeatures: receiveFeatures
};

function createWfsAxiosGetFeatureRequest(wfsURL) {
  return axios.create({
    baseURL: wfsURL,
    timeout: 20000,
    params: {
      service: 'WFS',
      version: '2.0.0',
      request: 'GetFeature',
      outputFormat: 'JSON',
      // cql_filter made in wfsURL
      // cql_filter: tyyppi='alusten hylyt,  ,  ,  '
      typeNames: 'muinaisjaannos_piste',
      //typeNames: 'Alusten_hylyt', //'Rauhoitetut_vedenalaiset', // 'Alusten_hylyt',
      srsname: 'EPSG:4326',
    }
  })
}

function createWfsAxiosGetFeatureRequest_Ahv(wfsURL) {
  return axios.create({
    baseURL: wfsURL,
    timeout: 20000,
    params: {
      service: 'WFS',
      version: '2.0.0',
      request: 'GetFeature',
      outputFormat: 'GEOJSON',
      typeNames: 'Vrak',
      srsname: 'EPSG:4326',
    }
  })
}

function createWFSWeatherINfo(update) {
  return axios.create({
    responseType: 'text',
    baseURL: WEATHER_URL,
    timeout: 20000,
    params: {
      service: 'WFS',
      request: 'GetFeature',
      STOREDQUERY_ID: 'fmi::observations::weather::multipointcoverage',
      bbox: '18.88, 59.28, 31.63, 66.05',
      starttime: moment.utc().subtract(21, "minutes").format(),
      parameters: 'wd_10min,ws,wg_10min,t2m'
    }
  })
}

function WFSWeatherWaveQuery(wfsURL) {
  return axios.create({
    responseType: 'text',
    baseURL: wfsURL,
    timeout: 20000,
    params: {
      service: 'WFS',
      request: 'GetFeature',
      STOREDQUERY_ID: 'fmi::observations::wave::multipointcoverage',
      bbox: '18.88, 59.28, 31.63, 66.05',
      starttime: moment.utc().subtract(360, "minutes").format(),
      parameters: 'WaveHs,ModalWDi,WTP',
    }
  })
}

function WFSWeatherTemperatureQuery(wfsURL) {
  return axios.create({
    responseType: 'text',
    baseURL: wfsURL,
    timeout: 20000,
    params: {
      service: 'WFS',
      request: 'GetFeature',
      //STOREDQUERY_ID: 'fmi::observations::mareograph::timevaluepair',
      STOREDQUERY_ID: 'fmi::observations::wave::multipointcoverage',
      bbox: '18.88, 59.28, 31.63, 66.05',
      starttime: moment.utc().subtract(360, "minutes").format(),
      parameters: 'TWATER'
      //parameters: 'TW_PT1H_AVG'
    }
  })
}

function WFSWeatherWaterHeightTempQuery(wfsURL) {
  return axios.create({
    responseType: 'text',
    baseURL: wfsURL,
    timeout: 20000,
    params: {
      service: 'WFS',
      request: 'GetFeature',
      // changed wfs-service to instant::
      // and to multipointcoverage
      STOREDQUERY_ID: 'fmi::observations::mareograph::instant::multipointcoverage',
      //STOREDQUERY_ID: 'fmi::observations::mareograph::multipointcoverage',
      bbox: '18.88, 59.28, 31.63, 66.05',
      // ch: 360 -> 180
      starttime: moment.utc().subtract(180, "minutes").format(),
      //endtime: 'now()',
      parameters: 'TW_PT1H_AVG'
    }
  })
}
function WFSWeatherWaterHeightQuery(wfsURL) {
  return axios.create({
    responseType: 'text',
    baseURL: wfsURL,
    timeout: 20000,
    params: {
      service: 'WFS',
      request: 'GetFeature',
      // changed wfs-service to instant::
      // and to multipointcoverage
      STOREDQUERY_ID: 'fmi::observations::mareograph::instant::multipointcoverage',
      //STOREDQUERY_ID: 'fmi::observations::mareograph::multipointcoverage',
      bbox: '18.88, 59.28, 31.63, 66.05',
      // ch: 360 -> 180
      starttime: moment.utc().subtract(180, "minutes").format(),
      //endtime: 'now()',
      parameters: 'WATLEV'
    }
  })
}

function WFSWeatherWaterTemperatureQuery(wfsURL) {
  return axios.create({
    responseType: 'text',
    baseURL: wfsURL,
    timeout: 20000,
    params: {
      service: 'WFS',
      request: 'GetFeature',
      STOREDQUERY_ID: 'fmi::observations::mareograph::timevaluepair',
      //STOREDQUERY_ID: 'fmi::observations::wave::timevaluepair',
      bbox: '18.88, 59.28, 31.63, 66.05',
      crs: 'EPSG:3857',
      starttime: moment.utc().subtract(60, "minutes").format(),
      parameters: 'TW_PT1H_AVG'
      //parameters: 'TWATER'
    }
  })
}

function createWFSVideoSpatilInfo(wfsURL) {
  return axios.create({
    baseURL: wfsURL,
    timeout: 20000,
    params: {
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      outputFormat: 'JSON',
      typeNames: 'velmukartta:Videoita_merelta', // 'meritietoportaali:videopisteet',
    }
  })
}

function createWFSPictureSpatilInfo(wfsURL) {
  return axios.create({
    baseURL: wfsURL,
    timeout: 20000,
    params: {
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      outputFormat: 'JSON',
      typeNames: 'velmukartta:Kuvia_merelta', // 'meritietoportaali:kuvapisteet',
    }
  })
}

// include: 'observations,connections',
function createSwimmingInfo(url) {
  return axios.create({
    baseURL: url,
    timeout: 20000,
    params: {
      service: '730,731',
      only: 'id,name,location,extensions,municipality',
      include: 'observations,connections',
      geometry: true,
      page_size: '1001',
    }
  })
}

function receiveFeatures(url) {
  return axios.create({
    baseURL: url,
    timeout: 20000
  })
}

export default wfsAxiosApi;
