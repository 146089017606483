var moment = require('moment');

import satelliteLayersConfig from './satellite-layers.configs/config';

import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import mapApi from './mapsInit';

let waterQualityCompabilitiesURL = satelliteLayersConfig.waterQualityConfigs.WMScapabilitiesURL;
let waterQualityURL = satelliteLayersConfig.waterQualityConfigs.layerRequestURL;
let turbidityTimeDimensions = {};
let qualities = {};

function _builderWaterTurbidityLayers(url, names) {
  return new Promise((resolve, reject) => {
    let turbidityLayers = [];

    let times = Object.keys(qualities);
    times.forEach((time, index) => {
      let nIndex = 0;  
      let layer = names[nIndex];
      while (qualities[time][0] != layer.layerOriginalName) {
        nIndex++;
        layer = names[nIndex];
      }
      qualities[time].sort(); // wanted drawing order is in this case reversed alphabetical order
      qualities[time].reverse();  
      let layerStr = qualities[time][0];
      qualities[time].forEach((layerName, index) => {
        if (index > 0) {
	      layerStr += ',' + layerName;
        }
      })
      let visibleID = index;
      let LayerCounctructor = new TileLayer({
        id: index++,
        finnishName: qualityLayerTime(time),
        name: qualityLayerTime(time),
        originalName: qualityLayerTime(time),
        visible: visibleID === 0 ? true : false,
        source: new TileWMS({
          url: url,
          params: {
            'TILED': true,
            'LAYERS': `${layerStr}`,
	    'TIME': time
          },
          transparent: 'false',
          projection: `EPSG:${name.layerEPSG}`,
          crossOrigin: 'anonymous'
        })
      });
      turbidityLayers.push(LayerCounctructor);
    })
    if (turbidityLayers.length == 10) {
      resolve(turbidityLayers)
    }
  })
}

function addWaterQualitiesTURBIDITY(type) {
  return new Promise((resolve, reject) => {
    let typedLayers = [];
    mapApi.parseWaterQualityWMS({
        map: 'map',
        url: waterQualityCompabilitiesURL
      })
      .then((wms) => {
        let layers = wms.Capability.Layer.Layer;

        if (type === satelliteLayersConfig.waterQualityConfigs.turbidity) {
	        turbidityTimeDimensions = {};    
          layers.forEach((layer) => {
            if (layer.Name.indexOf("EO_HR_WQ_LC8_TURB") > -1 || layer.Name.indexOf("EO_HR_WQ_S2_TURB") > -1) {
	            typedLayers.push(layer);
	            turbidityTimeDimensions[layer.Name] = layer.Dimension[0].values.split(",");
            }
          })
        }

        qualities = mapApi.getLastTimesMap(turbidityTimeDimensions);
	  
        let formatedNames = typedLayers.map((layer) => {
          let newLayer = {
            layerName: layer.Name,
            layerOriginalName: layer.Name,
            layerDesc: layer.Abstract,
            layerEPSG: layer.CRS[0],
            layerLegendURL: layer.Style[0].LegendURL[0].OnlineResource.replace('https://geoserver2.ymparisto.fi/geoserver/eo/ows?', satelliteLayersConfig.waterQualityConfigs.replacePartURL)
          }
          return newLayer;
        })
        _builderWaterTurbidityLayers(waterQualityURL, formatedNames)
          .then((buildedLayers) => {
            resolve(buildedLayers);
          })
      })
  })
}

function qualityLayerTime(time) {
  if (moment(time).format("DD-MM-YYYY hh:mm A", true)) {
    let date = moment(time).format("DD.MM.YYYY");
    return date;
  } else {
    return "without_time"
  }
}

export default addWaterQualitiesTURBIDITY;
