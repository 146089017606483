<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 24.44 20.78"
		:aria-labelledby    = "iconName"
		role                = "presentation"
		style="fill:#fff"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName | translate}}
		</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
			<path
				className="cls-1"
				d="M24.19 18.32a3.31 3.31 0 00-1.69.68 2.53 2.53 0 01-1 .43 2.56 2.56 0 01-1-.43 3.29 3.29 0 00-1.68-.63 3.32 3.32 0 00-1.69.63 2.47 2.47 0 01-1 .43 2.47 2.47 0 01-1-.43 3.31 3.31 0 00-1.69-.63 3.31 3.31 0 00-1.69.63 2.47 2.47 0 01-1 .43 2.47 2.47 0 01-1-.43 3.31 3.31 0 00-1.69-.63 3.31 3.31 0 00-1.52.63 2.47 2.47 0 01-1 .43 2.47 2.47 0 01-1-.43 3.31 3.31 0 00-1.69-.63 3.31 3.31 0 00-1.63.63 2.47 2.47 0 01-1 .43H0v1.4h.25a3.41 3.41 0 001.69-.63 2.47 2.47 0 011-.43 2.33 2.33 0 011 .43 3.41 3.41 0 001.69.63 3.32 3.32 0 001.69-.63 2.33 2.33 0 011-.43 2.47 2.47 0 011 .43 3.41 3.41 0 001.69.63 3.41 3.41 0 001.69-.63 2.47 2.47 0 011-.43 2.47 2.47 0 011 .43 3.41 3.41 0 001.69.63 3.41 3.41 0 001.69-.63 2.47 2.47 0 011-.43 2.53 2.53 0 011 .43 3.41 3.41 0 001.69.63 3.41 3.41 0 001.69-.63 2.47 2.47 0 011-.43h.25v-1.4zM20.13 14.39l-4.08-8V0L8.54 14.39H5.16l.52 1a6.61 6.61 0 001.79 2.11l.2.15.85-1.11-.2-.15a5.7 5.7 0 01-.66-.6h12.23l-.48.71 1.17.78 1.92-2.89zm-5.48 0h-4.53l4.53-8.68zm1.4 0V9.48l2.51 4.91zM9 8.24a2.62 2.62 0 00.75-.37 2.56 2.56 0 00.91-1.26 2.13 2.13 0 00.12-.6v-.28a.38.38 0 000-.1v-.22a1.86 1.86 0 00-.1-.37 2.36 2.36 0 00-.54-.9 2.46 2.46 0 00-1.7-.8 2.42 2.42 0 00-1.09.19 2.56 2.56 0 00-1.1.88 2.61 2.61 0 00-.38.89 2.08 2.08 0 00-.06.54.69.69 0 00.3.58.7.7 0 001.1-.58 1.27 1.27 0 01.05-.34 1.19 1.19 0 01.25-.5 1.06 1.06 0 01.63-.27 1 1 0 01.57.1 1.07 1.07 0 01.62.88 1 1 0 010 .42A1 1 0 019 6.6a1.09 1.09 0 01-.82.34H.73v1.4h7.49a2.64 2.64 0 00.78-.1z"
			/>
			</g>
		</g>
	</svg> 
	 
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'weather'
		},
		width: {
			type: [Number, String],
			default: 23
		},
		height: {
			type: [Number, String],
			default: 25
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

</style>