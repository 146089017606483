import VectorSource from 'ol/source/vector';
import wfsAxiosApi from '../../axios-wms-conf/axiosWFSConf'
import spatialApi from './spatialInfoLayers';
import mapApi from '../mapsInit';
// Features
import Feature from 'ol/feature';
// Point
import Point from 'ol/geom/point';
// Styling Point
import Style from 'ol/style/style';
import Icon from 'ol/style/icon';
// set the projections
import proj from 'ol/proj';
import proj4 from 'proj4';

proj.setProj4(proj4);
proj4.defs("EPSG:5048", "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
let proj5048_ = proj.get("EPSG:5048");
// spatial configuration
import spatialConfiguration from './spatial-config';
let Pictures_API = {
  addLocations: addLocations,
};
let PICTURESFeatureSize;

async function addLocations(map) {
  let PictureVectorLayerSource = new VectorSource({});
  let picturesInstance = new spatialApi.initializer(map, wfsAxiosApi.wfsCreateWFSPictureSpatilInfo);
  let spatielGroup = mapApi.getPlannerGroup(map, spatialConfiguration.groupName);
  let pictureVector = getPictureVector(spatielGroup, spatialConfiguration.PICTURE_NAME);
  pictureVector.setZIndex(2);
  let picturePoints = await picturesInstance.getSourceVectorObjects();
  await settingPictureFeatures(picturePoints.features, PictureVectorLayerSource);
  pictureVector.setSource(PictureVectorLayerSource);
  pictureVector.setStyle(function (feature) {
    let styleCache = {};
    let featuresSize = PICTURESFeatureSize;
    let style = styleCache[featuresSize];

    if (!style) {
      style = new Style({
        image: new Icon({
          src: 'https://meriopas.ymparisto.fi/meriopas/static/picturesIcons/photos_map.png',
          size: [54, 72],
          anchor: [0.5, 1],
          scale: 0.5,
        })
      })
      styleCache[featuresSize] = style
    }
    return style;
  })
}

function getPictureVector(collection, name) {
  let vector;
  collection.forEach((layer) => {
    if (layer.get("name") === name) {
      vector = layer;
    }
  })
  return vector;
}

// fid	OBJECTID	Tiedosto	Kuvaaja	Pvm	Teema	Laji	TieteellinenNimi	Koodi	Kuva	Luontoportti	Teema_sve	Teema_eng	Laji_sve	Laji_eng
async function settingPictureFeatures(locations, source) {
  let features = [];
  for (let i = 0; i < locations.length; ++i) {
    features[i] = new Feature({
      geometry: new Point(proj.transform([locations[i].geometry.coordinates[0], locations[i].geometry.coordinates[1]], proj5048_, 'EPSG:3857')),
      type: 'Pictures',
      name: locations[i].properties.Kuvaaja,
      species: locations[i].properties.Laji,
      speciesEng: locations[i].properties.Laji_eng,
      speciesSve: locations[i].properties.Laji_sve,
      Luontoportti: locations[i].properties.Luontoportti,
      date: locations[i].properties.Pvm,
      link: locations[i].properties.Kuva
    });
    features[i].set('fid', i);
  }
  source.clear();
  source.addFeatures(features);
  PICTURESFeatureSize = features.length;
}

export default Pictures_API;
