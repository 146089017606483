<template>
  <div v-show="popObjectContent !== null">
    <div class="pop-up-container" v-for="(item, index) in popObjectContent" :key="index">
      <span v-for="(value, key,index) in item.properties" :key="index">
        {{ key | translate }} : {{ value | roundValue(key) | translate }}
      </span>
      <div @click="closePopUp(popObject)">
        <icon-tools-close-base :iconColor="'#000000'" :width=10 :height=10>
        </icon-tools-close-base>
      </div>
    </div>
  </div>
</template>

<script>
  import IconToolsCloseBase from '../svg-system-components/icons-tools/IconToolsCloseBase';
  export default {
    components: {
      IconToolsCloseBase
    },
    props: ['popObjectContent', 'popObject'],
    filters: {
      roundValue(value, typeOfMeasure) {
        console.log('typeOfMeasure >>> ', typeOfMeasure);
        let roundedValue;
        switch (typeOfMeasure) {
          case "Sea_surface_temperature":
            return value.toFixed(1) + '°C';
            break;
          case "chla":
            return value.toFixed(1) + 'μg/l'
            break;
          case "Turbidity":
            return value.toFixed(1) + ' FNU';
            break;
          case "a400_adg":
            return value.toFixed(1) + ' 1/m';
            break;
          case "SDT":
            return value.toFixed(1) + 'm';
            break;
          case "algae_index":
            return 'algae_index' + value;
            break;
          default:
            return value.toFixed(1);
        }
      },
    },
    methods: {
      closePopUp(overlay) {
        overlay.popup.setPosition(undefined);
        this.expand = false;
        return false;
      }
    },
    mounted() {

    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

</script>

<style scoped lang="scss">
  .pop-up-container {
    background-color: white;
    padding: 5px 8px;
    border-radius: 2px;

    &:before {
      border-top-color: #cccccc;
      border-width: 11px;
      left: 48px;
      margin-left: -11px;
      top: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      top: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: white;
      border-width: 10px;
      right: 50%;
      transform: translate(50%, 0);
    }

    div {
      display: inline-block;
      padding-left: 0.5rem;
      cursor: pointer;

      &>* {
        vertical-align: baseline;
      }
    }
  }

</style>
