<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 14.51 13.993"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName}} icon
		</title>
        <g id="Group_170" transform="translate(-295.826 -26.723)">
        
            <rect x="293.684" y="32.968" transform="matrix(0.7071 0.7071 -0.7071 0.7071 112.5372 -204.2497)" :fill=iconColor width="18.272" height="1.504"/>
        
            <rect x="294.206" y="32.968" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 541.6807 -156.9325)" :fill=iconColor width="18.272" height="1.504"/>
        </g>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'box'
		},
		width: {
			type: [Number, String],
			default: 16
		},
		height: {
			type: [Number, String],
			default: 20
		},
		iconColor: {
			type: String,
			default: '#FFFFFF'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

	svg > * {
        fill: white;
    }

</style>