<template>
	<svg
		xmlns               = "http://www.w3.org/2000/svg"
		xmlns:xlink         = "http://www.w3.org/1999/xlink"
		x                   = "0px"
		y                   = "0px"
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 24.583 24.09"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName | translate}}
		</title>

      <g id="Group_1180" transform="translate(-2093.325 -300.516)" >
	<path id="Path_778" style="fill:none;stroke-width:1.5;" :stroke = iconStroke d="M2096.3,309.364l-1.943,1.642l9.7,12.6l12.75-8.047
		l-2.154-2.741"/>
	<g id="Group_1177" transform="translate(2101.797 311.461)" :fill=iconColor>
		<g id="Path_271" transform="translate(-1 0)">
			<path d="M7.188,2.985c-0.02-0.21-0.06-0.42-0.12-0.62c-0.03-0.1-0.06-0.2-0.1-0.29c-0.03-0.1-0.08-0.19-0.12-0.28
				c-0.13-0.25-0.29-0.49-0.47-0.7c-0.1-0.1-0.2-0.2-0.31-0.29c-0.65-0.58-1.53-0.89-2.47-0.8c-0.14-0.02-0.29-0.02-0.44-0.02
				c-0.7,0.03-1.34,0.28-1.85,0.68c-0.06,0.04-0.12,0.09-0.18,0.13c-0.08,0.07-0.15,0.14-0.22,0.21c-0.07,0.08-0.14,0.15-0.2,0.23
				c-0.13,0.17-0.24,0.34-0.34,0.52c-0.04,0.1-0.09,0.19-0.13,0.29c-0.07,0.18-0.13,0.38-0.17,0.58c-0.02,0.13-0.04,0.26-0.05,0.39
				s-0.02,0.27-0.02,0.41c0,2.25,3.6,5.47,3.6,5.47s3.6-3.22,3.6-5.47C7.208,3.275,7.198,3.125,7.188,2.985z M4.248,6.045
				c-0.22,0.27-0.44,0.51-0.65,0.73c-0.21-0.22-0.44-0.48-0.67-0.76c-1.05-1.28-1.43-2.18-1.43-2.59c0-1.68,1.32-1.92,2.1-1.92
				c0.79,0,2.1,0.24,2.1,1.92C5.698,3.845,5.318,4.755,4.248,6.045z"/>
			<path :fill=iconColor d="M7.068,2.365c-0.03-0.1-0.06-0.2-0.1-0.29c-0.03-0.1-0.08-0.19-0.12-0.28c-0.12-0.26-0.28-0.5-0.47-0.7
				c-0.1-0.1-0.2-0.2-0.31-0.29c-0.65-0.53-1.52-0.8-2.47-0.8c-0.87,0-1.66,0.22-2.29,0.66c-0.06,0.04-0.12,0.09-0.18,0.13
				c-0.08,0.07-0.15,0.14-0.22,0.21c-0.07,0.08-0.14,0.15-0.2,0.23c-0.13,0.17-0.24,0.34-0.34,0.52c-0.04,0.1-0.09,0.19-0.13,0.29
				c-0.07,0.18-0.13,0.38-0.17,0.58c-0.02,0.13-0.04,0.26-0.05,0.39s-0.02,0.27-0.02,0.41c0,2.25,3.6,5.47,3.6,5.47
				s3.6-3.22,3.6-5.47C7.198,3.045,7.148,2.685,7.068,2.365z M4.248,6.045c-0.22,0.27-0.44,0.51-0.65,0.73
				c-0.21-0.22-0.44-0.48-0.67-0.76c-1.05-1.28-1.43-2.18-1.43-2.59c0-1.68,1.32-1.92,2.1-1.92c0.79,0,2.1,0.24,2.1,1.92
				C5.698,3.845,5.318,4.755,4.248,6.045z"/>
		</g>
	</g>
	<g id="Group_1178" transform="translate(2096.729 303.726)">
		<g id="Path_271-2" transform="translate(0 0)" :fill=iconColor>
			<path style="fill:none;" d="M3.464,0c1.745-0.162,3.291,1.121,3.453,2.866C6.93,3.007,6.934,3.149,6.928,3.291
				c0,2.171-3.464,5.263-3.464,5.263S0,5.462,0,3.291C-0.073,1.54,1.288,0.062,3.039-0.011C3.181-0.017,3.323-0.013,3.464,0z"/>
			<path :fill=iconColor d="M3.464,6.436c0.192-0.208,0.397-0.439,0.6-0.685c1.006-1.219,1.364-2.07,1.364-2.461c0-0.59-0.17-1.038-0.505-1.333
				C4.587,1.663,4.069,1.5,3.464,1.5c-0.605,0-1.123,0.162-1.459,0.458C1.67,2.252,1.5,2.701,1.5,3.291
				c0,0.39,0.358,1.242,1.364,2.461C3.067,5.998,3.272,6.229,3.464,6.436 M3.464,8.553c0,0-3.464-3.092-3.464-5.263S1.551,0,3.464,0
				c1.913,0,3.464,1.12,3.464,3.291C6.928,5.461,3.464,8.553,3.464,8.553z"/>
		</g>
	</g>
	<g id="Group_1179" transform="translate(2107.124 304.578)">
		<g id="Path_271-3" transform="translate(0 0)" :fill=iconColor>
			<path :fill=iconColor d="M7.941,3.288c-0.03-0.27-0.08-0.53-0.17-0.78c-0.03-0.11-0.07-0.22-0.11-0.32c-0.03-0.08-0.06-0.15-0.1-0.21
				c-0.15-0.3-0.34-0.57-0.56-0.81c-0.07-0.09-0.16-0.16-0.25-0.24c-0.73-0.66-1.72-1.03-2.78-0.93c-0.16-0.01-0.32-0.02-0.48-0.01
				c-0.77,0.03-1.48,0.3-2.05,0.75c-0.06,0.04-0.13,0.09-0.19,0.15c-0.09,0.07-0.16,0.14-0.24,0.22c-0.24,0.25-0.44,0.53-0.6,0.83
				c-0.05,0.1-0.09,0.2-0.13,0.3c-0.04,0.08-0.07,0.17-0.1,0.27c-0.04,0.13-0.07,0.26-0.1,0.39c-0.03,0.14-0.05,0.28-0.06,0.43
				c-0.01,0.14-0.02,0.29-0.02,0.45c0,2.49,3.97,6.04,3.97,6.04s3.98-3.55,3.98-6.04C7.951,3.618,7.951,3.448,7.941,3.288z
				 M4.811,6.768c-0.29,0.35-0.58,0.67-0.84,0.94c-0.26-0.28-0.56-0.61-0.85-0.96c-1.5-1.82-1.62-2.74-1.62-2.97
				c0-0.75,0.22-1.32,0.65-1.7c0.43-0.37,1.08-0.58,1.82-0.58c0.75,0,1.4,0.21,1.82,0.58c0.44,0.38,0.66,0.95,0.66,1.7
				C6.451,4.018,6.331,4.938,4.811,6.768z"/>
			<path :fill=iconColor d="M7.771,2.508c-0.03-0.11-0.07-0.22-0.11-0.32c-0.03-0.08-0.06-0.15-0.1-0.21c-0.15-0.31-0.34-0.58-0.56-0.81
				c-0.07-0.09-0.16-0.16-0.25-0.24c-0.72-0.62-1.69-0.93-2.78-0.93c-0.95,0-1.84,0.24-2.53,0.74c-0.06,0.04-0.13,0.09-0.19,0.15
				c-0.09,0.07-0.16,0.14-0.24,0.22c-0.24,0.23-0.45,0.51-0.6,0.83c-0.05,0.1-0.09,0.2-0.13,0.3c-0.04,0.08-0.07,0.17-0.1,0.27
				c-0.04,0.13-0.07,0.26-0.1,0.39c-0.03,0.14-0.05,0.28-0.06,0.43c-0.01,0.14-0.02,0.29-0.02,0.45c0,2.49,3.97,6.04,3.97,6.04
				s3.98-3.55,3.98-6.04C7.951,3.318,7.891,2.888,7.771,2.508z M4.811,6.768c-0.29,0.35-0.58,0.67-0.84,0.94
				c-0.26-0.28-0.56-0.61-0.85-0.96c-1.5-1.82-1.62-2.74-1.62-2.97c0-0.75,0.22-1.32,0.65-1.7c0.43-0.37,1.08-0.58,1.82-0.58
				c0.75,0,1.4,0.21,1.82,0.58c0.44,0.38,0.66,0.95,0.66,1.7C6.451,4.018,6.331,4.938,4.811,6.768z"/>
		</g>
	</g>
	<path id="Path_779" style="fill:none;stroke-width:1.5;" :stroke = iconStroke d="M2108.035,303.957l-1.583-2.192l-2.605,1.743"/>
</g>
	</svg>
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'spatialInfo'
		},
		width: {
			type: [Number, String],
			default: 23
		},
		height: {
			type: [Number, String],
			default: 25
		},
		iconColor: {
			type: String,
			default: '#FFFFFF'
		},
    iconStroke: {
		  type: String,
      default : '#FFFFFF'
    }
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

</style>
