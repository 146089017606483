import Vue from "vue";
import Vuex from "vuex";

import mapsMainStore from './modules/maps-main.store';
import mapsMenuStore from './modules/maps-menu.store';
import mapsPopUpStore from './modules/maps-popUp-store';
// import keystoneSpeciesWindowStore
import keystoneSpeciesInfoWindow from './modules/maps-keystoneSpecies-info.store';

// store for map legend components
import mapsMainLegendStore from './modules/maps-main-legend.store';

// simple store for the transltions
import translationsStore from './modules/translations.store'

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    mapsMainStore,
    mapsMenuStore,
    mapsPopUpStore,
    keystoneSpeciesInfoWindow,
    mapsMainLegendStore,
    translationsStore
  }
})
