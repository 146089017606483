<template>
  <div class="menu-list__wrapper">
    <ul class="sidemenu__list" :class="{'sidemenu__list--visible' : baseMenuVisibility}">
      <li class="list__item" v-for="(item, index) in baseMapLayers" :key="index" @click="setVisible(item.finnishName, item);infoIndex=index">
        <!-- wrapper for map element -->
        <div class="list__item--general">
          <!-- wrapper for map picture element -->
          <div class="item-picture-wrapper">
            <img :alt="item.finnishName" :src="hashMapsPictures[item.finnishName]">
          </div>

          <!-- map layer name -->
          <span :class="{'invisible-layer-text' : !item.visible}">
            {{ item.finnishName | translate}}

          </span>
          <div class="list__item-button">
            <icon-close-eye-base v-if="!item.visible" icon-name="" icon-color="#fff" width="18" height="10">
            </icon-close-eye-base>
            <icon-open-eye-base v-if="item.visible" icon-name="" icon-color="#fff" width="18" height="9">
            </icon-open-eye-base>
          </div>
        </div>
        <div class="list__item--warning" :class="{'list__item--warning-viible' : item.visible && baseMapWarning !== ''}">
          {{ baseMapWarning }}
        </div>
        <div class="menu-info" v-if="infoIndex===index && currentlyActiveMap">
          {{ setMapDetails.translations | translate }}
        </div>
      </li>
    </ul>
    <div class="portal-link">
      <a :href="'portalLink' | translate">
      <img style="width: 120px;vertical-align: middle;" :src="'https://meriopas.ymparisto.fi/meriopas/static/logos/itamerilogo ' + currentLanguage.name + ' nega.svg'" alt="">
      </a>
    </div>  
  </div>
</template>
<script>
  import baseMapsConfig from '../../maps-constructor/basemap-layers.configs/config';
  import mapApi from '../../maps-constructor/mapsInit';
  import baseMapsUtils from './menu-base-maps-utils/utils';
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex';
  import BacckgroundMap from '@/assets/baseMapsPictures/BackgroundMap.png';
  import SeaMap from '@/assets/baseMapsPictures/SeaMap.png';
  import TopographicalMap from '@/assets/baseMapsPictures/TopographicalMap.png';
  import PlainMap from '@/assets/baseMapsPictures/PlainMap.png';
  import HillShadeMap from '@/assets/baseMapsPictures/HillShadeMap.png';
  import AerialMap from '@/assets/baseMapsPictures/AerialMap.png';
  import IconCloseEyeBase from '../svg-system-components/IconCloseEyeBase';
  import IconOpenEyeBase from '../svg-system-components/IconOpenEyeBase';
  export default {
    components: {
      IconCloseEyeBase,
      IconOpenEyeBase
    },
    data() {
      return {
        hashMapsPictures: {
          "plainMap": BacckgroundMap,
          "seaMap": SeaMap,
          "backgroundMap": PlainMap,
          "topographic": TopographicalMap,
          "hillShade": HillShadeMap,
          "aerial": AerialMap
        },
        currentlyActiveMap: "",
        infoIndex: null
      }
    },
    props: ['map'],
    computed: {
      ...mapGetters({
        baseMapLayers: 'getBaseMapLayers',
        baseMenuVisibility: 'getshowBaseMapMenu',
        baseMapWarning: 'getBaseMapWarning',
        getLoadingStatus: 'getLoadingStatus',
        currentLanguage: 'getCurrentLanguage'
      }),
      setMapDetails() {
        let detail = baseMapsUtils.baseMapsDetails.find((text) => {
          if (text.name === this.currentlyActiveMap.name) {
            return text;
          }
        });
        return detail;
      }
    },

    methods: {
      ...mapActions(['fetchBaseMapLayers', 'setVisibleLayer']),
      ...mapMutations(['setBaseMapWarning', 'setLoadingStatus']),
      async setVisible(name, item) {
        // Open attribution widget when OSM selected
        if (name === baseMapsConfig.plainMapName) {
          let controls = this.map.getControls().getArray();
          let attrWidget = controls[2];
          attrWidget.setCollapsed(false);
	}

        this.setLoadingStatus(true);
        console.log('item >> ', item);
        if (name === baseMapsConfig.hillShadeMap || name === baseMapsConfig.aerialMapName) {

          mapApi.changeMapProjection(this.map);

          await this.setVisibleLayer({
            mapInstance: this.map,
            name: name,
            layerGroupName: baseMapsConfig.baseMapsGroupTitle
          })

          this.currentlyActiveMap = this.baseMapLayers.filter(layer => layer.visible)[0];

          let warningMessage = "Only for view purpose"

          this.setBaseMapWarning(warningMessage);
        } else {
          if (this.map.getView().getProjection().getCode() == baseMapsConfig.projectionFin) {
            mapApi.setDefaultProjection(this.map)
            this.setBaseMapWarning("");
          }
          await this.setVisibleLayer({
            mapInstance: this.map,
            name: name,
            layerGroupName: baseMapsConfig.baseMapsGroupTitle
          })
          if (item.visible && this.baseMapWarning !== "") {

            setTimeout(() => {
              let warningMessage = ""
              this.setBaseMapWarning(warningMessage)
            }, 3000)
          }
          mapApi.asyncVectorRefresh(this.map);
          this.map.updateSize();
          this.currentlyActiveMap = this.baseMapLayers.filter(layer => layer.visible)[0];
        }
        this.setLoadingStatus(false);
      }
    },
    async mounted() {
      await this.fetchBaseMapLayers({
        mapInstance: this.map,
        name: baseMapsConfig.baseMapsGroupTitle
      });
      this.currentlyActiveMap = this.baseMapLayers.filter(layer => layer.visible)[0];
    }
  }

</script>

<style scoped lang="scss">
  .menu-list__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 47px);
  }

  .sidemenu__list {
    @include list;
    
    .list__item {
      display: flex;
      flex-direction: column;

      .list__item--general,
      .list__item--warning {
        padding: 0 10px;
        height: 46px;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;

        .item-picture-wrapper {
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
          }
        }

        span {
          margin-left: 8px;
          width: 80%;
        }

        .invisible-layer-text {
          opacity: 0.8;
        }
      }

      .list__item--warning {
        height: 0;
        opacity: 0;
        transition: all .25s cubic-bezier(0.4, 0.5, 0.2, 1);
        display: flex;
        justify-content: center;
        // background-color: #2B77B2;
        color: yellow;
      }

      .list__item--warning-viible {
        transition: all .25s cubic-bezier(0.4, 0.5, 0.2, 1);
        height: 46px;
        opacity: 1;

      }
    }
  }

  .menu-info {
    background-color: #074679;
    max-height: 250px;
    overflow-y: auto;
    margin: 0;
    padding: 1rem;
    word-break: break-word;
    color: white;
    text-align: left;
    overflow-x: hidden;
  }

  .testClass {
    background-color: red;
  }

  .tooltoip,
  .tooltip--bottom {
    padding: 0;
    margin: 0;
  }
	.portal-link-description {
    color: white;
  }
  
  .portal-link-image {
    height: 100px;
    width: 162px;
  }
</style>
