<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 20.134 20.134"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName}} icon
		</title>
        <rect :fill=iconColor id="Rectangle_19" width="1.5" height="9.152"/>
        <rect :fill=iconColor id="Rectangle_25" x="18.634" width="1.5" height="9.152"/>
        <rect :fill=iconColor id="Rectangle_21" width="9.152" height="1.5"/>
        <rect :fill=iconColor id="Rectangle_23" x="10.982" width="9.152" height="1.5"/>
        <rect :fill=iconColor id="Rectangle_22" y="18.634" width="9.152" height="1.5"/>
        <rect :fill=iconColor id="Rectangle_24" x="10.982" y="18.634" width="9.152" height="1.5"/>
        <rect :fill=iconColor id="Rectangle_20" y="10.982" width="1.5" height="9.152"/>
        <rect :fill=iconColor id="Rectangle_26" x="18.634" y="10.982" width="1.5" height="9.152"/>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'box'
		},
		width: {
			type: [Number, String],
			default: 16
		},
		height: {
			type: [Number, String],
			default: 20
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}
</style>