import Overlay from 'ol/overlay';

function windsLogic(map, element) {
  // feature PopUp storage
  let featurePopUp = {};
  // creating an overlay for info window
  let popup = new Overlay({
    element: element,
    positioning: 'center-center',
    stopEvent: true,
    autoPan: true,
    autoPanAnimation: {
      duration: 250
    }
  });
  map.addOverlay(popup);
  featurePopUp.popup = popup;
  return featurePopUp;
}

function WMSFeaturesLogic(map, element) {
  // feature PopUp storage
  let featurePopUp = {};
  // creating an overlay for info window
  let popup = new Overlay({
    element: element,
    positioning: 'center-center',
    stopEvent: true,
    autoPan: true,
    autoPanAnimation: {
      duration: 250
    }
  });
  map.addOverlay(popup);
  featurePopUp.popup = popup;
  return featurePopUp;
}

let featuresWindows = {
  windsFeature: windsLogic,
  WMSFeaturesLogic: WMSFeaturesLogic
}

export default featuresWindows;
