<template>
  <div class="menu-list__wrapper">
    <ul class="sidemenu__list" v-if="layers">
      <li class="list__item" v-for="(item, index) in layers" :key="index" @click="activateLayer(item.finnishName);showLayerInfo(item);infoIndex=index">
        <!-- wrapper for weather element -->
        <div class="list__item--general">
          <!-- container for weater svg icons components  -->
          <div>
            <img :src="item.icon" alt="" width="28" height="28">
          </div>
          <!-- names of weather menu elements -->
          <span :class="{'invisible-layer-text' : !item.visible}">
            {{ item.finnishName | translate }}
          </span>

          <!-- container for visible and invisible status -->
          <div class="item-eyes-container">
            <icon-close-eye-base v-if="!item.visible" icon-name="invisible" icon-color="#fff" width="18" height="10">
            </icon-close-eye-base>
            <icon-open-eye-base v-if="item.visible" icon-name="visible" icon-color="#fff" width="18" height="9">
            </icon-open-eye-base>
          </div>
        </div>
        <div v-if="infoIndex===index && currentLayerInfo" class="menu-info">
          {{ currentLayerInfo !== "" ? currentLayerInfo + "Details" : "" | translate }}
        </div>
      </li>
    </ul>
    <div class="portal-link">
      <a :href="'portalLink' | translate">
        <img style="width: 120px;vertical-align: middle;" :src="'https://meriopas.ymparisto.fi/meriopas/static/logos/itamerilogo ' + currentLanguage.name + ' nega.svg'" alt="">
      </a>
     </div>  
  </div>
  <!--{{ item.finnishName == 'Winds' ? 'Wind & air temp.' : 'Wave height & water temp.' }}-->
</template>

<script>
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex';
  import weatherConfigs from '../../maps-constructor/weather-constructor/weather-config';
  import IconCloseEyeBase from '../svg-system-components/IconCloseEyeBase';
  import IconOpenEyeBase from '../svg-system-components/IconOpenEyeBase';
  // import IconWaterInMenuBase from '../svg-system-components/IconWaterInMenuBase';
  // import IconWindsInMenuBase from '../svg-system-components/IconWindsInMenuBase';
  export default {
    components: {
      IconCloseEyeBase,
      IconOpenEyeBase,
      // IconWaterInMenuBase,
      // IconWindsInMenuBase
    },
    props: ['map'],
    data() {
      return {
        infoIndex: null,
      }
    },
    computed: {
      ...mapGetters({
        layers: 'getWeatherInfoLayer',
        getLoadingStatus: 'getLoadingStatus',
        getLayerInterval: 'getLayerInterval',
        currentLayerInfo: 'getCurrentLayerInfoWeather',
        currentLanguage: 'getCurrentLanguage'
      }),
    },
    methods: {
      ...mapActions(['setVisibleLayer', 'fetchWinds', 'fetchWaves', 'fetchTemperatures', 'fetchWaterHeight']),
      ...mapMutations({
        setLoadingStatus: 'setLoadingStatus', 
        setLayerInterval: 'setLayerInterval', 
        setCurrentLayerInfo: 'setCurrentLayerInfoWeather'
      }),

      showLayerInfo(item) {
        if (!this.currentLayerInfo && !item.visible) {
          this.setCurrentLayerInfo(item.name);
        } else if (this.currentLayerInfo && !item.visible) {
          this.setCurrentLayerInfo(item.name);
        } else {
          this.setCurrentLayerInfo("");
        }
      },
      activateLayer(name) {
        let layerInterval = this.getLayerInterval;
        if (name === weatherConfigs.weatherWindName) {
          layerInterval.wind = !layerInterval.wind;
        } else if (name === weatherConfigs.weatherWaveName) {
          layerInterval.wave = !layerInterval.wave;
        } else if (name === weatherConfigs.weatherTemperatureName) {
          layerInterval.temperature = !layerInterval.temperature;
        } else if (name === weatherConfigs.weatherWaterTemperatureName) {
          layerInterval.waterTemperature = !layerInterval.waterTemperature;
        } else if (name === weatherConfigs.weatherWaterHeightName) {
          layerInterval.waterHeight = !layerInterval.waterHeight;
        }
        this.setLayerInterval(layerInterval);
      },
    }
  }

</script>


<style scoped lang="scss">
  .menu-list__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 47px);
  }

  .sidemenu__list {
    @include list;

    .list__item {

      .list__item--general {
        padding: 0 10px;
        height: 46px;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        justify-content: space-between;

        span {
          width: 80%;
          margin-left: 9px;
        }

        .invisible-layer-text {
          opacity: 0.8;
        }

        .item-eyes-container {
          margin-right: 0px;
        }
      }
    }

    .sidemenu__list-info {
      padding: 10px 10px;
      text-align: left;
      color: white;
      opacity: 0.8;
    }
  }

  .menu-info {
    background-color: #074679;
    max-height: 250px;
    overflow-y: auto;
    margin: 0;
    padding: 1rem;
    word-break: break-word;
    color: white;
    text-align: left;
    overflow-x: hidden;
  }
	.portal-link-description {
    color: white;
  }
  
  .portal-link-image {
    height: 100px;
    width: 162px;
  }
</style>
