// imports for creating Vectors layers and clusters
import VectorSource from 'ol/source/vector';
//import source Cluster
import SourceCluster from 'ol/source/cluster';
import wfsAxiosApi from '../../axios-wms-conf/axiosWFSConf'
import spatialApi from './spatialInfoLayers';
import mapApi from '../mapsInit';
// Features
import Feature from 'ol/feature';
// Point
import Point from 'ol/geom/point';
// Styling Point
import Style from 'ol/style/style';
import Icon from 'ol/style/icon';
import Circle from 'ol/style/circle';
import Stroke from 'ol/style/stroke';
import Fill from 'ol/style/fill';
import Text from 'ol/style/text';
// set the projections
import proj from 'ol/proj';
// spatial configuration
import spatialConfiguration from './spatial-config';

let Wrecks_API = {
  // addLocations_AHV: addLocations_AHV,
  addLocations: addLocations,
}

async function addLocations(map) {
  let wrecksVectorLayerSource = new VectorSource({});
  let wrecksInstance = new spatialApi.wrecksInitializer(map, wfsAxiosApi.wfsGetFeatureRequest);
  let wrecksInstance_AHV = new spatialApi.wrecksInitializer(map, wfsAxiosApi.wfsGetFeatureRequest_AHV);
  let spatielGroup = mapApi.getPlannerGroup(map, spatialConfiguration.groupName);
  let wrecksPoints = await wrecksInstance.getSourceVectorObjects();
  let wrecksPoints_ahv = await wrecksInstance_AHV.getSourceVectorObjects();
  // changed to read 2 sources, 22.10.20 MH
  await settingWrecksFeatures_all(wrecksPoints, wrecksPoints_ahv, wrecksVectorLayerSource);
  let clusterSource = new SourceCluster({
    distance: 50,
    source: wrecksVectorLayerSource
  });
  let wrecksVector = getWrecksVector(spatielGroup, spatialConfiguration.wrecksName);
  wrecksVector.setZIndex(2);
  wrecksVector.setSource(clusterSource);
  wrecksVector.setStyle(function (feature) {
    let styleCache = {};
    let featuresSize = feature.get('features').length;
    let style = styleCache[featuresSize];

    if (!style) {
      if (featuresSize > 1) {
        style = new Style({
          image: new Circle({
            radius: 15,
            stroke: new Stroke({
              color: '#fff'
            }),
            fill: new Fill({
              color: '#3399CC'
            })
          }),
          text: new Text({
            text: featuresSize.toString(),
            fill: new Fill({
              color: '#fff'
            })
          })
        })
      } else {
        style = new Style({
          image: new Icon({
            src: 'https://meriopas.ymparisto.fi/meriopas/static/shipsPoiWrecksPictures/wreck.png',
            size: [54, 72],
            anchor: [0.5, 1],
            scale: 0.5,
          })
        })
      }
      styleCache[featuresSize] = style
    }
    return style;
  });
}

async function settingWrecksFeatures_all(locations, locations_ahv, source) {
  let features = [];
  let xkoord = 0;
  let ykoord = 0;
  for (let i = 0; i < locations.length; ++i) {
    // should check this, in MV serverice coordinates are lat-long, should be long-lat
    // longitude is the X value and latitude is the Y value. 
    // in Filnad: lat ~60-70, long ~20-30
    // case 2.6.2022
    if (locations[i].geometry.coordinates[0] > locations[i].geometry.coordinates[1]) {
      xkoord = locations[i].geometry.coordinates[1];
      ykoord = locations[i].geometry.coordinates[0];
    }
    else {
      xkoord = locations[i].geometry.coordinates[0];
      ykoord = locations[i].geometry.coordinates[1];
    }
    features[i] = new Feature({
      geometry: new Point(proj.transform([xkoord, ykoord], 'EPSG:4326', 'EPSG:3857')),
      DestinationName: locations[i].properties.KOHDE_NIMI,
      Municipality: locations[i].properties.Kunta,
      SpeciesLaji: locations[i].properties.Laji,
      Subtype: locations[i].properties.Subtype, //tyyppi,
      CreationDate: locations[i].properties.luontipvm,
      Positioning: locations[i].properties.paikannustarkkuus,
      Url: locations[i].properties.url,
      type: 'Wrecks'
    });
    features[i].set('id', i);
  }
  //ahv
  //
  for (let i = 0; i < locations_ahv.length; ++i) {
    let xkoord = 0;
    let ykoord = 0;
    // case 2.6.2022
    if (locations_ahv[i].geometry.coordinates[0] > locations_ahv[i].geometry.coordinates[1]) {
      xkoord = locations_ahv[i].geometry.coordinates[1];
      ykoord = locations_ahv[i].geometry.coordinates[0];
    }
    else {
      xkoord = locations_ahv[i].geometry.coordinates[0];
      ykoord = locations_ahv[i].geometry.coordinates[1];
    }
    features[i] = new Feature({
      geometry: new Point(proj.transform([xkoord, ykoord], 'EPSG:4326', 'EPSG:3857')),
      //geometry: new Point(proj.transform([locations_ahv[i].geometry.coordinates[1], locations_ahv[i].geometry.coordinates[0]], 'EPSG:4326', 'EPSG:3857')),
      FornID: locations_ahv[i].properties.FornID,
      DestinationName: locations_ahv[i].properties.Text,
      Municipality: locations_ahv[i].properties.By_,
      //Lagrum: locations_ahv[i].properties.Lagrum,
      // CreationDate: locations_ahv[i].properties.luontipvm,
      // Positioning: locations_ahv[i].properties.paikannustarkkuus,
      // Url: locations_ahv[i].properties.url,
      type: 'Wrecks'
    });
    features[i].set('id', i);
  }
  source.clear();
  source.addFeatures(features);
}

function getWrecksVector(collection, name) {
  let vector;
  collection.forEach((layer) => {
    if (layer.get("name") === name) {
      vector = layer;
    }
  })
  return vector;
}

export default Wrecks_API;
