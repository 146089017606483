import Vector from 'ol/layer/vector';
import Style from 'ol/style/style';

// for styling vector layer
import Stroke from 'ol/style/stroke';
import Fill from 'ol/style/fill';
import Circle from 'ol/style/circle';

let toolsLayers = [

  new Vector({
    id: new Date().getTime(),
    zIndex:10,
    finnishName: 'Tools',
    name: 'Tools',
    visible: true,
    wrapX: false,
    style: new Style({
      fill: new Fill({
        color: 'rgba(61, 137, 199, 0.2)'
      }),
      stroke: new Stroke({
        color: '#3d89c7',
        width: 2
      }),
      image: new Circle({
        radius: 7,
        fill: new Fill({
          color: '#ffcc33'
        })
      })
    })
  })
];

export default toolsLayers;
