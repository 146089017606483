var moment = require('moment');

import WMSCapabilities from 'ol/format/wmscapabilities';
import wmsAxiosApi from '../../axios-wms-conf/axiosWmsConf';
import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import mapApi from '../mapsInit';
import satelliteLayersConfig from '../satellite-layers.configs/config';

let satelliteLayersApi = {
  parseSatelliteWMS: parseSatelliteWMS,
  addLayersNames: addLayersNames,
  getLastSatelliteLayersName: getLastSatelliteLayersName
};

function _builderSatelliteLayers(url, names) {
  return new Promise((resolve, reject) => {
    let satelliteLayers = [];
    names.forEach((name, index) => {
      let visibleID = index;
      let LayerConstructor = new TileLayer({
        id: index++,
        finnishName: name.layerName,
        name: name.layerName,
        type: 'satellite',
        visible: visibleID === 0 ? true : false,
        source: new TileWMS({
          url: url,
          params: {
            'LAYERS': name.layerOriginalName,
            'TIME': name.time ? name.time : '',
            'PREVIEW': name.preview ? name.preview : '',
            'PRIORITY': name.priority ? name.priority : '',
          },
          transparent: 'true',
          projection: `EPSG:${name.layerEPSG}`,
          crossOrigin: 'anonymous'
        }),
      });
      satelliteLayers.push(LayerConstructor);
    })
    if (satelliteLayers.length == satelliteLayersConfig.satelliteConfigs.layersPerGroup) {
      resolve(satelliteLayers)
    }
  })
}

function addLayersNames(map, item) {
  let satelliteUrl;
  let satelliteCompabilitiesURL;
  switch (item.name) {
    case 'trueColourPictures':
      satelliteUrl = satelliteLayersConfig.satelliteConfigs.layerRequestURL;
      satelliteCompabilitiesURL = satelliteLayersConfig.satelliteConfigs.WMScapabilitiesURL;
      break;
    case 'landsat':
      satelliteUrl = satelliteLayersConfig.landsatConfigs.layerRequestURL;
      satelliteCompabilitiesURL = satelliteLayersConfig.landsatConfigs.WMScapabilitiesURL;
      break;
    case 'sentinel2':
      satelliteUrl = satelliteLayersConfig.sentinel2Configs.layerRequestURL;
      satelliteCompabilitiesURL = satelliteLayersConfig.sentinel2Configs.WMScapabilitiesURL;
      break;
    case 'sentinel3':
      satelliteUrl = satelliteLayersConfig.sentinel3Configs.layerRequestURL;
      satelliteCompabilitiesURL = satelliteLayersConfig.sentinel3Configs.WMScapabilitiesURL;
      break;
    default:
      console.log('Unknown layer name');
      return;
  }
  return new Promise((resolve, reject) => {
    if (satelliteCompabilitiesURL) {
      parseSatelliteWMS({
          map: 'map',
          url: satelliteCompabilitiesURL
        })
        .then((wms) => {
          let layers = mapApi.getLastSatelliteLayersName(wms);
          let formatedNames = layers.map((layer) => {
            let nameDate = checkingDates(layer.Name);
            let newLayer = {
              layerName: moment(nameDate[0]).format('DD.MM.YYYY'),
              layerDesc: layer.Abstract,
              layerOriginalName: layer.Name,
              layerEPSG: layer.CRS[0]
            }
            return newLayer;
          })
          _builderSatelliteLayers(satelliteUrl, formatedNames)
            .then((buildedLayers) => {
              resolve(buildedLayers);
            })
        })
        .catch((error) => {
          reject(error);
        })
    } else { // getCapabilities document does not include layer details
      let formatedNames = [];
      let timespan = 3;
      let layerOriginalName = 'DEFAULT';
      let priority = 'leastCC';
      if (item.name === 'landsat') {
        timespan = 6;
      } else if (item.name === 'sentinel3') {
        timespan = 1;
        layerOriginalName = 'DEFAULT' // 'SEASONCHANGE' //'DEFAULT' // 'OLCIDEFAULT';
        priority = '';
      }
      // get last days
      for (let i = 1; i < 10; i++) {
        let date1;
        let date2;
        let time;
        let dateName;

	if (timespan == 1) {
          dateName = moment.utc().subtract(i * timespan, 'days').format('DD.MM');
          date1 = moment.utc().subtract(i * timespan, 'days').format('YYYY-MM-DD');
          date2 = moment.utc().subtract(i * timespan, 'days').format('YYYY-MM-DD');
	}
	else {
	  dateName = moment.utc().subtract(i * timespan, 'days').format('DD.MM') + ' - ' + moment.utc().subtract(i * timespan - (timespan-1), 'days').format('DD.MM');
          date1 = moment.utc().subtract(i * timespan, 'days').format('YYYY-MM-DD');
	  date2 = moment.utc().subtract(i * timespan - timespan, 'days').format('YYYY-MM-DD');  
	}
        time = date1 + '/' + date2 + '/P1D';
        formatedNames.push({
          layerName: dateName,
          layerDesc: item.name + ' ' + dateName,
          layerOriginalName: layerOriginalName,
          layerEPSG: '4326',
          time: time,
          preview: '2',
          priority: priority
        })
      }
      // Add one collection from july
      let year = moment.utc().subtract(1, 'years').format('YYYY');
      if (parseInt(moment.utc().format('M')) >= 9) {
        year = moment.utc().format('YYYY');
      }
      let time = moment.utc(year + '-07-15').format('YYYY-MM-DD') + '/' + moment.utc(year + '-07-15').format('YYYY-MM-DD') + '/P1D';
      let dateName = moment.utc(year + '-07-15').format('DD.MM');
      formatedNames.push({
        layerName: dateName,
        layerDesc: item.name + ' ' + dateName,
        layerOriginalName: layerOriginalName,
        layerEPSG: '4326',
        time: time,
        preview: '2',
        priority: priority
      })
      _builderSatelliteLayers(satelliteUrl, formatedNames)
        .then((buildedLayers) => {
          resolve(buildedLayers);
        })
    }
  })
}

// parse satellite source
function parseSatelliteWMS({ map, url }) {
  let parser = new WMSCapabilities();
  return new Promise((resolve, reject) => {
    let axios = wmsAxiosApi.wmsCompabilitiesRequest(url);
    axios
      .get()
      .then(
        (response) => {
          let result = parser.read(response.data);
          if (result) {
            resolve(result)
          }

        }
      )
      .catch(function (error) {
        reject(error);
      })
  })
}

// get 10 last pictures from satellite source
function getLastSatelliteLayersName(wmsInfo) {
  let layers = wmsInfo.Capability.Layer.Layer;
  let layerNamesContainer = []
  let endLayer = layers.length - satelliteLayersConfig.satelliteConfigs.layersPerGroup;
  for (let i = layers.length - 1; i > endLayer - 1; i--) {
    let layerObj = layers[i]
    layerNamesContainer.push(layerObj);
  }
  if (layerNamesContainer.length == satelliteLayersConfig.satelliteConfigs.layersPerGroup) {
    return layerNamesContainer;
  }
}


function checkingDates(name) {
  let timePairValues = [];
  let nameCollection = name.split("_");
  nameCollection.forEach((item) => {
    var pattern = /^[0-9]+T[0-9]+$/; //20180414T101019
    if (pattern.test(item)) {
      timePairValues.push(item)
    }
  })
  return timePairValues;
}


export default satelliteLayersApi;
