// AIS Shiptype number

let aisShiptypeFirstNumber = {
  1: "Reserved",
  2: "Wing In Ground",
  3: "Special Category",
  4: "High-Speed Craft",
  5: "Special Category",
  6: "Passenger",
  7: "Cargo",
  8: "Tanker",
  9: "Other",
};

function getAisShipType(doubleDigit) {
  let firstDigit = doubleDigit.toString()[0];
  return aisShiptypeFirstNumber[firstDigit];
}


let spatialUtils = {
  getAisShipType: getAisShipType
};


export default spatialUtils;
