<template>
  <div>
    <div>
      <div class="text-xs-center">
        <div>
          <v-btn class="control-menu-button" color="secondary" @click="switchDefaultMenu(defaultButton, defaultButton.name)"
            large flat icon>
            <icon-menu-base :icon-name="'infoTool' | translate" />
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import IconMenuBase from '../svg-system-components/IconMenuBase'
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex';
  export default {
    components: {
      IconMenuBase
    },
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        defaultButton: 'getDefaultButton',
        baseMenuVisibility: 'getshowBaseMapMenu'
      })
    },
    methods: {
      ...mapMutations(['changeBaseMapVisibility']),
      ...mapActions(['setMenuIcon']),
      switchDefaultMenu(button, name) {
        this.setMenuIcon(button);
        this.$router.push({
          name: "menu",
          params: {
            name: name
          }
        });
      }
    }
  }

</script>

<style scoped>
  .control-menu-button {
    margin: 0;
  }

</style>
