// open layers imports
import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import spatialConfiguration from './spatial-constructor/spatial-config';

// configs for retrieving layer
// url change 29.3.2021/MH and 2.2.2023
const Navigational_restrictionsURL = 'https://avoinapi.vaylapilvi.fi/vaylatiedot/ows'
//const Navigational_restrictionsURL = 'rajoitusalueet_a/vaylatiedot/ows'
// changed  22.10.20/MH
//'Vesiliikenteen_aluerajoitukset' -> 'AM.VesiliikenteenAluerajoitukset'
// 
const Navigational_restrictions_layers = [
  'vesivaylatiedot:rajoitusalueet_a'
];

let navigationalModels = [

  // layer for navigational restrictions (only 1)
  new TileLayer({
    id: 0,
    zIndex: 1,
    finnishName: spatialConfiguration.NAVIGATIIONAL_RESTRICTIONS_NAME,
    name: spatialConfiguration.NAVIGATIIONAL_RESTRICTIONS_NAME,
    dataType: 'JSON',
    visible: false,
    source: new TileWMS({
      url: Navigational_restrictionsURL,
      params: {
        'TILED': true,
        'LAYERS': Navigational_restrictions_layers,
        'buffer': 10
      },
      transparent: 'true',
      projection: "EPSG:3857",
      crossOrigin: 'anonymous'
    }),
    opacity: 0.4
  })
];

export default navigationalModels;
