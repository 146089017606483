import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import spatialConfiguration from './spatial-constructor/spatial-config';

const seal_URL = 'https://kartor.regeringen.ax/arcgis/services/Miljo/Skyddsomraden/MapServer/WMSServer?';
const seal_areas = [
  "Naturreservat"
];
let sealProtectedLayers = [
  new TileLayer({
    id: 0,
    zIndex: 1,
    dataType: 'GeoJSON',
    // minZoom: 10, no effects? 3.9.2020 MH
    finnishName: spatialConfiguration.SEAL_Protected_AreaS_NAME,
    name: spatialConfiguration.SEAL_Protected_AreaS_NAME,
    visible: false,
    source: new TileWMS({
      url: seal_URL,
      params: {
        'TILED': true,
        'LAYERS': seal_areas
      },
      transparent: 'true',
      projection: "EPSG:3857",
      crossOrigin: 'anonymous'
    }),
    opacity: 0.4,
  }),
];

export default sealProtectedLayers;
