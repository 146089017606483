<template>
  <div class="bottom-buttons-wrapper">
    <div>
      <v-btn color="secondary" @click="zoomToInitialState()" :title="buttonsConfigs.mapInitial | translate" medium flat
        icon>
        <icon-finland-base :height="27" />
      </v-btn>
    </div>
    <div>
      <v-btn color="secondary" @click="zoomToUserLocation()" :title="buttonsConfigs.userLocation | translate" medium
        flat icon>
        <icon-my-location-base :height='22.5' />
      </v-btn>
    </div>
    <div>
      <v-btn class="zoomIn" @click="zoomInMap()" color="secondary" :title="buttonsConfigs.zoomIn | translate" medium
        flat icon>
        <icon-plus-base :height='22.622' />
      </v-btn>
    </div>
    <div>
      <v-btn class="zoomOut" color="secondary" @click="zoomOutMap()" :title="buttonsConfigs.zoomOut | translate" medium
        flat icon>
        <icon-minus-base :height='22.622' />
      </v-btn>
    </div>
  </div>
</template>

<script>
  import IconPlusBase from '../svg-system-components/IconPlusBase';
  import IconMinusBase from '../svg-system-components/IconMinusBase';
  import IconMyLocationBase from '../svg-system-components/IconMyLocationBase';
  import IconFinlandBase from '../svg-system-components/IconFinlandBase';
  import mapApi from '../../maps-constructor/mapsInit';
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex';
  export default {
    components: {
      IconPlusBase,
      IconMinusBase,
      IconMyLocationBase,
      IconFinlandBase
    },
    props: ['map'],
    data() {
      return {
        mapInstance: this.map,
        buttonsConfigs: {
          mapInitial: "mapInitial",
          userLocation: "userLocation",
          zoomIn: "zoomIn",
          zoomOut: "zoomOut"
        }
      }
    },
    computed: {
      ...mapGetters({
        calendar: 'getCalendar',
        turbidityLayers: 'getWaterQualityTurbidityLayers',
        watersurfaceLayers: 'getWaterSurfaceTempLayers',
        getCenter: 'getCenterCoordinates',
        currentZoom: 'getCurrentZoom',
      })
    },
    methods: {
      zoomInMap() {
        mapApi.zoomIn(this.mapInstance);
        this.setRouteParams(400);
      },
      zoomOutMap() {
        mapApi.zoomOut(this.mapInstance);
        this.setRouteParams(400);
      },
      zoomToInitialState() {
        mapApi.returnToInitialZoomCenter(this.mapInstance);
        this.setRouteParams(1000);
      },
      zoomToUserLocation() {
        mapApi.zoomToUserLocation(this.mapInstance);
        this.setRouteParams(1000);
      },
      setRouteParams(timeout) {
        setTimeout(() => {
          let center = this.getCenter;
          let newCenterSplit = center.split(" ");
          let queryPosition = newCenterSplit[1] + "," + newCenterSplit[4];
          this.$router.push({
              name: 'MapsMain',
              params: {
                location: queryPosition,
                zoom: this.currentZoom
              }
            })
          }, timeout);
      }
    },
  }

</script>

<style scoped lang="scss">
  .bottom-buttons-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, minmax(1rem, auto));

    @media screen and (max-width: 768px) {
      grid-template-rows: repeat(2, minmax(1rem, auto));
    }
  }

  .zoomIn,
  .zoomOut {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

</style>
