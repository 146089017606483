<template>
  <svg
    xmlns               = "http://www.w3.org/2000/svg"
    xmlns:xlink         = "http://www.w3.org/1999/xlink"
    x                   = "0px"
    y                   = "0px"
    :width              = "width"
    :height             = "height"
    viewBox             = "0 0 23 22.5"
    :aria-labelledby    = "iconName"
    role                = "presentation"
  >
    <title
      :id     = "iconName"
      lang    = "en"
    >
			{{iconName}} icon
		</title>
  <g id="Group_1181" data-name="Group 1181" transform="translate(-286 -447.5)">
    <g id="Rectangle_311" data-name="Rectangle 311" class="cls-1" transform="translate(286 448)">
      <rect class="cls-2" width="23" height="22"/>
      <rect class="cls-3" x="0.5" y="0.5" width="22" height="21"/>
    </g>
    <line id="Line_224" data-name="Line 224" class="cls-1" x2="22" transform="translate(286.5 453.5)"/>
    <g id="Rectangle_312" data-name="Rectangle 312" class="cls-1" transform="translate(291 462)">
      <rect class="cls-2" width="4" height="4"/>
      <rect class="cls-3" x="0.5" y="0.5" width="3" height="3"/>
    </g>
    <g id="Rectangle_313" data-name="Rectangle 313" class="cls-1" transform="translate(291 457)">
      <rect class="cls-2" width="4" height="4"/>
      <rect class="cls-3" x="0.5" y="0.5" width="3" height="3"/>
    </g>
    <g id="Rectangle_314" data-name="Rectangle 314" class="cls-1" transform="translate(296 462)">
      <rect class="cls-2" width="4" height="4"/>
      <rect class="cls-3" x="0.5" y="0.5" width="3" height="3"/>
    </g>
    <g id="Rectangle_315" data-name="Rectangle 315" class="cls-1" transform="translate(296 457)">
      <rect class="cls-2" width="4" height="4"/>
      <rect class="cls-3" x="0.5" y="0.5" width="3" height="3"/>
    </g>
    <g id="Rectangle_316" data-name="Rectangle 316" class="cls-1" transform="translate(301 462)">
      <rect class="cls-2" width="4" height="4"/>
      <rect class="cls-3" x="0.5" y="0.5" width="3" height="3"/>
    </g>
    <g id="Rectangle_317" data-name="Rectangle 317" class="cls-1" transform="translate(301 457)">
      <rect class="cls-2" width="4" height="4"/>
      <rect class="cls-3" x="0.5" y="0.5" width="3" height="3"/>
    </g>
    <line id="Line_225" data-name="Line 225" class="cls-1" y2="4" transform="translate(292.5 447.5)"/>
    <line id="Line_226" data-name="Line 226" class="cls-1" y2="4" transform="translate(303.5 447.5)"/>
  </g>
	</svg>
</template>
<script>

  export default {
    props: {
      iconName: {
        type: String,
        default: 'box'
      },
      width: {
        type: [Number, String],
        default: 23
      },
      height: {
        type: [Number, String],
        default: 22.5
      },
      iconColor: {
        type: String,
        default: '#FFFFFF'
      }
    }
  }

</script>

<style scoped>

  svg {
    display           : inline-block;
    vertical-align    : middle;
  }
  svg > * {
    fill : white
  }

  .cls-1, .cls-3 {
    fill: none;
  }

  .cls-1 {
    stroke: #a31818;
  }

  .cls-2 {
    stroke: none;
  }

</style>
