let waterQualityConfigs = {
  turbidity: "turbidity",
  cdom: "CDOM",
  secchiDepth: "secchiDepth",
  type: 'WATERQUALITY',
  WMScapabilitiesURL  : "https://geoserver2.ymparisto.fi/geoserver/eo/wms?version=1.3.0&request=GetCapabilities&service=WMS",
  layerRequestURL     : "https://geoserver2.ymparisto.fi/geoserver/eo/wms?x",
  replacePartURL      : "https://geoserver2.ymparisto.fi/geoserver/eo/wms?x",
  layersPerGroup: 10
};

let waterSurfaceConfigs = {
  surfaceTemperature: "surfaceTemperature",
  algalBlooms: "algalBlooms",
  chlrophyll: "chlrophyll_a",
  type: "WATERSURFACE",
  WMScapabilitiesURL  : "https://geoserver2.ymparisto.fi/geoserver/eo/wms?version=1.3.0&request=GetCapabilities&service=WMS",
  layerRequestURL     : "https://geoserver2.ymparisto.fi/geoserver/eo/wms?x",
  replacePartURL      : "https://geoserver2.ymparisto.fi/geoserver/eo/wms?x",
  layersPerGroup: 10
}

let satelliteConfigs = {
  satellites: "trueColourPictures",
  type: "SATELLITES",
  WMScapabilitiesURL  : "https://geoserver2.ymparisto.fi/geoserver/eo_hr_rgb/wms?version=1.3.0&request=GetCapabilities&service=WMS",
  layerRequestURL     : "https://geoserver2.ymparisto.fi/geoserver/eo_hr_rgb/wms?x",
  layersPerGroup: 10
}

let landsatConfigs = {
  satellites: "landsat",
  type: "SATELLITES",
  WMScapabilitiesURL  : null,
  layerRequestURL     : "https://paikkatieto.ymparisto.fi/proxy/proxy.ashx?https://services-uswest2.sentinel-hub.com/ogc/wms?x",
  layersPerGroup: 10
}

let sentinel2Configs = {
  satellites: "sentinel2",
  type: "SATELLITES",
  WMScapabilitiesURL  : null,
  layerRequestURL     : "https://paikkatieto.ymparisto.fi/proxy/proxy.ashx?https://services.sentinel-hub.com/ogc/wms?x",
  layersPerGroup: 10
}

let sentinel3Configs = {
  satellites: "sentinel3",
  type: "SATELLITES",
  WMScapabilitiesURL  : null,
  layerRequestURL     : 'https://paikkatieto.ymparisto.fi/proxy/proxy.ashx?https://creodias.sentinel-hub.com/ogc/wms/?x',
  layersPerGroup: 10
}

export default {
  waterQualityConfigs,
  waterSurfaceConfigs,
  satelliteConfigs,
  landsatConfigs,
  sentinel2Configs,    
  sentinel3Configs
}
