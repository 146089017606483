<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 13.489 27.39"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName | translate}}
		</title>
<g id="finland" transform="translate(-0.283 -0.256)">
	<path id="FI-01" style="fill:#FFFFFF;" d="M1.928,27.371l0.021,0.02l-0.008,0.006l-0.014-0.008l-0.023,0.029l-0.015-0.016
		l0.007-0.022v-0.006L1.928,27.371z M1.834,27.362l-0.01,0.027l0.024,0.031l0.024,0.006v0.013H1.9v-0.013H1.882l0.006-0.009
		L1.876,27.41l0.007-0.017v-0.015l-0.009-0.005l0.006-0.007H1.874v-0.015H1.863l-0.006,0.013L1.834,27.362z M1.885,27.321v0.022
		l0.017,0.012l0.007,0.016h0.023l0.025,0.018l-0.009,0.01l0.013,0.009l0.017-0.006l-0.006-0.01l0.006-0.012H1.972v-0.017H1.943
		l-0.009-0.019l0.02,0.011h0.033v-0.021H1.979l-0.007-0.012l-0.014,0.011l-0.015-0.018l-0.019,0.006l-0.011-0.014H1.9l-0.013,0.014
		L1.885,27.321z M1.356,27.252H1.37l0.008-0.04h-0.01l-0.017-0.023H1.34v0.007l-0.017-0.009L1.31,27.193l0.026,0.038L1.356,27.252z
		 M1.238,27.186L1.23,27.198v0.008h0.022l-0.009,0.012H1.22L1.2,27.251h0l0.007,0.015l0.011,0.006v-0.011h0.009l0.009,0.012v-0.01
		l0.015-0.009v-0.012l0.012,0.01l0.009-0.024l0.013,0.007l-0.014,0.011l0.009,0.019v0.006l0.028,0.006v-0.016l0.031,0.007v-0.026
		L1.31,27.199l-0.013-0.005v0.013l-0.026-0.026l-0.027,0.01L1.238,27.186z M0.827,27.16h0.026v0.013l0.037,0.031l0.01-0.021H0.89
		v-0.016H0.863l-0.006-0.012l-0.033-0.013L0.827,27.16z M0.819,27.135l0.017,0.012h0.015v-0.04L0.819,27.135z M1.506,27.103
		l-0.023-0.017v0.008l-0.024,0.012v0.009l0.012,0.012l-0.042,0.048l0.01,0.03h0.012v0.008l0.043-0.007l-0.007-0.022l0.022-0.019
		l-0.008,0.019v0.013h0.026h0.009v-0.009h0.009v-0.03h0.01l-0.013-0.017H1.521v-0.016l-0.008-0.007l0.023-0.017l-0.022-0.01
		L1.506,27.103z M1.436,27.092l-0.008-0.008l-0.04,0.011v0.033L1.4,27.136l-0.018,0.031v0.01l0.01,0.011l-0.006,0.006H1.4
		l-0.005-0.008l0.027-0.02l-0.011-0.008l0.03-0.012l0.014-0.016l-0.008-0.022h0.016l-0.005-0.013H1.47v-0.01l-0.027,0.006v0.007
		L1.436,27.092z M1.336,27.097L1.32,27.133l-0.032,0.03v0.014h0.04v-0.011l0.009,0.005l0.016-0.007l0.018-0.04v-0.036l0.014-0.015
		l-0.011-0.006l-0.019,0.008L1.336,27.097z M1.236,27.074l0.015-0.01l0.006,0.008l0.006-0.019L1.25,27.027l-0.013,0.026
		L1.236,27.074z M1.383,27.021l-0.015,0.014l0.016,0.008l0.028-0.007V27.03h0.015v0.006l0.022-0.005l-0.033-0.022H1.38L1.383,27.021
		z M1.516,27.021l0.021,0.012l0.01-0.009L1.53,27.013l0.014-0.018L1.53,26.989l-0.016,0.019L1.516,27.021z M1.404,26.984h0.021
		v0.012l0.039,0.014h0.032v-0.016h0.01V26.98l-0.039,0.007v-0.01l-0.02-0.007l-0.009,0.018v-0.017H1.416L1.404,26.984L1.404,26.984z
		 M1.576,26.902v0.023l0.03,0.045l0.024,0.008l0.006-0.01L1.628,26.96l0.007-0.009l0,0l0.007-0.006l-0.011-0.014v-0.02l-0.009-0.015
		H1.6l0.007-0.01H1.591L1.576,26.902z M1.122,26.874v0.009H1.113l0.02,0.037H1.121l-0.01,0.011v-0.019l-0.006-0.009l0,0v0.027
		l0.014,0.026l-0.017,0.019v0.018l-0.01,0.015v0.01h0.023v0.018l0.012,0.011h0.015l-0.011,0.026l0.018,0.008l0.021-0.014l0.01-0.017
		l-0.011-0.023v-0.045l0.011,0.005l0.017-0.016l-0.016-0.012l0.009-0.066l-0.028-0.018V26.86l-0.015-0.007l0,0l0.012,0.018
		L1.148,26.9v-0.034l-0.02-0.022L1.122,26.874z M1.501,26.874l0.047-0.009v-0.023l-0.029,0.006l-0.017,0.021L1.501,26.874z
		 M1.241,26.846v0.015H1.235L1.226,26.87l0.011,0.01l0.029-0.018v-0.031L1.241,26.846z M1.341,26.818l-0.006,0.024l-0.016,0.006
		l-0.013,0.023H1.32l0.017-0.015h0.012l0.014-0.03l-0.011-0.019L1.341,26.818z M1.192,26.757l-0.018,0.017v0.017L1.148,26.81
		l0.009,0.025l0.022-0.014v-0.029l0.009-0.015l0.017,0.007l0.015-0.007l0.007-0.016l-0.011-0.016l0.007-0.024H1.2l-0.011,0.035
		L1.192,26.757z M1.608,26.737v0.017l-0.01,0.008l0.007,0.021l0.018-0.009l-0.008,0.017l0.008,0.011l0.017-0.014v0.007h0.014
		l0.006-0.016L1.645,26.73l-0.018-0.014v0.014l-0.02-0.023L1.608,26.737z M1.241,26.606h0.015v0.019l-0.008,0.008v0.018
		l-0.025,0.007l0.011,0.039L1.228,26.71l0.017,0.007l0.015-0.019h0.02l-0.016,0.016l0.015,0.012l-0.005,0.038l0.008,0.025
		l0.022-0.014l0.006-0.029L1.3,26.742l0.028-0.01l0.012-0.047l0.011-0.007v-0.014l-0.013-0.011L1.32,26.658v-0.025l-0.044-0.032
		H1.27l-0.026-0.02l-0.016,0.01l0.012,0.014L1.241,26.606z M1.734,26.575l-0.012,0.034l-0.01-0.018H1.705l0.011,0.023H1.707v0.013
		l-0.009-0.008l-0.009,0.01v0.02l0.036,0.029v0.026l0.031,0.017v-0.009l0.013,0.013l0.006-0.013l-0.014-0.021v-0.007l0.01,0.006
		l0.006-0.013l0.016-0.007l0.01,0.007L1.792,26.62H1.781l-0.013-0.023h-0.01v-0.009l-0.007-0.007l-0.016,0.006L1.734,26.575z
		 M1.418,26.575l0.016,0.03l0.011-0.011L1.43,26.555L1.418,26.575L1.418,26.575z M1.3,26.542H1.279l0.025,0.037l-0.008,0.025
		l0.023,0.011l0.006-0.009l-0.011-0.011l0.014-0.022l-0.013-0.014l0.007-0.016l-0.034-0.008L1.3,26.542z M1.362,26.525H1.349
		l-0.01,0.029l0.026-0.016l0.01,0.008l0.006-0.009l-0.009-0.015v-0.015L1.362,26.525z M1.969,26.492H1.955v0.009l0.007,0.01
		L1.938,26.52v0.009h0.017v0.012l0.022,0.02l0.008-0.011l-0.006-0.014h0.008v-0.05l-0.01-0.009L1.969,26.492z M1.88,26.458
		l0.027,0.073l0.014-0.011H1.915v-0.028L1.89,26.439l-0.013,0.023L1.88,26.458z M1.257,26.442H1.248v0.009l0.034,0.02l0.008-0.009
		v0.009l0.009-0.012v-0.026l-0.023-0.011L1.27,26.428v-0.011H1.249v0.021L1.257,26.442z M1.21,26.481l0.009-0.009l0.01,0.008
		l0.011-0.018l0.008-0.053h-0.01l-0.041,0.044L1.21,26.481z M2.045,26.407l0.01,0.042l0.037,0.026l0.006-0.009l-0.021-0.012
		l-0.005-0.013l0.011-0.005l-0.032-0.037L2.045,26.407z M1.722,26.415l-0.018,0.02v0.033l-0.011,0.008l0.006,0.047H1.7l0.023-0.036
		H1.74v-0.013l0.011-0.007v-0.024l0.01-0.013l-0.013-0.029H1.736l-0.022-0.012L1.722,26.415z M0.992,26.367l0.007,0.016H1.01
		l0.011-0.039h-0.01l-0.018,0.019L0.992,26.367z M0.963,26.351v0.007h0.009v0.008H0.98L1,26.348v-0.031H0.981L0.959,26.35
		L0.963,26.351z M2.024,26.291l0.008,0.036l0.009-0.018v0.029h0.01v-0.009h0.014v-0.038L2.05,26.285l-0.014-0.019L2.024,26.291z
		 M0.7,26.272l-0.02,0.032l-0.018-0.019l-0.021,0.049v0.023l0.021,0.028L0.634,26.38l-0.015,0.035l0.011-0.006l0.01,0.013v0.034
		l-0.012,0.009l0.028,0.026l0.011-0.037L0.65,26.442l0.011-0.023L0.646,26.41h0.017l0.014,0.018l0.015,0.069l0.019-0.007v0.007
		h0.017l0.018-0.034l0.025,0.026v0.017H0.752l-0.007,0.008l0.017,0.012l-0.021,0.022v-0.05H0.716l-0.017,0.019v0.006H0.71v0.02
		l0.009-0.018l0.009,0.025v0.012l-0.011,0.006l0.007,0.013l0.028-0.034v0.047L0.74,26.623H0.726l-0.01,0.009v0.029l-0.017,0.016
		H0.684l-0.012,0.016v-0.064l-0.024,0.014l0.007,0.049H0.647l-0.007,0.02l0.026,0.006v0.009l-0.015,0.008l0.006,0.047H0.638
		L0.625,26.67l-0.013-0.028v-0.013l0.014-0.045l0.017-0.026l0.006-0.034H0.66l-0.006-0.021L0.6,26.469l-0.014,0.008l0.013,0.027
		v0.012H0.59l0.01,0.033l-0.009,0.036H0.582l-0.009-0.012v-0.018H0.556l-0.018,0.023l-0.025-0.025H0.49v-0.012H0.482l-0.007,0.021
		l-0.009,0.005L0.485,26.6h0.008L0.5,26.64l-0.008,0.03l0.015,0.035l0.019,0.013v0.015h0.007l-0.008,0.042l0.018,0.053v0.027
		l0.018,0.035l-0.005,0.012l0.021,0.031l-0.006,0.011l0.006,0.021l0.024,0.032v0.013l0.018,0.009l0.017-0.006v-0.026l0.013-0.013
		l0.016,0.009l0.008,0.038l0.02-0.007l0.006,0.015l0.016-0.022l0.018,0.014l0.016-0.019l0.028,0.043l0.015-0.089l0.011,0.09
		l0.026,0.041l0.007-0.013v-0.039l-0.014-0.021v-0.039h0.025l0.013,0.021l0.007,0.061h-0.02v0.012l0.009,0.011l0.011-0.012
		l0.007,0.008h0.018l0.015,0.048l0.016,0.019l0.007-0.015l0.018,0.015v-0.016l0.017-0.008l0.03,0.053v0.05l0.008,0.014l0.025-0.012
		v0.022l0.023-0.008v-0.014H1.044l0.019-0.027l0.013,0.051L1.07,27.263l0.012,0.006l-0.017,0.008l0.006,0.027l0.009-0.016
		l0.02-0.013l-0.011-0.012h0.014v-0.018l0.029-0.021v-0.01l-0.01-0.011H1.113L1.1,27.175l0.007-0.009v-0.042l0.011-0.032H1.1
		l0.006-0.013H1.12v-0.037l-0.014-0.017v0.014l-0.017,0.009l-0.024-0.023H1.021L1,27H0.983l-0.014-0.019H0.963l-0.007,0.047
		l-0.01-0.02h-0.01v0.023L0.93,27.039H0.916l-0.025-0.044h0.027v-0.022l0.009-0.015l-0.014-0.02l-0.017,0.009v-0.055h0.012
		l0.005-0.006l0.007-0.032l-0.008-0.031h0.003l0.006-0.025l-0.01-0.008l-0.026,0.013l-0.007,0.022l0.008,0.021H0.878l-0.009-0.029
		l0.017-0.034H0.878l-0.006-0.024v-0.017l0.012-0.007l0.007-0.034l-0.007-0.014h0.021l0.014,0.025v0.008H0.917L0.9,26.742
		l0.016,0.017v0.012l0.016,0.011l0.007-0.012l0.019,0.034h0.015l0.03-0.032l0.006,0.024h0.014l0.032,0.03l0.014-0.009v-0.031h0.012
		V26.77h0.02l0.014-0.01h0.013l0.009-0.018l0.02,0.019l0.016-0.012l0.011-0.03H1.172l-0.015-0.01L1.14,26.724l-0.036-0.06
		l0.008-0.009l0.024,0.033v-0.013l0.015-0.006v-0.016l-0.007-0.038l0.01-0.011v-0.017l0.018-0.013l-0.009-0.006l-0.011,0.005
		l-0.014-0.019L1.1,26.552V26.53l-0.011,0.01v0.016h-0.01v0.01v-0.01l0.036-0.062l-0.009-0.015l-0.017,0.015v-0.038L1.071,26.45
		v0.014L1.043,26.44H1.034l0.017,0.046L1.015,26.46h0.011v-0.017l-0.008,0.014H1.006l-0.008-0.02h-0.01l-0.006-0.008l0.009-0.018
		l0.013,0.007V26.41v-0.011l-0.013-0.007l-0.032-0.005l-0.031,0.006L0.9,26.378l-0.014,0.018l-0.033-0.012L0.822,26.4l-0.02-0.017
		l0.026-0.055l-0.013-0.006l-0.021,0.017v-0.013l0.008-0.01v-0.023l-0.011,0.012v-0.029l-0.055-0.007H0.717L0.7,26.272z
		 M2.083,26.158L2.08,26.142H2.066v0.027l-0.011-0.017l-0.008,0.024l0.018,0.013l-0.009,0.006v0.012h0.007l0.02,0.013l0.013-0.031
		L2.09,26.173l0.013-0.011l-0.008-0.02L2.083,26.158z M2.018,26.098l0.007,0.021h0.016v0.01l0.019,0.013h0.026l-0.018-0.027
		L2.053,26.12l-0.009-0.006h0.014L2.03,26.096L2.018,26.098z M0.289,26.622v0.027l-0.014,0.03l0.005,0.008h0.016l0.007,0.007
		l0.036-0.01l0.009,0.011v-0.031L0.34,26.671l-0.011-0.024v-0.01h0.009l0.006-0.009H0.318l-0.011,0.024H0.288l0.01-0.016v-0.024
		H0.293L0.289,26.622z M0.427,26.522v0.027l-0.015,0.016l-0.01,0.039L0.381,26.64H0.369v-0.008L0.36,26.637l0.006,0.062
		l-0.009,0.013l0.006,0.034H0.341l-0.008,0.029l0.007,0.043l-0.008,0.007v0.015l0.017,0.015l0.011,0.036h0.006l0.013-0.008
		l0.018,0.015l0.033-0.016l-0.015-0.039l0.008-0.013l-0.006-0.015H0.43l0.005-0.016L0.414,26.79v-0.023l-0.011-0.035l0.005-0.012
		l0.022,0.066l0.018-0.008l-0.009,0.034l0.021,0.01v0.023l0.02,0.008l0.02-0.011l0.013-0.059v-0.067L0.488,26.71l-0.015-0.025h0.009
		V26.66l0.009-0.012l-0.014-0.021l-0.008,0.009H0.461v-0.014l-0.009-0.024h-0.01V26.54H0.435L0.427,26.522z M0.674,26.613L0.7,26.53
		H0.683v-0.014l-0.007-0.01l-0.028,0.047L0.674,26.613z M0.449,26.391L0.4,26.423l-0.007,0.035l0.015,0.008l0.006,0.021l0.015-0.065
		l0.017-0.016v-0.015L0.449,26.391z M0.572,26.399v0.03h0.007v-0.015l0.011-0.012l0.01,0.007l0.01-0.028H0.6L0.572,26.399z
		 M0.52,26.399l0.023,0.02v-0.015l-0.012-0.031L0.52,26.365v0.023V26.399z M0.55,26.354l-0.011,0.009v0.017h0.01v0.014l0.021-0.011
		v-0.01H0.564v-0.025H0.539L0.55,26.354z M0.591,26.292l0.032,0.074l0.03-0.083l-0.018,0.015v0.02l-0.01-0.016l-0.01,0.022
		L0.606,26.31l0.01-0.047h-0.02L0.591,26.292z M1.428,27.009l0.028,0.021l-0.008,0.007v0.012l0.029-0.008v0.008L1.5,27.042
		l-0.009-0.017l-0.05-0.018L1.428,27.009z M0.521,26.809l-0.013,0.005v0.015l0.008,0.027l0.015-0.034L0.521,26.809z M1.671,26.699
		v0.03h0.008l0.013-0.02v-0.016l0,0l-0.006-0.01v-0.032H1.674v0.038L1.671,26.699z M1.8,26.658l0.024,0.036l0.007-0.036
		l-0.012-0.031L1.8,26.658z M1.682,26.597l0.009-0.035l0.01,0.009l0.008-0.007l0.008,0.007v-0.022L1.7,26.542l-0.01,0.006
		L1.682,26.597z M2.016,26.534l0.032,0.036l0.008-0.014l-0.018-0.027L2.016,26.534z M0.542,26.494H0.537v0.023l0.008-0.011
		l0.013,0.008h0.007l-0.007-0.029L0.542,26.494z M1.311,26.464v0.012H1.32l0.019-0.021L1.304,26.44v0.027L1.311,26.464z
		 M0.511,26.458l0.016,0.032l0.01-0.021v-0.036L0.511,26.458z M0.604,26.415H0.588v0.009L0.6,26.442l0.006-0.006l0.016,0.012
		l0.009-0.023H0.62l-0.01-0.017v0.008L0.604,26.415z M0.819,26.375l0.015,0.009v-0.009l0.009,0.006v-0.012l-0.009-0.012H0.828
		l-0.011,0.018L0.819,26.375z M0.9,26.362h0.01v-0.01l0.022,0.013v-0.018l-0.01-0.008H0.91l-0.011,0.009L0.9,26.362z M0.84,26.341
		l0.014,0.029l0.011,0.005V26.35l-0.012-0.008l0.007-0.026L0.84,26.341z M1.717,26.306v0.027l0.028,0.035v-0.031l-0.013-0.01
		l0.011-0.014l-0.009-0.014l-0.015,0.007H1.717z M1.553,26.306l-0.015,0.015l0.024,0.016l0.011-0.014l-0.011-0.012v-0.022h-0.02
		L1.553,26.306z M1.953,26.28l0.023,0.035l0.007-0.025h0.008l-0.008-0.014V26.26H1.976l-0.013,0.006L1.953,26.28z M2.04,26.248
		l-0.019-0.006L2.015,26.25L2,26.242v0.008l0.007,0.013h0.017l0.006,0.011l0.027-0.022v-0.009L2.04,26.248z M0.921,26.242
		l-0.01,0.018l0.008,0.01v-0.013H0.93l0.009,0.027l0.005-0.048l-0.01,0.008v-0.011L0.921,26.242z M1.892,26.169h0.011v0.02
		l-0.008,0.007l0.008,0.023v0.013l0.01,0.009h0.016l0.007-0.045l-0.013-0.01v-0.014l-0.009,0.007l-0.018-0.024v0.013L1.892,26.169z
		 M2.031,26.043l0.025,0.027l0.037,0.005v-0.027l-0.009-0.009H2.076v-0.012h-0.03L2.031,26.043z M2.016,25.991l0.017,0.008v0.02
		l0.027-0.036H2.014v0.011L2.016,25.991z"/>
	<path id="FI-02" style="fill:#FFFFFF;" d="M11.854,23.414h-0.017l-0.029,0.035l-0.01,0.044l-0.062,0.078l-0.014,0.06l-0.062,0.062
		l-0.048,0.058v0.016l-0.041,0.043l-0.015,0.011l-0.039,0.008H11.45l0.006,0.071l-0.2,0.263l-0.063,0.128l-0.028,0.038v0.013
		l-0.049,0.032l-0.043,0.047l-0.185,0.134l-0.043,0.06l-0.048,0.02h-0.033l-0.014,0.018l-0.057,0.032l-0.016,0.063l-0.1,0.11
		l-0.035,0.148l-0.034,0.072l-0.044-0.014l-0.047,0.008l-0.037-0.009l-0.012,0.005v-0.007l-0.047,0.07L10.3,25.139l-0.14,0.153
		l-0.013-0.007l-0.015,0.039l-0.1,0.132l-0.053,0.012l-0.041,0.085l-0.159,0.163l0,0H9.736v-0.025L9.73,25.673l-0.057-0.026
		l-0.045-0.091l-0.013-0.064H9.57l-0.018-0.008L9.53,25.507l-0.044-0.005L9.461,25.44v-0.017l-0.04-0.015L9.345,25.4l-0.07,0.017
		H9.228l-0.059-0.051l-0.09-0.133l-0.077-0.032l-0.1-0.024l-0.088-0.108l-0.015-0.027l0.01-0.009l0.024,0.033l0.03,0.006
		l0.075-0.037l-0.005-0.014H8.917L8.91,25.007v-0.029l-0.014-0.041H8.882l-0.026,0.022L8.821,24.93l0.021-0.041l-0.014-0.046v-0.047
		l0.038-0.039l0.055-0.011H8.909v-0.012L8.89,24.717L8.874,24.73l-0.012-0.014l0.009-0.013v-0.021l0.033-0.047l-0.01-0.049l0,0
		l0.026-0.011h0.047l0.036-0.024l0.015,0.018L9.06,24.56l0.009-0.013v-0.036L9.1,24.495v-0.024l-0.028,0.006l-0.037-0.033
		l-0.008-0.016l0.007-0.019L9.06,24.39l-0.045-0.061l-0.019-0.063l-0.01,0.007l-0.008-0.009l-0.044-0.013l-0.018-0.023h0.045v-0.02
		l-0.055-0.016H8.877L8.86,24.203l-0.03-0.007v0.025l-0.066-0.033l-0.053-0.044H8.69L8.662,24.16l-0.04-0.006l0.007-0.023
		l0.042-0.023l0.024-0.045l0.029,0.013h0.03l0.02,0.026h0.017l0.017-0.009h0.016v-0.017h0.012v-0.032l0.013-0.019l0.112-0.007
		l0.02,0.013l0.022-0.01l0.069,0.013l0.073,0.036h0.039l0.059,0.033l0.035,0.007L9.3,24.132h0.11l0.088-0.033l0.114,0.044h0.083
		l0.145-0.011l0.218-0.057l0.015,0.007v-0.018h0.014l0.013-0.016l0.025-0.059h0.036v-0.036l0.092-0.06l-0.007-0.069l0.024-0.06
		h0.011l0.013,0.025l0.031-0.032l0.038,0.036v0.045l0.034,0.008l0.027-0.006l0.017,0.02l0.059-0.01v0.019l0.03-0.008l0.01-0.02
		l0.034-0.012h0.042l0.043,0.011v-0.038l0.022-0.019v-0.076h0.006l0.077,0.021l0.077-0.03l0.017,0.01h0.084l0.188-0.058h0.048
		l0.031-0.083l0.125-0.136l0.045-0.029l0.111-0.047l0.009-0.014l0.013-0.075l0,0l0.6-0.316l0.04-0.018l0.013,0.029l0,0l-0.079,0.078
		l-0.036,0.125l-0.19,0.2L11.854,23.414z"/>
	<path id="FI-03" style="fill:#FFFFFF;" d="M2.707,22.587l-0.022-0.048l0.025-0.047l-0.027-0.094l0.018-0.035V22.28l0.028,0.018
		l0.015-0.015l0.006,0.01l-0.006,0.01l0.015-0.011l0.011-0.055L2.728,22.19l0.047-0.061l0.065-0.013l-0.029-0.041L2.805,22.06
		v-0.005l-0.014-0.024l-0.032-0.016v-0.008l-0.011-0.005l-0.01,0.012v0.007H2.721v-0.039l0.014-0.1L2.72,21.86v-0.016l0.015-0.008
		V21.8l-0.043-0.031l-0.024,0.007v0.01H2.66v-0.013l-0.068,0.013l-0.016-0.037l-0.023-0.014l0.03-0.04l-0.005-0.1l-0.039-0.03
		l0.008-0.012l0.03,0.021l0.074-0.224v-0.039H2.633L2.627,21.2l0.037-0.016l0.026-0.028l0.008-0.034l0.017-0.013h0.018v-0.006
		l0.02-0.022v-0.005h0.009l0.023-0.021v-0.006L2.8,21.035l0.01-0.049l0.007,0.015l-0.009,0.024h0.01l0.046-0.043l-0.049-0.067
		l-0.02-0.084v-0.156l0.039-0.063l0.009-0.04l0.065,0.044l0.012,0.065h0.015V20.7h0.017l0.006,0.013l0.037,0.012v0.012l0.048,0.019
		v-0.007l0.014,0.006v0.012l0.1,0.046l0.007-0.01l0.057,0.019v-0.015l0.084,0.037l0.084-0.1l0.032-0.011l0.056-0.112l0.017-0.016
		v-0.022h0.019l0.019-0.018l-0.007-0.048L3.511,20.49v-0.038h0.024v-0.025l0.023-0.049l-0.013-0.02l0.023-0.018l0,0l0.031-0.042
		l0.008-0.019v-0.043l0.011-0.02l0.038-0.064v0.006l0.01-0.017H3.658l0.007-0.013l0.011,0.007l0.016-0.019l0.006,0.016l0.007-0.008
		l-0.028-0.037l0.072-0.048l0.024,0.031v-0.006l0.015-0.006v-0.015l-0.018-0.01H3.747l0.022-0.006v-0.009L3.8,20.011l-0.005-0.014
		l0.018-0.01v-0.046H3.8v-0.01h0.03l0.011,0.014h0.012V19.78l-0.06,0.03l-0.01-0.02l-0.04,0.018l0.022-0.051l0.041-0.023
		l-0.023-0.045H3.772l0.014-0.02l-0.063-0.04l0.006-0.031l0.04-0.043l0.013,0.011l0.074-0.046l-0.028-0.049l0.077-0.049l0.009,0.012
		l0.033-0.023l-0.013-0.032l-0.023,0.012v-0.016l0.019-0.007l0.017,0.007h0.011l0.014,0.006v0.014h0.015l-0.006-0.007h0.016v-0.007
		h0.006l0.017,0.008v0.007h0.009l-0.006,0.01h0.052l-0.016-0.015v-0.009l0.012,0.006l-0.009-0.019v-0.006l0.031,0.012v0.007H4.078
		v0.006h0.017l0.093,0.051l-0.009-0.094h0.032v-0.018H4.24l0.012,0.044l0.053-0.012l0.033,0.033L4.33,19.398l0.008,0.006l0.1-0.125
		l0.039-0.111v-0.012l0.012-0.008l0.016-0.046l0.143-0.071l0.03,0.039l0.01-0.006l0.034,0.021h0.013l0.042,0.021l0.013,0.015
		l0.013-0.015h0.013l0.025,0.049v0.01H4.832l0.019,0.028h0.007l0.021,0.024l-0.005,0.009l0.037,0.048l0.078,0.049l0,0l0.078,0.165
		l0.1,0.089h0.109l0.071,0.02l0.088,0.057l0.079,0.077l0.076,0.105l-0.025,0.022v-0.021l-0.022,0.015v0.013l-0.007-0.007H5.527
		v0.014l-0.014,0.019l0.042-0.017l0.064,0.077v-0.022l0.057-0.019l0.051,0.054l0,0v0.012L5.684,20.09L5.64,20.097v0.034
		l-0.009,0.006v0.037l-0.029,0.014v-0.014l-0.033,0.009l0.007,0.019l0.026-0.007v0.01l0.051-0.039l0.013,0.043l-0.012,0.005
		l0.007,0.036L5.65,20.258l0.02,0.053l0.027-0.014l0.016,0.047v0.112H5.77l0.044,0.02l0.014,0.025v0.065l-0.093,0.022l-0.008-0.031
		l-0.022,0.067l0.029-0.012l0.038,0.059l-0.019,0.02H5.77l0.03,0.041h0.009v0.014l0.064,0.137l0.038,0.128L5.98,21.06l-0.079,0.075
		l-0.008,0.033l0.042,0.013l0.039-0.013l0.007,0.022l-0.032,0.011l0.013,0.064l-0.033,0.008l0.01,0.04l-0.021,0.008l0.013,0.053
		H5.925L5.918,21.36H5.911l0.011,0.031l-0.014,0.006l-0.011-0.01l-0.006,0.027l0.012,0.034l-0.014,0.018l-0.036-0.011l-0.02-0.022
		l0.012,0.034l0.014-0.007l0.011,0.009l-0.032,0.007l-0.006,0.022l-0.029,0.026l-0.02-0.053H5.771l0.006-0.016l-0.023,0.018
		l-0.009-0.019l-0.04,0.022H5.696l0.006,0.02l-0.013,0.009l0.023,0.042l-0.026,0.039l-0.134-0.082l-0.115,0.161v0.016L5.366,21.75
		L5.34,21.789l0,0l-0.023-0.034L5.24,21.724l-0.014-0.035v-0.044L5.215,21.62l0.01-0.055L5.211,21.5H5.178v0.06h-0.03l-0.007-0.083
		l-0.033,0.039l0.013,0.027v0.03H5.114l-0.019-0.025l-0.011,0.018l-0.03,0.011l-0.1,0.1l-0.179-0.011l0.016,0.11l-0.048-0.035
		l-0.032,0.016L4.685,21.75l-0.038-0.026v-0.018l-0.013-0.011l-0.011,0.012l-0.009-0.006l0.013-0.013l-0.02-0.029l-0.011,0.01
		l-0.012-0.01v-0.032l-0.067,0.039v0.008l0,0v-0.006l-0.023,0.069l0.009,0.017h0.006v0.026l-0.015,0.007H4.479l-0.008,0.015
		l-0.013-0.007l0.008,0.024H4.46l-0.013,0.033l-0.346,0.149H4.026l0,0H3.935l-0.176-0.089l-0.076,0.237l-0.021-0.006l-0.065,0.035
		l-0.134,0.223l-0.142,0.007l-0.1,0.166l-0.15,0.122l-0.3-0.1L2.707,22.587z"/>
	<path id="FI-04" style="fill:#FFFFFF;" d="M10.498,21.076l-0.014,0.071l-0.039,0.026l0.03,0.011v0.014l-0.023,0.029l-0.028,0.019
		l-0.02-0.039l-0.027,0.029l-0.063-0.009l-0.008,0.006v0.006l0.026,0.015h0.017l0.037,0.022l-0.026,0.048l-0.051-0.043L10.3,21.287
		l-0.023-0.016l-0.012,0.005l-0.037-0.014l0.012,0.017l-0.016,0.029v0.034l0.011,0.018h-0.008l-0.016,0.056h-0.013l-0.008,0.017
		l0.027,0.043l-0.01,0.056h-0.028l-0.036-0.028l-0.022,0.005l-0.017-0.013l-0.015,0.029l0.024,0.024v0.027H10.1l-0.038,0.026
		l-0.007,0.02l0.042,0.025v0.049l0.042,0.024l0.009,0.013l-0.012,0.022l0,0v0.023l0,0l0.062,0.008l0.016,0.053l-0.005,0.069
		l0.021,0.01l0.1-0.058l0.17,0.212v0.053l-0.016,0.032l-0.037,0.042h-0.069L10.23,22.13l-0.063-0.01l-0.074-0.042h-0.026
		l-0.069,0.021H9.871l-0.127-0.088v0.014l-0.052-0.062l-0.028-0.019v0.027l-0.041-0.017l-0.018,0.021H9.593l-0.018-0.039
		l-0.032-0.04L9.49,21.867l-0.005-0.041l0.039-0.045l-0.01-0.016l-0.075-0.018L9.43,21.714l-0.034-0.026L9.37,21.641l-0.067-0.073
		l-0.116-0.053l-0.008-0.029h-0.01l-0.036-0.029l-0.009-0.02H9.1V21.41l-0.023-0.032l-0.019,0.018l0.027,0.017l-0.006,0.019H9.062
		l0.01,0.027H9.049L9.01,21.486l-0.019,0.056l-0.01-0.007H8.972L8.954,21.51v-0.013H8.928l-0.01,0.04H8.905v-0.011l0.015-0.019
		l-0.008-0.009L8.89,21.517v0.033l-0.029-0.007l0.007-0.014l-0.011-0.041l-0.056-0.059l0.005,0.018l-0.014,0.008v0.025l-0.025-0.007
		l-0.022,0.04H8.673l-0.028-0.046H8.639l-0.02,0.026l0.025,0.046l-0.018,0.037l-0.021-0.034l-0.031-0.019L8.55,21.496l-0.008-0.023
		l-0.009,0.014H8.518l-0.014,0.02H8.49l-0.018,0.03l0.018,0.032l-0.078,0.044l-0.06-0.07l0,0L8.34,21.559l0.007,0.046L8.319,21.66
		l0.1,0.117l-0.118,0.031v0.029l-0.009,0.009l0.02,0.044H8.299v0.008l-0.066,0.019l0.025,0.029l-0.007,0.038l0.016,0.062l0.021,0.02
		L8.28,22.104l-0.024,0.011L8.24,22.103l-0.009-0.037L8.22,22.075l-0.008-0.008l-0.052-0.08l-0.025,0.024l-0.01-0.006v0.02
		l-0.041,0.006l0.019,0.042L8.074,22.1l-0.011-0.007l-0.012,0.025H8.039v-0.024v-0.012H8.032L8.016,22.1v0.025H7.963v0.008
		l-0.04,0.026l-0.017-0.017l-0.1,0.193l0.214,0.346l-0.08,0.047l-0.01,0.047L7.938,22.8v0.054l-0.019,0.05l0.03-0.014l0.029,0.005
		l-0.006,0.03l0.018,0.04l-0.011,0.016l0.005,0.025l-0.009,0.019v0.034l-0.013,0.015L8,23.084l0.028,0.018l0.041,0.063l0.04,0.089
		l0.012,0.009l0.025,0.081l-0.01,0.065H8.128h0.006l-0.007,0.045l0.007-0.009v0.007l-0.009,0.042l-0.077-0.031l-0.035-0.029
		l-0.045-0.015v0.019l-0.01,0.009v0.013l-0.023,0.013v0.033l-0.017,0.018H7.909v0.013H7.898v0.082l-0.016-0.013H7.835h0.013
		l0.014,0.025v0.036l0.006,0.018H7.88L7.872,23.73l-0.026,0.007l0.009,0.035h0.038v-0.041h0.008l0.007,0.034h0.017l0.008,0.016
		H7.839v0.014l0.024,0.041l0.009,0.035l-0.056,0.047l0.024,0.048l0.061,0.065L7.862,24.09v0.013l0.035-0.007v0.03l0.075,0.076
		l0.018,0.007l0,0l0.009-0.008l0.064,0.006l0,0h0.015l0.016,0.034l0.007,0.063l0.009,0.019l0,0l0.036-0.012l0.02,0.015l0.078-0.047
		l0.043,0.11l-0.044,0.052l0.035,0.033l0.093-0.054l0.017-0.024l0.016-0.063l0.083-0.035h0.036l0.018,0.089v0.009l0.033,0.038
		l0.011,0.036l-0.017,0.063l0.138,0.074l0.073-0.009l0.025-0.02l0.022,0.01h0.067l0,0l0.026-0.011h0.047l0.036-0.024l0.015,0.018
		l0.042-0.009l0.009-0.013v-0.036l0.031-0.016v-0.024l-0.028,0.006l-0.037-0.033L9.026,24.43l0.007-0.019l0.026-0.019l-0.045-0.061
		l-0.019-0.063l-0.01,0.007l-0.008-0.009l-0.044-0.013L8.915,24.23H8.96v-0.021l-0.055-0.016H8.876l-0.017,0.011l-0.03-0.007v0.025
		l-0.066-0.033L8.71,24.145H8.686l-0.028,0.016l-0.04-0.006l0.007-0.023l0.042-0.023l0.024-0.045l0.029,0.013h0.03l0.02,0.026h0.017
		l0.017-0.009H8.82v-0.017h0.012v-0.032l0.013-0.019l0.112-0.007l0.02,0.013l0.022-0.01l0.069,0.013l0.073,0.036H9.18l0.059,0.033
		l0.035,0.007l0.022,0.022h0.11L9.494,24.1l0.114,0.044h0.083l0.145-0.011l0.218-0.057l0.015,0.007v-0.018h0.014l0.013-0.016
		l0.025-0.059h0.036v-0.036l0.092-0.06l-0.007-0.069l0.024-0.06h0.011l0.013,0.025l0.031-0.032l0.038,0.036v0.045l0.034,0.008
		l0.027-0.006l0.017,0.02l0.059-0.01v0.019l0.03-0.008l0.01-0.02l0.034-0.012h0.042l0.043,0.011v-0.038l0.022-0.019v-0.076h0.006
		l0.077,0.021l0.077-0.03l0.017,0.01h0.084l0.188-0.059h0.048l0.031-0.083l0.125-0.136l0.045-0.029l0.111-0.047l0.009-0.014
		l0.013-0.075l0,0l0.013-0.034l0.047-0.046l0.028-0.014l0.042-0.073l0.015-0.071v-0.035l-0.024-0.039l0.032-0.024l-0.089-0.116
		v-0.053l0.049-0.1l-0.1-0.112v-0.015l-0.07-0.021l-0.054-0.1l-0.057-0.282l0.024-0.086l-0.042-0.039l-0.09-0.188l-0.018-0.136
		l-0.03-0.048l-0.042-0.024l-0.035,0.038l-0.026-0.016l-0.017,0.024l0.007,0.028l-0.047-0.007l-0.014-0.009l-0.018-0.024l0.015-0.02
		v-0.007l-0.028-0.027l0.026-0.131l0.014-0.022v-0.006l-0.008-0.007h-0.029l-0.047-0.038l-0.038-0.01V21.37l0.037-0.009
		l-0.006-0.028l-0.027-0.012l-0.073-0.072v-0.028l-0.09-0.056l-0.081-0.1l-0.127-0.021l-0.024-0.02L10.498,21.076z"/>
	<path id="FI-05" style="fill:#FFFFFF;" d="M8.52,17.642l-0.1-0.054l-0.013-0.525l-0.061-0.01l-0.028-0.021v-0.09l-0.01-0.013
		l-0.141-0.013l-0.1-0.087v-0.027l0.022-0.026l-0.017-0.02h0.013l-0.008-0.021h0.014L8.086,16.71l0.006-0.006h-0.01l-0.013,0.012
		l-0.038-0.083l-0.149-0.13l0.04-0.049l-0.011-0.019l0.029-0.006l-0.007-0.023l0.022,0.011l0.114-0.135l0.1,0.016l-0.017-0.043
		l0.026-0.012l0.009,0.018l0.016,0.007v-0.049l0.027-0.06h0.009l0.049-0.039v-0.034l-0.02-0.018l0.02-0.019l0.011,0.015h0.012
		l0.014-0.012v0.005v0.01L8.303,16.08l0.028,0.035l0.01-0.008l-0.017-0.012l0.027-0.015v-0.01l0.026-0.012l0.009,0.015l0.111-0.046
		l0.023-0.072l0.03,0.012L8.541,15.95l0.006-0.014h0.011l0.008-0.009v-0.014h-0.01v-0.038h0.011l0.005-0.02l-0.017-0.017
		l0.019-0.063l0.264-0.2l0.042,0.009l0.021,0.018l0.012-0.007l0.157,0.042l0.025-0.049H9.076l-0.009-0.029l0.035-0.009l0.01-0.024
		l-0.036-0.092l0.038-0.1v-0.022l-0.023-0.016l0.017-0.013l-0.009-0.032l-0.056-0.075H9.028l-0.005-0.021l-0.061-0.082l0.074-0.167
		l0.138,0.01l0.131-0.018l0.038-0.026v-0.009h0.014l0.058-0.038l0.04,0.013h0.013l0.069,0.03l0.017-0.029v0.032l0.118,0.038
		l0.046-0.208h0.011L9.72,14.693v-0.01l0.028,0.018v-0.007l-0.022-0.008l0.021-0.019v-0.01l-0.011,0.014h-0.01l0.009-0.065
		l0.1-0.091l0.257-0.057l0.015,0.016l0.01-0.02l0.172-0.033v-0.007h0.026l0.014-0.026l0.013,0.005l-0.017,0.019h0.007v0.019h0.018
		l-0.014-0.008v-0.016l0.112-0.021l0.008-0.007l0.015-0.055l-0.007-0.012l0.027-0.111l0.03-0.048l-0.009-0.047l0.175-0.077
		l-0.03-0.007v-0.007h0.017v-0.014h0.012l0.006,0.023l0.164-0.073l0.1,0.027l0.085-0.031h0.029h-0.027l0.031-0.032l0.032,0.008
		l-0.012-0.013h-0.016l0.033-0.035l0.009,0.009v-0.023l0.029-0.029l-0.005-0.067l0.117-0.007l0.2,0.086l0.025-0.009v-0.018
		l0.031-0.018l0.178,0.033l0.048-0.01l0,0l-0.007,0.041l-0.017,0.025v0.123v0.143l0.016,0.069l-0.11,0.153l-0.042,0.038l-0.008,0.04
		l0.037,0.078h0.072l0.049,0.013l0.025-0.008l0.092,0.03l0.041,0.03l0.008,0.035l-0.081,0.131l0.034,0.04l0.015-0.012l-0.011,0.017
		l0.047,0.057h-0.025l-0.047,0.031h-0.108l-0.12,0.083l-0.013,0.125l-0.017,0.047l0.021,0.094l-0.009,0.086l0.033,0.134l0.007,0.025
		l0.059,0.074l-0.013,0.048l0.056,0.075h0.144l0.024-0.008h0.083h0.033l0.016,0.008h0.038l0.048,0.049l0.005,0.024l-0.054,0.064
		l0.015,0.074l0.091,0.165v0.033l-0.015,0.027l-0.069,0.027l-0.072,0.073l0.009,0.029l-0.012,0.109l0.03,0.076l0.016,0.01v0.046
		l0.024,0.029l0.013,0.053l-0.008,0.023l0.007,0.023l-0.013,0.08l0.071,0.095l0.033,0.019l0.082,0.019l0.08,0.052l0.021,0.026
		l0.071,0.052l-0.006,0.048l0.03,0.03l0.106,0.019l0.03,0.1l-0.048,0.046l0.054,0.123l0.043,0.061v0.077l-0.029,0.135l-0.088,0.164
		l-0.1,0.125l-0.041,0.063l-0.069,0.222l-0.127,0.055L12,18.34l0,0l-0.11,0.021l-0.109,0.113l-0.06-0.109l-0.047-0.331l-0.184,0.023
		l-0.261,0.048l-0.291,0.017l-0.15,0.02l-0.414-0.114l-0.065,0.027l0.018,0.033l-0.126,0.255l0,0l-0.1,0.216l-0.02-0.015
		l-0.051-0.079l-0.046-0.02l-0.028-0.026l-0.093-0.141L9.7,18.104H9.676l-0.119-0.1l-0.045-0.05l-0.089-0.038l-0.018,0.009
		l-0.122-0.1l-0.066-0.01l0.017,0.027l-0.053,0.013L9.16,17.846l-0.007-0.019H9.144l0.008-0.018l-0.007-0.007l-0.178-0.061
		l-0.138-0.018l-0.014,0.015l-0.008-0.007L8.7,17.719L8.52,17.642z"/>
	<path id="FI-06" style="fill:#FFFFFF;" d="M6.34,24.22l0.014,0.1h0.007l0.022-0.018l0.023,0.035l0.02-0.008l0.028-0.027l0.018,0.1
		l0.086-0.019l0.084,0.033h0.022v0.041l0.029,0.026l0.012,0.027l0.021,0.009l0.006,0.021l-0.01,0.024l0.021,0.045l-0.036,0.036
		l0.007,0.019l0.016,0.01l-0.047,0.054l-0.02,0.066L6.63,24.8l-0.019,0.024l-0.1,0.031l-0.035,0.044l0.014,0.025h0.008v0.02
		l-0.02,0.02v0.014h0.031v0.017l0.01,0.006l-0.023,0.046L6.48,25.062l-0.018,0.074h0.043l0.052,0.021l0.017,0.123H6.553
		l-0.014,0.054l0.014,0.039h0.016v0.017l0,0v0.014l0.055,0.068v0.022l-0.069,0.014l-0.006,0.026l-0.03,0.058l0.008,0.025
		l0.059-0.072l0.03,0.034l0.052,0.017l-0.053,0.019l-0.066,0.04l0.028,0.084l-0.055-0.016l-0.009-0.022l-0.021-0.014l-0.111,0.025
		v0.01H6.367v0.011H6.341v0.01H6.274v-0.016l-0.025-0.011l-0.015,0.037H6.215l-0.014-0.011l-0.03,0.026H6.117l-0.039-0.024h-0.02
		l-0.027-0.019l-0.053,0.069v0.08l0.014,0.035l-0.031,0.059l-0.137,0.016l-0.028-0.058l0.012-0.032l-0.029-0.025L5.74,25.851
		l-0.061,0.062l0.011,0.031l-0.021,0.01l-0.056-0.076v-0.042l-0.067-0.023l-0.047,0.01L5.467,25.81H5.445l-0.049-0.036l-0.08,0.014
		l-0.042-0.02l-0.015-0.016l-0.022,0.009l0,0l-0.03-0.01H5.195l-0.054-0.008l-0.074,0.008l-0.035-0.025l-0.035,0.013l-0.011-0.03
		l0.007-0.028l-0.064-0.025H4.874l-0.036-0.019l-0.015-0.033l-0.031-0.021v-0.029l-0.017-0.007h-0.05l-0.12,0.067l-0.059-0.018
		L4.54,25.625L4.507,25.58l-0.083-0.072l-0.017-0.022l0.049-0.1l0.032-0.019l-0.009-0.009l-0.088-0.022v-0.1l0.022-0.018l0.12,0.008
		L4.526,25.17l-0.008-0.013l0.04,0.006v-0.02l0.014-0.025l-0.011-0.012l0.005-0.028l-0.045-0.022l-0.06-0.085l0,0l0.019-0.014h0.055
		v-0.016l-0.009-0.014l0,0l0.081,0.022l0.031-0.008l0.023,0.033l0.012,0.042l0.017,0.027h0.008l0.008-0.01h0.022l0.032,0.019v-0.006
		l-0.015-0.01v-0.014H4.75l-0.01-0.009h0.014l0.01-0.016l0.033-0.009L4.829,25l0.012-0.023l0.022,0.008v-0.011l0.021,0.012
		l-0.015,0.01l0.013,0.015l0.025-0.017l0.012-0.025h0.012h0.064l0.03-0.012l0.026,0.019l0,0v0.022h0.021l0.012-0.01l0.031,0.018
		l0.051,0.111l0.028-0.034L5.18,25.026l-0.039-0.048l0.019-0.005v-0.021l0.012,0.007l0.036-0.031l-0.015-0.033l-0.08,0.026
		l0.011-0.024l-0.008-0.016l0.006-0.017l-0.049-0.017l0.051-0.049l0.022-0.007l0.025,0.017l0.051-0.02l0.145-0.081l0.011-0.013
		h0.016l0.019,0.011v-0.008l0.048-0.011l0.038,0.011v-0.006l-0.013-0.025l0.007-0.017h0.05l0.054-0.014l0.049-0.063V24.56h0.009
		l0.017-0.02l-0.037-0.039l-0.011-0.029l0.02-0.019l0,0l0.01-0.02l0.029,0.015l0.089-0.036l0.012,0.006L5.79,24.4h0.018l0.01-0.023
		l0.02-0.015l0.012,0.005l0.039-0.036l0.088-0.049l0.019,0.034h0.008l0.071,0.03h0.029l0.022,0.018l0.011-0.019l0.017-0.087
		l0.041,0.112l0.043-0.009l0.088-0.033l-0.01-0.024L6.34,24.22z"/>
	<path id="FI-07" style="fill:#FFFFFF;" d="M4.876,17.256l0.006-0.017l0.017,0.006v-0.013l0.022-0.01v-0.009h0.016v0.019h0.008
		l0.028,0.063l-0.018-0.01l-0.013-0.029l-0.008,0.012v-0.014H4.926l-0.005,0.019H4.898v0.009L4.876,17.256z M3.994,17.926
		l-0.015,0.012v0.015H3.99v0.01l0.005-0.011h0.012V17.93L3.994,17.926z M4.188,18.195l-0.02-0.02h-0.01v0.007L4.15,18.17h0.015
		l0.014,0.005v-0.007l-0.015-0.011h0.009l-0.009-0.021l0.013-0.011l-0.024-0.037l0.029,0.009l-0.011-0.031l-0.014,0.009v-0.017
		H4.145v-0.036H4.121v-0.01l-0.027-0.006l-0.047,0.009l-0.01-0.028H4.024H4.016v0.009H4.008l0.016,0.015l-0.014,0.01l-0.009-0.007
		v0.009h0.017v0.038l0.053,0.023l0.018-0.008L4.104,18.1v0.005H4.078v0.012l0.017,0.022v0.009H4.066v-0.013h0.011L4.069,18.12H4.06
		l0.007,0.032v0.006l0,0l0.017,0.04l-0.009,0.024l0,0l0.006-0.011v0.031l0.045,0.05l0,0l0.011,0.02v-0.023l0.033-0.012v0.008
		l0.027-0.009v-0.014l0,0l0.006-0.047l-0.007-0.026L4.188,18.195z M4.981,17.311v0.008H4.962v0.021v0.009l0,0l-0.007,0.023H4.946
		v0.02l-0.024-0.024v0.008l0.018,0.017l-0.006,0.018H4.927l0.01,0.023H4.919l-0.025-0.028H4.879v0.015v-0.005v0.009l0.019,0.039
		L4.89,17.493H4.881l0.02,0.018l-0.007-0.018v-0.008l0.034,0.052L4.92,17.573L4.911,17.58v0.032l-0.02,0.006v-0.013l-0.014-0.021
		H4.869l0.013,0.013v0.009H4.861l-0.006,0.009H4.834L4.824,17.6l-0.036-0.009V17.57L4.78,17.587H4.753L4.73,17.546L4.716,17.54
		v-0.021H4.69l-0.011,0.009L4.67,17.52l0.007,0.03l-0.02,0.017l-0.006,0.029l0.013,0.109v0.016L4.65,17.726l0.016,0.006v0.021H4.644
		l0.026,0.026L4.659,17.8l-0.028,0.007l0.037,0.035l0.013,0.037v0.007H4.673l-0.015,0.017l-0.006-0.007l-0.011,0.017l0.02,0.016
		l-0.01,0.011l-0.017-0.013l0.011,0.025l-0.014-0.006l-0.006,0.007l-0.013-0.031L4.596,17.91l-0.009-0.018L4.58,17.897l-0.007-0.011
		l-0.006,0.037l0.024,0.014L4.556,17.95L4.55,17.963l-0.023-0.024L4.499,17.95l0.008,0.017l-0.014-0.012l-0.012,0.006l-0.02-0.018
		l0.014,0.019H4.46v0.027l-0.025-0.034h-0.04l-0.01,0.023l-0.021-0.034H4.355l0.01,0.018H4.351l0.01,0.008l0.021,0.047l-0.01,0.011
		l-0.008-0.01l-0.006,0.014l-0.027-0.029l-0.014-0.041L4.295,17.95l0.007,0.02l-0.006,0.01l0.017,0.006l0.045,0.074l-0.014,0.044
		h-0.02v-0.032L4.31,18.063v-0.024L4.293,18.03v0.015l-0.007,0.009H4.268v-0.007H4.259l-0.014,0.03l0.012,0.01l-0.013,0.011v0.009
		H4.226l0.006,0.03l0.026,0.022l-0.019,0.03h-0.01h0.006l-0.009-0.012L4.212,18.19l0.013,0.026h0.014H4.231l0.005,0.018
		l-0.021,0.023h0.017v0.019l0,0l0.062,0.04l0.155,0.133l0.021,0.01l0.009,0.019l0.011-0.006v-0.019l0.019-0.033v-0.021l0.011-0.01
		h0.007v-0.012l0.019-0.007v-0.007l0.032-0.019l0.041,0.016l0.012-0.005H4.62l0.008-0.009l0.022,0.006l0.014,0.022l0.017-0.006
		l0.032,0.039l0.01-0.01v-0.056l0.023-0.038l0.114,0.1l0.021,0.029l0.157,0.13l0,0l-0.042,0.045l-0.062-0.034l-0.042,0.049
		l-0.071,0.042l-0.027-0.007L4.773,18.63v-0.018h0.008l-0.009-0.024H4.78l-0.006-0.024l-0.018,0.029H4.735l0.009,0.008l0.008,0.042
		l-0.035,0.028v0.012l0.015,0.01l-0.013,0.015l0.005,0.006H4.717l-0.016-0.021L4.683,18.7l0.076,0.077l0.005-0.013h0.01v-0.008
		l0.022,0.009v-0.007l0.03-0.031l0.029,0.051H4.846v0.016l-0.013,0.007v0.015h0.026v0.009l-0.008,0.008l0.013,0.01l-0.009,0.014
		l0.077,0.055l0.008,0.172l0.012,0.026H4.944v0.039l0.055,0.13l-0.013,0.008l0,0l0.078,0.165l0.1,0.089h0.109l0.071,0.02
		l0.088,0.057l0.079,0.077L5.587,19.8l-0.025,0.022v-0.021L5.54,19.816v0.013l-0.007-0.007H5.524v0.014l-0.014,0.02l0.042-0.017
		l0.065,0.077l-0.005-0.023l0.057-0.019l0.051,0.054l0,0h0.008l0.191-0.1l0.271,0.057l-0.046-0.033l0.016-0.123l0.018-0.052
		l-0.012-0.084l0.016-0.009l-0.014-0.026l0.055-0.026l-0.034-0.2l0.147-0.182l0.142-0.064l0,0l-0.131-0.162l-0.111-0.161L6.163,18.7
		l-0.147-0.093l-0.065-0.1l-0.15-0.373l-0.157-0.06l-0.04-0.036l-0.02-0.085l-0.012-0.014h0.006l-0.009-0.04l-0.046-0.017
		l-0.086-0.166L5.421,17.55L5.33,17.496l-0.065-0.015l0.006-0.02l-0.044-0.026l0,0L5.195,17.42v-0.017v0.008l-0.018-0.008v0.013
		H5.17l-0.048-0.039l-0.018,0.032H5.098v-0.016l0.015-0.015H5.106v-0.017l-0.025-0.013L5.074,17.33H5.055l-0.051-0.036l0,0v0.01
		L4.981,17.311z"/>
	<path id="FI-08" style="fill:#FFFFFF;" d="M8.315,21.531l-0.057,0.028l-0.063-0.066l-0.117-0.1l0.011-0.033h0.03l0.021-0.023
		l0.034-0.057l0.015-0.053l0.031-0.005l-0.025-0.033l0.024-0.021L8.21,21.099l-0.024-0.032v-0.072l-0.039-0.061L8.121,20.85
		l-0.037-0.066L8.052,20.74l-0.058,0.032l0.009,0.025l-0.049,0.044l-0.148-0.19L7.79,20.638l-0.024,0.013l-0.037-0.05l0.032-0.031
		v-0.016l-0.019-0.017l-0.109-0.044l-0.031-0.107l0.048-0.013l0.045-0.033l0.113-0.007l0.046,0.049l0.007,0.021l0.052-0.1
		l0.014-0.045H7.881l-0.076-0.107l0.018-0.044l0.107,0.021L7.925,20.11l-0.028-0.013L7.829,20.04v-0.01l-0.027-0.032L7.785,19.94
		l-0.018-0.017v-0.034l-0.026-0.031l-0.02-0.154l0.04-0.009l-0.008-0.066L7.715,19.54l-0.014-0.136l-0.048-0.058H7.637l0.055-0.126
		l0.061-0.083l-0.042-0.046l0,0l-0.159,0.071l-0.113-0.027L7.344,19l-0.048-0.034l-0.073-0.03l-0.182-0.01l-0.057-0.021l-0.263-0.2
		l-0.123,0.1l-0.031,0.184l-0.034,0.082l-0.052,0.024l0,0l-0.142,0.064l-0.147,0.182l0.033,0.2L6.17,19.567l0.014,0.026
		l-0.016,0.009l0.012,0.084l-0.018,0.052l-0.016,0.123l0.046,0.034l-0.271-0.057l-0.191,0.1H5.722l0,0v0.012l-0.043,0.114
		l-0.044,0.007v0.034l-0.009,0.006v0.037l-0.029,0.014v-0.014l-0.033,0.009l0.007,0.019l0.026-0.007v0.01l0.051-0.039l0.013,0.043
		l-0.012,0.005l0.007,0.036l-0.011,0.008l0.02,0.053l0.027-0.014l0.016,0.047v0.112h0.057l0.044,0.02l0.014,0.026v0.065L5.73,20.563
		l-0.008-0.031L5.7,20.599l0.029-0.012l0.038,0.059l-0.019,0.02h0.017l0.03,0.041h0.009v0.014l0.064,0.136l0.038,0.128l0.069,0.049
		l-0.079,0.075l-0.008,0.033l0.042,0.013l0.039-0.013l0.007,0.022l-0.032,0.011l0.013,0.064l-0.033,0.008l0.01,0.04l-0.021,0.008
		l0.013,0.053H5.92l-0.007-0.014H5.906l0.011,0.031l-0.014,0.006l-0.011-0.01l-0.006,0.027l0.012,0.034L5.884,21.44l-0.036-0.011
		l-0.02-0.022l0.012,0.034l0.014-0.007l0.011,0.009L5.833,21.45l-0.006,0.022l-0.029,0.026l-0.02-0.053H5.766l0.006-0.016
		l-0.023,0.018L5.74,21.428L5.7,21.45H5.691l0.006,0.021L5.684,21.48l0.023,0.042l-0.026,0.039l-0.134-0.082L5.432,21.64v0.016
		l-0.071,0.069l-0.026,0.039l0,0l0.013,0.114l0.027,0.043l0.02-0.007l0.011,0.011l0.006,0.049l0.08,0.055h0.11l0.015-0.016
		l0.03,0.15l0.049,0.069l0.025,0.008h0.032v-0.015h0.01v0.02H5.77v-0.018v0.015l0.014,0.007l-0.013,0.006l0.014,0.015L5.8,22.258
		l-0.013-0.031l0.006-0.01l0.073-0.052v-0.016l0.061,0.041l0.047,0.111l0.024,0.028l0.056,0.029l-0.007,0.15l0.041,0.015l0.03,0.054
		l-0.04,0.075l-0.037,0.027l-0.053,0.01L5.962,22.71l-0.021,0.069l0.005,0.053l0.127,0.164l0.148,0.154l-0.026,0.032l-0.048,0.021
		v0.09l0.021,0.058h0.053l0.042,0.016l0.005,0.037l-0.015,0.06l0,0l0.018,0.006l-0.043-0.013l0,0l-0.021-0.024l-0.031,0.09
		l0.01,0.037l0.028,0.034l0.019,0.08l0.014-0.072h0.016l0.029-0.02l0.031,0.021v0.053l-0.042,0.048l0.075,0.016l-0.023,0.077
		l0.005,0.058l0.047,0.071l0,0l0.119-0.04v-0.022l0.02,0.012h0.03l0.028,0.029l0.045-0.044l0.077,0.013l0.024-0.016l0.179-0.016
		l0.078-0.336l-0.007-0.105h0.03l0.008-0.009l0.037,0.015L7.1,23.391l0.03-0.03l0.035-0.055l0.01,0.006l0.012-0.009v0.009
		l0.014,0.006v-0.007l0.048,0.024l0.009,0.013h-0.01l-0.006,0.016l0.029,0.015l0.071-0.2l0.023-0.02l-0.009-0.05l0.039,0.016
		L7.37,23.201l0.052-0.009l0.017-0.055l0.006,0.018l0.062,0.051v0.027l0.016,0.022v0.018l0.024,0.022l0.034-0.008l0.009-0.032
		l0.015,0.027v0.025l0.008,0.015h0.011v0.1l0.017,0.038l0.032,0.028l0.024,0.045l0.03,0.005l0.025-0.071l0.04,0.02h0.044
		l0.019-0.034l0.007,0.016l0.012-0.009l0.015,0.033l-0.014,0.03l0.016,0.042h0.011v-0.013h0.009l0.017-0.018v-0.033l0.023-0.013
		v-0.024l0.01-0.009v-0.019l0.045,0.015l0.035,0.029l0.077,0.031l0.009-0.042v-0.007L8.12,23.471l0.007-0.045H8.121h0.008
		l0.01-0.064l-0.025-0.081l-0.012-0.009l-0.04-0.089L8.021,23.12l-0.028-0.018l-0.038-0.01l0.013-0.015v-0.034l0.009-0.019
		l-0.005-0.025l0.011-0.016l-0.018-0.04l0.006-0.03l-0.029-0.005l-0.03,0.014l0.019-0.05v-0.054l-0.008-0.025l0.01-0.047l0.08-0.047
		l-0.214-0.346l0.1-0.193l0.017,0.017l0.04-0.026v-0.008h0.053v-0.025L8.025,22.1h0.007v0.011v0.024h0.012l0.012-0.025l0.011,0.007
		l0.029-0.027l-0.019-0.042l0.041-0.006v-0.02l0.01,0.006l0.025-0.024l0.052,0.081l0.008,0.008l0.011-0.009l0.009,0.037l0.016,0.012
		l0.024-0.011l0.008-0.038l-0.021-0.02l-0.016-0.062l0.007-0.038l-0.025-0.029l0.066-0.019v-0.008h0.013l-0.02-0.044l0.009-0.009
		v-0.029l0.118-0.031l-0.1-0.117l0.027-0.055l-0.007-0.046l0.012-0.016L8.315,21.531z"/>
	<path id="FI-09" style="fill:#FFFFFF;" d="M8.12,24.341l0.035-0.012l0.02,0.015l0.078-0.047l0.044,0.11l-0.044,0.052l0.034,0.033
		l0.093-0.054l0.017-0.024l0.016-0.063l0.083-0.035h0.036l0.017,0.089v0.009l0.033,0.037l0.011,0.036L8.575,24.55l0.138,0.073
		l0.073-0.009l0.026-0.02l0.022,0.01l0.067-0.005l0,0l0.01,0.05l-0.032,0.047v0.021L8.87,24.73l0.012,0.014l0.012-0.013l0.019,0.017
		v0.012l0.012,0.005l-0.056,0.011l-0.038,0.039v0.047l0.014,0.046l-0.02,0.041l0.035,0.029l0.026-0.022H8.9l0.014,0.041v0.029
		l0.007,0.014h0.016v0.014l-0.075,0.037l-0.03-0.006l-0.024-0.033l-0.01,0.009l0.015,0.027l0.088,0.108l0.1,0.024l0.077,0.032
		l0.09,0.132l0.059,0.05h0.047l0.07-0.017l0.076,0.008l0.04,0.015v0.017l0.025,0.062l0.045,0.005l0.022-0.023l0.018,0.008h0.045
		l0.013,0.064l0.045,0.091l0.057,0.027l0.006,0.018v0.025h0.042l0,0L9.645,25.88l-0.038,0.058l0.006,0.04l-0.034,0.016l-0.018,0.019
		H9.548l-0.02-0.063l0.006-0.013l-0.009-0.008l-0.013,0.005l-0.02-0.005l-0.014,0.009l0.016,0.02l-0.021,0.01l0.008,0.009v0.024
		l-0.009,0.023l0.006,0.019L9.5,26.049l0.006,0.022l-0.014,0.007v0.019l0.015,0.01v0.023l-0.01,0.007l-0.033-0.013l-0.022,0.025
		L9.413,26.14v-0.008H9.391H9.386l0.006,0.021H9.376l-0.009-0.014l-0.025-0.008l0.009,0.023l0.028,0.018v0.013l-0.047-0.017
		l-0.013-0.017v-0.02L9.296,26.11l-0.01,0.009l-0.028-0.022H9.249l-0.009-0.02l-0.011,0.01v0.012L9.2,26.108v0.014l0.012,0.024H9.23
		l0.011,0.03v0.013H9.225L9.197,26.16L9.18,26.168v0.011H9.171l-0.018-0.016V26.14l-0.046-0.05v0.019l0.017,0.011l-0.006,0.01H9.077
		v-0.011L9.1,26.111v-0.013l-0.007-0.01l-0.022,0.006l-0.029-0.01l-0.006-0.027l-0.022,0.014l-0.009,0.02L8.99,26.076v-0.012
		L8.955,26.05l-0.013,0.014l-0.026,0.009V26.05l0.009-0.016l-0.018-0.027l0.006-0.019h0.015l0.073,0.062l0.009-0.015l-0.029-0.018
		L8.97,26l-0.026-0.011v-0.013l-0.03-0.01h-0.03l0.01,0.063H8.888l-0.006,0.032H8.868l-0.025-0.012l-0.026-0.04l-0.018,0.02
		l-0.013-0.013L8.742,26.05l-0.008-0.029l-0.015-0.012l0.006-0.01l-0.006-0.017h-0.02l0.023,0.069l-0.02,0.028H8.687l-0.011-0.011
		l0.01,0.042l-0.01,0.015l-0.01-0.013v0.017l0.022,0.053l-0.014-0.006l-0.015-0.044H8.642v0.015l0.024,0.014L8.64,26.219L8.59,26.16
		l-0.012,0.006v0.014l0.017,0.032l0.021,0.006v0.017l0.014,0.017l-0.012,0.007v0.009H8.587v-0.02l-0.025-0.007v0.015H8.549v-0.023
		L8.54,26.22l0.031-0.01v-0.007l-0.007-0.017l0.007-0.011L8.55,26.147l-0.011,0.026H8.517l-0.031,0.025l-0.037-0.037v-0.013
		L8.41,26.129l0.016,0.08H8.4l-0.014,0.045H8.347v0.022l-0.019-0.017H8.32l-0.016-0.054l-0.018-0.011l-0.008,0.009L8.27,26.196
		l-0.008,0.007v-0.01H8.246l-0.01,0.018H8.225v0.033l0.007,0.006v0.021l-0.024-0.02l-0.007-0.027l-0.015-0.007l-0.007,0.009
		l-0.025-0.032l-0.015-0.005l-0.016-0.046l-0.017-0.01l0,0l0.011-0.012v-0.013l0.008-0.029L8.17,26.05v-0.016l-0.022-0.023
		l0.016-0.008l0.006-0.016l0.011,0.006l0.025-0.009l0.006-0.046l0.049-0.074l0.035-0.013h0.035l0.017,0.01l0.029,0.047l0.036,0.022
		l0.028,0.007l-0.01-0.022L8.362,25.77l-0.041-0.006l-0.052,0.021v0.032l-0.031,0.047l-0.025,0.069l-0.005-0.041l-0.031-0.008H8.146
		v-0.037l-0.032-0.062l-0.026-0.02L7.997,25.74l0.016-0.016l-0.026-0.019l-0.015-0.044l0.006-0.04l-0.033-0.039v-0.041l-0.026-0.015
		L7.91,25.511H7.894v0.028H7.868l-0.022-0.021l0,0l-0.01-0.1l-0.039-0.039L7.75,25.352l-0.029,0.006l-0.022-0.016l0.017-0.056
		l0.032-0.022l-0.008-0.03l0.011-0.032l0.051-0.073l-0.123-0.02l-0.02-0.08l0.007-0.024h0.016v0.017l0.014,0.012l0.046-0.031v-0.051
		l0.014-0.047L7.74,24.863l-0.016-0.006v-0.066l-0.068-0.043l0.064-0.1l0.039-0.034l0.013,0.041h0.02l0.075,0.018l0.036-0.042
		v-0.032l0.082-0.019l0.048-0.038l0.016-0.063l0.034-0.047v-0.038l0.02-0.02l0.036-0.007L8.12,24.341z"/>
	<path id="FI-10" style="fill:#FFFFFF;" d="M6.498,13.51l0.006,0.021l-0.008,0.02v-0.012H6.479v-0.013v-0.011l0.018-0.008
		l0.008,0.008L6.498,13.51z M5.989,13.275h0.017l-0.012,0.034l-0.013,0.013l0.012,0.02v0.027l-0.018-0.007v0.034H5.938L5.93,13.388
		V13.4l-0.01-0.014L5.898,13.4l0.016-0.021l-0.016,0.007l0.006-0.016H5.898v-0.016h0.016v-0.007l0.011,0.01h0.012l0.021-0.016
		l0.02-0.03v-0.039l0.008,0.009L5.989,13.275z M9.746,0.307l0.023,0.207l0.2,0.3l0.2,0.127l-0.018,0.1l0.086,0.105l0.561,0.284
		l0.247,0.113l0.039,0.174l0.187,0.516l-0.134,0.259l-0.429,0.542V3.2l-0.029,0.191l0.06,0.113l0.081,0.075l-0.477,0.372l-0.1,0.052
		l0.059,0.092h0.218l0.151,0.052L10.56,4.571l-0.3,0.583l0.244,1.042l0.752,0.36l0.077,0.17l0.017,0.009V6.76l0.05,0.123
		l0.027,0.043l0.1,0.214l0.036,0.044l0.1,0.2l0.291,0.268l0.083,0.065l-0.1,0.46l-0.1,0.131l-0.082,0.15l-0.053,0.079l-0.007,0.027
		l-0.031,0.032l-0.006,0.018h-0.021l-0.015,0.045l-0.023,0.008l-0.006,0.022l0.029,0.009v0.014l-0.034,0.027l-0.032-0.011
		l-0.006,0.02h-0.014v0.01l0.015,0.007l-0.01,0.014h-0.015L11.498,8.8V8.792h-0.015l-0.021,0.064l0.012,0.022l-0.493,0.817
		l-0.036,0.1l-0.007,0.1l0.03,0.2l0.063,0.142v0.01l-0.015,0.015v0.014l0.029,0.01l0.005,0.017l0.016,0.005l0.232,0.4v0.042
		l0.028,0.044l-0.009,0.014l0.007,0.014h0.019l0.02,0.015l-0.007,0.023l0.068,0.113l0.111,0.307l0,0h-0.349v0.066l-0.623-0.216
		l-0.033,0.2h-0.053l-0.017,0.01l-0.014-0.012l0.008,0.04h-0.016l-0.01,0.05H10.4v0.081l0.006,0.023l-0.008,0.033H10.39l0.058,0.175
		l0.014,0.01h0.04l0.006,0.039l0.018,0.011l0.026-0.039h0.03l0.039,0.016l-0.007,0.023l0.019,0.024v0.033l-0.022,0.017l0.014,0.014
		L10.598,11.9l0.037,0.059l-0.058,0.065v0.069l-0.03,0.024h-0.09l0.022,0.1l0.019,0.014l-0.015,0.039l0.072,0.026l0.01,0.023h0.017
		v0.023l0.026-0.018l0.021,0.019l-0.03,0.017v0.012l0.007,0.02l0.011,0.006v0.06h-0.079l-0.025,0.036h-0.06v-0.032l-0.056-0.026
		v0.015h-0.066v0.036l-0.076,0.008v-0.032l0.022-0.025h-0.012l-0.009,0.01l-0.046,0.009v0.009l0.019,0.007v0.04h0.01l-0.008,0.03
		l-0.019-0.007l-0.007,0.008v0.021l0.067,0.039l0.03,0.077l0.053,0.073l0.007,0.122h-0.055l-0.267,0.119l-0.065-0.017l-0.022-0.02
		l-0.259-0.019l-0.007-0.021H9.673v0.006h0.013v0.01l-0.154-0.011l0.006-0.028l0.029,0.006V12.9l0.013-0.009v-0.014H9.569
		l-0.012,0.028l-0.021-0.008l-0.081-0.161l-0.044-0.162l-0.038,0.008l-0.006,0.009h-0.02l-0.025,0.033l-0.013,0.037L9.282,12.67
		l-0.105-0.1l-0.036,0.054l0.008,0.015H9.142l-0.01-0.007l-0.169,0.259H8.955l-0.011,0.007l0.007,0.015l-0.013,0.02l-0.006-0.006
		v0.017l-0.068,0.107v0.012l0.011,0.006L8.86,13.077l-0.007-0.011l-0.05,0.078l-0.021,0.01l0.009,0.017l-0.03,0.031l-0.042-0.023
		l-0.031,0.015v0.028h0.015v0.022H8.662v-0.041l-0.153,0.07h-0.02l-0.149-0.02v0.007v-0.008l-0.007,0.025L8.311,13.25l-0.305-0.01
		l-0.248-0.03v-0.263l-0.408,0.22l-0.413-0.005l-0.07,0.04L6.81,13.315l-0.088,0.047l-0.035,0.04H6.679l-0.068,0.129l-0.05,0.051
		l0,0l-0.008-0.01h0.014l-0.011-0.014v-0.01l-0.008-0.015l-0.015-0.007L6.498,13.5l-0.067-0.021v-0.016H6.398v0.007H6.385v-0.006
		l-0.013,0.007l-0.017-0.006L6.32,13.436v-0.031l-0.012-0.014l0.008-0.018l-0.023,0.01L6.28,13.369l-0.007,0.016H6.264l-0.009-0.016
		v0.011l-0.013,0.015l-0.018-0.008l0.007-0.012H6.225l-0.009-0.01v0.007L6.208,13.36l0.009,0.029H6.208L6.2,13.416H6.193
		l-0.014-0.033l-0.027-0.017l-0.013,0.013v0.014h-0.01v0.032H6.121l-0.01,0.015l0.008,0.007l-0.02,0.012v0.015H6.087v-0.013H6.073
		v-0.009H6.052l0.006-0.028l-0.007-0.012h0.008l0.007,0.008v-0.018l-0.015-0.01v-0.007l-0.024-0.028v-0.013H6.006L5.998,13.3
		l0.016-0.022l0.017,0.042h0.01l-0.009-0.039v-0.014l-0.028-0.024H5.993v0.011l-0.014-0.006L5.953,13.2l-0.019,0.01l-0.007-0.016
		v-0.031H5.896v-0.04L5.887,13.17H5.875l0.007-0.063l-0.013,0.055l-0.054-0.023v-0.021l0.025-0.007l0.026-0.035V13.06l-0.042,0.021
		H5.799v-0.023L5.81,13.05v-0.011L5.791,13.07v0.015l-0.016,0.006l-0.029-0.017v-0.012l-0.018,0.007v-0.01l-0.011-0.005v0.01
		L5.7,13.039l-0.021-0.006l-0.018-0.044v0.04H5.649l-0.006-0.013v0.007H5.637L5.635,13l0,0l-0.021-0.022l-0.006-0.034L5.592,12.92
		v0.013l0.018,0.03v0.013L5.583,12.94l-0.017-0.01H5.559l0.03,0.034v0.034L5.568,13l0.009,0.011l-0.006,0.014v0.021l-0.01,0.007
		l0.006,0.011H5.539l0.037,0.023l-0.008,0.026h-0.01v-0.015l-0.005,0.007H5.545l-0.014-0.021l0.006,0.036l-0.019,0.007l-0.012-0.008
		l0.01,0.034l-0.011-0.012v0.01H5.498v-0.01H5.493L5.48,13.108V13.07l0.007-0.006l0.01,0.006v-0.01l0.013-0.013v-0.032L5.498,13
		l-0.02-0.1l-0.017-0.024l0.007-0.031l0.019-0.006l-0.02-0.066l-0.018-0.024L5.443,12.71l0.008-0.039l-0.033-0.013l-0.014-0.034
		l-0.037-0.05v-0.017l0.013-0.012v-0.068l-0.05-0.084l-0.046-0.107l-0.023-0.02v-0.041l0.011-0.035L5.26,12.164l0.006-0.022
		l-0.024-0.044l0.011-0.022V12.04L5.24,12.023L5.214,12.01H5.19L5.16,12H5.134l-0.05-0.047l-0.066-0.022l0.006-0.032l-0.015-0.025
		l-0.021-0.013l-0.01-0.03l0.006-0.037l-0.03-0.039v-0.037L4.93,11.632l0.011-0.139l0.028-0.069v-0.025l-0.006-0.017l-0.019-0.016
		l-0.028-0.085l0.011-0.073l0.076-0.058l-0.005-0.04l0.012-0.018l0.006-0.034l0.008,0.014h0.011l0.018-0.02l0.033-0.014v-0.017
		l-0.013-0.036l0.078-0.059l0.021-0.051V10.8l0.011-0.038l-0.016-0.041v-0.034L5.159,10.65l0.024-0.052l0.01-0.1l0.013-0.028
		l-0.021-0.054V10.39l-0.012-0.013V10.35l0.014-0.017l0.026-0.007l-0.009-0.059l0.007-0.008h0.018l0.036,0.034l0.02-0.006v-0.033
		l0.013-0.018l0.007-0.053l-0.025-0.05v-0.028l-0.037-0.1L5.227,10L5.213,9.965L5.171,9.914V9.863L5.162,9.848v-0.04L5.154,9.8
		H5.131L5.117,9.745L5.098,9.727L5.093,9.7L5.06,9.676L5.034,9.633L5.009,9.585V9.557L4.964,9.495l-0.02-0.108L4.918,9.356
		L4.898,9.32L4.821,9.192l0.015-0.041l0.025-0.016V9.11l0.011-0.028L4.84,9.044L4.818,9.033L4.835,9l-0.01-0.033l0.019-0.012
		L4.837,8.926l0.017-0.02l0.03-0.013L4.898,8.9l0.065-0.032h0.044l0.019-0.033l0.022-0.094L5.07,8.714L5.052,8.681H5.029V8.658
		L5.018,8.627v-0.03L5.008,8.57V8.555l0.034-0.046V8.475l0.014-0.03L5.045,8.427L4.998,8.4L4.956,8.408l-0.075-0.03H4.856
		l-0.037-0.02h-0.02L4.778,8.394H4.755l-0.06-0.038L4.686,8.343L4.664,8.335L4.646,8.284l0.013-0.047l0.028-0.015l0.008-0.036
		l0.041-0.032l0.011-0.027L4.725,8.093L4.717,8.06l0.031-0.014l0.023,0.01l0.027-0.04l0.013-0.007V7.933l0.021-0.044l-0.02-0.014
		V7.847L4.766,7.768V7.75l0.007-0.041L4.764,7.687V7.666l-0.017-0.02l0.008-0.013L4.749,7.586l0.009-0.019L4.745,7.539l0.018-0.042
		L4.755,7.43l0.011-0.042l-0.015-0.03L4.743,7.31l0.01-0.03V7.237l0.013-0.015V7.176l0.027-0.064l0.029-0.017l0.01-0.022l0.02,0.013
		h0.015L4.88,7.059l0.053-0.042v-0.03l0.021-0.044l-0.015-0.03V6.886L4.885,6.854H4.854l-0.04-0.057L4.798,6.752L4.729,6.713
		L4.728,6.68l-0.02-0.017L4.659,6.645L4.644,6.63V6.605L4.628,6.589L4.617,6.522L4.59,6.475L4.576,6.42V6.406L4.549,6.327H4.534
		L4.52,6.309H4.498L4.491,6.331L4.444,6.342l-0.01,0.017L4.405,6.375H4.393L4.38,6.364L4.365,6.31l0.006-0.053V6.228l0.022-0.046
		L4.371,6.164V6.12l0.012-0.04L4.375,6.038L4.342,6.023l-0.044-0.05L4.283,5.928l0.006-0.055L4.274,5.852H4.249L4.198,5.813
		L4.18,5.776H4.121L4.093,5.728L4.036,5.687L4.028,5.663l0.011-0.038l-0.05-0.031L3.956,5.617H3.913L3.898,5.589L3.859,5.574V5.566
		l0.006-0.01l-0.01-0.013L3.813,5.518V5.471H3.794L3.787,5.492L3.74,5.507L3.716,5.47H3.693L3.65,5.448H3.62L3.582,5.379H3.55
		L3.498,5.43L3.48,5.436V5.427l0.011-0.048l-0.01-0.02L3.49,5.328V5.322L3.47,5.327L3.434,5.318L3.418,5.333L3.38,5.323L3.351,5.349
		L3.329,5.327l-0.057,0.03H3.247L3.233,5.325l-0.016,0.01L3.19,5.324l-0.02,0.01H3.152L3.126,5.289L3.092,5.2l0.009-0.036H3.087
		L3.061,5.11L3.033,5.092L3.019,5.064L2.972,5.009H2.945V4.994L2.924,4.989l-0.07,0.016L2.785,4.99l-0.02-0.023V4.928l0.016-0.022
		V4.881L2.733,4.839L2.718,4.808L2.68,4.8L2.671,4.772L2.633,4.761l-0.016-0.01L2.611,4.737h-0.07L2.502,4.721L2.489,4.7
		L2.464,4.688L2.452,4.672V4.646L2.443,4.63l0.008-0.018L2.434,4.549L2.44,4.511L2.416,4.462L2.388,4.454L2.342,4.479L2.315,4.47
		L2.28,4.409L2.263,4.345L2.246,4.313L2.19,4.277V4.263L2.152,4.231L2.123,4.184L2.075,4.156L2.067,4.13L2.037,4.1H2.014V4.087
		L1.978,4.056H1.971L1.959,4.073H1.935V4.054L1.91,4.047H1.889L1.873,4.066L1.847,4.058l0.028-0.023L1.846,3.979L1.82,3.968
		l-0.009-0.04l0.024-0.021l0.028,0.006l0.024-0.057V3.822l-0.035-0.05H1.836L1.798,3.749L1.774,3.683L1.757,3.661l-0.031-0.03
		L1.677,3.616L1.654,3.6H1.626h-0.05L1.523,3.573H1.508l-0.027-0.02l0.192-0.192l0.379,0.26l0.057-0.21L2.052,3.354L2.04,3.295
		L1.977,3.133l0.02-0.1l0.1-0.115l0.2-0.162L2.68,2.867l0.118,0.207l0.288,0.442l0.208,0.348l0.021,0.119l0.164,0.284l0.04,0.336
		l0.176-0.079l0.071,0.051l0.223,0.122l0.274-0.005l0.136,0.186l0.3-0.194l0.259-0.041l0.064-0.137l0.05-0.216l0.109-0.052
		l0.125,0.03l0.1,0.145l0.092-0.032v0.115L5.559,4.52l0.054,0.06l0.053,0.028l0.336,0.106l0.194,0.138l0.087,0.229L6.33,5.102
		l0.018-0.168L6.402,4.91h0.011l0.02,0.017l0.023-0.012L6.463,4.89h0.011l0.018-0.011L6.513,4.9l0.013-0.009l0.012-0.038
		l0.027-0.012L6.556,4.714l0.009-0.078l0.016-0.025L6.573,4.588V4.5l0.013-0.038l0.007-0.07l0.02-0.039l0.045-0.034l0.014-0.026
		l0.022-0.02l0.015-0.049l0.033-0.035l0.015-0.006l0.012-0.02l0.113-0.075V4.073l0.01-0.008h0.013l0.07-0.04h0.013v0.018
		l0.049,0.036h0.02L7.098,4.1l0.043-0.03V4.05l0.027-0.025l0.013-0.056L7.21,3.905v-0.02l0.022-0.044V3.794l0.013-0.032l0.053-0.069
		V3.656L7.276,3.639L7.268,3.611V3.552L7.236,3.483V3.407L7.229,3.372L7.268,3.3L7.216,3.117V3.094l0.018-0.023V3.028L7.222,3.006
		L7.23,2.974L7.224,2.938l0.031-0.026V2.881l0.014-0.015V2.851l0.009-0.014l-0.011-0.03l0.01-0.021L7.271,2.739l0.018-0.031V2.666
		L7.31,2.654l0.007-0.037l0.034-0.013V2.555l0.017-0.024L7.376,2.5h0.012L7.371,2.463l-0.02,0.006V2.446h0.012V2.431l-0.011-0.01
		H7.336v-0.01l0.016-0.016V2.371L7.37,2.329l0.009-0.055l0.033-0.031L7.398,2.182l0.031-0.1l-0.01-0.021L7.391,2.035v-0.02
		l0.057-0.031l0.048-0.047l0.046-0.119L7.525,1.785V1.746l-0.01-0.047L7.492,1.676L7.454,1.662L7.446,1.648l0.019-0.044l0.037-0.029
		L7.528,1.5l0.034-0.035l0.069-0.013l0.062-0.035l0.024-0.029l0.014-0.045l0.032-0.014V1.3l0.009-0.018l0.026-0.015l0.017-0.043
		H7.83v-0.01V1.153l0.1-0.064l0.015-0.025h0.019l0.021-0.025l0.038-0.091V0.92l-0.01-0.039l0.031-0.027l0.019-0.03l0.011-0.061
		l0.031-0.02l0.068-0.012l0.065-0.039l0.068-0.018L8.315,0.68l0.012,0.04l0.029,0.013h0.019L8.432,0.69h0.071v0.05l0.016,0.031
		l0.02,0.009l0.032-0.01l0.027-0.017h0.034l0.027,0.022L8.68,0.817l0.043,0.03l0.028-0.024H8.79l0.037-0.048h0.019l0.088-0.049
		L9.004,0.7V0.675L8.998,0.651L8.979,0.638l0.009-0.022L9.02,0.588l0.021,0.006l0.025-0.008l0.032-0.035L9.12,0.512l0.03-0.021
		l0.1-0.01l0.016-0.035v-0.04l0.017-0.042l0.018-0.011l0.006-0.015l0.041-0.025h0.078l0.038,0.028l0.032,0.011l0.056-0.04
		l0.13-0.033l0.04-0.017h0.009l0.006,0.051L9.746,0.307z"/>
	<path id="FI-11" style="fill:#FFFFFF;" d="M5.317,21.737L5.24,21.706l-0.014-0.035v-0.044l-0.011-0.025l0.01-0.055l-0.014-0.065
		H5.178v0.06h-0.03l-0.007-0.083l-0.033,0.039l0.013,0.027v0.03H5.114l-0.019-0.026l-0.011,0.018l-0.03,0.011l-0.1,0.1l-0.179-0.011
		l0.016,0.11l-0.048-0.035l-0.032,0.016l-0.026-0.007l-0.038-0.026v-0.019l-0.013-0.011l-0.011,0.012l-0.009-0.006l0.013-0.013
		L4.61,21.651l-0.011,0.01l-0.012-0.009V21.62L4.52,21.659v0.008l0,0v-0.006L4.497,21.73l0.009,0.017h0.006v0.026L4.497,21.78H4.482
		l-0.008,0.015l-0.013-0.007l0.008,0.024H4.463L4.45,21.845l-0.346,0.149H4.029l0,0v0.013l0.007,0.017H4.03l-0.007,0.042
		l0.024,0.009v0.027l0.015,0.06l-0.014,0.025l0.023,0.04l-0.029,0.018h-0.02l-0.051,0.022l-0.007,0.032H3.97l0.012,0.2l-0.067,0.02
		h-0.04l-0.09,0.04l-0.076,0.1l0.042,0.032h0.013l0.019,0.008v0.009L3.77,22.716v0.019h0.01l0.008-0.024v-0.023l0.016,0.01v0.031
		l0.039,0.007v0.023l-0.011,0.009l0.022,0.051l0.022,0.021l0.016,0.005l0.019-0.005v0.009l0.022,0.016l-0.005,0.049l0.054,0.021
		l0.005,0.024l-0.056,0.025l0.011,0.027l0.022-0.014l0.031,0.051l0.037,0.04l-0.006,0.04L4.01,23.136l0.007,0.013l-0.013,0.048
		l-0.02,0.035H3.975l-0.014-0.01L3.92,23.241v0.015l-0.016,0.015H3.89v0.016l-0.025,0.017l0.013,0.052v0.01l-0.044,0.005
		l0.007,0.032l0.027,0.027l-0.032,0.009l0.007,0.03l0.035-0.015v-0.032h0.011v0.011h0.014v0.04l0.015,0.024l-0.024,0.049
		l-0.047-0.041l0.039,0.1l-0.058,0.039v0.049l-0.043,0.046l-0.022-0.012l0.082,0.163l0.016,0.011l0.008-0.01h0.05l0.007,0.035
		l-0.017,0.006l0.02,0.06l-0.072,0.017l0.012,0.05l-0.014-0.025H3.849v0.024l-0.015-0.007L3.827,24.06H3.812l0.01,0.025v0.014
		l-0.069-0.021v-0.006l-0.016,0.007l0.036,0.12l-0.036,0.026l0.043,0.051v0.016h0.01l0.03,0.073l-0.007,0.014l0.014,0.018v0.018
		H3.89l0.006,0.012v-0.013h0.065l0.084,0.038l0.013-0.014l0.087,0.071l0.051,0.03H4.27l0.009,0.029l0.016-0.007l-0.012-0.03h0.053
		l0.07-0.036l0.07,0.01l0.013-0.034l0.035,0.007L4.51,24.565l0.047,0.033l0.088,0.087l-0.124,0.048l-0.007-0.016H4.488v0.021H4.48
		l0.009,0.011h0.018v0.038H4.52v0.01h0.025l-0.017,0.011l-0.046,0.007v0.012l0.04,0.006l0.005,0.012h0.015l-0.03,0.086l0,0
		l0.081,0.022l0.031-0.008l0.023,0.033l0.012,0.042l0.017,0.027h0.008l0.008-0.01h0.022l0.032,0.019v-0.005l-0.015-0.01v-0.014
		h0.005l-0.01-0.009H4.74l0.01-0.016l0.033-0.009l0.032,0.012l0.011-0.023l0.022,0.008v-0.012l0.021,0.012L4.854,25l0.013,0.015
		l0.025-0.017l0.012-0.025H4.98l0.03-0.012l0.026,0.019v0.022h0.021l0.012-0.01L5.1,25.01l0.05,0.111l0.028-0.034L5.164,25.03
		l-0.039-0.048l0.019-0.005v-0.021l0.012,0.007l0.036-0.031l-0.015-0.033l-0.08,0.026l0.011-0.024L5.1,24.885l0.006-0.017
		l-0.049-0.017l0.051-0.049l0.022-0.007l0.025,0.017l0.051-0.02l0.145-0.081l0.011-0.013h0.016l0.019,0.012v-0.008l0.048-0.011
		l0.038,0.012v-0.006L5.47,24.672l0.007-0.017h0.05l0.054-0.014l0.049-0.063v-0.012h0.009l0.017-0.02l-0.037-0.039l-0.011-0.029
		l0.02-0.019l0,0l0.01-0.02l0.029,0.015l0.089-0.036l0.012,0.006l0.006-0.018h0.018l0.01-0.023l0.02-0.015h0.012l0.039-0.036
		l0.088-0.049l0.019,0.034h0.008l0.071,0.03h0.029l0.022,0.018l0.011-0.019l0.017-0.087l0.041,0.112l0.043-0.009l0.088-0.033
		L6.3,24.305l0.037-0.063l0,0l-0.026-0.07l0.017-0.074l-0.011-0.014l0.018-0.054l-0.043-0.051v-0.015l-0.013-0.02l-0.013-0.058
		L6.4,23.947l0,0l-0.047-0.071l-0.005-0.058l0.023-0.077l-0.075-0.016l0.042-0.048v-0.053l-0.031-0.021l-0.029,0.02H6.262
		l-0.014,0.072l-0.019-0.08l-0.028-0.034l-0.01-0.037l0.031-0.09l0.021,0.024l0,0l0.025,0.008l0,0l0.015-0.06l-0.005-0.037
		l-0.042-0.016H6.183l-0.021-0.058v-0.09l0.048-0.021l0.026-0.032l-0.148-0.154l-0.127-0.164l-0.005-0.053l0.021-0.069l0.026-0.021
		l0.053-0.01l0.037-0.026l0.04-0.075l-0.03-0.054l-0.041-0.015l0.007-0.15l-0.056-0.029l-0.024-0.028l-0.047-0.111l-0.061-0.041
		v0.016L5.808,22.24l-0.006,0.01l0.013,0.031L5.8,22.293l-0.014-0.015l0.013-0.006l-0.014-0.007V22.25v0.018H5.778v-0.02h-0.01
		v0.015H5.736l-0.025-0.008l-0.049-0.069l-0.03-0.15l-0.015,0.016h-0.11l-0.08-0.055l-0.006-0.049L5.41,21.937l-0.02,0.007
		l-0.027-0.043L5.35,21.787L5.317,21.737z"/>
	<path id="FI-12" style="fill:#FFFFFF;" d="M2.189,21.851l0.009-0.048l0.015-0.023l-0.01,0.056L2.189,21.851z M2.238,21.699h0.011
		v0.021h0.01v0.008v0.061l0.006,0.023H2.256v0.03H2.247l0.006-0.016L2.239,21.81v0.022l-0.01,0.007V21.83v-0.091L2.238,21.699z
		 M2.2,21.744v0.028L2.184,21.78l0.01-0.053l0.026-0.044l-0.007,0.091v-0.033L2.2,21.744z M2.205,21.625l0.014,0.03l-0.038,0.1
		l-0.007-0.01v-0.034l0.015-0.04h0.009v-0.029l-0.017,0.013l0.022-0.032L2.205,21.625z M2.063,21.249l0.025,0.026l-0.017,0.073h0.01
		v0.021l-0.007-0.007l-0.015,0.005l-0.02-0.055L2.063,21.249z M2.045,20.854H2.07l0.015,0.041l-0.007,0.033H2.065v0.014H2.051
		l-0.008-0.02L2.045,20.854z M2.182,20.547l0.045,0.026l0.011-0.011h0.008L2.23,20.615l-0.05,0.019H2.148l-0.005,0.009L2.13,20.631
		l-0.02,0.014l-0.022-0.008v-0.019v0.006l0.011-0.011L2.09,20.6l0.032-0.012l0.008-0.022L2.182,20.547z M2.182,20.402l0.022,0.023
		h0.012l-0.008,0.013l0.018,0.014l0.02,0.036l0.007,0.071l-0.018-0.024l-0.023-0.011l0.008-0.025l-0.045-0.051L2.182,20.402z
		 M2.426,20.327l-0.011,0.01l0.007,0.017l0.006-0.007l0.011,0.005l-0.01,0.011l0.006,0.007h0.012l-0.006,0.014l0.006,0.012
		L2.44,20.407H2.429v-0.013l-0.016-0.007l-0.016,0.017L2.38,20.39v-0.014l0.041-0.048L2.426,20.327z M2.149,20.197l0.057,0.055h0.01
		v0.043l0.013,0.06l-0.016,0.014l-0.009-0.033H2.19l-0.007,0.009v0.029l-0.01-0.008H2.164l-0.027-0.034v-0.04l0.01,0.005
		l-0.006,0.015l0.008,0.012l0.011-0.015l-0.009-0.025H2.137l-0.006-0.029L2.115,20.23l0.013-0.015h0.011L2.149,20.197z
		 M2.364,20.197h0.015l0.024,0.022v0.024l-0.036-0.024v0.01l-0.01-0.006v-0.016h0.009l-0.017-0.009v0.008v-0.018h0.012L2.364,20.197
		z M2.411,20.178l0.037,0.019H2.441l0.01,0.028l-0.047-0.017V20.18L2.411,20.178z M2.205,20.158l0.009,0.006l-0.009,0.007
		l0.015,0.01v0.028l0.013-0.02v0.02v0.016H2.22l-0.015,0.013l-0.01-0.006l-0.006-0.02H2.182v-0.029h0.02l-0.007-0.02l0.007-0.006
		L2.205,20.158z M2.399,20.109v0.013h0.014l0.008,0.008l0.008-0.01l0.006,0.018l0.026,0.017l0.028-0.007l-0.028,0.05L2.439,20.19
		v-0.005h0.009v-0.008v-0.006l-0.007,0.006H2.424l-0.045-0.023v-0.012l-0.015-0.017L2.399,20.109z M2.473,20.016l0.017,0.038
		l-0.009,0.024H2.472l0.009,0.03l0.011,0.007l-0.033,0.029l-0.032-0.039l0.022-0.019L2.427,20.08l0.014-0.032l0.011-0.008v-0.025
		L2.473,20.016z M2.633,19.984l0.01,0.017l-0.014,0.014H2.6l0.01-0.024h0.016v-0.016L2.633,19.984z M2.293,19.566l0.008,0.007H2.31
		v0.014h0.015L2.33,19.6l-0.008,0.009l-0.03-0.007L2.285,19.59h0.011L2.293,19.566z M2.793,19.449v-0.007l0.039,0.041v0.007
		l0.016,0.007l-0.006,0.019h0.012l-0.01,0.005v0.017H2.836v0.012h-0.01l-0.009-0.011H2.811l-0.027-0.041L2.779,19.47l0.007-0.006
		l-0.017-0.022h0.01v0.009L2.793,19.449z M2.239,19.43h0.017v0.017h0.007l-0.013,0.01v0.009H2.238v0.019h0.02l0.009-0.04
		l0.009,0.006l-0.006,0.01h0.008v0.023l-0.012,0.007v0.023L2.25,19.507l-0.015,0.034H2.224l-0.009,0.057v0.016L2.23,19.63
		l0.022-0.012l0.007,0.011l0.017-0.009l-0.01-0.006v-0.016h0.009l0.015,0.016l0.037,0.009l0.005-0.029h-0.01l0.031-0.018v-0.007
		H2.346l0.016-0.017v0.014l0.024-0.015v-0.023l0.007,0.011l0.014-0.009v0.01l0.035,0.011l-0.007,0.014h0.013v0.01l0.015,0.01
		l0.016,0.032L2.465,19.63v0.029l-0.007,0.014l0.02,0.023v0.022H2.439l-0.016-0.016l0.014,0.048l-0.019-0.022L2.409,19.75H2.385
		l0.005-0.057l0.017-0.009L2.37,19.689l-0.007,0.01l0.006,0.028l-0.011-0.008l-0.023,0.05H2.326v0.026l0.024-0.006v0.019
		l-0.011,0.008l0.01,0.017l-0.016-0.007L2.32,19.842H2.29L2.24,19.81v-0.036l-0.013-0.032l-0.017,0.03H2.181v0.011l-0.014-0.007
		l-0.016-0.038h0.006v-0.012L2.151,19.72v0.007l-0.014-0.021l0.007-0.007v-0.018h0.012v-0.016l-0.014-0.013l-0.024-0.063H2.109
		L2.12,19.56h0.006v-0.007l-0.045-0.021v-0.008v-0.012h0.008l-0.011-0.024v-0.02l0.023,0.009v0.026l0.01,0.01l0.005-0.007
		l0.035,0.02l0.006-0.01l0.006,0.011H2.18l0.006-0.011L2.178,19.48l0.01-0.008l0.01,0.009v-0.007l0.023-0.027v-0.011l0.012-0.006
		L2.239,19.43z M2.752,19.409l0.017,0.013v0.009L2.76,19.437v0.013H2.747v0.037l0.013,0.008v0.011l0.014,0.02L2.755,19.51v-0.011
		l-0.02-0.008v0.018l-0.013,0.013l0.01,0.017l-0.007,0.047v0.007h-0.02l-0.008-0.016H2.689v-0.026l-0.013-0.03v-0.01h0.011
		L2.68,19.497H2.667l0.009-0.014l0.023-0.01l0.007-0.041h0.026L2.752,19.409z M3.372,19.409v0.011h0.009H3.373v0.032l-0.01-0.02
		v-0.034L3.372,19.409z M3.339,19.38h0.016v0.011h0.007L3.357,19.41v0.068l-0.012,0.018l-0.007,0.043H3.33l-0.004-0.001v0.014H3.319
		l-0.024-0.017H3.284l-0.007-0.027H3.263v0.012L3.24,19.515l0.037,0.023l-0.006,0.055l-0.017-0.008v0.014l-0.021-0.017H3.225v0.008
		H3.211L3.2,19.574l-0.051-0.008l0.006-0.011h0.028l-0.046-0.033v0.017l-0.02-0.007L3.11,19.539L3.094,19.53l-0.021,0.012
		l-0.01-0.008h-0.01v-0.049l0.017-0.056h0.013v0.016h0.015l0.006,0.015l0.01-0.014l-0.008-0.013l-0.029-0.015l0.009-0.015v0.009
		h0.008v-0.014h0.01v0.013h0.013v0.013l0.017,0.011l0.013,0.03v0.024l-0.006,0.013h0.016H3.17v-0.009l0.012-0.005l-0.02-0.022
		v-0.022H3.154l-0.01-0.016v-0.025l0.011,0.02l0.024-0.019v-0.007l0.017-0.007h0.019v0.019l0.018-0.016l-0.005,0.011l0.009,0.007
		H3.226l0.011,0.02l0.017-0.023l0.013,0.012h0.016v0.024l0.018-0.019v-0.038L3.339,19.38z M2.362,19.306h0.033l0.014,0.01
		l0.011-0.012l0.032,0.01l-0.018,0.014l0.011,0.029l-0.01,0.013v0.009l-0.029,0.027l0.009,0.015l-0.011,0.03h0.007l-0.025,0.016
		l-0.038,0.052l-0.014-0.007l0.01-0.018H2.339l0.016-0.015l-0.009-0.007v-0.017H2.36l0.008-0.011H2.353l0.01-0.014H2.321
		l-0.015,0.013v0.011H2.297v-0.005H2.281v-0.037l0.014-0.017H2.272v-0.01l-0.034,0.014H2.229l0.027-0.018L2.245,19.36l0.016-0.028
		l-0.012-0.006h0.018v0.014h0.012l0.032-0.028l0.027-0.01L2.362,19.306z M3.118,19.254l0.008,0.023l0.02-0.027l0.015,0.01v-0.006
		h0.015v0.008h0.025l0.009,0.024L3.185,19.28v0.01H3.176l0.02,0.008v0.018l-0.023,0.01l-0.007,0.013H3.157L3.15,19.361H3.139
		l-0.011,0.024H3.126l-0.007-0.022H3.101H3.079l-0.011,0.025l-0.034,0.013v-0.025l0.02-0.007l-0.008-0.007v-0.016h0.013v-0.024
		l0.019-0.025h0.01v-0.026h0.015v-0.012h0.012l-0.006-0.012l0.006-0.006L3.118,19.254z M2.258,19.24l0.015,0.014l-0.013,0.02
		l0.009,0.015H2.26v0.017H2.249v0.01H2.23v0.01H2.211v-0.011l-0.015-0.008l0.014-0.016v-0.01l0.008-0.019h0.023v-0.014L2.258,19.24z
		 M2.745,19.24l0.009,0.007l-0.011,0.007l0.011,0.01l-0.015,0.008v0.01H2.716l0.011-0.015h-0.02l0.01-0.011H2.7v-0.021v0.008h0.018
		l0.008-0.009L2.745,19.24z M2.845,19.157H2.829l0.006-0.01L2.845,19.157z M2.822,19.129h0.007l-0.016,0.006v-0.014L2.822,19.129z
		 M2.801,19.099h0.016v0.009H2.805v0.006h0.006v0.007L2.799,19.13h0.008v0.011h0.008l-0.009,0.016h0.015h-0.03L2.8,19.146H2.789
		V19.14l-0.03,0.01l0.006-0.012h0.026v-0.021l-0.012,0.01H2.766l0.006-0.008H2.764h0.015l0.008-0.007l0.007,0.007V19.11H2.768
		l0.033-0.006l-0.009-0.012L2.801,19.099z M2.781,19.107v-0.011l0.011-0.006L2.781,19.107z M2.86,19.092h0.022l-0.016,0.011H2.89
		v0.015H2.885h0.011H2.887h0.01l-0.008,0.024l0.009-0.005l-0.009,0.015v-0.008H2.876v0.007L2.85,19.143l0.021-0.01l-0.009-0.008
		v-0.018H2.838v-0.011l0.012,0.006l0.007-0.006H2.848v-0.01H2.835V19.08L2.86,19.092z M2.85,19.086H2.839l0.019-0.007L2.85,19.086z
		 M3.512,18.673h0.014l0.012,0.031h0.01v0.029l-0.015,0.022l-0.007-0.009v-0.025l-0.014,0.006l-0.013-0.006l0.008-0.051
		L3.512,18.673z M3.779,18.279l0.029,0.013l0.017,0.025l-0.026-0.015h-0.01v0.025H3.786L3.78,18.343l0.005,0.01l-0.01,0.012
		L3.77,18.346l-0.019-0.007v-0.011l0.017-0.042L3.779,18.279z M4.228,18.261l0.062,0.04l0.155,0.133l0.021,0.01l0.009,0.019
		l0.011-0.006v-0.02l0.02-0.033v-0.021l0.011-0.01h0.007v-0.012l0.019-0.007v-0.007l0.032-0.019l0.041,0.016l0.012-0.005H4.617
		l0.008-0.009l0.022,0.006l0.014,0.022l0.017-0.006l0.032,0.039l0.01-0.01v-0.056l0.023-0.038l0.114,0.1l0.021,0.029l0.157,0.13l0,0
		l-0.042,0.045l-0.062-0.034l-0.042,0.049l-0.071,0.042l-0.027-0.007L4.77,18.614v-0.018h0.008l-0.009-0.024h0.008l-0.006-0.024
		l-0.018,0.029H4.732l0.009,0.008l0.008,0.042l-0.035,0.028v0.012l0.015,0.01l-0.013,0.015l0.005,0.006H4.714l-0.013-0.003
		l-0.018,0.007l0.076,0.077l0.005-0.013h0.01v-0.008l0.022,0.009V18.76l0.03-0.031l0.029,0.051H4.846v0.016l-0.013,0.007v0.015
		h0.026v0.009l-0.008,0.008l0.013,0.01l-0.009,0.014l0.077,0.055l0.008,0.172l0.012,0.026H4.944v0.039l0.055,0.13l-0.013,0.008l0,0
		L4.908,19.24l-0.037-0.048l0.005-0.009l-0.021-0.024H4.848l-0.019-0.028h0.009v-0.01l-0.025-0.049H4.8l-0.013,0.014l-0.013-0.015
		L4.732,19.05H4.719l-0.034-0.021l-0.01,0.006l-0.03-0.039l-0.143,0.071l-0.016,0.046l-0.012,0.008v0.012l-0.038,0.111l-0.1,0.126
		l-0.008-0.006l0.008-0.006l-0.033-0.033L4.25,19.337l-0.012-0.044H4.209v0.018H4.177l0.009,0.094l-0.093-0.051H4.077v-0.006h0.015
		v-0.007l-0.031-0.012v0.006l0.009,0.019l-0.012-0.006v0.009l0.016,0.015H4.026l0.006-0.01H4.016v-0.007l-0.017-0.007H3.993v0.007
		H3.978l0.005,0.007H3.968v-0.013l-0.014-0.007H3.943l-0.017-0.007l-0.019,0.007v0.016l0.023-0.011l0.013,0.031L3.91,19.401
		l-0.009-0.012l-0.077,0.049l0.028,0.049l-0.074,0.046l-0.013-0.011l-0.04,0.043l-0.006,0.031l0.063,0.04l-0.014,0.02h0.011
		l0.023,0.045l-0.041,0.023l-0.022,0.051l0.04-0.018l0.01,0.019l0.06-0.03v0.165H3.837l-0.01-0.014h-0.03v0.01H3.81v0.046
		l-0.018,0.01l0.005,0.014l-0.031,0.007v0.009l-0.022,0.006h0.023l0.018,0.01v0.015L3.77,20.03v0.006l-0.024-0.031l-0.072,0.048
		l0.028,0.037l-0.007,0.008l-0.006-0.016l-0.017,0.019l-0.011-0.007l-0.007,0.013h0.008l-0.01,0.017v-0.006l-0.037,0.063
		l-0.011,0.02v0.043l-0.008,0.019l-0.03,0.042l0,0l-0.023,0.018l0.013,0.02l-0.023,0.049v0.025H3.51v0.038l0.015,0.027l0.007,0.048
		l-0.019,0.018H3.494v0.022l-0.017,0.015l-0.056,0.112l-0.032,0.011l-0.084,0.1L3.221,20.77v0.015l-0.057-0.019l-0.007,0.011
		l-0.1-0.046v-0.012l-0.015-0.006v0.007l-0.048-0.019v-0.012l-0.037-0.012l0,0.018H2.94v-0.019H2.925l-0.012-0.065l-0.065-0.044
		l-0.009,0.04L2.8,20.67v0.156l0.02,0.084l0.049,0.067L2.823,21.02h-0.01l0.009-0.024l-0.007-0.015l-0.01,0.049L2.79,21.044v0.006
		l-0.023,0.021H2.758v0.005l-0.02,0.022v0.006H2.72l-0.017,0.013l-0.008,0.034l-0.026,0.028l-0.037,0.016l0.006,0.111h0.018v0.039
		l-0.074,0.224l-0.029-0.021l-0.008,0.011l0.039,0.03l0.005,0.1l-0.03,0.04l0.023,0.014l0.016,0.037l0.068-0.013v0.013h0.008v-0.01
		l0.023-0.007l0.043,0.03v0.036l-0.015,0.008v0.016l0.015,0.022l-0.014,0.1v0.039h0.017v-0.007l0.01-0.011l0.011,0.005v0.008
		l0.032,0.016l0.014,0.024v0.005l0.006,0.015l0.029,0.041L2.78,22.123l-0.047,0.061l0.042,0.047l-0.011,0.055l-0.015,0.011
		l0.006-0.01l-0.006-0.01l-0.015,0.015l-0.028-0.018v0.083l-0.018,0.036l0.027,0.094l-0.024,0.047l0.022,0.048l0,0l-0.14-0.013
		l-0.106,0.039l-0.051,0.077L2.36,22.731l0,0l-0.011-0.005L2.34,22.704h0.007l0.009-0.038l0.007,0.006l0.007-0.045H2.361V22.62
		l-0.047,0.039l-0.008-0.016l0.013-0.014l0.01-0.09l0.015-0.04l-0.018-0.027l0.008-0.009l0.009-0.071l-0.009-0.1l-0.008,0.013v-0.01
		l0.024-0.041l0.009,0.01L2.349,22.3l0.012,0.013l0.023-0.007v-0.1l0.023-0.036l0.018-0.009v-0.017h0.009v-0.017L2.428,22.12
		l0.008-0.016l-0.007-0.011l-0.018,0.03v-0.029H2.396l0.011-0.059l-0.009,0.006v-0.033H2.38l-0.012,0.041L2.33,22.056v-0.03
		l0.028-0.039V21.98l0.018-0.079l-0.01-0.012l-0.011,0.047l-0.01-0.01l-0.01,0.007l0.01-0.029l-0.014,0.013l-0.005-0.022v-0.017
		l0.012-0.032h0.008v0.03l0.016-0.024l0.006,0.023l0.012-0.037l0.011-0.089l-0.022-0.014L2.355,21.75H2.348L2.34,21.783H2.332v0.023
		H2.325v-0.074l-0.017,0.016v-0.017l-0.007-0.016l0.013-0.035h0.007v-0.011H2.31l-0.011,0.034l-0.025,0.008H2.261l-0.025-0.03
		v-0.018L2.262,21.6l-0.01-0.007l0.009-0.021l-0.018,0.009l-0.009,0.022l-0.008-0.011l-0.018,0.029H2.195l-0.009,0.021v-0.019
		l-0.01,0.009l0.038-0.124l0.007-0.052l-0.035,0.08l-0.008-0.009v-0.031l-0.009,0.011l-0.021,0.006l-0.01-0.006v-0.016H2.126
		l-0.013-0.018l0.011-0.015h0.018H2.136l0.026-0.075l-0.009-0.006l-0.018,0.016l-0.021,0.006v-0.067h0.028l0.026,0.037l0.015-0.025
		v-0.025l-0.02-0.008v-0.033h0.014v-0.029h0.008l0.011-0.018h0.011v-0.024h0.032V21.2l-0.041-0.014l-0.006-0.019h0.012v-0.009h-0.01
		l-0.054,0.02v-0.052l-0.008-0.014l-0.016,0.013v0.016l-0.02,0.02v0.012l-0.01,0.007v0.024l-0.015-0.007l-0.01-0.018v-0.023
		l0.016,0.01l0.011-0.005L2.08,21.147H2.07l0.007-0.043L2.07,21.092l0.006-0.041l0.023-0.035l-0.015-0.008l-0.014,0.01v-0.02h0.008
		v-0.027h0.011v0.015h0.017l0.016,0.007l0.026-0.022v-0.03l0.01-0.013h0.009v-0.01l0.007,0.006l0.013-0.045v-0.031H2.174
		l-0.01,0.012H2.153l-0.01-0.016l-0.015-0.047v-0.052L2.12,20.736l0.019-0.011l0.006-0.011v-0.011h0.02v-0.008h0.016l0.017,0.026
		l0.018,0.008l0.008-0.009l-0.006-0.017l0.009-0.009v-0.011l0.033-0.019v-0.017h0.009v-0.08l0.014,0.006l0.007-0.005l-0.011-0.017
		h0.009v-0.01L2.282,20.53l-0.017-0.007V20.51l0.018,0.016l0.011-0.014h0.02l0.03,0.026l0.051,0.025l0.026-0.01l0.017,0.006v-0.007
		v-0.057l0.023-0.056V20.39l0.011-0.035L2.53,20.33l-0.018-0.038l0.006-0.009v-0.018h0.011v-0.011h0.01v-0.018l-0.028-0.005
		l0.015-0.014l-0.02-0.007L2.49,20.191l0.007-0.008l0.007,0.01l0.009-0.007v-0.02l0.016,0.011l0.006,0.038h0.014v-0.036
		l-0.013-0.021h-0.01l-0.02-0.037l0.007-0.023l0.033,0.006l0.009-0.013l0.013,0.016l0.008-0.01l-0.01-0.022l0.01-0.016V20.05
		l0.028-0.014l0.015,0.008l0.007,0.022l0.012-0.01v0.016h0.008v0.008h0.012v0.018l0.017,0.008l0.013-0.021l0.009,0.012v0.014h0.011
		v0.013h0.014v0.01l0.016-0.006l0.021-0.025l-0.006-0.009v-0.026h-0.02l-0.007,0.013l-0.01-0.01l0,0L2.709,20.06H2.695l-0.009-0.016
		H2.665l-0.016-0.04l0.015-0.022H2.656l-0.007,0.014H2.631v-0.031v0.006l0.019-0.014l-0.012-0.006v-0.012l-0.011,0.014H2.608
		L2.6,19.937v-0.011l-0.05-0.071L2.543,19.82H2.55l0.007-0.029h0.028v0.012l0.013-0.014h0.028l-0.049-0.022l-0.02,0.011v-0.057
		l0.007-0.039l0.007-0.019h0.016v-0.015h0.01l-0.009-0.031H2.577l0.017-0.011L2.58,19.59l0.016-0.009l-0.005-0.008l0.029-0.007
		v-0.029l0.012,0.006l-0.009,0.008v0.024l0.011,0.052L2.623,19.64l0.013,0.009h0.009v0.019l0.02,0.008l0.015,0.022h0.026
		l-0.01,0.021l0.013-0.006l0.025,0.015l0.023-0.061l0.025,0.009l0.026,0.045l0.023-0.011l-0.024-0.011l-0.005-0.045h0.006
		l0.012-0.019l0.008,0.023l0.008-0.026l0.013-0.015H2.84l0.015-0.028h0.014l0.009-0.017h0.008v0.029l0.006,0.018h0.021v-0.006
		l0.023-0.025v0.018l0.014,0.006l0.02-0.036h0.007l0.017-0.012l0.019,0.009l0.006,0.033l-0.014,0.022l0.01,0.015L3.036,19.6
		l0.02,0.041v-0.038l0.006-0.008h0.02v0.03l0.006-0.006l0.007,0.007l0.01-0.016v0.016v0.018l-0.012,0.017l-0.006-0.007L3.08,19.679
		l0.015-0.007l0.011,0.023L3.1,19.715l-0.022,0.009l-0.024-0.017l-0.019,0.005l-0.006,0.013l0.006,0.013l0.007-0.01v0.044h0.007
		v0.014H3.014l0.022,0.026v0.022h0.011l0.009,0.018l0.02,0.01l0.012-0.006l-0.015-0.039l-0.016-0.012v-0.046H3.07l0.01-0.016
		l0.025,0.008l0.009,0.012l0.009-0.011L3.112,19.72l0.009-0.016L3.104,19.69l-0.007-0.025l0.009-0.009v-0.061l0.015,0.009v0.009
		L3.142,19.6h0.026l-0.006-0.011h0.014l0.007,0.017l-0.009,0.038v0.014h0.013v-0.013l0.009,0.008v-0.019l0.025-0.007v-0.015
		l0.031,0.006l0.007-0.025l0.01-0.012h0.008V19.57l0.015,0.016l0.033,0.009l-0.009-0.052v-0.021l0.019-0.017v-0.02h0.013v-0.013
		h0.024v-0.029h0.025l0.032,0.053l0.009-0.008l0.022,0.014l0.008-0.032l-0.014-0.023V19.43H3.446L3.44,19.417l0.017,0.007v-0.013
		l0.026-0.009v-0.013l-0.059-0.114L3.403,19.27l-0.031-0.025l0.006-0.033l-0.009-0.017l-0.034-0.008v-0.016l-0.03-0.023v-0.021
		l0.021-0.032l0.008-0.02l-0.008-0.01l0.006-0.024h0.024v-0.008l0.034,0.01l0.018-0.014l0.03-0.006l0.018,0.033v-0.007l0.008,0.008
		l0.021,0.057v-0.032l0.016,0.015l0.011,0.031l0.038,0.021l-0.028-0.043L3.457,19.04l-0.039-0.075l0.007-0.015l-0.013-0.015
		l0.024-0.03v-0.017l0.016-0.017l0.008-0.039l0.013-0.015v-0.014l-0.015-0.015l0.049,0.018l0.006-0.007v0.008L3.502,18.86
		l-0.014,0.005l-0.01-0.005l-0.006,0.009L3.5,18.884L3.495,18.9l0.012,0.008l0.009,0.035v0.013v-0.014l-0.008,0.01l0,0l-0.009,0.021
		h0.027l0.011-0.016l0.008,0.025l0.009,0.011h0.007l-0.007,0.022l0.011,0.02v0.025l0.011-0.007l0.014-0.036v0.033l0.01,0.009v-0.093
		h0.01l0.028-0.07v-0.021l-0.013-0.01l-0.01-0.026h0.02v0.015h0.012l-0.013-0.042h0.008l0.006,0.024l0.025,0.006l-0.012-0.019
		v-0.034l0.025-0.026l-0.008-0.026h-0.01l-0.019,0.041l-0.021,0.013l-0.009-0.038l-0.021,0.012H3.576l0.011-0.013l-0.008-0.019
		l0.016-0.012v0.016h0.03v-0.011h0.012l0.006-0.006v-0.024l0.014,0.018l0.008-0.01l-0.007-0.013l0.025-0.007l-0.01-0.036
		l0.008-0.045h0.006l0.017-0.015l0.013-0.035h0.027v0.012l0.016,0.007l0.017-0.045L3.77,18.514l0.013-0.018v-0.065h0.016
		l0.022,0.021v0.025l-0.009-0.012L3.805,18.48l0.01,0.022L3.81,18.513l0.019-0.011v-0.023h0.012l0.015,0.029v-0.009l0.01,0.036
		l0.007-0.019h0.008l-0.012-0.05v-0.02h0.014l0.033,0.026l0.006-0.011l0.017,0.037h0.013l0.008,0.013l-0.009,0.041l0.014,0.038
		l0.009-0.039l-0.012-0.014l0.006-0.02l0.03,0.015v0.016H4.01v0.022l0.018,0.019v0.02l0.014-0.005l0.01-0.041l-0.03-0.033v-0.022
		H4.03v-0.015l-0.014-0.012v-0.017l0.008,0.011h0.01v0.017h0.014v0.009H4.032v0.011l0.015,0.019l0.009-0.007v-0.012h0.036
		l-0.006-0.045H4.1v-0.013l0.015-0.009l0.009,0.022l0.009-0.023h-0.01V18.43l-0.015-0.012l-0.033-0.007v-0.016H4.063v0.014
		l-0.022-0.03l0.01-0.009h0.033l-0.009-0.02h0.01v-0.013h0.02v0.011h0.012l0.008,0.009h0.013l-0.012-0.062l0,0l0.011,0.02v-0.023
		L4.17,18.28v0.008l0.027-0.009v-0.014l0,0l0.018,0.02L4.228,18.261z M3.951,18.253h0.012l0.024,0.036h0.011v-0.011h0.016v0.01
		h0.007V18.3l-0.015,0.04H3.994v0.015l-0.011,0.047l-0.009-0.011v-0.017H3.945h0.012V18.4v0.015l-0.023-0.006l0.012,0.021
		l-0.01,0.01l-0.049-0.022l-0.023-0.049l0.007-0.013l-0.03-0.022V18.32l0.03-0.018l0.015-0.025h0.009l0.007-0.01h0.01l0.024-0.031
		h0.007L3.951,18.253z M3.841,18.222l0.008,0.024l-0.012,0.007v0.019l-0.01-0.016V18.24h0.007L3.82,18.224v-0.015h0.014v0.011
		L3.841,18.222z M3.978,18.094l0.015-0.007v0.015h0.012l0.02-0.012l0.016,0.019l-0.007,0.009l0.028,0.041l0,0l0.017,0.04
		L4.07,18.223l0,0H4.055v0.013l-0.026-0.008l0.008,0.027v0.009l-0.024-0.017v-0.009l-0.009,0.007v0.006h-0.01L3.976,18.22v0.017
		L3.94,18.244H3.922l0.009,0.015L3.91,18.277l-0.015-0.016H3.881v-0.014H3.864v-0.018L3.85,18.211h0.006l0.008,0.007v-0.01h0.017
		l-0.01-0.023h0.013v-0.02l0.017,0.02l-0.009-0.041l0.02-0.011v0.016l0.01-0.006v-0.01l0.088,0.019v-0.015h0.016l-0.01-0.011
		L4,18.132l-0.02-0.01l-0.01-0.024h0.01v0.009L3.978,18.094z M3.936,18.043h0.023v0.045L3.95,18.08H3.942v-0.01H3.933l-0.007-0.01
		L3.936,18.043z M3.963,17.929v0.024l-0.014-0.015H3.923l0.027-0.025L3.963,17.929z"/>
	<path id="FI-13" style="fill:#FFFFFF;" d="M10.18,18.352l0.126-0.255l-0.018-0.033l0.065-0.028l0.414,0.114l0.15-0.02l0.291-0.017
		l0.261-0.048l0.184-0.023l0.047,0.331l0.06,0.109l0.109-0.113l0.11-0.021l0,0l0.1,0.123v0.013l0.013,0.022l0.039,0.036l0.019,0.032
		l0.122,0.139l0.021,0.069l0.142,0.1l0.109,0.2l0.341,0.153l0.063,0.07l0.01,0.021l0.075,0.031h0.016l0.012,0.051L13.1,19.48
		l0.044,0.01l0.071,0.054l0.016,0.024h0.022l0.014,0.016h0.014l0.028,0.05l0.076,0.057v0.057l0.009,0.032l0.01,0.009v0.092
		l0.018,0.083l0.027,0.035l0.072,0.053l0.024,0.032l0.008,0.026l0.023,0.016h0.018l0.04,0.044l-0.014,0.045l0.058,0.025l0.093,0.213
		l-0.145,0.271l0.011,0.032h-0.03l-0.065,0.228v0.037l-0.009,0.012v0.052l-0.012,0.012h-0.019v0.007l-0.14,0.342l-0.041,0.013v0.015
		l-0.013,0.014l-0.009,0.036v0.05l-0.03,0.012l-0.211,0.254l0.016,0.016l-0.008,0.035l-0.012,0.016l-0.045-0.01l-0.075,0.082
		l-0.028,0.013v0.023l-0.1,0.124h-0.07l-0.031,0.047l0.007,0.039l-0.035,0.074l-0.176,0.25v0.009l-0.075,0.079l-0.032,0.051
		l-0.046,0.031l0.006,0.049l-0.012,0.022h-0.03l-0.07,0.1h-0.018v0.021l-0.059,0.073l0,0l-0.013-0.029l-0.04,0.018l-0.6,0.316l0,0
		l0.013-0.034l0.047-0.046l0.028-0.014l0.042-0.073l0.015-0.071v-0.035l-0.024-0.039l0.032-0.024l-0.089-0.116v-0.053l0.049-0.1
		l-0.1-0.112v-0.015l-0.07-0.021l-0.054-0.1l-0.057-0.282l0.024-0.086l-0.043-0.039l-0.09-0.188l-0.018-0.136l-0.03-0.048
		l-0.042-0.024l-0.035,0.038l-0.026-0.016l-0.017,0.024l0.007,0.028L11.027,21.7l-0.014-0.009l-0.018-0.024l0.015-0.02V21.64
		l-0.028-0.027l0.026-0.131l0.014-0.022v-0.006l-0.008-0.007h-0.029l-0.047-0.038l-0.038-0.01v-0.006l0.037-0.009l-0.006-0.028
		l-0.027-0.012l-0.073-0.072v-0.028l-0.09-0.056l-0.081-0.1l-0.127-0.021l-0.024-0.02l0,0v-0.029l0.05-0.058l-0.065-0.089
		l-0.01-0.033l0.011-0.038l0.042-0.041l0.041,0.006l0.034-0.061v-0.033l0.033-0.039l0.018-0.013l0.034,0.023h0.01v0.013l0.02,0.029
		l0.058,0.031v0.018l0.032,0.037h0.012l0.029-0.071l0.144-0.1l-0.027-0.027l-0.021-0.014l-0.008,0.006l-0.021-0.026l-0.019-0.007
		l-0.006-0.01l0.007-0.011h-0.013l-0.012-0.034l-0.024-0.017V20.44l-0.032-0.022l-0.008-0.042V20.37l0.031,0.034h0.02l0.017-0.009
		l-0.013-0.025h0.01v-0.01l-0.032-0.011l0.016-0.022v-0.01l0.105-0.063l-0.033-0.064l-0.128-0.064l-0.012-0.02h-0.016l-0.011-0.016
		l-0.023-0.011l-0.02-0.026v-0.021l-0.026-0.049l-0.134-0.185l-0.011-0.032l-0.049-0.034l-0.047-0.017l0.054-0.091l-0.013-0.017
		v-0.008l0.029-0.014l0.005-0.022l0.029-0.017v-0.03h-0.018l0.031-0.021l-0.007-0.014l0.031-0.031l0.025-0.187l-0.06-0.06h-0.013
		l-0.031-0.031l-0.017-0.111l-0.051-0.147l0.01-0.033l0.023-0.018l0.018,0.017l0.036-0.031l-0.015-0.018l-0.014,0.005l-0.036-0.017
		l-0.012-0.01v-0.015h-0.012l-0.022-0.208l0.008-0.027l0.014-0.013l-0.022-0.032l-0.039-0.032l-0.03,0.01h-0.037V18.5l-0.029-0.012
		l-0.04-0.068L10.18,18.352z"/>
	<path id="FI-14" style="fill:#FFFFFF;" d="M5.942,15.051l0.029-0.012l0.019-0.044l0.061-0.061h0.042l0.027-0.012l0.055,0.01
		l0.081-0.035l0.026,0.008l0.031,0.03l0.013,0.028H6.314v0.029h0.018v-0.01l0.021,0.009l0.034-0.006l0.033,0.016h0.049l0.018,0.008
		l0.016,0.025l-0.01,0.014l-0.032,0.018H6.413l-0.025-0.01H6.351v-0.019H6.344L6.329,15.02H6.284l-0.022-0.016l-0.028,0.013H6.216
		l-0.007,0.017h0.006l0.037-0.017L6.239,15.04v0.022l0.007,0.005l0.014-0.01l0.006,0.031h0.017l0.01,0.025H6.3v0.009l0.01,0.019
		l-0.012,0.032l0.006,0.054H6.291l-0.005,0.012l-0.008-0.005l-0.033,0.034l-0.031,0.011v0.012H6.207l-0.024-0.01l0,0L6.168,15.27
		v-0.008L6.141,15.25v-0.024l-0.008-0.013h0.02l-0.005-0.021l-0.012,0.009H6.095v0.008l0.028,0.011l-0.028,0.014H6.047l-0.009,0.007
		l-0.021-0.015L5.996,15.2l0.006-0.014l-0.005-0.013L5.966,15.15l-0.014-0.023v-0.011h0.01l-0.017-0.021l0.008-0.012l-0.016-0.012
		v-0.03L5.942,15.051z M6.718,14.272l-0.011-0.005l-0.006,0.009v0.006L6.718,14.272z M12.034,12.559l-0.03-0.033l-0.006-0.117
		l-0.008-0.006l-0.013-0.043l0.006-0.009l-0.01-0.008l-0.048-0.219l-0.241-0.39h-0.015l0.01-0.015l-0.009-0.01l-0.069-0.232
		l-0.07-0.2l0,0h-0.349v0.066l-0.623-0.216l-0.033,0.2h-0.053l-0.017,0.01l-0.014-0.012l0.008,0.04h-0.016l-0.01,0.05h-0.028v0.081
		l0.006,0.023l-0.008,0.033h-0.008l0.058,0.175l0.014,0.01h0.04l0.006,0.039l0.018,0.011l0.026-0.039h0.03l0.039,0.016l-0.007,0.023
		l0.019,0.024v0.033l-0.022,0.017l0.014,0.014L10.606,11.9l0.037,0.059l-0.058,0.065v0.069l-0.03,0.024h-0.09l0.022,0.1l0.019,0.014
		l-0.015,0.039l0.072,0.026l0.01,0.023h0.017v0.023l0.026-0.018l0.021,0.019l-0.03,0.017v0.012l0.007,0.02l0.011,0.006v0.06h-0.079
		l-0.025,0.036h-0.06v-0.032l-0.056-0.026v0.015h-0.066v0.036l-0.076,0.008v-0.032l0.022-0.025h-0.012l-0.009,0.01l-0.046,0.009
		v0.009l0.019,0.007v0.04h0.01l-0.008,0.03l-0.019-0.007l-0.007,0.008v0.021l0.067,0.039l0.03,0.077l0.053,0.073l0.007,0.122h-0.055
		l-0.267,0.119l-0.065-0.017l-0.022-0.019L9.702,12.94l-0.007-0.021H9.681v0.006h0.013v0.01L9.54,12.923l0.006-0.027l0.029,0.006
		v-0.015l0.013-0.009v-0.014H9.577l-0.012,0.027l-0.021-0.008l-0.081-0.161L9.419,12.56l-0.038,0.008l-0.006,0.009h-0.02L9.33,12.61
		l-0.013,0.037L9.29,12.656l-0.105-0.1L9.149,12.61l0.008,0.015H9.15l-0.01-0.007l-0.169,0.259H8.963l-0.011,0.007l0.007,0.015
		l-0.013,0.019L8.94,12.912v0.017l-0.068,0.107v0.012l0.011,0.006l-0.015,0.008l-0.007-0.011l-0.05,0.078l-0.021,0.01l0.009,0.017
		l-0.03,0.031l-0.042-0.023l-0.031,0.015v0.028h0.015v0.022H8.67v-0.041l-0.153,0.07h-0.02l-0.149-0.02v0.007v-0.008l-0.007,0.025
		l-0.022-0.027l-0.305-0.01l-0.248-0.03v-0.263l-0.408,0.22l-0.413-0.005l-0.07,0.04L6.818,13.3L6.73,13.347l-0.035,0.04H6.687
		l-0.068,0.129l-0.05,0.051l0,0v0.038l0.007,0.019l0.007-0.015h0.016v0.014h0.007v0.01H6.6v0.04l0.01-0.014v-0.014l0.007-0.005
		v0.014v-0.02l0.014-0.015l0.013,0.005v0.013h0.007l0.017-0.058l-0.007,0.048l0.018,0.016l0.02-0.008l0.012,0.01l-0.015,0.009
		l0.008,0.009v0.025l0.026,0.023h0.006l0.02,0.044l0.014,0.011L6.763,13.78l0.026,0.022l-0.006,0.021H6.79l0.011,0.033v-0.009h0.018
		v0.008v-0.007l0.015,0.019l-0.021,0.006l0.013,0.018L6.82,13.899l0.02,0.011l0.011,0.023v0.005H6.824v0.017H6.817l0.03,0.012v0.01
		H6.823l0.03,0.018l0.008,0.025v0.009H6.814l-0.011,0.013l0.006,0.007l-0.008,0.019H6.79l-0.016,0.02H6.763l-0.009-0.022H6.729
		v0.022l0.012,0.018l-0.005,0.01l0.018,0.007l0.013,0.018v0.007L6.743,14.14l0.01,0.018H6.736v0.012l0.035-0.009l0.026,0.005
		l0.023,0.023v0.006l0.007,0.02l-0.033,0.007L6.771,14.21l-0.013,0.006l0.011,0.036v0.006L6.756,14.25v0.007H6.714l0.008,0.038
		l-0.011,0.008l0.047,0.025H6.752l0.012,0.02l0.028,0.023v0.011l-0.01,0.008L6.76,14.381l0.006,0.02l-0.011,0.012H6.738v0.014v0.009
		l0.018-0.008h0.033l0.02,0.014v0.014H6.783v0.023l-0.02,0.014l0.033,0.006v0.012h0.015l0.011,0.022H6.81L6.802,14.54l-0.048-0.022
		h-0.01v0.01h0.007v0.035l-0.024-0.009l-0.008,0.01l0.015,0.018H6.75l0.012,0.012l-0.011,0.005v0.028L6.734,14.62v0.007v0.014h0.01
		v0.008l0.014,0.015l-0.005,0.023l-0.031,0.015l-0.017-0.013l-0.007,0.01l0.013,0.088l-0.012,0.026l0.01,0.006l0.008-0.006
		l0.061,0.009v0.009h0.017l0.029,0.039h0.029l0.021,0.019L6.863,14.91l0.012,0.009l0.008,0.046l0.021,0.024H6.89v0.01l0.012,0.006
		v0.018l0.015,0.018H6.911v0.024v0.052h-0.02v0.016l0.023,0.036l0.029,0.008L6.95,15.19l0.042,0.024v0.051l-0.029,0.022H6.938
		l-0.01,0.009l-0.017-0.01l-0.009-0.029H6.881l-0.039-0.029L6.83,15.196L6.814,15.18H6.803l-0.046-0.021v0.009L6.744,15.16v-0.017
		H6.719l-0.044-0.02l0.032,0.031l-0.013,0.012v0.032l-0.021,0.006l0.023,0.07l0.064,0.053l0.018,0.028l0.053,0.038l0.027-0.016
		l0.022,0.007v0.014H6.873l0.016,0.006v0.008l0.012,0.007H6.895l0.007,0.015H6.89l0.012,0.007v0.007l-0.024,0.007l-0.016,0.039
		v0.018H6.847v0.022l-0.012,0.007l0.019,0.013H6.83v0.017H6.815v0.016H6.77H6.764l0,0H6.748l0.023-0.008v-0.013l-0.016-0.038h0.021
		v-0.007l-0.044-0.044l-0.018,0.006l0.007,0.011H6.715l-0.012-0.015l-0.016,0.005l-0.01-0.026l-0.016-0.01v-0.021h0.012
		l-0.006-0.026l-0.033-0.015l-0.02,0.034l-0.025-0.031V15.35h-0.02l-0.022-0.013l-0.006,0.019l-0.022,0.01l-0.041,0.008
		l-0.029-0.012l-0.044,0.019v0.038l-0.013,0.014H6.384v0.01H6.358l-0.032-0.007l-0.007-0.009l-0.044,0.01l-0.038-0.019l0.007,0.007
		l-0.023,0.018v0.013L6.15,15.468l-0.012,0.028l0.02,0.026v0.024H6.152l-0.007-0.022l-0.021-0.012L6.11,15.519L6.102,15.54H6.09
		l-0.008,0.045l0.008,0.026L6.08,15.62v0.022l-0.022-0.014v-0.04l0.007-0.007l-0.01-0.02l-0.036,0.008l-0.009,0.01l-0.072,0.029
		l0.01,0.038l0.02,0.005l0.013,0.031v0.036H5.973l-0.02-0.007l0.011,0.019l-0.007,0.019l0.008,0.005v0.014l-0.008,0.007l0.018,0.015
		v0.033v-0.008l-0.018,0.038H5.944l-0.008,0.016h-0.01l-0.013-0.008l-0.019,0.013h0.014v0.01h-0.02l0.008,0.013l-0.021,0.009v-0.009
		H5.86l-0.01,0.018H5.828l-0.005,0.007h0.014l0,0l0.009,0.009v-0.014h0.011v0.024l-0.068,0.042V16h0.015l-0.017,0.005v0.04
		l-0.022,0.02h0.006l0.015-0.015l0.03-0.007l-0.037,0.04L5.763,16.11v0.011l-0.026,0.016h0.025v0.008l-0.01-0.006v0.008h0.014
		l-0.019,0.01l0.02,0.016v0.019l-0.016,0.006v0.026H5.738l0.018,0.017l0.006,0.02l-0.01,0.026l-0.016,0.009v0.016l-0.017-0.005
		L5.714,16.32H5.688v0.01H5.68H5.662l-0.015-0.032l-0.01,0.009L5.616,16.3v0.016l0.017,0.016v0.022l0.015,0.009h0.007v0.021
		l0.018,0.013l-0.03,0.01v-0.021L5.628,16.38l0.006,0.008H5.623l0.012,0.01H5.601l0.007,0.007L5.6,16.428v0.01h0.018v0.036
		l0.03,0.01l0.006,0.026l-0.061-0.025l-0.008-0.048L5.57,16.424v0.011H5.562l0.01,0.014l-0.005,0.013H5.559v0.011l-0.01,0.006H5.541
		l0.013,0.008L5.549,16.5H5.517l-0.009,0.015l-0.015-0.006v0.015H5.479l-0.007,0.028l-0.013-0.01H5.448l-0.007-0.008H5.434
		l0.021,0.017l-0.012,0.007l0.015,0.017v0.017l-0.04,0.023l-0.037-0.009v0.023l0.009,0.007l-0.021-0.01l0.006,0.021H5.364
		l0.006,0.008H5.348v0.039l-0.006,0.013H5.334l0.005,0.015H5.331l0.008,0.015h-0.01l0.012,0.007v0.007l-0.01,0.014l-0.027,0.007
		L5.29,16.793H5.274l-0.01,0.019v0.112l-0.015,0.008H5.197l0,0l-0.023-0.02v0.013l0,0v0.006L5.159,16.98l0.015,0.022h-0.01
		L5.147,16.97l0.005,0.015l-0.011,0.024l0.016-0.008l0.009,0.02L5.15,17.026l-0.018,0.039H5.118L5.109,17.08H5.096l-0.027,0.025
		H5.04l-0.009-0.009v0.006H5.014l-0.007-0.011v0.013l-0.009-0.011v0.009l-0.013,0.009l0.024,0.007l-0.012,0.007v0.019l0.031,0.045
		l-0.013,0.024H5v0.012l-0.009-0.006l0.007,0.017l0,0l0.051,0.036h0.019l0.007,0.018L5.1,17.303v0.017h0.007l-0.015,0.015v0.016
		h0.006l0.018-0.032l0.048,0.039h0.007v0.053l0.018,0.008v-0.008v0.017l0.032,0.015l0,0l0.044,0.026l-0.006,0.02l0.065,0.015
		l0.091,0.054l0.016,0.166l0.086,0.166l0.046,0.017l0.009,0.04H5.566l0.012,0.014l0.02,0.085l0.04,0.036l0.157,0.06l0.15,0.373
		l0.065,0.1l0.147,0.093l0.073,0.063l0.111,0.161l0.131,0.162l0,0l0.052-0.023l0.034-0.083l0.031-0.184l0.123-0.1l0.263,0.2
		l0.057,0.021l0.182,0.01l0.073,0.03l0.048,0.034l0.095,0.135l0.113,0.027l0.159-0.071l0,0l0.055-0.106l0.008-0.17l0.108-0.057
		l-0.028-0.262v-0.009H7.828v-0.01h0.015l-0.007-0.107l-0.021-0.148l-0.024-0.066l0.138-0.118v-0.006L7.921,18.02l0.029-0.013h0.007
		l0.165-0.146l0.006-0.009l-0.009-0.031l0.023-0.005l0.011,0.017l0.034-0.031l-0.014-0.025l0.023,0.017l0.085-0.048v-0.013H8.269
		l0.009-0.01L8.3,17.741l0.215-0.1l0,0l-0.1-0.054l-0.013-0.525l-0.061-0.01l-0.028-0.021v-0.09l-0.01-0.013l-0.141-0.013
		l-0.1-0.087v-0.027l0.022-0.026l-0.017-0.02H8.08l-0.008-0.021h0.014l-0.005-0.025l0.006-0.006h-0.01l-0.013,0.012l-0.038-0.083
		l-0.149-0.13l0.04-0.049l-0.01-0.019l0.029-0.006l-0.007-0.024l0.023,0.011l0.114-0.135l0.1,0.016l-0.017-0.042l0.026-0.012
		l0.009,0.019L8.2,16.268v-0.049l0.028-0.06h0.009l0.049-0.039v-0.035l-0.02-0.018l0.02-0.019l0.011,0.015h0.012l0.014-0.012v0.005
		v0.01l-0.022,0.013l0.028,0.035l0.01-0.008l-0.017-0.012l0.027-0.015v-0.01l0.026-0.012l0.009,0.015l0.111-0.046l0.023-0.072
		l0.03,0.011l-0.009-0.017l0.006-0.014h0.011l0.008-0.009v-0.014h-0.01v-0.038h0.011l0.005-0.02l-0.017-0.017l0.018-0.063l0.264-0.2
		l0.042,0.009L8.898,15.6l0.012-0.008l0.156,0.042l0.025-0.049H9.084l-0.009-0.029l0.035-0.009l0.01-0.024l-0.036-0.091l0.038-0.1
		V15.31l-0.023-0.016l0.017-0.013l-0.009-0.032l-0.056-0.075H9.036l-0.005-0.02L8.97,15.071l0.073-0.167l0.138,0.009l0.13-0.018
		l0.038-0.026v-0.008h0.014l0.058-0.038l0.04,0.013h0.013l0.069,0.03l0.017-0.029v0.032l0.118,0.038l0.046-0.208h0.011l-0.009-0.008
		v-0.01l0.028,0.018v-0.007l-0.021-0.008l0.021-0.019v-0.01l-0.011,0.014h-0.01l0.009-0.065l0.1-0.091l0.257-0.057l0.015,0.016
		l0.011-0.02l0.172-0.033l0.005-0.007h0.026l0.014-0.026l0.012,0.005l-0.016,0.019h0.007v0.019h0.018l-0.014-0.008v-0.016
		l0.111-0.021l0.008-0.007l0.015-0.055l-0.007-0.011l0.026-0.111l0.03-0.048l-0.009-0.047l0.175-0.076l-0.03-0.008v-0.006h0.017
		v-0.004h0.012l0.006,0.023l0.164-0.073l0.1,0.027l0.085-0.031h0.03h-0.027l0.031-0.032l0.032,0.008l-0.012-0.013H11.09l0.033-0.035
		l0.009,0.009v-0.023l0.029-0.029v-0.067l0.117-0.007l0.2,0.086l0.025-0.009v-0.018l0.031-0.018l0.178,0.033l0.048-0.01l0,0
		l0.122-0.17L11.76,13.53l-0.036-0.075l0.326-0.159l0.031,0.026l0.107,0.048v-0.072l-0.076-0.55L12.034,12.559z"/>
	<path id="FI-15" style="fill:#FFFFFF;" d="M10.085,18.566l-0.02-0.015l-0.051-0.079l-0.046-0.02L9.94,18.426l-0.093-0.141
		l-0.163-0.174H9.66l-0.119-0.1l-0.045-0.05l-0.089-0.038l-0.018,0.009l-0.122-0.1l-0.066-0.01l0.017,0.027l-0.053,0.013
		l-0.021-0.009l-0.007-0.019H9.128l0.008-0.018l-0.007-0.007l-0.178-0.061L8.813,17.73l-0.014,0.015l-0.008-0.007l-0.107-0.012
		l-0.165-0.095l0,0l-0.215,0.1l-0.022-0.018l-0.009,0.009h0.013v0.013L8.2,17.783l-0.023-0.017l0.014,0.025l-0.033,0.032
		l-0.011-0.017l-0.023,0.005l0.009,0.031l-0.006,0.009l-0.165,0.146H7.955L7.926,18.01l0.008,0.011v0.006l-0.138,0.118l0.024,0.066
		l0.021,0.148l0.007,0.107H7.833v0.01H7.85v0.009l0.027,0.262l-0.109,0.057l-0.007,0.17L7.706,19.08l0,0l0.042,0.046l-0.061,0.083
		l-0.055,0.126h0.016l0.048,0.058l0.014,0.136l0.038,0.088l0.008,0.066l-0.04,0.009l0.02,0.154l0.026,0.031v0.034l0.018,0.017
		l0.017,0.058l0.027,0.032v0.01l0.068,0.057l0.028,0.013l0.005,0.018l-0.107-0.021L7.8,20.139l0.076,0.107h0.046l-0.014,0.045
		l-0.052,0.1L7.849,20.37l-0.046-0.049L7.69,20.328l-0.045,0.033l-0.048,0.013l0.031,0.107l0.109,0.044l0.019,0.017v0.016
		l-0.032,0.031l0.037,0.05l0.024-0.013l0.016,0.013l0.148,0.19l0.049-0.044L7.989,20.76l0.058-0.032l0.032,0.044l0.037,0.067
		l0.026,0.084l0.039,0.061v0.072l0.024,0.032l0.009,0.069L8.19,21.178l0.025,0.033l-0.031,0.005l-0.015,0.053l-0.034,0.057
		l-0.021,0.023h-0.03l-0.012,0.033l0.117,0.1l0.063,0.066l0.057-0.028l0.027,0.024l0,0l0.06,0.07l0.078-0.044l-0.018-0.032
		l0.018-0.03h0.014l0.014-0.02h0.015l0.009-0.014l0.008,0.023l0.024,0.027l0.031,0.019l0.021,0.034l0.018-0.037l-0.025-0.046
		l0.02-0.026h0.006l0.028,0.046h0.072l0.022-0.04l0.025,0.007v-0.025l0.014-0.008L8.785,21.43l0.056,0.059l0.011,0.041l-0.007,0.014
		l0.029,0.007v-0.033l0.022-0.019l0.008,0.009l-0.015,0.019v0.011h0.013l0.01-0.04h0.026v0.013l0.018,0.025h0.009l0.01,0.007
		l0.02-0.056l0.039-0.027h0.023l-0.01-0.027h0.017l0.006-0.02l-0.028-0.017l0.019-0.018l0.023,0.032v0.027h0.024l0.009,0.02
		l0.036,0.029h0.01l0.008,0.029l0.116,0.053l0.067,0.073l0.026,0.047l0.034,0.026l0.009,0.034l0.075,0.018l0.01,0.016l-0.039,0.045
		l0.005,0.041l0.053,0.029l0.032,0.04l0.018,0.039h0.012l0.018-0.021l0.041,0.017v-0.027l0.028,0.019l0.052,0.062v-0.014L9.855,22.1
		h0.127l0.069-0.021h0.026l0.074,0.042l0.063,0.01l0.148,0.069h0.069l0.037-0.042l0.016-0.032v-0.053l-0.17-0.212l-0.1,0.058
		l-0.021-0.01l0.005-0.069l-0.016-0.053l-0.062-0.008l0,0v-0.023l0,0l0.012-0.022l-0.009-0.014l-0.042-0.024v-0.049l-0.042-0.025
		l0.007-0.02l0.038-0.026h0.013v-0.027l-0.024-0.024l0.015-0.029l0.017,0.013l0.022-0.005l0.036,0.028h0.028l0.01-0.056
		l-0.027-0.042l0.008-0.017h0.013l0.016-0.056h0.008l-0.011-0.018v-0.034l0.016-0.029l-0.012-0.017l0.037,0.014l0.012-0.005
		l0.023,0.016l0.009-0.006l0.051,0.043l0.026-0.048l-0.037-0.022h-0.017L10.29,21.24v-0.006l0.008-0.006l0.063,0.009l0.027-0.029
		l0.02,0.039l0.028-0.019l0.023-0.029v-0.014l-0.03-0.011l0.039-0.026l0.014-0.071l0.01-0.017l0,0v-0.029l0.05-0.058l-0.065-0.089
		l-0.01-0.033l0.011-0.038l0.042-0.041l0.041,0.006l0.034-0.061v-0.033l0.033-0.039l0.018-0.013l0.034,0.023h0.01v0.013l0.02,0.029
		l0.058,0.031v0.018l0.032,0.037h0.012l0.029-0.071l0.144-0.1l-0.028-0.027l-0.021-0.014l-0.008,0.006l-0.021-0.026l-0.02-0.007
		l-0.006-0.01l0.007-0.011h-0.013l-0.012-0.034l-0.024-0.017v-0.019l-0.032-0.022l-0.008-0.042v-0.006l0.031,0.034h0.02l0.017-0.009
		l-0.013-0.025h0.01v-0.01l-0.032-0.011l0.016-0.022v-0.01l0.105-0.063l-0.033-0.064l-0.128-0.064l-0.012-0.02h-0.016l-0.011-0.016
		l-0.023-0.011l-0.02-0.026v-0.021l-0.026-0.049l-0.134-0.185l-0.011-0.032l-0.049-0.034l-0.047-0.017l0.054-0.091l-0.013-0.017
		v-0.008l0.029-0.014l0.005-0.022l0.029-0.017v-0.03h-0.018l0.031-0.021l-0.007-0.014l0.031-0.031l0.025-0.187l-0.06-0.06h-0.013
		l-0.031-0.031l-0.017-0.111l-0.051-0.147l0.01-0.033l0.023-0.018l0.018,0.017l0.036-0.031l-0.015-0.018l-0.014,0.005l-0.036-0.017
		l-0.012-0.01v-0.015h-0.012l-0.022-0.208l0.008-0.027l0.014-0.013l-0.022-0.032l-0.039-0.032l-0.03,0.01h-0.037v0.008l-0.029-0.012
		l-0.04-0.068l-0.073-0.075L10.085,18.566z"/>
	<path id="FI-16" style="fill:#FFFFFF;" d="M6.407,23.93l0.119-0.04v-0.022l0.02,0.012h0.03l0.028,0.029l0.045-0.044l0.077,0.013
		l0.024-0.016l0.179-0.016l0.078-0.336L7,23.405h0.03l0.008-0.009l0.037,0.015l0.047-0.016l0.03-0.03l0.035-0.055l0.01,0.006
		l0.012-0.009v0.009l0.014,0.006v-0.007l0.048,0.024l0.009,0.013H7.27l-0.006,0.016l0.029,0.015l0.071-0.2l0.023-0.02l-0.009-0.05
		l0.039,0.016l-0.025,0.076l0.052-0.009l0.017-0.055l0.006,0.018l0.062,0.051v0.027l0.016,0.022v0.018l0.024,0.022l0.034-0.008
		l0.009-0.032l0.015,0.027v0.025l0.008,0.015h0.011v0.1l0.017,0.038l0.032,0.028l0.024,0.045l0.03,0.005l0.025-0.071l0.04,0.02
		h0.044l0.019-0.034l0.007,0.016l0.012-0.009l0.015,0.033l-0.014,0.03l0.016,0.041l0,0v0.082l-0.016-0.013H7.85h0.013l0.014,0.025
		v0.037l0.006,0.017h0.012l-0.008,0.045l-0.026,0.007l0.009,0.035h0.038v-0.041h0.008l0.007,0.034l0.017-0.005l0.008,0.016H7.854
		v0.014l0.024,0.041l0.009,0.035l-0.056,0.047l0.024,0.048l0.061,0.065l-0.039,0.058v0.013l0.035-0.007v0.03l0.075,0.076
		l0.018,0.007l0,0l0.008-0.008l0.065,0.006l0,0h0.015l0.016,0.034l0.007,0.063l0.009,0.019l0,0l-0.036,0.018l-0.036,0.007
		l-0.02,0.021v0.038l-0.034,0.047l-0.016,0.063L7.935,24.58l-0.082,0.019v0.032l-0.036,0.042l-0.075-0.018h-0.02l-0.013-0.041
		L7.67,24.648l-0.064,0.1l0.068,0.043v0.066l0.016,0.006l0.016,0.042l-0.014,0.047v0.051l-0.046,0.031l-0.015-0.012v-0.017H7.615
		l-0.007,0.023l0.02,0.08l0.123,0.019L7.7,25.2l-0.011,0.032l0.008,0.03l-0.032,0.021l-0.017,0.056l0.022,0.015l0.029-0.006
		l0.047,0.027l0.039,0.039l0.01,0.1l0,0l0.016,0.063l-0.01,0.03l-0.08,0.058l-0.037,0.01l-0.091-0.068l-0.1,0.024l-0.022-0.069
		l-0.063,0.01L7.38,25.61l-0.062,0.012H7.282l-0.028-0.023l-0.029,0.024l-0.02,0.037H7.169v0.028H7.132v0.015l-0.1-0.074l0,0
		l0.006-0.038l-0.011-0.053l-0.046-0.111l-0.043-0.006l-0.061,0.1L6.81,25.504l-0.024,0.02L6.77,25.466l-0.07-0.06l-0.015-0.084
		H6.673l-0.009,0.012v0.037l-0.086,0.007l0,0v-0.017H6.562l-0.014-0.039l0.014-0.054h0.021l-0.017-0.123l-0.052-0.021H6.471
		l0.018-0.074l0.016-0.015l0.023-0.046l-0.01-0.006v-0.017H6.487v-0.014l0.02-0.02v-0.02H6.499l-0.014-0.025l0.035-0.044l0.1-0.031
		l0.019-0.024l0.033-0.006l0.02-0.065l0.047-0.054l-0.016-0.01l-0.007-0.019l0.036-0.036l-0.021-0.045l0.01-0.024l-0.006-0.021
		l-0.021-0.009l-0.012-0.027l-0.029-0.026v-0.041H6.651l-0.084-0.033l-0.086,0.019l-0.018-0.1l-0.028,0.028l-0.02,0.008
		l-0.023-0.035L6.37,24.31H6.363l-0.014-0.1l0,0l-0.026-0.07l0.017-0.074l-0.011-0.014l0.018-0.054l-0.043-0.051v-0.015l-0.013-0.02
		l-0.013-0.058L6.407,23.93z"/>
	<path id="FI-17" style="fill:#FFFFFF;" d="M2.446,24.477l0.034,0.016l0.022,0.024H2.463l-0.032-0.041L2.446,24.477z M2.389,24.469
		h0.033l0.009,0.013H2.42l-0.012,0.022l0.03,0.033L2.43,24.551l-0.023-0.006v0.014H2.392v0.009l-0.03-0.012l0.009-0.023
		l-0.018-0.014l0.021-0.018h0.017L2.389,24.469z M2.494,24.396h0.012l0.026-0.007l0.01,0.019H2.56l0.009,0.01l-0.015,0.015h0.009
		v0.017H2.538L2.52,24.429H2.496l0.008-0.009H2.493v-0.007l-0.037,0.005V24.41h0.011l-0.015-0.013l0.021-0.013L2.494,24.396z
		 M2.52,23.476h0.031v0.014h0.015v0.023l-0.02-0.01l0.006,0.021H2.54l-0.036-0.011v0.01H2.495l-0.04-0.026v0.018l0.034,0.028v0.026
		v-0.014l-0.016-0.007l-0.021-0.027v-0.019l-0.007,0.007l-0.011-0.01l-0.007,0.01l-0.011-0.013l0.005-0.013h0.018v-0.008h0.012
		l0.045,0.032h0.022v-0.011L2.51,23.488h0.013l-0.01-0.015L2.52,23.476z M4.02,21.992v0.014l0.007,0.017H4.021l-0.007,0.042
		l0.024,0.009v0.027l0.015,0.06l-0.014,0.025l0.023,0.04l-0.029,0.018h-0.02l-0.051,0.022l-0.007,0.032h0.006l0.011,0.2l-0.067,0.02
		h-0.04l-0.09,0.04l-0.076,0.1l0.042,0.032h0.013l0.019,0.008v0.009L3.76,22.715v0.018h0.018l0.008-0.024v-0.023l0.016,0.011v0.031
		l0.039,0.007v0.023L3.83,22.767l0.022,0.051l0.022,0.021l0.016,0.006l0.019-0.006v0.009l0.022,0.016l-0.005,0.049l0.054,0.021
		l0.005,0.024l-0.056,0.025l0.012,0.027l0.022-0.014l0.031,0.051l0.037,0.04l-0.006,0.04L4.01,23.135l0.007,0.013l-0.013,0.048
		l-0.02,0.035H3.975l-0.014-0.01L3.92,23.239v0.016L3.904,23.27H3.89v0.016l-0.025,0.017l0.013,0.052v0.01L3.834,23.37l0.007,0.032
		l0.027,0.027l-0.032,0.009l0.007,0.03l0.035-0.015v-0.032h0.011v0.011h0.014v0.04l0.015,0.024l-0.024,0.049l-0.047-0.041l0.039,0.1
		l-0.058,0.039v0.049l-0.044,0.046l-0.022-0.012l0.082,0.163l0.015,0.012l0.008-0.011h0.05l0.007,0.035l-0.017,0.006l0.02,0.06
		l-0.072,0.017l0.012,0.05l-0.014-0.025H3.847v0.024L3.832,24.05l-0.007,0.009H3.81l0.01,0.025v0.014l-0.069-0.021v-0.006
		l-0.016,0.007l0.036,0.12l-0.036,0.027l0.044,0.051v0.016h0.01l0.03,0.073l-0.007,0.014l0.014,0.017v0.018h0.063l0.006,0.012
		v-0.013H3.96l0.084,0.038l0.013-0.014l0.087,0.071l0.051,0.031h0.074l0.009,0.029l0.016-0.007l-0.012-0.03h0.053l0.07-0.035
		l0.07,0.01l0.012-0.034l0.035,0.007l-0.014,0.087l0.047,0.033l0.088,0.087l-0.124,0.048l-0.007-0.016H4.486v0.021H4.478l0.009,0.01
		h0.018v0.038h0.012v0.01h0.025l-0.018,0.011l-0.046,0.007v0.012l0.04,0.006l0.005,0.012h0.015l-0.03,0.087l0,0l0.009,0.014v0.016
		H4.462l-0.019,0.014l0,0l-0.036-0.009l-0.02-0.058L4.34,24.901l-0.058,0.006l-0.044,0.021l-0.05-0.047H4.15l-0.01-0.026
		l-0.014,0.006L4.09,24.845l-0.016-0.058l-0.049-0.039l-0.009,0.051l0.024,0.025v0.011l-0.015,0.026v0.017l-0.021,0.008l0.017,0.031
		v0.028l-0.015,0.049l-0.021-0.013l-0.014,0.013l-0.077,0.03l-0.016-0.022l-0.132-0.009l-0.068,0.029l0.066,0.084l-0.066-0.025
		L3.56,25.114l-0.058,0.008L3.45,25.161l-0.048-0.014l-0.01-0.012l-0.034,0.014l-0.049,0.044l0.032,0.015v0.009l-0.034-0.015
		l-0.022,0.045l-0.014,0.006l-0.034-0.068l-0.128,0.007l-0.013,0.009l-0.017-0.009l0.012-0.025L3.036,25.09v-0.076h-0.03
		l-0.022-0.02L2.96,25.002l-0.045-0.057L2.872,24.92l0.022-0.032H2.841l-0.025,0.018l0.014,0.03l-0.033,0.027l-0.022,0.008
		l-0.02-0.014l-0.037,0.02l-0.011-0.01l-0.005-0.039l-0.024-0.075l-0.013,0.021v0.017l0,0l-0.019,0.01l-0.029,0.047l0,0L2.6,24.958
		l-0.066,0.005l0,0l-0.06-0.049l-0.02-0.056v-0.026l0.016-0.021H2.452l-0.009-0.019l-0.039-0.014l-0.011-0.022l0.022-0.007
		l0.025,0.022l0.029-0.013l0.022,0.013l0.02-0.017l-0.029-0.013l0.01-0.019H2.48v-0.018l0.015-0.009H2.49l0.005-0.013H2.476
		l-0.018-0.018l0.047-0.015v-0.024l-0.034-0.033l0.009-0.015l0.014,0.008l0.008-0.007l-0.014-0.012v-0.018l0.007-0.008l-0.016-0.013
		h0.04V24.52h0.014l0.04,0.025h0.005l-0.037-0.033v-0.014l0.013-0.011l-0.013-0.013l0.021-0.005v-0.025l0.023-0.007l0.06,0.047
		l0.005-0.012l-0.018-0.016l0.031-0.009L2.624,24.43l-0.039-0.032H2.568v-0.009l-0.037-0.023l-0.009-0.028v-0.016l0.031-0.006
		l-0.005-0.032l-0.021-0.015l0.027-0.021l0.01,0.007v0.021l0.03,0.014l-0.011-0.028l-0.028-0.021l0.014-0.015h0.013v-0.011
		l0.017-0.014v-0.024l0.008-0.008l-0.029-0.007l-0.022-0.015l0.006-0.006l0.014,0.008l-0.009-0.013l0.017-0.011l-0.04-0.021V24.09
		l0.042,0.007L2.577,24.08l0.034,0.009l-0.029-0.023l-0.008-0.011l0.005-0.009l-0.028-0.028h-0.02L2.48,23.982l0.011-0.028
		l0.093,0.035l0.015-0.012v0.015h0.01l0.012-0.016l0.012,0.007V23.96l0.013-0.016l-0.027-0.023l-0.01-0.024l-0.04-0.044
		l-0.012,0.015l-0.054-0.064H2.485v-0.009l0.022-0.007l0.02,0.013l0.006-0.01h0.008l0.038,0.012l0.011,0.013h0.017l0.017,0.017
		l0.065-0.029v-0.018L2.637,23.75h0.01l0.006-0.015L2.64,23.741l-0.008-0.007H2.64v-0.006l-0.015,0.005v0.011h0.008l-0.007,0.011
		H2.6l-0.012,0.01l-0.019-0.053v-0.024l-0.01-0.024l-0.018-0.009l-0.022,0.012l0.01-0.022l-0.008-0.014h0.011v-0.009l-0.006-0.01
		L2.503,23.62v-0.023l0.059-0.011l0.016,0.04L2.6,23.632l0.026,0.024h0.007l0.008,0.007l0.011-0.01h0.007v0.018L2.7,23.698h0.07
		l-0.024-0.019l-0.013-0.024H2.712l-0.01-0.009v-0.007h0.01l-0.009-0.01H2.69l-0.006-0.012h0.015l-0.018-0.021L2.66,23.589h0.017
		l-0.021-0.018v-0.069l-0.011,0.01l-0.017-0.034v-0.012l-0.006-0.025l-0.011-0.008H2.587v-0.024h0.011l-0.029-0.023l-0.01-0.03
		l0.033,0.007l-0.015-0.01v-0.011H2.568L2.56,23.313l-0.012-0.005l-0.011-0.022h0.01l0.025,0.03l0.015-0.027v-0.012h-0.01
		l0.006-0.014h0.01l0.024,0.011L2.59,23.216l-0.031-0.02l-0.022-0.027h0.01l-0.021-0.012l-0.005,0.009l0.011,0.023l-0.029-0.011
		v-0.016l-0.025-0.007L2.47,23.138v-0.024l-0.02-0.02H2.441l0.012-0.01l0.024,0.012v-0.015l0.015,0.013l0.018-0.008l0.012,0.012
		v0.014l0.007,0.009L2.515,23.13v0.011h0.022V23.12h0.011l-0.006-0.032H2.55v-0.031l0.01-0.009l-0.027-0.016H2.524l-0.028-0.02
		h0.016l-0.017-0.023h0.017v-0.01L2.506,22.96l-0.011,0.014l-0.008-0.036h0.007v-0.014l0.008-0.007l-0.013-0.04l-0.023-0.021
		L2.45,22.822l0.013-0.036L2.42,22.757l0.022-0.035l-0.024-0.006l-0.006,0.006H2.42v0.01H2.412l-0.005-0.007l-0.013,0.028
		l-0.028-0.009v-0.011l-0.012-0.009v-0.017h-0.01L2.4,22.661l0.051-0.077l0.106-0.039l0.14,0.013l0,0h0.068l0.3,0.1l0.15-0.122
		l0.1-0.166l0.142-0.007l0.134-0.223l0.065-0.034l0.021,0.006l0.076-0.237l0.176,0.089L4.02,21.992z"/>
	<path id="FI-18" style="fill:#FFFFFF;" d="M7.384,26.605l-0.007,0.013l0.005,0.012l-0.02,0.014l-0.017-0.008H7.32v0.04l0.007,0.011
		h-0.01l-0.025-0.03H7.284v0.012L7.252,26.63H7.237L7.23,26.595L7.192,26.6l-0.011-0.011l-0.01-0.056l-0.015-0.007v-0.032
		l-0.015-0.01l0.054-0.013h0.033l0.011,0.025l0.023,0.017l0.024,0.005v0.021h0.012l0.015,0.044l0.031,0.012l0.031-0.011
		L7.384,26.605z M7.566,26.573l-0.007-0.011l0.005-0.055H7.548l-0.01-0.016l0.011-0.016H7.536l-0.008-0.013H7.513L7.478,26.43
		l-0.01,0.033h0.009l0.015,0.046H7.5v0.031l-0.024,0.007l0.008,0.028v0.012H7.475l0.008,0.032l0.018-0.013H7.53l0.019-0.024
		l0.011,0.022h0.019l0.014-0.018l-0.009-0.007L7.566,26.573z M7.187,26.646L7.176,26.64v0.008l-0.01-0.01v-0.025l-0.018-0.006v-0.01
		H7.126l-0.01-0.028l0.012-0.019L7.11,26.521l-0.031,0.03v0.09l0.017,0.037l0.032,0.026l-0.024,0.021l0.024,0.031H7.14v0.023
		l0.019-0.014V26.72h0.025v-0.024h0.014L7.191,26.67L7.187,26.646z M7.787,25.521l0.022,0.021h0.026v-0.028h0.016l0.009,0.015
		l0.026,0.015v0.041l0.033,0.039l-0.006,0.04l0.015,0.044l0.026,0.019l-0.016,0.016l0.091,0.025l0.026,0.02l0.032,0.062v0.037h0.031
		l0.031,0.008l0.005,0.041l0.025-0.069L8.21,25.82v-0.032l0.052-0.021l0.041,0.005l0.069,0.145l0.01,0.022l-0.028-0.007L8.318,25.91
		l-0.029-0.047l-0.017-0.01H8.248l-0.035,0.013L8.164,25.94l-0.006,0.046l-0.025,0.008l-0.011-0.006l-0.006,0.016L8.1,26.012
		l0.022,0.023v0.016L8.077,26.08l-0.008,0.029v0.013l-0.011,0.012l0,0H8.049l-0.006,0.034l0.013,0.012v0.01l-0.013-0.005v0.008
		l0.018,0.029v0.047H8.055v0.032l-0.011,0.008l-0.026-0.007v-0.014l-0.007-0.013H7.993l0.014,0.037l-0.006,0.009l0.012,0.053
		l-0.035-0.041v-0.01H7.951l0.006,0.025l-0.026-0.016l-0.02,0.016l0.02,0.031H7.915v0.025H7.894l-0.008-0.038l-0.02-0.036
		l0.01-0.026l-0.02-0.01l0.006-0.016l-0.04-0.04v-0.011l-0.013-0.02L7.802,26.22l0.016,0.078l0.012,0.02l-0.01,0.007l0.013,0.021
		H7.84v0.018l-0.049-0.01v-0.022H7.774l-0.007-0.01l-0.019-0.007l-0.016-0.028l0.007,0.058H7.76v0.013l-0.015,0.035l0.021-0.006
		l0.007,0.023v0.014l-0.042-0.017l-0.016-0.022l0.011-0.016l-0.013-0.016H7.696l-0.049-0.04v-0.019l-0.025-0.027l-0.013,0.008v0.018
		l-0.026-0.038L7.511,26.18l-0.013,0.015l0.008,0.017l-0.007,0.014v0.016l0.006,0.005l0.033-0.013l0.006,0.043l0.047,0.039
		l-0.026,0.041v0.019l0.03,0.015l0.043,0.073h0.024L7.67,26.48L7.663,26.5l0.008,0.011L7.643,26.54v0.008l-0.008,0.01H7.619
		l-0.021,0.009l-0.036-0.006l0.015-0.026l-0.008-0.008l-0.012-0.052l-0.01,0.006l-0.015-0.026l-0.053-0.009L7.468,26.43v-0.009
		l-0.033-0.015l-0.014-0.054l-0.032-0.027l-0.032-0.007l0.005,0.009h0.012l0.011,0.031l0.026,0.017l0.007,0.033l0.015,0.014
		l0.015,0.038L7.44,26.471h0.007l-0.059,0.041l0.02,0.039h0.007l0.016,0.015l-0.005,0.015H7.412l0.007,0.029h0.015l0.008,0.01
		l-0.009,0.014l0.03,0.022v0.021l-0.03,0.016l-0.016,0.006l-0.025-0.044l-0.009-0.024v-0.035H7.372l-0.037,0.015L7.32,26.603
		l-0.015-0.034l-0.019-0.015l-0.014-0.025l-0.017-0.006v0.017H7.248l-0.02-0.014L7.215,26.5l-0.021-0.014L7.158,26.5H7.144
		l-0.006-0.01v-0.029h0.024v-0.015l0.049,0.04l0.011-0.024l-0.029-0.014l0.015-0.024l-0.018-0.02v0.009H7.166l-0.054,0.078H7.12
		l-0.007,0.023H7.097l-0.023,0.032l-0.013-0.04H7.019l-0.007,0.009l-0.01-0.01v0.017h0.016l0.019,0.013l0.009,0.025l-0.021,0.061
		l-0.027,0.025v0.014l-0.023,0.028v0.008H6.964l-0.013-0.014l-0.033,0.009h-0.07l0.008-0.036H6.841l-0.028,0.013v0.015H6.744
		l-0.026,0.012l-0.016-0.006l-0.011,0.014H6.67l-0.007,0.026L6.638,26.72v0.059l-0.012,0.008l0.011,0.011l-0.01,0.011l-0.011,0.01
		l-0.007-0.021l-0.031,0.006L6.6,26.84l-0.063-0.038l-0.033,0.005l0.005,0.014l-0.006,0.011l-0.022-0.005l-0.033,0.022h-0.01
		l-0.009-0.04h0.027l-0.009-0.013v-0.021H6.43v0.015H6.419l-0.015,0.052l-0.028,0.015l0.01,0.032v0.019H6.355l-0.013,0.015
		l-0.021-0.016v-0.011l-0.007-0.008l0.01-0.04l-0.008-0.012l-0.007,0.007l-0.025-0.009l-0.006-0.018l-0.019-0.012v-0.012
		l-0.01-0.008l-0.005,0.014h0.004L6.235,26.81v0.019l0.023-0.006v0.006l-0.007,0.049l0.022,0.007l0.008-0.013l0.006,0.012
		l0.014-0.008h0.013v0.022H6.305l0.005,0.025H6.288v-0.018H6.27v-0.024L6.226,26.87l-0.01,0.018l-0.055,0.02l-0.006,0.014
		l-0.032-0.006l-0.04,0.018l0.032,0.019l0.015-0.011l0.008,0.02L6.113,26.97l-0.026-0.011l-0.013,0.015l0.01,0.008H6.07
		l-0.023-0.055l-0.014,0.008l-0.009-0.032l-0.011-0.01h-0.01v0.009l0.015,0.013v0.027l-0.016,0.031l0.025,0.011l0.008-0.015
		l0.011,0.034l-0.01,0.028l-0.068,0.071H5.956l-0.02,0.037H5.923l0.01,0.03l-0.021,0.017H5.888v0.026l-0.009,0.013l-0.017,0.017
		l-0.027,0.009v0.01l-0.022,0.005l-0.007,0.015H5.785l-0.024,0.031v-0.033l0.034-0.024l0.012-0.035H5.796l0.043-0.055l-0.021,0.006
		v-0.029L5.803,27.15l-0.007,0.02l-0.007-0.01l-0.01,0.038H5.753v-0.026l-0.011,0.006H5.726l-0.01,0.006v0.028l0.009,0.009
		l-0.017,0.011v-0.005l-0.013,0.005v-0.025l0.02-0.033l0.025-0.017v-0.029l0.019-0.021H5.752l0.005-0.053l-0.029-0.021H5.715
		l-0.007,0.024H5.695l0.006-0.008l-0.02-0.012v0.019H5.67l0.01,0.008l-0.006,0.011l0.023,0.011v0.01L5.68,27.104l-0.024-0.008
		L5.638,27.11v0.016l-0.021,0.006l-0.028-0.033v0.025H5.575v-0.025l-0.009,0.008l-0.008-0.012l-0.006,0.009H5.535l-0.011,0.019
		h0.012l0.007,0.015h0.016l0.011,0.017v0.012l-0.01-0.005v0.007H5.543v-0.006L5.534,27.17l-0.018-0.009V27.15l-0.038-0.008v-0.018
		h0.016v-0.015l-0.038,0.027l0.01,0.018H5.455v0.015l-0.016-0.01v-0.005H5.402l0.022,0.013v0.013l-0.01-0.009H5.357l-0.009-0.029
		V27.13l0.013-0.006H5.333l-0.017,0.017v0.017l-0.044,0.044H5.236L5.19,27.185H5.169l0.076,0.03v0.029l-0.014-0.012H5.186v-0.006
		h0.009L5.208,27.2l-0.045,0.015l-0.036,0.067l-0.026,0.016H5.077v-0.011h0.018l-0.014-0.036L5.06,27.263l-0.014-0.011H5.015
		L5.02,27.26h0.025v0.019l-0.014,0.008l0.006,0.021H5.02l-0.018-0.032h-0.01l0.009,0.016l-0.006,0.006l0.02,0.017l0.008,0.025
		l-0.027-0.009L4.979,27.35L4.96,27.357H4.918l-0.021-0.011l-0.033,0.015l-0.05-0.007l-0.089,0.029l-0.01,0.014H4.671h-0.01v-0.009
		L4.646,27.38v-0.017H4.637l0.013-0.017l0.038-0.023l0.033,0.024h0.008l-0.011-0.018v-0.017l0.087-0.048H4.764l-0.02,0.007
		l-0.009,0.025h-0.02l-0.006,0.009H4.683l0.006-0.029l-0.018-0.01l-0.009,0.009v0.013h-0.01l-0.005,0.02H4.638v0.033l-0.01,0.014
		l-0.065,0.036L4.55,27.415l-0.036,0.016l-0.006,0.011l-0.032,0.006L4.462,27.46v0.035l0.01,0.007v0.014l0.016,0.006l-0.014,0.015
		v0.013h0.009l0.008,0.017l-0.024,0.006l-0.011-0.005l-0.044,0.027l-0.016-0.016l-0.007,0.01H4.375v0.007H4.339H4.326v-0.013
		l-0.014-0.005l-0.01,0.01l0.008,0.007l-0.011,0.006l-0.025-0.009l0.007,0.018H4.273l-0.01-0.013l-0.044,0.01l-0.013-0.009
		l-0.05,0.02l-0.02-0.013l-0.031,0.03H4.091v-0.007l0.013-0.016v-0.01l0.025-0.01v-0.017H4.14v-0.02h0.015l0.008,0.01l0.011-0.006
		v-0.012l0.021,0.008l0.022-0.023l0.022-0.008v-0.013h0.024l0.007-0.012h0.016l0.01-0.025h0.028v-0.009h0.062l0.013-0.013
		L4.388,27.41v-0.014l-0.022-0.01l0.01-0.031l0.009-0.009H4.41l0.029-0.038L4.418,27.27L4.4,27.285v0.027l0.011,0.006l-0.009,0.019
		l-0.013,0.008H4.375V27.33l0.011,0.007v-0.019L4.375,27.3H4.363l-0.016,0.011v0.032l-0.011,0.013H4.321v-0.014H4.306l0.01-0.079
		l0.013-0.039l0.019-0.008l0,0l0.017-0.017l0.048-0.01l0.023-0.039H4.419l-0.013,0.009l-0.014,0.029l-0.086,0.028l-0.04,0.034
		l-0.01-0.005l-0.021,0.007l-0.006,0.017H4.25l0.028-0.013v0.013L4.265,27.29h0.008l-0.007,0.013v0.008l-0.015,0.021l0.014,0.027
		v0.033l-0.023,0.013L4.23,27.399l-0.008,0.019l-0.006-0.006v-0.02H4.203L4.19,27.366H4.17l-0.009-0.037l-0.01,0.012v0.036
		l0.017-0.006l0.007,0.011h0.011L4.2,27.419l-0.026-0.028l-0.013,0.021l-0.016-0.006v0.013l-0.018,0.013l-0.022-0.013H4.086
		L4.067,27.41l-0.022-0.03l-0.006-0.023l-0.011-0.006l0.018-0.022L4.032,27.31l-0.02-0.012H4.004l-0.008-0.031h-0.01L3.98,27.185
		l0.021-0.005v-0.009h0.012v0.016H4.002l0.008,0.014H4.001v0.032h0.015l-0.013,0.015l0.014,0.005L4.01,27.272l0.006,0.015
		l0.028-0.03h0.021l0.013,0.016l0.006-0.008L4.076,27.24l0.008-0.009h0.042v0.005L4.12,27.257h0.02l0.007,0.018h0.009v-0.01
		l0.027-0.009v-0.014l0.009-0.01h0.014v-0.026H4.192l-0.007-0.009v-0.029l-0.018-0.019l0.007-0.008l-0.012-0.005l0.011-0.017
		L4.206,27.1l0.031,0.016l0.061,0.011l0.01-0.017l0.046-0.016v-0.006l0.009-0.016l0.018-0.007l0.061,0.011l-0.027,0.042l0.01,0.009
		l0.019-0.038l0.032-0.018H4.441l-0.008-0.014H4.345h-0.02l-0.007,0.016H4.307l-0.014,0.015l0.01,0.015l-0.007,0.016l-0.029-0.01
		v-0.008l-0.02,0.008l-0.009-0.014h0.013l0.007-0.01H4.235l-0.007-0.013v-0.008l-0.016-0.013l-0.018,0.02H4.176l-0.013-0.017v-0.033
		H4.2v-0.012l-0.02-0.008l0.027-0.012l0,0l0.033,0.03l0.014-0.018v0.007l0.086-0.019l0.053-0.048v-0.024h0.022l0.054-0.04h0.017
		v-0.05L4.512,26.8h0.022l-0.011,0.034l0.053,0.049l0.018-0.037h0.04l0.055-0.015l0.1-0.068h0.037l0.029,0.019l0.04-0.023
		l-0.018-0.07l0.013-0.008l-0.067-0.047l0.01-0.013h0.02v-0.022l0.012,0.025v-0.048h0.018l0.032-0.043l0.018,0.02l0.043-0.031
		l0.008-0.069v-0.042l-0.012-0.008v-0.02h0.023l0.032-0.035h0.017v-0.045l0.009-0.051l-0.01-0.046h-0.1l0.022-0.031v-0.019
		l0.029-0.04l0.09-0.01l0.024,0.007l0.03-0.031v-0.036l-0.017-0.023l0.015-0.009v-0.011l-0.021-0.039h0.047v-0.033l0.034-0.064
		v-0.013l-0.055-0.027l0.036-0.07l0,0l0.022-0.009l0.015,0.016l0.042,0.02l0.08-0.014l0.049,0.037h0.022l0.032,0.013l0.047-0.01
		l0.067,0.023v0.041l0.056,0.076l0.021-0.01l-0.011-0.031l0.061-0.062l0.038,0.019l0.029,0.025l-0.011,0.032l0.028,0.058
		l0.138-0.016l0.031-0.058l-0.014-0.035v-0.08l0.053-0.069L6,25.742h0.02l0.039,0.024h0.053l0.03-0.026l0.014,0.011h0.019
		l0.015-0.037l0.025,0.011v0.016h0.067v-0.01h0.026V25.72h0.014v-0.01l0.111-0.025l0.021,0.014l0.009,0.022l0.055,0.017L6.49,25.654
		l0.066-0.039l0.053-0.02l-0.052-0.017l-0.03-0.034l-0.059,0.072L6.46,25.591l0.03-0.058l0.005-0.026l0.069-0.014v-0.022
		l-0.055-0.068v-0.014l0,0l0.086-0.007v-0.037l0.009-0.012h0.012l0.015,0.083l0.07,0.06l0.016,0.059l0.024-0.02l0.067,0.017
		l0.061-0.1l0.043,0.005l0.046,0.111l0.011,0.053l-0.006,0.038l0.1,0.074v-0.015H7.1V25.67h0.036l0.02-0.036l0.029-0.025
		l0.028,0.023h0.035l0.062-0.013l0.028-0.038l0.063-0.009l0.022,0.069l0.1-0.024l0.091,0.068l0.037-0.01l0.08-0.058l0.01-0.03
		l-0.015-0.061L7.787,25.521z"/>
	<path id="FI-19" style="fill:#FFFFFF;" d="M3.508,27.311v0.017l0.008,0.007l-0.032,0.039l-0.015-0.036l0.007-0.03h0.019v0.007
		h0.011v-0.011L3.508,27.311z M3.578,27.281v0.022l-0.013,0.028v0.054L3.544,27.4l-0.015-0.06l0.024-0.033v-0.018l0.014-0.029
		L3.578,27.281z M3.678,27.253l0.039,0.01v0.024l-0.007,0.01l-0.049,0.02l-0.009-0.013h0.006l-0.007-0.013l0.007-0.009l-0.006-0.016
		L3.678,27.253z M3.785,27.247h0.009l-0.008,0.009l0.027,0.028l-0.008,0.008l0.012,0.013l-0.009,0.007l-0.04-0.021v-0.025
		l0.009-0.017L3.785,27.247z M4.115,27.072h0.023l-0.023,0.015v0.012l0.01,0.008l-0.009,0.012v0.007l-0.039,0.025l0.018,0.012
		L4.072,27.18l0.011,0.023l-0.031,0.009v-0.013L4.04,27.192l0.008-0.017l-0.006-0.052l0.024,0.007l0.016-0.013v-0.01h-0.03
		l-0.013-0.01l0.042-0.031l0.021,0.008v-0.016L4.115,27.072z M4.129,26.948l0.044,0.011l-0.01,0.022l0.011,0.005l-0.005,0.016H4.18
		v0.019H4.172l-0.008,0.015l-0.031-0.015l-0.018,0.023H4.09L4.083,27.03l-0.022,0.011H4.036v-0.012l0.013-0.006l0.021,0.006v-0.034
		l0.024-0.042L4.129,26.948z M3.062,26.935H3.1l0.015,0.008v0.011l-0.054,0.019l-0.033-0.006v-0.018l-0.023-0.008v-0.012
		l0.053-0.023L3.062,26.935z M2.655,26.809l0.052,0.02l0.032-0.006l0.015,0.007l-0.008,0.01h0.007v0.035l-0.025,0.019l-0.016-0.007
		L2.7,26.893v0.015L2.712,26.9l0.007,0.011l0.009,0.044L2.702,26.97l-0.07,0.009l-0.071-0.007v-0.01l0.007-0.006l-0.024-0.018
		l0.015-0.027l-0.008-0.009l0.008-0.027l0.034-0.015l-0.01-0.008l0.033-0.036l0.017-0.011l0.007,0.006L2.655,26.809z M3.527,26.758
		v0.03l-0.01,0.006l0.006,0.015l-0.019,0.039H3.487l-0.011,0.015V26.9H3.459l-0.014,0.008l-0.013-0.006l-0.009,0.01l-0.005-0.017
		l0.013-0.018l-0.01-0.016l0.023-0.057h0.015l0.044-0.033L3.527,26.758z M3.209,26.731v0.052L3.19,26.788l-0.014,0.016h-0.04
		l0.036,0.022v0.032H3.163v-0.007l-0.019,0.006l0.015,0.016h-0.02l-0.012,0.016H3.095L3.086,26.87l0.007-0.017l-0.02-0.006
		l0.006,0.012l-0.013,0.029L3.029,26.85l0.01-0.023h0.014l-0.009-0.018v-0.02l0.057-0.02l0.016,0.01l0.032-0.044l0.053-0.016
		L3.209,26.731z M2.694,26.659l-0.015,0.019l0.007,0.015l-0.034,0.008l0.01,0.028H2.646l-0.016-0.01l-0.016,0.02H2.606v-0.016
		l0.016-0.062l0.073-0.01L2.694,26.659z M3.437,26.618v0.034l0.021,0.027v0.019l-0.03,0.048H3.417l-0.016,0.033l-0.052,0.012
		l-0.011,0.014H3.336v-0.011h-0.01v-0.005l-0.017,0.005l0.008-0.023H3.303v-0.014h0.008l0.008-0.012l0.024-0.065l-0.017,0.011
		l-0.011-0.008l-0.022,0.01l-0.011-0.006l-0.006,0.019l-0.028,0.012v-0.013H3.214l0.025-0.016v-0.007h0.04l0.01,0.007l0.017-0.013
		l0.044,0.007l0.038-0.038L3.437,26.618z M3.611,26.535l0.008,0.025l-0.024,0.009v0.022l-0.018,0.012l-0.011,0.021v0.043
		l-0.055,0.015H3.489l-0.026,0.014v-0.018l-0.02-0.024V26.63l0.055-0.032l0.008-0.032l0.031-0.011h0.045L3.611,26.535z
		 M3.016,26.525h0.038v0.006l0.028,0.007l-0.016,0.015v0.03H3.054L3.045,26.59l0.011,0.015H3.05H2.998l-0.034-0.022l0.007-0.034
		l-0.02-0.005v-0.007L3.016,26.525z M4.167,26.525l0.024,0.026l-0.052,0.092L4.094,26.69v0.036L4.069,26.74H4.054l-0.01,0.046v0.017
		l0.014,0.008l-0.013,0.011H4.02v0.014l0.03,0.013l-0.012,0.013h0.011v0.009l0.013,0.01L4.05,26.904l0.008,0.009h0.019l-0.026,0.02
		l-0.006,0.049l-0.013,0.024l-0.026,0.022l-0.034,0.006l-0.017,0.013l0.009,0.064H3.953V27.1L3.94,27.118v0.007l0.034,0.006
		l-0.006,0.044l-0.023,0.012l-0.007-0.021l-0.014,0.017l-0.019-0.005l-0.023,0.021H3.869l-0.022-0.061l0.011-0.02l-0.017-0.014
		l-0.02,0.056l0.006,0.009l-0.038,0.008v-0.009L3.77,27.153l-0.025,0.015l-0.021-0.024H3.699v0.009l-0.06,0.023l-0.018,0.017
		L3.606,27.18v0.021l-0.013,0.013H3.574v-0.009v-0.016h0.009l-0.009-0.045H3.566v0.027l-0.022,0.027l-0.01-0.01v-0.041H3.519
		l0.011,0.058l-0.014,0.014L3.498,27.17l0.009-0.035l-0.023-0.05V27.02l0.028-0.005l0.024-0.039h0.011l0.014,0.015v0.013
		l0.014-0.007l0.017,0.012v0.02l0.013,0.008l-0.006,0.021l0.018-0.007v-0.017l-0.016-0.016V27l0.019-0.016l-0.043-0.01l-0.018-0.018
		v-0.009H3.545l0.008-0.028h0.031l0.1-0.056H3.671l-0.054,0.013l-0.045-0.014v-0.007l-0.038,0.021H3.528l0.006-0.008l-0.009-0.012
		v-0.028l0.027-0.017v-0.009l0.022-0.021l-0.007-0.045l0.014-0.032l0.018-0.007l0.027,0.025L3.611,26.74v0.012l-0.029,0.019h0.016
		l0.047-0.009l0.015,0.014l0.019-0.016h0.038l0.007-0.02H3.718l0.02-0.022h0.023l0.015,0.007l0.1-0.02L3.91,26.71l0.017-0.011v0.01
		h0.015l0.062-0.015l0.021-0.014v-0.032l0.013-0.013l-0.011-0.008l0.013-0.019l0.011,0.016l0.012-0.017l0.009-0.031v-0.017
		l0.021-0.021l0.031-0.01l0.013,0.007L4.167,26.525z M3.378,26.481l0.034,0.011l0.019,0.029v-0.015h0.022l0.005-0.01l0.046,0.017
		v0.016l-0.018,0.034L3.5,26.575l-0.024,0.007v0.011H3.464l0.008-0.015H3.459l-0.017-0.017v0.023l-0.039,0.035v0.009H3.356
		l0.012,0.024h0.008l-0.008,0.013l-0.057-0.01l-0.022,0.013H3.252l-0.03-0.01l0.014-0.018H3.225l0.01-0.015l0.007,0.018l0.045-0.007
		l0.006,0.008l0.009-0.009l0.033,0.011v-0.008l-0.008-0.011l0.012-0.019H3.324l-0.008,0.017l-0.042,0.009l0.006-0.01l-0.034-0.006
		l-0.01-0.02l-0.009,0.018H3.22L3.2,26.591l0.007-0.024l-0.006-0.009h0.014v-0.019l0.017-0.014v-0.007H3.217v-0.013l0.014-0.008
		v0.007l0.014-0.011L3.251,26.5h0.024l0.039-0.011l0.031,0.006l0.01,0.013l0.013-0.017L3.378,26.481z M3.109,26.473l0.012,0.006
		v0.011l0.021,0.01v0.019L3.129,26.53H3.108l-0.049-0.022l-0.048-0.01l0.019-0.009h0.018v-0.01l0.034-0.015h0.034L3.109,26.473z
		 M2.716,26.408l0.037,0.011v0.009l0.006,0.011h0.023v0.019l-0.037-0.01l-0.022,0.007l-0.012-0.007l0.006-0.017H2.704l-0.01-0.018
		L2.716,26.408z M3.565,26.394L3.549,26.42H3.56v0.015h0.031l0.019,0.018l0.028-0.006l0.017,0.006l0.017-0.012l0.007,0.012H3.672
		v0.014l-0.073,0.07l-0.032,0.007l-0.006-0.005L3.51,26.557v-0.05l-0.074-0.018v-0.011l0.013-0.016v-0.024h0.027l-0.011-0.01v-0.015
		h0.019l0.047-0.024l0.037-0.007v0.008L3.565,26.394z M3.423,26.38l0.009,0.012v0.028l-0.035,0.015l-0.106,0.027l-0.02-0.013v-0.01
		l0.011-0.01l-0.024,0.009v-0.009l0.009-0.012l0.042-0.013L3.423,26.38z M3.623,26.31v0.014L3.59,26.371H3.582L3.52,26.389
		l-0.043,0.023l-0.033-0.011l-0.006,0.011L3.43,26.406v-0.028l0.013-0.011L3.623,26.31z M3.344,26.259v0.017l0.025,0.016
		l0.015,0.042h0.012v0.018L3.36,26.329v0.022H3.341l-0.007,0.01H3.312v-0.008L3.28,26.361v-0.013H3.265v-0.02l-0.009-0.007v-0.018
		l0.014-0.014h0.015v-0.008l0.012-0.008h0.008l0.008-0.017L3.344,26.259z M2.873,26.221l0.021,0.02v0.01h0.01l-0.01,0.03
		L2.867,26.27H2.852v-0.012h0.034v-0.006H2.863l-0.008-0.015l0.006-0.016L2.873,26.221z M2.687,26.203l0.009,0.017L2.69,26.234
		l0.007,0.012v0.025l-0.023-0.01l0.006,0.031l-0.029,0.026v-0.023l0.007-0.049l0.03-0.049L2.687,26.203z M3.011,26.138l0.06,0.021
		l0.01,0.02l0.008-0.006l0.008,0.013l0.025,0.008l0.01,0.017l-0.008,0.022h0.029h0.011v-0.008l0.008,0.01l-0.007,0.034L3.129,26.28
		l-0.007-0.014L3.111,26.28l-0.005-0.006H3.082l-0.008-0.009h0.007L3.07,26.258v-0.016l-0.045-0.017l-0.007-0.026H3.011
		l-0.016-0.013l0.013-0.006L3.011,26.138z M2.928,26.128l0.054,0.029l0.01,0.029l0.013,0.008l0.021,0.059l0.018,0.007v0.023
		l0.02,0.012v0.012l0.009,0.021l-0.018,0.016v0.021h0.04l0.025,0.009l-0.007,0.018l0.028,0.01l-0.009,0.021h0.022v0.016l-0.027,0.01
		v0.012h-0.05L3,26.451l-0.016-0.008v-0.007H2.963l-0.061-0.017L2.896,26.4l0.043,0.008l0.014-0.005L2.927,26.39v-0.01l-0.033-0.01
		l-0.026-0.018l-0.007-0.019v-0.009h0.017l0.018-0.015H2.862v-0.015h0.034l0.016-0.04l-0.027-0.017l-0.007-0.023l0.013-0.064
		l-0.005-0.01l0.007-0.007l0.015,0.005v-0.011L2.928,26.128z M2.828,25.978l0.023,0.023v-0.012l0.015,0.009v0.012l0.013,0.008v0.025
		l-0.013,0.022H2.854v0.016H2.836l0.007,0.01l-0.025,0.007l-0.022,0.027l0.01,0.046H2.791v0.023H2.782v0.013l-0.015-0.013
		L2.76,26.175l0.007-0.024h0.01v-0.019l-0.014-0.007l0.018-0.029l-0.012-0.02l0.02-0.019v-0.009l0.013-0.008h0.01v-0.01l0.015-0.013
		v-0.015L2.828,25.978z M2.588,25.987l0.016,0.048l-0.015,0.006l-0.023-0.042v-0.02h0.012L2.588,25.987z M2.24,25.908l0.029,0.013
		l0.009,0.023l0.031,0.015l0.01,0.029h0.01l0.008,0.04l-0.008,0.009l0.006,0.023l0.02,0.01v0.021l0.01,0.011h0.007l0.007,0.006
		l-0.006,0.015H2.336v0.014l-0.012-0.009H2.301l-0.014-0.014l0.015-0.013H2.276v-0.013h0.015v-0.012H2.279L2.26,26.094l-0.009-0.007
		v-0.035l0.011-0.006l0.032,0.007l0.009-0.009l-0.015-0.041H2.271l-0.016-0.014l0.006-0.008l-0.02-0.036L2.24,25.908z M2.403,25.885
		l0.02,0.006l0.009,0.012v0.015l0.018,0.011v0.052h0.025l0.015,0.01L2.483,25.97h0.025l0.006,0.029h0.01v-0.01l0.021,0.009
		l0.023,0.027L2.561,26.04h0.017v0.013H2.56v-0.019H2.532l-0.01-0.01v0.05l0.023,0.042l-0.017,0.011v0.01l-0.02,0.018L2.469,26.14
		v-0.021h0.007L2.47,26.104l-0.033-0.011v-0.017l-0.015-0.007v-0.031l-0.018,0.009l-0.042-0.038l0.018-0.077v0.01l0.02-0.018
		l0.011-0.017l-0.006-0.016L2.403,25.885z M2.203,25.843l0.007,0.021h0.016l0.012,0.017v0.011H2.225l0.009,0.009l-0.005,0.016
		l-0.016-0.015l-0.008-0.03l-0.028-0.024l-0.018,0.007v-0.012l0.017-0.005l0.017,0.015v-0.014L2.203,25.843z M2.223,25.812
		l0.015,0.006l0.008,0.016l-0.01,0.03l-0.02-0.03l0.013-0.006L2.223,25.812z M2.284,25.721h0.022v0.02l0.017-0.012H2.34v0.03
		l-0.013-0.007l-0.009,0.031l0.009-0.007l0.022,0.023l0.008-0.008l0.02,0.031L2.36,25.828v0.015h0.01v-0.01l0.008,0.006v0.022H2.371
		l0.017,0.013l0.005,0.024l-0.008,0.011l-0.062-0.084l0.007,0.02L2.324,25.86l0.017,0.039H2.333v0.016l-0.01,0.005l-0.016-0.015
		H2.298l-0.006-0.006L2.3,25.89l-0.017-0.044l-0.021,0.016v-0.013l-0.018-0.012h0.008v-0.012l-0.02-0.028v-0.009l-0.008-0.024h0.011
		L2.23,25.749h0.02v-0.006l0.008,0.014l0.021-0.012l0.013-0.02H2.267l-0.009-0.009v-0.007L2.284,25.721z M2.361,25.497l0.014,0.006
		l0.023,0.03L2.38,25.555l-0.015-0.006l-0.009-0.011l0.007-0.008l-0.021-0.019v-0.014L2.361,25.497z M2.144,25.411L2.18,25.45v0.016
		H2.168l-0.017-0.015H2.136l-0.011-0.034l0.011-0.024L2.144,25.411z M2.288,25.389l0.014,0.01l-0.018,0.019L2.27,25.407
		l-0.009,0.009H2.25l0.006-0.015l0.026-0.021L2.288,25.389z M2.272,25.289h0.007v0.029l0.013,0.017v0.022L2.27,25.374v-0.021H2.25
		l0.011,0.021l-0.021,0.02l-0.006-0.015l0.011-0.008l-0.016-0.035l0.016,0.008v-0.006V25.33H2.228v-0.013l0.009-0.008l0.016,0.017
		v-0.009l0.013-0.008L2.26,25.301L2.272,25.289z M2.211,25.207H2.22l0.008,0.02l0.013-0.007v-0.009h0.052l-0.016,0.015l0.005,0.018
		l-0.035,0.018L2.23,25.317H2.209l-0.007-0.023l-0.02-0.025l0.036-0.012l0.007-0.019H2.204v0.013l-0.017-0.007v0.011v-0.033
		l0.006-0.016L2.211,25.207z M2.447,24.853L2.458,24.9l0.068,0.059l0,0l0.066-0.005l0.017-0.01h0.045v-0.057l0,0V24.87l0.013-0.021
		l0.034,0.077l0.005,0.039l0.011,0.01l0.037-0.021l0.02,0.014l0.022-0.008l0.033-0.027l-0.014-0.03l0.025-0.018h0.053l-0.022,0.032
		l0.043,0.025l0.045,0.057l0.024-0.008l0.022,0.02h0.03v0.076l0.055,0.077l-0.012,0.025l0.017,0.009l0.013-0.009l0.128-0.007
		L3.27,25.25l0.014-0.006l0.022-0.045l0.028,0.012v-0.009l-0.032-0.015l0.049-0.044l0.034-0.014l0.01,0.012l0.048,0.014l0.052-0.039
		l0.059-0.008l0.118-0.032l0.072,0.026l-0.066-0.084l0.062-0.032l0.132,0.009l0.015,0.011l0.077-0.03l0.014-0.014l0.021,0.014
		l0.015-0.049v-0.028l-0.017-0.031l0.021-0.008v-0.017l0.015-0.026v-0.011l-0.024-0.025l0.009-0.051l0.049,0.039l0.016,0.058
		l0.036,0.016l0.014-0.006l0.01,0.026h0.038l0.05,0.047l0.044-0.021l0.058-0.006l0.047,0.008l0.02,0.058l0.036,0.009l0,0l0.06,0.086
		l0.046,0.022l-0.005,0.028l0.011,0.012l-0.014,0.025v0.02l-0.04-0.006l0.008,0.013l0.007,0.057l-0.12-0.008l-0.022,0.017v0.1
		l0.088,0.022l0.009,0.009l-0.032,0.02l-0.049,0.1L4.4,25.497l0.083,0.072l0.033,0.046l0.01-0.026l0.059,0.018l0.12-0.067h0.051
		l0.017,0.007v0.042l0.031,0.021l0.015,0.033l0.036,0.019H4.91l0.064,0.025l-0.007,0.028l0.011,0.03l0.035-0.013l0.035,0.026
		l0.074-0.009l0.054,0.009h0.012l0.03,0.01l0,0l-0.036,0.07l0.055,0.027v0.013l-0.034,0.064v0.033H5.156l0.021,0.039v0.011
		l-0.015,0.009l0.017,0.023v0.036l-0.03,0.031l-0.024-0.007l-0.09,0.01l-0.029,0.04v0.019l-0.022,0.031h0.1l0.01,0.046l-0.009,0.051
		v0.045H5.068l-0.032,0.035H5.013v0.02l0.012,0.008v0.042l-0.008,0.069l-0.043,0.031l-0.018-0.02l-0.02,0.045H4.918v0.048
		l-0.012-0.025v0.022h-0.02l-0.01,0.013l0.067,0.047L4.93,26.702l0.018,0.07l-0.04,0.023l-0.029-0.019H4.842l-0.1,0.068
		l-0.055,0.015h-0.04l-0.018,0.037l-0.053-0.049l0.011-0.034H4.565L4.539,26.84v0.05H4.522l-0.054,0.04H4.446v0.024l-0.053,0.048
		l-0.086,0.019v-0.007l-0.014,0.018l-0.033-0.03l0,0l0.01-0.01l-0.01-0.014l0.011-0.017l-0.007-0.007L4.25,26.948l-0.03,0.035H4.2
		v-0.016h0.011L4.19,26.951l0.022-0.01l-0.017-0.012H4.16L4.12,26.91v-0.014L4.088,26.88v-0.04l0.018-0.009L4.085,26.78l0.022-0.022
		l0.025-0.055l0.039-0.03v-0.039L4.2,26.622l0.006-0.034l0.021-0.008h0.014l0.043-0.1v-0.023l0.008-0.018l0.007-0.009h0.013
		l0.024-0.032l-0.022-0.017H4.303l-0.017,0.021v0.007h0.009v0.013l-0.037,0.035v0.025l-0.025,0.015l-0.01-0.018H4.215l-0.05,0.035
		l-0.072,0.022l-0.028,0.053l-0.07,0.063l-0.054,0.013L3.918,26.68H3.87l-0.006,0.007L3.8,26.698v0.01L3.775,26.72L3.77,26.735
		l-0.017,0.014l-0.058,0.006l0.01-0.036l0.033-0.005l-0.006-0.01l0.045-0.021l-0.032-0.031v-0.024H3.707l0.008,0.016l-0.008,0.009
		l-0.023-0.008v0.016H3.669v-0.007l-0.008-0.006l-0.018,0.006l-0.021-0.023h0.024l0.006-0.013H3.64v-0.024l0.041-0.053l0.048-0.046
		V26.48l0.015-0.009l-0.011-0.02l0.008-0.017l0.014,0.007l0.027-0.023v-0.031l0.022-0.022l0.025-0.048H3.812l-0.029,0.047
		L3.76,26.381l-0.033-0.018l-0.015,0.009h-0.05l-0.026-0.014l0.038-0.062V26.29l-0.117,0.043l-0.009,0.01l-0.1,0.027l-0.01-0.007
		v-0.048H3.423L3.397,26.28H3.385l-0.034-0.052l-0.092,0.011l-0.01-0.01l0.008-0.025l-0.025,0.011l-0.01,0.015l-0.034-0.028
		l-0.043-0.008l-0.011-0.045h0.002L3.13,26.126H3.119l-0.012,0.009l-0.019-0.006l-0.007-0.018l0.017-0.036l-0.024,0.022
		l-0.026-0.013l0.006-0.018h0.01l-0.01-0.028l0.012-0.015H3.059l-0.016,0.014l-0.016-0.013H3.013l0.009-0.025L2.988,25.99v0.017
		l-0.013,0.036h-0.01v-0.008h0.007v-0.024l-0.008-0.009H2.952v0.013l0.01,0.01v0.025l0.01,0.009v0.043l0.006,0.008h0.008v0.017
		L3,26.14l-0.014,0.021l-0.023-0.014v-0.015l-0.047-0.017l-0.01,0.026v-0.007L2.89,26.143l-0.006-0.01l-0.048-0.004v0.034H2.821
		l0.007-0.009l-0.024-0.006V26.12l0.025-0.009v-0.015l0.017,0.007l0.026-0.008v-0.031l0.008-0.017h0.009v-0.03l0.015-0.015v-0.021
		L2.89,25.967V25.96h0.015v-0.009l-0.016-0.009v-0.021h0.012v-0.019L2.892,25.89l0.006-0.006l0.01,0.008l0.008-0.009l-0.015-0.02
		v-0.017l0.011,0.008v-0.006v-0.036l0.014-0.04l-0.074,0.066l-0.016,0.051l-0.035,0.013l-0.014-0.009l-0.008,0.016H2.755
		l-0.008,0.014l-0.013-0.008l0.013,0.072L2.74,26.008H2.734l-0.009,0.01l-0.008,0.05l-0.031,0.039l-0.038,0.011l-0.009,0.011
		l-0.014-0.025l0.019-0.034h0.017l-0.009-0.035H2.636l-0.008-0.018l-0.015-0.01L2.6,25.983v-0.014l-0.089-0.041H2.49v-0.011
		l-0.041-0.039h0.014v-0.021H2.451v-0.032l0.006-0.018l0.008,0.022l0.016-0.01V25.81l-0.013-0.009l0.013-0.006v-0.017H2.436
		l-0.014-0.021l-0.018-0.006v-0.018H2.395v-0.012l-0.037-0.029h0.014v-0.011h0.01l-0.006-0.009l0.008-0.009H2.365v-0.024
		l0.008-0.007h0.062l0.013,0.006l-0.006,0.009h0.009v0.008l0.016-0.006l0.015,0.01H2.5L2.486,25.63h0.013v-0.011l-0.014-0.008
		l-0.023-0.038l0.041-0.013l-0.028-0.006l-0.006-0.019L2.43,25.518l-0.007-0.01v-0.006l-0.011-0.009l0.025-0.029l-0.036-0.03
		l0.007-0.015h0.015V25.39l-0.015-0.007l-0.011,0.013l-0.006-0.013l-0.01,0.011l0.007-0.063L2.38,25.345H2.369l-0.006-0.01
		l0.02-0.031l-0.06-0.022l0.039-0.011l0.006-0.03h0.054l0.03,0.022v-0.014l0.023-0.005v-0.012l-0.008-0.011l-0.013,0.006
		l-0.031-0.008l-0.016-0.027v-0.021H2.394l-0.01-0.01v-0.01L2.37,25.145v0.02l0.042,0.062l-0.015-0.008l-0.01,0.011H2.359v0.007
		l-0.08-0.029v0.013h0.026v0.01l-0.046,0.007l-0.032-0.007L2.219,25.22l0.011-0.006l-0.023-0.011l0.023-0.01l-0.014-0.014h0.021
		l0.019-0.023h0.013l0.025,0.022v-0.021h0.012V25.15l0.026,0.006L2.318,25.12l-0.011-0.007v-0.024h0.018l0.006-0.025l-0.089-0.056
		h0.016v-0.019h0.021l0.007-0.007v-0.013l0.02-0.01l0.021,0.018v0.021l0.031,0.035L2.353,25.04H2.36v0.019h0.011l0.017,0.027
		l0.012-0.01l0.018,0.033H2.409v0.017l-0.025-0.008l-0.01,0.015l0.038,0.005l0.012-0.012l0.033,0.047l0.008-0.024l-0.02-0.036
		l0.013-0.016v-0.014l-0.014-0.014V25.06l-0.026-0.008v-0.023l-0.015-0.025H2.395v0.012l-0.022-0.032l-0.01-0.019V24.94L2.34,24.933
		l0.017-0.024H2.341l-0.023-0.022l0.028-0.036H2.318l-0.016-0.014L2.293,24.77h0.03l0.012,0.019l0.067,0.035l0.014,0.026H2.43
		L2.447,24.853z"/>
</g>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'mapInitial'
		},
		width: {
			type: [Number, String],
			default: 23
		},
		height: {
			type: [Number, String],
			default: 19
		},
		iconColor: {
			type: String,
			default: '#FFFFFF'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

    svg > * {
        fill: #ffffff;
    }

</style>