<template>
  <div v-show="popObjectContent && popObjectContent.properties" class="infobox">
    <v-icon class="window-info--close" @click="closePopUp(popObject)" size="1.5rem">
      clear
    </v-icon>
    <div class="infobox__default" >
      <div class="single-infobox__full-info" v-if="popObjectContent && popObjectContent.properties">
        <p class="infobox__text" v-for="(value, key, index) in popObjectContent.properties" :key="index" v-if="checkProperty(value, key)">
          <span class="property-headings">{{ key | translate }}:</span>
          <span>
            {{ checkValue(value, key) | translate }} <span v-if="key === 'NopRaj_kmh'">km/h</span><span v-if="key === 'NopRaj_kn'">kn</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import IconToolsCloseBase from '../svg-system-components/icons-tools/IconToolsCloseBase';
  import moment from 'moment';
  export default {
    components: {
      IconToolsCloseBase
    },
    props: ['popObjectContent', 'popObject'],
    methods: {
      closePopUp(overlay) {
        overlay.popup.setPosition(undefined);
        return false;
      },
      checkProperty(value, key) {
        if (value && value !== 'NaN' && value !== 'Null' && value !== ' ' && key !== 'OBJECTID' && 
          key !== 'Shape' && key != 'RajTunnus' && key !== 'Shape_Area' && key !== 'DigPohja' && 
          key !== 'CDDATunnus' && key !== 'IUCNKategoria' && key !== 'LPAlue' && key !== 'ViimPaatNimi' && 
          key !== 'ViimPaatPvm' && key !== 'KohdeId' && key !== 'KohdeTyyppi' && key !== 'IUCNLuokkaNimi' && 
          key !== 'TyyppiLyhenne' && key !== 'LsAlueTunnus' && key !== 'address' && key !== 'address_id' && key !== 'status' &&
          key !== 'hashtags' && key !== 'expected_datetime' && key !== 'lat' && key !== 'long' && key !== 'media_url' && 
          key !== 'updated_datetime' && key !== 'zipcode' && key !== 'requested_datetime' && key !== 'description' && 
          key !== 'service_code' && key !== 'service_notice' && key !== 'service_request_id' && key !== 'reference_point_GEO' &&
          key !== 'PALETTE_INDEX2' && // ruovikot PixelValue
          key !== 'kohdeid' && key !== 'lsaluetunnus' && key !== 'tyyppilyhenne' && key !== 'kohdetyyppi' && // Kansallispuistot ja suojelualueet
	        key !== 'paatpvm' && key !== 'paatnimi' && key !== 'viimpaatpvm' && key !== 'viimpaatnimi' && // Kansallispuistot ja suojelualueet
	        key !== 'lakiperuste' && key !== 'lakkautuspvm' && key !== 'cddatunnus' && key !== 'iucnkategoria' && // Kansallispuistot ja suojelualueet
          key !== 'iucnluokkanimi' && key !== 'lpalue' && key !== 'ely' && key !== 'olotila' && // Kansallispuistot ja suojelualueet
          key !== 'olotilamuutospvm' && key !== 'digpohja' && key !== 'muutospvm' && key !== 'voimaantulopvm' && // Kansallispuistot ja suojelualueet
          key !== 'shape_length' && key !== 'shape_area'  &&  // Kansallispuistot ja suojelualueet
          key !== 'Bilaga' ) { // && key !== 'Datumbeslutetträderikraft' ) { // Ahvenanmaan suojelualueet
          return true
        } else {
          return false;
        }
      },
      checkValue(value, key) {
        if (value.toString().length > 8 && moment(value).isValid()) {
          return moment(value).format("DD.MM.YYYY HH:mm");
        } else if (key === 'algaebloom_singlevaluelist_201808151546174') {
          return 'algae' + value;
        } else if ( key === 'ice_combined_situation_singlevaluelist_201806011206544') {
          return 'ice' + value;
        } else if ( key === 'snow_combined_situation_singlevaluelist_201806122300253') {
          return 'snow' + value;
        } else {
          return value.toString().trim();
        }
      }
    },
  }

</script>

<style scoped lang="scss">
  .window-info--close {
    position: absolute;
    right: 2px;
    cursor: pointer;
    top: 2px;
  }

  .window-info--actions {
    justify-content: center;
    height: 1rem;
    padding: 0;
  }

  .infobox {
    background-color: white;
    border: 2px solid #052D4D;
    border-radius: 5px;
    width: auto;
    height: auto;
    max-width: 320px;
    min-width: 280px;
    
    .infobox__default {
      padding: 1rem 2rem 1rem 1rem;
    }

    button {
      margin: 0;
      color: white;
    }

    .single-infobox__full-info {
      font-family: "Montserrat";

      .infobox__text {
        color: black;
        text-align: left;
        display: flex;
        justify-content: space-between;
        margin: 0;

        .property-headings {
          font-family: "Montserrat-Semibold";
          font-size: 11px;
          text-transform: uppercase;
          max-width: 40%;
          white-space: nowrap;
        }

        .propperty-headings--type {
          display: none;
        }

        .property-link {
          a {
            text-decoration: none;
            color: black;
          }
        }

        span {
          &:last-of-type {
            width: 50%;
            font-size: 11px;
            box-sizing: border-box;
            padding-left: 1rem;
          }
        }

        .property-values--type {
          display: none;
        }

      }
    }
  }

</style>
