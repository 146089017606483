// Styling Point
import Style 	            from 'ol/style/style';

import Stroke 				    from 'ol/style/stroke';

import CircleStyle 				from 'ol/style/circle';

import Fill 				      from 'ol/style/fill';

import Feature            from 'ol/feature';


let positionFeature = new Feature();
positionFeature.setStyle(new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: '#3399CC'
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 2
    })
  })
}));

export default positionFeature;
