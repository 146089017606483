// imports for creating Vectors layers and clusters
import VectorSource from 'ol/source/vector';
import poiAxiosConf from '../../poi-axios-conf/axios.conf';
import spatialApi from './spatialInfoLayers';
import mapApi from '../mapsInit';
// Features
import Feature from 'ol/feature';
// Point
import Point from 'ol/geom/point';
// Styling Point
import Style from 'ol/style/style';
import Icon from 'ol/style/icon';
// set the projections
import proj from 'ol/proj';
// spatial configuration
import spatialConfiguration from './spatial-config';
import store from '../../store/modules/maps-main.store'

let POI_API = {
  getPOIFeatures: getPOIFeatures,
  getPOIFeaturesToExtents: getPOIFeaturesToExtents
}

async function getPOIFeatures(map, poi_bbox) {
  // const URL = 'http://api.geonames.org/wikipediaBoundingBoxJSON'; // proxy for https is needed
  //const URL = 'https://paikkatieto.ymparisto.fi/proxy/proxy.ashx?http://api.geonames.org/wikipediaBoundingBoxJSON?';
  const URL = 'https://paikkatieto.ymparisto.fi/proxy/proxy.ashx?http://api.geonames.org/searchJSON?&country=FI';
  let poiVectorLayerSource = new VectorSource({});
  let poiInstance = new spatialApi.POIInfo(map, poiAxiosConf.createPOIPoints);
  let spatielGroup = mapApi.getPlannerGroup(map, spatialConfiguration.groupName);
  let mapextent = map.getView().calculateExtent(map.getSize());
  // transform exten coordinates - change 15.1.2024
  let specifiedExtents = mapApi.transformExtentsTo4326(mapextent);
  let poiPoints = await poiInstance.getSourceVector(URL, specifiedExtents);
  let poiVector = getPoiVector(spatielGroup, spatialConfiguration.poiName);
  poiVector.setZIndex(2);
  await settingPOIFeatures(poiPoints, poiVectorLayerSource);
  poiVector.setSource(poiVectorLayerSource);
  poiVector.setStyle(function () {
    let styleCache = {};
    let featuresSize = 25;
    let style = styleCache[featuresSize];
    if (!style) {
      style = new Style({
        image: new Icon({
          src: 'https://meriopas.ymparisto.fi/meriopas/static/poiIcons/poi-on-map.png',
          size: [32, 32],
        })
      })
    }
    styleCache[featuresSize] = style
    return style;
  })
}

async function settingPOIFeatures(locations, source) {
  let features = [];
  //var aa = 24.74078; //21.37;
  // var bb = 60.22877; //59.785;
  let currentLanguage = store.state.currentActiveLanguage.name ? store.state.currentActiveLanguage.name : 'fi';
  if (locations) {
    for (let i = 0; i < locations.length; ++i) {
      //aa = Number(locations[i].lng); //aa + 0.01; // 
      //bb = Number(locations[i].lat); // bb + 0.01; // 
      features[i] = new Feature({
        geometry: new Point(proj.transform([Number(locations[i].lng), Number(locations[i].lat)], 'EPSG:4326', 'EPSG:3857')),
        title: locations[i].name,
        type: 'POI',
        //wikiURL: locations[i].wikipediaUrl.replace('en.wikipedia', currentLanguage + '.wikipedia'),
        //summary: locations[i].summary,
        lat: locations[i].lat,
        lon: locations[i].lng
      });

      features[i].set('id', i);
    }

    if (source) {
      source.clear();
      source.addFeatures(features);
    } else {
      return features;
    }
  } else {
    return [];
  }
}

async function getPOIFeaturesToExtents(map, extents) {
  const URL = 'https://paikkatieto.ymparisto.fi/proxy/proxy.ashx?http://api.geonames.org/searchJSON?&country=FI';
  let spatielGroup = mapApi.getPlannerGroup(map, 'Spatial info');
  let existingLayerWithPOI = checkPOIInGroup(spatielGroup);
  let poiInstance = new spatialApi.POIInfo(map, poiAxiosConf.createPOIPoints);
  let POI_POINTS;
  if (existingLayerWithPOI !== undefined) {
    POI_POINTS = await poiInstance.getSourceVector(URL, extents);
    let poiFeatures = await settingPOIFeatures(POI_POINTS);
    spatielGroup.forEach((layer) => {
      if (layer.values_.name === spatialConfiguration.poiName) {
        removeFeatures(layer.getSource());
        layer.getSource().addFeatures(poiFeatures);
        layer.getSource().refresh();
      }
    })
  }

  function removeFeatures(source) {
    source.clear();
  }

  function checkPOIInGroup(group) {
    let checkedLayer;
    group.forEach((layer) => {
      if (layer.values_.name === spatialConfiguration.poiName) {
        checkedLayer = layer;
      }
    })
    return checkedLayer;
  }

}


function getPoiVector(collection, name) {
  let vector;
  collection.forEach((layer) => {
    if (layer.get("name") === name) {
      vector = layer;
    }
  })
  return vector;
}

export default POI_API;
