<template>
  <v-tooltip right color="secondary" light close-delay="100" content-class="keystone-tooltip">
    <v-icon color="secondary" size="19" slot="activator"> info </v-icon>
    <span style="color: black"> {{ 'infoAbout' | translate }} {{ name }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    props: ["name"]
  }

</script>

<style scoped lang="scss">


</style>
