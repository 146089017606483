var moment = require('moment');
// spatial utils
import spatialUtils from './utils';
// imports for creating Vectors layers and clusters
import VectorSource from 'ol/source/vector';
import aisAxiosConf from '../../swagger-axios-conf/axios.conf';
import spatialApi from './spatialInfoLayers';
import mapApi from '../mapsInit';
// Features
import Feature from 'ol/feature';
// Point
import Point from 'ol/geom/point';
// Styling Point
import Style from 'ol/style/style';
import Icon from 'ol/style/icon';
// set the projections
import proj from 'ol/proj';
// spatial configuration
import spatialConfiguration from './spatial-config';

// AIS API
let aisAPI = {
  getAISFeatures: getAISFeatures,
  getSingleAIS: getSingleAIS
};

const URL = 'https://meri.digitraffic.fi/api/ais/v1/locations';
const metaDataURL = "https://meri.digitraffic.fi/api/ais/v1/vessels";

let AISFeatureSize;
let shipTypeImageHash = {
  "Reserved": 'https://meriopas.ymparisto.fi/meriopas/static/aisTypes/Unspecified-up.png',
  "Wing In Ground": 'https://meriopas.ymparisto.fi/meriopas/static/aisTypes/Unspecified-up.png',
  "High-Speed Craft": 'https://meriopas.ymparisto.fi/meriopas/static/aisTypes/Unspecified-up.png',
  "Special Category": 'https://meriopas.ymparisto.fi/meriopas/static/aisTypes/Unspecified-up.png',
  "Passenger": 'https://meriopas.ymparisto.fi/meriopas/static/aisTypes/Passenger-up.png',
  "Cargo": 'https://meriopas.ymparisto.fi/meriopas/static/aisTypes/Cargo-up.png',
  "Tanker": 'https://meriopas.ymparisto.fi/meriopas/static/aisTypes/Tanker-up.png',
  "Other": 'https://meriopas.ymparisto.fi/meriopas/static/aisTypes/Unspecified-up.png',
};

async function getAISFeatures(map, params) {
  let aisVectorLayerSource = new VectorSource({});
  let aisInstance = new spatialApi.AISSpatialLayer(map, aisAxiosConf.createLatestPointsRequest);
  let spatialGroup = mapApi.getPlannerGroup(map, spatialConfiguration.groupName);
  let aisVector = getAISVector(spatialGroup, spatialConfiguration.AISName);
  aisVector.setZIndex(2);
  let aisPoints = await aisInstance.getSourceVector(URL, params.from);
  let aisMetadata;
  if (sessionStorage.getItem('aisMetadata')) { // Store to session storage, so ais metadata is fetched only once
    aisMetadata = JSON.parse(sessionStorage.getItem('aisMetadata'));
  } else {
    aisMetadata = await aisInstance.getAllAISMetadata(metaDataURL, params.from, aisAxiosConf.allAISMetadata);
    sessionStorage.setItem('aisMetadata', JSON.stringify(aisMetadata));
  }
  let metaMMSI = aisMetadata.map(metaElement => metaElement.mmsi);

  let normalFilter = aisPoints.map(shipPoint => {
    let mmsi = metaMMSI.indexOf(shipPoint.mmsi);
    if (mmsi > -1) {
      shipPoint.shipType = aisMetadata[mmsi].shipType;
      shipPoint.draught = aisMetadata[mmsi].draught;
      shipPoint.callSign = aisMetadata[mmsi].callSign;
      shipPoint.eta = aisMetadata[mmsi].eta;
      shipPoint.imo = aisMetadata[mmsi].imo;
      shipPoint.name = aisMetadata[mmsi].name;
      shipPoint.destination = aisMetadata[mmsi].destination;
      return shipPoint;
    } else {
      return shipPoint
    }
  });

  normalFilter = normalFilter.filter(point => point.shipType);

  await settingAISFeatures(normalFilter, aisVectorLayerSource);

  aisVector.setSource(aisVectorLayerSource);

  aisVector.setStyle(function (feature) {
    let styleCache = {};
    let featuresSize = AISFeatureSize;
    let style = styleCache[featuresSize];

    if (!style) {
      let shipType = feature.getProperties().shipType;
      let shipDirection = parseFloat(feature.getProperties().heading);
      let radianShipDirection = !isNaN(shipDirection) ? shipDirection * (Math.PI / 180) : 0;
      style = new Style({
        image: new Icon({
          src: shipTypeImageHash[shipType],
          size: [38, 38],
          rotation: radianShipDirection
        })
      })
      styleCache[featuresSize] = style
    }
    return style;
  })
}


async function settingAISFeatures(locations, source) {
  let features = [];
  for (let i = 0; i < locations.length; ++i) {
    features[i] = new Feature({
      geometry: new Point(proj.transform([locations[i].geometry.coordinates[0], locations[i].geometry.coordinates[1]], 'EPSG:4326', 'EPSG:3857')),
      mmsi: locations[i].mmsi,
      type: 'AIS',
      callSign: locations[i].callSign,
      draught: locations[i].draught,
      eta: locations[i].eta,
      imo: locations[i].imo,
      destination: locations[i].destination,
      name: locations[i].name,
      heading: locations[i].properties.heading,
      latitude: locations[i].geometry.coordinates[1].toFixed(5),
      longitude: locations[i].geometry.coordinates[0].toFixed((5)),
      cog: locations[i].properties.cog + "°",
      sog: locations[i].properties.sog + "kn",
      timestamp: locations[i].properties.timestamp,
      timestampExternal: moment(locations[i].properties.timestampExternal).format("DD.MM hh:mm"),
      shipType: spatialUtils.getAisShipType(locations[i].shipType)
    });
    features[i].set('id', i);
  }
  source.clear();
  source.addFeatures(features);
  AISFeatureSize = features.length;
}


async function getSingleAIS(map, mmsi) {
  const URL = `https://meri.digitraffic.fi/api/ais/v1/vessels/${mmsi}`;
  let aisInstance = new spatialApi.AISSingleInfo(map, aisAxiosConf.createSingleShipRequest);
  let singleAIS = await aisInstance.getSingleShipInfo(URL);
  return singleAIS;
}

function getAISVector(collection, name) {
  let vector;
  collection.forEach((layer) => {
    if (layer.get("name") === name) {
      vector = layer;
    }
  })
  return vector;
}

export default aisAPI;
