<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 11.141 6.5"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName}} 
		</title>
	<g 
		:fill = iconColor
	>
	<path id="arrow_up" style="opacity:1;enable-background:new    ;" d="M0,0.929c0-0.097,0.041-0.19,0.112-0.257L0.67,0.114
        C0.737,0.042,0.831,0,0.929,0c0.097,0,0.19,0.041,0.257,0.112L5.571,4.5l4.387-4.386C10.024,0.042,10.117,0.001,10.214,0
        c0.097,0,0.19,0.041,0.257,0.112l0.558,0.56c0.071,0.067,0.112,0.16,0.112,0.257c0,0.097-0.041,0.19-0.112,0.257l-5.2,5.2
        C5.763,6.458,5.669,6.5,5.571,6.5c-0.097,0-0.19-0.041-0.257-0.112l-5.2-5.2C0.042,1.121,0,1.027,0,0.929z"
    />
	</g>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: ''
		},
		width: {
			type: [Number, String],
			default: 24
		},
		height: {
			type: [Number, String],
			default: 24
		},
		iconColor: {
			type: String,
			default: 'currentColor'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

</style>