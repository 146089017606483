import baseMapsConfig from '@/maps-constructor/basemap-layers.configs/config'

let baseMapsDetails = [
  {
    name: baseMapsConfig.backgroundMapName,
    translations: "backgroundMapDetails"
  },
  {
    name: baseMapsConfig.seaMapName,
    translations: "seaMapDetails"
  },
  {
    name: baseMapsConfig.plainMapName,
    translations: "plainMapDetails"
  },
  {
    name: baseMapsConfig.topographicMapName,
    translations: "topographicDetails"
  },
  {
    name: baseMapsConfig.depthModelMapName,
    translations: "depthModelDetails"
  },
  // Commented out since map projection does not match
  // {
  //   name: baseMapsConfig.hillShadeMap,
  //   translations: "hillShadeDetails"
  // },
  // {
  //   name: baseMapsConfig.aerialMapName,
  //   translations: "aerialMapDetails"
  // }

];

let utils = {
  baseMapsDetails
}

export default utils;
