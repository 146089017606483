<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 23 22.5"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName}} icon
		</title>
<g id="Rectangle_311" transform="translate(286 448)">
	<g>
		<path d="M-263.973-425.5h-21.054v-21.044h21.054V-425.5z M-284.027-426.5h19.054v-19.044h-19.054V-426.5z"/>
	</g>
	<g>
		<path d="M-264.409-425.956h-20.182v-20.133h20.182V-425.956z M-283.591-426.956h18.182v-18.133h-18.182V-426.956z"/>
	</g>
</g>
<g id="Rectangle_312" transform="translate(291 462)">
	<g>
		<path d="M-282.729-443.145h-4.644v-4.645h4.644V-443.145z M-286.373-444.145h2.644v-2.645h-2.644V-444.145z"/>
	</g>
	<g>
		<path d="M-283.184-443.6h-3.733v-3.733h3.733V-443.6z M-285.917-444.6h1.733v-1.733h-1.733V-444.6z"/>
	</g>
</g>
<g id="Rectangle_313" transform="translate(291 457)">
	<g>
		<path d="M-282.729-443.7h-4.644v-4.644h4.644V-443.7z M-286.373-444.7h2.644v-2.644h-2.644V-444.7z"/>
	</g>
	<g>
		<path d="M-283.184-444.156h-3.733v-3.733h3.733V-444.156z M-285.917-445.156h1.733v-1.733h-1.733V-445.156z"/>
	</g>
</g>
<g id="Rectangle_314" transform="translate(296 462)">
	<g>
		<path d="M-282.173-443.145h-4.644v-4.645h4.644V-443.145z M-285.817-444.145h2.644v-2.645h-2.644V-444.145z"/>
	</g>
	<g>
		<path d="M-282.629-443.6h-3.733v-3.733h3.733V-443.6z M-285.362-444.6h1.733v-1.733h-1.733V-444.6z"/>
	</g>
</g>
<g id="Rectangle_315" transform="translate(296 457)">
	<g>
		<path d="M-282.173-443.7h-4.644v-4.644h4.644V-443.7z M-285.817-444.7h2.644v-2.644h-2.644V-444.7z"/>
	</g>
	<g>
		<path d="M-282.629-444.156h-3.733v-3.733h3.733V-444.156z M-285.362-445.156h1.733v-1.733h-1.733V-445.156z"/>
	</g>
</g>
<g id="Rectangle_316" transform="translate(301 462)">
	<g>
		<path d="M-281.618-443.145h-4.644v-4.645h4.644V-443.145z M-285.262-444.145h2.644v-2.645h-2.644V-444.145z"/>
	</g>
	<g>
		<path d="M-282.073-443.6h-3.732v-3.733h3.732V-443.6z M-284.806-444.6h1.732v-1.733h-1.732V-444.6z"/>
	</g>
</g>
<g id="Rectangle_317" transform="translate(301 457)">
	<g>
		<path d="M-281.618-443.7h-4.644v-4.644h4.644V-443.7z M-285.262-444.7h2.644v-2.644h-2.644V-444.7z"/>
	</g>
	<g>
		<path d="M-282.073-444.156h-3.732v-3.733h3.732V-444.156z M-284.806-445.156h1.732v-1.733h-1.732V-445.156z"/>
	</g>
</g>
<g>
	<rect x="1.473" y="5.438" width="20.054" height="1.477"/>
</g>
<g>
	<rect x="5.211" y="0.5" width="1.477" height="3.651"/>
</g>
<g>
	<rect x="16.322" y="0.5" width="1.477" height="3.651"/>
</g>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'box'
		},
		width: {
			type: [Number, String],
			default: 23
		},
		height: {
			type: [Number, String],
			default: 22.5
		},
		iconColor: {
			type: String,
			default: '#FFFFFF'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}
	svg > * {
		fill : white
	}

</style>