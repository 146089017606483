import axios from 'axios';

let POIAxiosApi = {
  createPOIPoints: createPOIPoints,
};

function createPOIPoints(POI_URL, POI_BBOX) {
  return axios.create({
    baseURL: POI_URL,
    timeout: 20000,
    params: {
      north: POI_BBOX[0],
      south: POI_BBOX[1],
      east: POI_BBOX[2],
      west: POI_BBOX[3],
      username: "ulad.dvi",
      maxRows: 100
    }
  })
}

export default POIAxiosApi;
