<template>
	<svg 
		xmlns               = "http://www.w3.org/2000/svg" 
		xmlns:xlink         = "http://www.w3.org/1999/xlink" 
		x                   = "0px" 
		y                   = "0px" 
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 48 48"
		:aria-labelledby    = "iconName"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName | translate}}
		</title>
<g id="Layer_2" data-name="Layer 2"><g id="Layer_4" data-name="Layer 4"><g id="icon-location"><path class="cls-1" d="M44.78,12A23.47,23.47,0,0,1,48,24a23.44,23.44,0,0,1-3.22,12,23.81,23.81,0,0,1-8.73,8.73A23.47,23.47,0,0,1,24,48a23.47,23.47,0,0,1-12-3.22,23.81,23.81,0,0,1-8.73-8.73A23.44,23.44,0,0,1,0,24,23.47,23.47,0,0,1,3.22,12,24,24,0,0,1,12,3.22,23.47,23.47,0,0,1,24,0a23.47,23.47,0,0,1,12,3.22A24,24,0,0,1,44.78,12ZM42.41,31.77a19.76,19.76,0,0,0,0-15.53A19.78,19.78,0,0,0,31.77,5.59a19.78,19.78,0,0,0-15.54,0A19.78,19.78,0,0,0,5.59,16.24a19.76,19.76,0,0,0,0,15.53A19.8,19.8,0,0,0,16.23,42.41a19.78,19.78,0,0,0,15.54,0A19.8,19.8,0,0,0,42.41,31.77Zm-7.1-19.08A15.4,15.4,0,0,1,40,24a15.38,15.38,0,0,1-4.69,11.31A15.38,15.38,0,0,1,24,40a15.38,15.38,0,0,1-11.31-4.69A15.38,15.38,0,0,1,8,24a15.4,15.4,0,0,1,4.69-11.31A15.38,15.38,0,0,1,24,8,15.38,15.38,0,0,1,35.31,12.69Zm-2.82,19.8A11.58,11.58,0,0,0,36,24a11.56,11.56,0,0,0-3.51-8.48A11.57,11.57,0,0,0,24,12a11.57,11.57,0,0,0-8.49,3.52A11.56,11.56,0,0,0,12,24a11.58,11.58,0,0,0,3.51,8.49A11.6,11.6,0,0,0,24,36,11.6,11.6,0,0,0,32.49,32.49ZM29.66,18.35A7.69,7.69,0,0,1,32,24a8,8,0,0,1-8,8,8,8,0,0,1-8-8,8,8,0,0,1,8-8A7.7,7.7,0,0,1,29.66,18.35Z"/></g></g></g>
	</svg>  
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'userLocation'
		},
		width: {
			type: [Number, String],
			default: 23
		},
		height: {
			type: [Number, String],
			default: 19
		},
		iconColor: {
			type: String,
			default: '#FFFFFF'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

    svg > * {
        fill: #ffffff;
    }

</style>