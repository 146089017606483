const seaMapURL = "https://julkinen.traficom.fi/s57/wms?";
const backgroundMapURL = "https://paikkatieto.ymparisto.fi/proxy/proxy.ashx?https://karttakuva.maanmittauslaitos.fi/maasto/wmts?x";
const topographicMapURL = "https://paikkatieto.ymparisto.fi/proxy/proxy.ashx?https://karttakuva.maanmittauslaitos.fi/maasto/wmts?x";
const depthModelMapURL = '';

let baseMapsConfig = {
  baseMapsGroupTitle: "Base maps",
  plainMapName: "plainMap",
  seaMapName: "seaMap",
  seaMapURL: seaMapURL,
  backgroundMapName: "backgroundMap",
  backgroundMapURL: backgroundMapURL,
  topographicMapName: "topographic",
  topographicMapURL: topographicMapURL,
  projectionFin: 'EPSG:3067'
}

export default baseMapsConfig;
