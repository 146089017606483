<template>
  <v-app>
    <div class="map-wrapper" id="map-wrapper">
      <div class="fullscreen" id="fullscreen">
        <div id="map" class="map" :class="{'map-in__loading' : getLoadingStatus}">
        </div>

        <v-progress-circular v-if="getLoadingStatus" class="map-loader" :size="100" :width="7" color="blue"
          indeterminate>
        </v-progress-circular>

        <!-- menu header  -->
        <app-header class="app-header" v-if="getMap !== null" v-bind:map="getMap">
        </app-header>

        <!-- custom compass -->
        <!-- <div id="compass-control">
          <span id="compass-icon">
            <icon-compass-base />
          </span>
        </div> -->

        <!-- menu wrapper -->
        <router-view v-if="getMap !== null" v-bind:map="getMap" class="map__menu-wrapper">
          <menu-wrapper v-if="getMap !== null" class="map__menu-wrapper" v-bind:map="getMap">
          </menu-wrapper>
        </router-view>

        <!-- contains all buttons -->
        <div class="control-buttons-wrapper__position" :class="{'control-buttons-wrapper__position--calendar-off' : !calendar.visible}">
          <control-buttons-wrapper class="map__control-buttons" v-bind:map="getMap" :class="{'map__control-buttons--calendar-off' : !calendar.visible}">
          </control-buttons-wrapper>
          <control-buttons-calendar class="map__control-calendar" :title="'calendar' | translate" v-bind:map="getMap">
          </control-buttons-calendar>

          <div :title="'nearestObservations' | translate" class="obs" v-show="Object.keys(obsProps).length !== 0" :class="showObsProps ? 'obs-visible' : ''"
            @click="showObsProps=!showObsProps">
            <v-icon class="window-info--close" size="1.5rem">
              chevron_right
            </v-icon>
            <div class="place" :class="!showObsProps ? 'place-small' : ''">{{ obsProps.windPlace }}</div>
            <div class="iconBox" v-if="(obsProps.windDirection !== 'NaN' && obsProps.windSpeed !== 'NaN') || obsProps.temperature !== 'NaN'">
              <img v-if="obsProps.windDirection !== 'NaN' && obsProps.windSpeed !== 'NaN'" :style="'transform:rotate(' + obsProps.windDirection + 'deg)'"
                :src="windIcon(obsProps.windSpeed)"/>
              <div v-if="obsProps.windDirection !== 'NaN' && obsProps.windSpeed !== 'NaN'" :title=" obsProps.windDirection + '°'">{{obsProps.windSpeed}}</div>
              <img v-if="obsProps.windDirection === 'NaN' && obsProps.windSpeed === 'NaN'" src="https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/temp.png" />
              <div v-if="obsProps.windDirection === 'NaN' && obsProps.windSpeed === 'NaN'" style="font-size:12px;margin-top:31px">{{round(obsProps.temperature)}}°</div>
            </div>
            <div class="props">
              <div v-show="obsProps.temperature !== 'NaN'" style="font-size:20px"> {{ obsProps.temperature }}<span
                  style="font-size:16px">°C</span></div>
              <div style="font-size:12px">{{ formatDate(obsProps.windTime) }}</div>
            </div>
          </div>
        </div>

        <!-- here goes Center icon -->
        <icon-center-base iconColor="#2b77b2" class="map__center-sign" />

        <!--liner is a scale box in the bottom of the map -->
        <div id="liner">
          <div v-if="getMap !== null" class="map__coordinates">
            {{ getCenter }}
          </div>

        </div>

        <info-window v-show="getMap !== null" v-bind:popObjectContent="weatherPopUpWindow" :popObject="popObject"
          :mapClickType="mapClickType" id="infoWindow">
        </info-window>

        <w-m-s-info-windows v-show="getMap !== null" id="WMSInfoWindow" v-bind:popObjectContent="WMSPopUpWindow"
          :popObject="WMSPopObject" />
        <w-m-s-complex-info-windows v-show="getMap !== null && popObjectContent !==undefined" id="WMSComplexInfoWindow"
          v-bind:popObjectContent="WMSComplexPopUpWindow" :popObject="WMSComplexPopObject" />

        <div v-show="errorMapPopUp" class="map__basic-error-popup">
          <div>
            <span> {{ errorMapPopUp }} </span>
          </div>
          <v-btn @click="mapIntanceError('')" color="red" flat>
            Close
          </v-btn>
        </div>

        <keystone-species-info />

        <layers-legend-map />

      </div>
    </div>
  </v-app>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex';
  // header
  import AppHeader from './map-header/Header';
  // control buttons and calendar components
  import ControlButtonsWrapper from './map-control-buttons/ControlButtonsWrapper';
  import ControlButtonsCalendar from './map-control-buttons/ControlButtonsCalendar';
  // menu wrapper component
  import MenuWrapper from './map-menus/MenuWrapper.vue';
  // map info window
  import InfoWindow from './map-info-windows/InfoWindow.vue'
  // info window for simple WMS features
  import WMSInfoWindows from './map-info-windows/WMSInfoWindows.vue';
  // info store for compex WMS features
  import WMSComplexInfoWindows from './map-info-windows/WMSComplexInfoWindows.vue'
  // keystoneSpecies info-window component
  import KeystoneSpeciesInfo from './map-menus/menu-layers-menu-composer/KeystoneSpeciesInfo.vue'
  // map legend component
  import LayersLegendMap from './map-menus/menu-layers-menu-composer/LayersLegendMap.vue';
  // map instance
  import mapApi from '../maps-constructor/mapsInit.js';
  // map-init-utils
  import mapInitUtils from '../maps-constructor/map-init-utils/utils';
  // drawingFeatureLogicApi
  import drawingToolsBehaviorApi from '../maps-constructor/drawingToolsBehavior';
  // helper for features
  import helperAPI from './mapsMainFeatureHelper.js';
  // center icon
  import IconCenterBase from './svg-system-components/IconCenterBase';
  // compas icon
  // import IconCompassBase from './svg-system-components/compass-icon/IconCompassBase';
  import weatherConfigs from '../maps-constructor/weather-constructor/weather-config';
  import moment from 'moment';

  export default {
    props: {
      mainMap: String
    },
    components: {
      AppHeader,
      ControlButtonsWrapper,
      ControlButtonsCalendar,
      MenuWrapper,
      InfoWindow,
      WMSInfoWindows,
      WMSComplexInfoWindows,
      KeystoneSpeciesInfo,
      LayersLegendMap,
      IconCenterBase
      // IconCompassBase
    },
    data() {
      return {
        mapInstance: null,
        mapWrapper: 'map',
        positionBox: 1,
        view: null,
        zoom: null,
        infoWindowActivity: true,
        popObject: null,
        WMSPopObject: null,
        WMSComplexPopObject: null,
        popObjectContent: {},
        mapClickType: "",
        windInterval: 1,
        waveInterval: 1,
        temperatureInterval: 1,
        waterHeightInterval: 1,
        waterTemperatureInterval: 1,
        obsProps: {},
        showObsProps: true
      }
    },
    computed: {
      ...mapGetters({
        baseMenuVisibility: 'getshowBaseMapMenu',
        getMap: 'getMap',
        getCenter: 'getCenterCoordinates',
        currentZoom: 'getCurrentZoom',
        satelliteXML: 'getSatelliteXML',
        satelliteLayersName: 'getSatelliteLayersNames',
        weatherPopUpWindow: 'getWeatherPopUpWindow',
        WMSPopUpWindow: 'getWMSPopUpWindowData',
        WMSComplexPopUpWindow: 'getWMSComplexPopUpWindowData',
        errorMapPopUp: 'getMapInstanceErrorPopUp',
        calendar: 'getCalendar',
        keystoneSpecies: 'getKeystoneSpeciesInfoWindow',
        layers: 'getWeatherInfoLayer',
        getLoadingStatus: 'getLoadingStatus',
        getLayerInterval: 'getLayerInterval'
      })
    },
    watch: {
      getLayerInterval(layerInterval) {
        if (layerInterval.wind === true && this.windInterval === 0) {
          this.setVisible(weatherConfigs.weatherWindName, true);
          this.showObsProps = true;
        } else if (layerInterval.wind === false && this.windInterval !== 0) {
          this.setVisible(weatherConfigs.weatherWindName, false);
          clearInterval(this.windInterval);
          this.windInterval = 0;
        }
        if (layerInterval.wave === true && this.waveInterval === 0) {
          this.setVisible(weatherConfigs.weatherWaveName, true);
        } else if (layerInterval.wave === false && this.waveInterval !== 0) {
          this.setVisible(weatherConfigs.weatherWaveName, false);
          clearInterval(this.waveInterval);
          this.waveInterval = 0;
        }
        if (layerInterval.temperature === true && this.temperatureInterval === 0) {
          this.setVisible(weatherConfigs.weatherTemperatureName, true);
        } else if (layerInterval.temperature === false && this.temperatureInterval !== 0) {
          this.setVisible(weatherConfigs.weatherTemperatureName, false);
          clearInterval(this.temperatureInterval);
          this.temperatureInterval = 0;
        }
        if (layerInterval.waterHeight === true && this.waterHeightInterval === 0) {
          this.setVisible(weatherConfigs.weatherWaterHeightName, true);
          // poistettu 17.9.2020 this.showObsProps = true;
        } else if (layerInterval.waterHeight === false && this.waterHeightInterval !== 0) {
          this.setVisible(weatherConfigs.weatherWaterHeightName, false);
          clearInterval(this.waterHeightInterval);
          this.waterHeightInterval = 0;
        }	
      },
      getCenter(newCenter) {
        if (this.$route.name === "MapsMain") {
          let newCenterSplit = newCenter.split(" ");
          let queryPosition = newCenterSplit[1] + "," + newCenterSplit[4];
          this.$router.push({
            name: 'MapsMain',
            params: {
              location: queryPosition,
              zoom: this.currentZoom
            }
          });
        }
      }
    },

    async mounted() {
      /****************************************************************************/
      /* Check routing params in order to follow saved coordinates and zoom level */
      /****************************************************************************/
      if (this.$route.params.location !== undefined) {
        let center = this.$route.params.location.trim();
        let centerArray = center.split(',').map(Number);
        let currentZoom = parseInt(this.$route.params.zoom);
        await this.initMap({
          center: centerArray.reverse(),
          zoom: currentZoom
        });
      } else {
        await this.initMap({
          center: [23.75, 60.25],
        });
        // zoom To User location
       mapApi.zoomToUserLocation(this.getMap)
      }

      this.setLoadingStatus(true); 

      /******************************************************************************/
      /* Loading predefined layer at startup, currently setting visibility to false */
      /******************************************************************************/
      let layerInterval = this.getLayerInterval;
      layerInterval.wind = false;
      this.setLayerInterval(layerInterval);
      setTimeout(() => {
        layerInterval.wave = false;
        this.setLayerInterval(layerInterval);
      }, 1000);
      setTimeout(() => {
        layerInterval.temperature = false;
        this.setLayerInterval(layerInterval);
      }, 1000);
      setTimeout(() => {
        layerInterval.waterTemperature = false;
        this.setLayerInterval(layerInterval);
      }, 1000);
      setTimeout(() => {
        layerInterval.waterHeight = false;
        this.setLayerInterval(layerInterval);
      }, 1000);
        
      // fetching overlay object
      // info store for non-WMS features
      let element = document.getElementById('infoWindow');
      // setting for ojects with non-wms features
      this.popObject = mapApi.addFeatureWindsInfoWindow(this.getMap, element);

      // info store for WMS features
      let elementWMS = document.getElementById('WMSInfoWindow');
      this.WMSPopObject = mapInitUtils.addingFeatureWindowLogic(this.getMap, elementWMS);

      // info store for WMS complex features  WMSComplexInfoWindow
      let elementComplexWMS = document.getElementById('WMSComplexInfoWindow');
      this.WMSComplexPopObject = mapInitUtils.addingFeatureWindowLogic(this.getMap, elementComplexWMS);


      /**********************************/
      /* Here goes map events listeners */
      /**********************************/

      // setting cursor on each feature if feature found
      this.getMap.on('pointermove', (e) => {
        let pixel = this.getMap.getEventPixel(e.originalEvent);
        let hit = this.getMap.hasFeatureAtPixel(pixel);

        this.getMap.getTargetElement().style.cursor = hit ? 'pointer' : '';
      })

      // setting click event to show pop - up info windows
      mapApi.asyncVectorRefresh(this.getMap);

      // click event listener
      this.getMap.on("click", (e) => {
        this.closeBaseMap();
        this.getMapClickType = "single"
        let layerOnClick = this.getMap.forEachLayerAtPixel(e.pixel, function (layer) {
          if (mapInitUtils.checkWMSLayers(layer)) {
            return layer;
          }
        });

        if (layerOnClick) {
          // logic for features with WMS and WMS get feature info
          if (layerOnClick && !layerOnClick.values_.dataType && layerOnClick.values_.type !== 'satellite') {
            let viewResolution = this.getMap.getView().getResolution();

            let featureInfoUrl = layerOnClick.getSource().getGetFeatureInfoUrl(
              e.coordinate, viewResolution, 'EPSG:3857', {
                'INFO_FORMAT': 'application/json'
              });
            mapInitUtils.getFeatureInfo(featureInfoUrl)
              .then((featureInfo) => {
                this.WMSPopObject.popup.setPosition(e.coordinate);
                this.createWMSInfoWindows(featureInfo.features);
                mapApi.asyncVectorRefresh(this.getMap);
              })
          // info from ulr dismiss - only from data on the map
          // change 28.8.2020
          //
          } else if (layerOnClick && layerOnClick.values_.dataType === "GeoJSON" || layerOnClick.values_.dataType === "JSON" || layerOnClick.values_.dataType === "text/xml") {
            let viewResolution = this.getMap.getView().getResolution();
            let featureInfoUrl;
            if (layerOnClick.values_.dataType === "JSON") {
              featureInfoUrl = layerOnClick.getSource().getGetFeatureInfoUrl(
                e.coordinate, viewResolution, 'EPSG:3857', {
                  'INFO_FORMAT': 'application/json'
                });
            }
            if (layerOnClick.values_.dataType === "GeoJSON") {
              featureInfoUrl = layerOnClick.getSource().getGetFeatureInfoUrl(
              e.coordinate, viewResolution, 'EPSG:3857', {
                'INFO_FORMAT': 'GeoJSON'
              });
            }

            // text/plain
            if (layerOnClick.values_.dataType === "text/plain") {
              featureInfoUrl = layerOnClick.getSource().getGetFeatureInfoUrl(
              e.coordinate, viewResolution, 'EPSG:3857', {
                'INFO_FORMAT': 'text/plain'
              });
            }  
            
            // text/xml
            if (layerOnClick.values_.dataType === "text/xml") {
              featureInfoUrl = layerOnClick.getSource().getGetFeatureInfoUrl(
              e.coordinate, viewResolution, 'EPSG:3857', {
                'INFO_FORMAT': 'text/xml'
              });
            }  

            mapInitUtils.getFeatureInfo(featureInfoUrl)
              .then((featureInfo) => {
                featureInfo = featureInfo.features;
                if (featureInfo) {
                  if (featureInfo && featureInfo[0] && featureInfo[0].properties && featureInfo[0].properties.Open311_ObservationID && featureInfo[0].properties.Open311_Service_Code) {
                    console.log('fetch additional feature info ' );
                    let url = 'https://rajapinnat.ymparisto.fi/api/kansalaishavainnot/1.0/requests.json?service_code=' + featureInfo[0].properties.Open311_Service_Code + '&extension=true&service_request_id=' +featureInfo[0].properties.Open311_ObservationID;
                    mapInitUtils.getFeatureInfo(url).then((additionalFeatureInfo) => {
                      this.WMSComplexPopObject.popup.setPosition(e.coordinate);
                      let data = [];
                      data[0] = { properties: additionalFeatureInfo[0]}; 
                      // commented, same case goes to line  this.createInfoWindows(data);
                      // and creates popup 4.9.2020 MH
                      //this.createWMSComplexWindows(data);
                    });
                  } else {
                    this.WMSComplexPopObject.popup.setPosition(e.coordinate);
                    this.createWMSComplexWindows(featureInfo);
                  }
                  mapApi.asyncVectorRefresh(this.getMap);
                }
              })
          }
        } else {
          // Close popups by clicking on map
          this.WMSPopObject.popup.setPosition(undefined);
          this.WMSComplexPopObject.popup.setPosition(undefined);
          this.popObject.popup.setPosition(undefined);
        }

        // logic for features with WFS or JSON
        var feature = this.getMap.forEachFeatureAtPixel(e.pixel, (feature) => {
          return feature;
        })

        if (
          feature &&
          feature.getProperties().features &&
          feature.getProperties().features.length > 1
        ) {
          let clusterPosition = feature.getGeometry().flatCoordinates;
          mapApi.zoomToCordinate(this.getMap, clusterPosition);
          return;
        } else if (feature) {
          if (drawingToolsBehaviorApi.checkInteraction(this.getMap) == true) {
            console.log('draw');
          } else {
            helperAPI.createFeatureInfoBox(this.getMap, feature)
              .then((data) => {

                if (data == undefined) {
                  return;
                }
                this.popObject.popup.setPosition(e.coordinate)
                this.createInfoWindows(data);
              })
          }
        }
      })

      this.getMap.on("dblclick", (e) => {
        this.mapClickType = "double"
        var feature = this.getMap.forEachFeatureAtPixel(e.pixel, (feature) => {
          return feature;
        })
        if (
          feature &&
          feature.getProperties().features &&
          feature.getProperties().features.length > 1
        ) {
          let clusterPosition = feature.getGeometry().flatCoordinates;
          mapApi.zoomToCordinate(this.getMap, clusterPosition);
          return;
        } else if (drawingToolsBehaviorApi.checkInteraction(this.getMap) == true) {
          console.log('drawing end');
        } else if (feature) {
          helperAPI.createFeatureInfoBox(this.getMap, feature)
            .then((data) => {
              if (data == undefined) {
                return;
              }
              this.popObject.popup.setPosition(e.coordinate)
              this.createInfoWindows(data);
            })
        }
      })
 
      // get current center coordinates
      this.getMap.on('moveend', () => {
        let center = mapApi.viewCenterCoordinates(this.getMap);
        let zoomLevel = this.getMap.getView().getZoom();
        this.setCurrentZoom(zoomLevel);
        this.positionBox = center;
        this.setCeneterCoordinates(center);

        let extent = this.getMap.getView().calculateExtent(this.getMap.getSize());
        if (mapApi.checkIfLayerVisible(this.getMap, "placesOfInterest") == true) {
          mapApi.addNewPOIS(this.getMap, extent);
        }

        // Show nearest weather observations
        if (this.windInterval !== 0) {
          this.obsProps = mapApi.getNearestObservation(this.getMap, weatherConfigs.weatherWindName);
        } else {
          this.obsProps = {};
        }
      });
    },
    beforeDestroy() {
      clearInterval(this.windInterval);
      clearInterval(this.waveInterval);
      clearInterval(this.temperatureInterval);
      clearInterval(this.waterHeightInterval);      
    },
    methods: {
      ...mapActions(
        [
          'getCompabilities',
          'initMap',
          'parseSatellitePictures',
          'fetchSatelliteLayersNames',
          'createInfoWindows',
          'createWMSInfoWindows',
          'createWMSComplexWindows',
          'fetchWeathersLayers',
          'fetchWinds',
          'fetchWaves',
          'fetchTemperatures',
          'fetchWaterTemperature',
          'fetchWaterHeight',	  
          'setVisibleLayer',
          'zoomToUserLocation'
        ]
      ),
      ...mapMutations(
        [
          'setCeneterCoordinates',
          'setCurrentZoom',
          'setSelectedWeatherLayers',
          'setSelecteSubLayers',
          'mapIntanceError',
          'setLoadingStatus',
          'setLayerInterval',
          'closeBaseMap'
        ]
      ),
      async setVisible(name, interval) {
        this.setLoadingStatus(true);
        if (name === weatherConfigs.weatherWindName) {
          if (interval) {
            await this.fetchWinds(this.getMap);
            this.windInterval = setInterval(() => { // Fetch wind data every 10 minutes
              this.fetchWinds(this.getMap);
	          this.obsProps = mapApi.getNearestObservation(this.getMap, weatherConfigs.weatherWindName);
            }, 10 * 60 * 1000);
            setTimeout(() => {
              this.obsProps = mapApi.getNearestObservation(this.getMap, weatherConfigs.weatherWindName);
            }, 1000);
          }
        } else if (name === weatherConfigs.weatherWaveName) {
          if (interval) {
            await this.fetchWaves(this.getMap);
            this.waveInterval = setInterval(() => { // Fetch wave data every 30 minutes
              this.fetchWaves(this.getMap);
            }, 30 * 60 * 1000);
          }
        } else if (name === weatherConfigs.weatherTemperatureName) {
          if (interval) {
            await this.fetchTemperatures(this.getMap);
            this.temperatureInterval= setInterval(() => { // Fetch temperature data every 30 minutes
              this.fetchTemperatures(this.getMap);
            }, 30 * 60 * 1000); // 30 * 60 * 1000
            // await this.fetchWaterTemperatures(this.getMap);
            // this.temperatureInterval= setInterval(() => { // Fetch temperature data every 30 minutes
            //   this.fetchWaterTemperatures(this.getMap);
            // }, 30 * 60 * 1000); // 30 * 60 * 1000
          }
	      } else if (name === weatherConfigs.weatherWaterHeightName) {
          if (interval) {
            await this.fetchWaterHeight(this.getMap);	
            //console.log("fetchWaterHeight: " + name + " interval: " + interval)
	          this.waterHeightInterval = setInterval(() => { // Fetch water height data every 30 minutes
              this.fetchWaterHeight(this.getMap);
            }, 30 * 60 * 1000);
          }
        }
        this.setVisibleLayer({
          mapInstance: this.getMap,
          name: name,
          layerGroupName: weatherConfigs.weatherGroupName
        });
        setTimeout(() => {
          let weatherLayerAmount = this.layers.filter(sel => sel.visible).length;
          this.setSelectedWeatherLayers(weatherLayerAmount);
          mapApi.asyncVectorRefresh(this.getMap);
          this.setLoadingStatus(false);
        }, 100);
      },
      windIcon(speed) {
        // if (speed < 7) {
        //   return '/static/weatherIcons/vihrea_alas.svg'; //'punainen_alas.svg'; v_testi.svg
        // } else if (speed < 14) {
        //   return '/static/weatherIcons/keltainen_alas_kesk5.svg';
        // } else {
        //   return '/static/weatherIcons/punainen_alas.svg';
        // }
        if (speed < 7) {
          return 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wind_3.0_g.png';
        } else if (speed < 14) {
          return 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wind_3.0_y.png';
        } else {
          return 'https://meriopas.ymparisto.fi/meriopas/static/weatherIcons/wind_3.0_r.png';
        }
      },
      round(value) {
        return Math.round(value);
      },
      formatDate(date) {
        return moment(date).format("HH:mm");
      }
    }
  }

</script>

<style scoped lang="scss">
  h1 {
    color: $firstColor;
    margin: 0;
  }

 ::v-deep  {

    .ol-rotate {
      display: none;
    }

    
    .ol-control {
      position: fixed;
      bottom: 1%;
      right: 5px;
      background-color: transparent;
      padding: 0;
      border-radius: 0;

      &:hover {
        background-color: transparent;
      }

      .ol-rotate-custom-compass-reset {
        background-color: white;
        border-radius: 50%;
        height: 2.5rem;
        width: 2.5rem;
        outline: none;
      }
    }

    .ol-zoom {
      background-color: transparent;
      border-radius: none;
      display: none;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      button.ol-zoom-in {
        padding: 2rem;
        display: flex;
        border-radius: 30px;
        line-height: 0rem;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        margin-bottom: 1rem;
        font-weight: 100;
        background-color: rgba(0, 60, 136, 0.9);
        outline: none;
      }

      button.ol-zoom-out {
        padding: 2rem;
        display: flex;
        border-radius: 30px;
        line-height: 0rem;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        font-weight: 100;
        background-color: rgba(0, 60, 136, 0.9);
        outline: none;
      }
    }

    .ol-full-screen {
      display: none;
    }

    .ol-scale-line {
      background-color: transparent;
      position: relative;
      border-color: $primary;
      color: $primary;
      bottom: 0;
      left: 0;
      padding: 0;

      .ol-scale-line-inner {
        background-color: transparent;
        border-color: #61605d;
        border-width: 2px;
        color: #61605d;
      }
    }

    .ol-overlay-container {
      z-index: 94;
    }

    // Measurment tooltips
    .tooltip {
      position: relative;
      background: rgb(61, 137, 199);
      border-radius: 5px;
      color: white;
      padding: 0.5rem;
      white-space: nowrap;
    }

    .tooltip-measure {
      opacity: 1;
      font-weight: bold;
    }

    .tooltip-static {
      background-color: #052d4d;
      display: flex;
      padding: .5rem;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      align-items: center;

      .tooltip-static--delete {
        width: 10px;
        height: 10px;
        background-image: url("https://meriopas.ymparisto.fi/meriopas/static/toolsMenuIcons/close.svg");
        background-repeat: no-repeat;
        background-position: center;
        align-self: flex-start;
        margin-left: 10px;
      }

    }

    .tooltip-measure:before,
    .tooltip-static:before {
      border-top: 6px solid rgb(61, 137, 199);
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      content: "";
      position: absolute;
      bottom: -6px;
      margin-left: -7px;
      left: 50%;
    }

    .tooltip-static:before {
      border-top-color: #3d89c7;
    }
  }

  .window-info--close {
    position: absolute;
    right: 2px;
    cursor: pointer;
    top: 2px;
  }

  .obs {
    position: relative;
    background-color: #074679;
    border: 2px solid #1884bf;
    border-right-style: none;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 150px;
    height: 80px;
    color: white;
    font-size: 12px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;

    @media screen and (max-width: 768px) {
      top: 15%;
      bottom: auto;
    }
  }

  .obs-visible {
    -webkit-transform: translate(-63%, 0) !important;
    transform: translate(-63%, 0) !important;
  }

  .iconBox {
    position: relative;
    width: 80px;
    height: 80px;
    top: -13px;
    left: -10px;
    color: black;
  }

  .iconBox img,
  .iconBox div {
    position: absolute;
    left: 0;
    top: 0;
  }

  .iconBox div {
    display: inline-block;
    width: 80px;
    height: 80px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    margin-top: 29.5px;
    margin-left: 0;
  }

  .place {
    text-overflow: ellipsis;
    width: 115px;
    white-space: nowrap;
    overflow-x: hidden;
    margin-left: 10px;
    margin-top: 3px;
    text-align: left;
  }

  .place-small {
    width: 47px;
  }

  .props {
    position: absolute;
    top: 28px;
    left: 62px;
    line-height: 18px;
  }

  #liner {
    position: absolute;
    top: 58px;
    right: 0;
    margin-right: .5rem;
    margin-bottom: .5rem;
  }

  .ol-zoom {
    padding: 2rem;
  }

  .fullscreen:fullscreen {
    height: 100%;
  }

  .map-wrapper {
    height: 100%;
    width: 100%;

    .header {
      width: 100%;
      margin: 0;
      height: 5rem;
      background-color: gray;
    }
  }

  .map-loader {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  .map__center-sign {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    pointer-events: none;
  }

  .map__coordinates {
    position: absolute;
    bottom: 0;
    width: 13rem;
    color: #61605d;
    font-size: 12px;
    width: 5rem;
    right: 100%;
    line-height: 1;
    margin-right: .5rem;
    text-align: left;

  }

  .map-wrapper .fullscreen {
    height: 100%;
    width: 100%;
    display: flex;

    .map {
      flex-basis: 100%;
      position: relative ; 
      // relative absolute;
    }
  }

  .app-header {
    position: absolute;
    height: 47px;
    width: 100%;
    top: 0;
    background-color: rgb(9, 91, 157);
  }

  .ol-rotate-custom-compass {
    border-radius: none;
    background-color: transparent;
    padding: 0;
  }

  .map__menu-button {
    position: absolute;
    top: 0;
    padding: 1rem;
  }

  .control-buttons-wrapper__position {
    position: fixed;
    top: 200px;
    right: 0;
    height: auto;
    z-index: 90;
    width: 55px;

    @media screen and (max-width: 768px) {
      position: fixed;
      top: 100px;
      right: 0;
      height: auto;
    }

    @media screen and (max-width: 414px) {
      position: fixed;
      top: 50px;
      right: 0;
      height: auto;
    }

    .map__control-buttons {
      background-color: #074679;
      border: 2px solid hsl(201, 78%, 42%);
      border-right-style: none;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      margin-bottom: 10px;
    }

    .map__control-buttons--calendar-off {
      margin-bottom: 10px;
    }

    .map__control-calendar {
      position: relative;
      // top			: calc(100% + 19.8px);
      background-color: rgb(7, 70, 121);
      border-top: 2px solid hsl(201, 78%, 42%);
      border-bottom: 2px solid hsl(201, 78%, 42%);
      border-left: 2px solid hsl(201, 78%, 42%);
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      margin-bottom: 10px;
    }
  }

  .control-buttons-wrapper__position--calendar-off {
    @media screen and (max-width: 768px) {
      position: fixed;
      top: 100px;
      right: 0;
      height: auto;
    }

    @media screen and (max-width: 414px) {
      position: fixed;
      top: 50px;
      right: 0;
      height: auto;
    }
  }

  .map__control-buttonsBottom {
    position: absolute;
    bottom: 5%;
    right: 0;
    height: auto;
    padding: 1rem;
  }

  .map__menu-wrapper {
    position: absolute;
    width: 266px;
    min-width: 0rem;
    bottom: 0;
    top: 49px;
    padding: 0;
  }
  
  .map-alert {
    height: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .map__basic-error-popup {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #323232;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 1rem;

    div {
      &:first-of-type {
        margin-right: 1rem;

        span {
          color: white;
        }
      }
    }
  }

</style>
