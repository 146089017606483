<template>
  <div class="base-wrapper__sidemenu" :class="{ 'base-wrapper__sidemenu--visible': baseMenuVisibility }">
    <div class="sidemenu-header">
      <v-btn class="sidemenu-header__button" @click="closeBaseMap()" color="secondary" medium flat icon>
        <component :is="setMenuIcon">
        </component>
      </v-btn>
      <p> {{ setMenuText | translate}} </p>
      <div class="sidemenu-header__button-close" style="right:-28px;top:46px" @click="toggleMenu()">
        <v-icon v-show="baseMenuVisibility">
          chevron_left
        </v-icon>
        <v-icon v-show="!baseMenuVisibility">
          chevron_right
        </v-icon>
      </div>
    </div>
    <keep-alive>
      <component :is="setDynamicMenu" v-bind:map="map" v-touch="{
                left: () => swipe('Close'),
                right: () => swipe('Open')
            }">
      </component>
    </keep-alive>
  </div>
</template>

<script>
  import mapApi from '../../maps-constructor/mapsInit';
  import {
    mapGetters,
    mapMutations,
  } from 'vuex';
  import menuTitlesConfig from '../../maps-constructor/menu-titles.configs/configs';
  // icons components
  import IconMenuBase from '../svg-system-components/IconMenuBase'
  import IconMapBase from '../svg-system-components/IconMapBase';
  import IconLayerBase from '../svg-system-components/IconLayerBase';
  import IconSpatielBase from '../svg-system-components/IconSpatielBase';
  import IconWeatherBaseMap from '../svg-system-components/IconWeatherBaseMap';
  // menu components
  import MenuBaseMap from './MenuBaseMap.vue';
  import MenuSpatial from './MenuSpatial.vue';
  import Menu from './Menu.vue';
  import MenuSatellite from './MenuSatellite.vue';
  import MenuWeatherMap from './MenuWeatherMap.vue';
  export default {
    components: {
      MenuBaseMap,
      MenuSpatial,
      Menu,
      MenuSatellite,
      MenuWeatherMap,
      IconMenuBase,
      IconMapBase,
      IconLayerBase,
      IconSpatielBase,
      IconWeatherBaseMap
    },
    props: ['map'],
    data() {
      return {
        dynamicComponentMenu: "",
        iconsHash: {
          'info': "IconMenuBase",
          'maps': "IconMapBase",
          'satellitePictures': "IconLayerBase",
          'spatialInfo': "IconSpatielBase",
          'weather': "IconWeatherBaseMap"
        },
        swipeDirection: "None"
      }
    },
    computed: {
      ...mapGetters({
        buttonsActivity: 'getButtonsActivity',
        baseMenuVisibility: 'getshowBaseMapMenu',
        keyStoneLayers: 'getKeyStoneSpecies',
        baseMapLayers: 'getBaseMapLayers',
        getMap: 'getMap',
        getCenter: 'getCenterCoordinates',
        zoom: 'getCurrentZoom',
      }),
      setMenuIcon() {
        let selected = this.buttonsActivity.filter((button) => {
          if (button.isActive == true) {
            return button;
          }
        })
        return this.iconsHash[selected[0].name];
      },
      setMenuText() {
        let text = '';
        this.buttonsActivity.forEach((button) => {
          if (button.isActive == true) {
            text = button.name
          }
        })
        return text;
      },
      setDynamicMenu() {
        let dynamicMenu = this.buttonsActivity.filter((button) => button.isActive == true);
        switch (dynamicMenu[0].name) {
          case menuTitlesConfig.mapsMenu:
            this.dynamicComponentMenu = 'MenuBaseMap';
            break;
          case menuTitlesConfig.spatialMenu:
            this.dynamicComponentMenu = 'MenuSpatial';
            break;
          case menuTitlesConfig.menu:
            this.dynamicComponentMenu = 'Menu'; 
            break;
          case menuTitlesConfig.satelliteMenu:
            this.dynamicComponentMenu = 'MenuSatellite';
            break;
          case menuTitlesConfig.weatherMenu:
            this.dynamicComponentMenu = 'MenuWeatherMap';
            break;
          default:
            this.dynamicComponentMenu = "MenuBaseMap";
        }
        return this.dynamicComponentMenu;
      }
    },
    methods: {
      ...mapMutations(['closeBaseMap', 'changeBaseMapVisibility']),
      setVisible(name) {
        mapApi.setVisibility(this.map, name);
      },
      toggleMenu() {
        if (this.baseMenuVisibility) {
          this.closeBaseMap();
        } else {
          this.changeBaseMapVisibility();
        }
      },
      swipe(direction) {
        if (direction == "Close") {
          this.closeBaseMap();
        } else  if (direction == "Open") {
          this.changeBaseMapVisibility();
        } else {
          return;
        }
      },
    },

    watch: {
      getCenter(newCenter) {
        if (this.$route.name === "menu") {
          let newCenterSplit = newCenter.split(" ");
          let queryPosition = newCenterSplit[1] + "," + newCenterSplit[4];
          this.$router.push({
            name: 'menu',
            params: {
              locationCoords: queryPosition,
              zoomLevel: this.zoom
            }
          }); //.catch(() => { /* ignore */ });
          // this.$router.push('/').catch(() => { /* ignore */ });
          // if (this.$route.path !== path) this.$router.push(path)
        }
      }
    },
    mounted() {
      if (this.$route.params.locationCoords !== undefined) {
        let center = this.$route.params.locationCoords.trim();
        let currentZoom = parseInt(this.$route.params.zoomLevel);
        let centerArray = center.split(',').map(Number);
        mapApi.zoomToSelectedPosition(this.map, centerArray.reverse(), currentZoom);
      } else {
        return;
      }
    }
  }

</script>

<style scoped lang="scss">

  .base-wrapper__sidemenu {
    @include base-wrapper__sidemenu;

    .sidemenu-header {
      p {
        font-family: 'Montserrat-Semibold';
        font-size: 15px;
        padding: 11.2px;
      }
    }
    .sidemenu-header__button {
      margin: 0;
    }
  }
  .sidemenu-header__button-close {
    background-color: rgb(9, 91, 157);
    border: 2px solid #1884bf;
    border-left-style: none;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    font-size: 12px;
  }

  .base-wrapper__sidemenu--visible {
    transform: translate(0%, 0%);
  }

  .icon {
    color: white !important;
  }
  .theme--light.v-icon
  {
    color: white !important;
  }
</style>
