<template>
	<svg
		xmlns               = "http://www.w3.org/2000/svg"
		xmlns:xlink         = "http://www.w3.org/1999/xlink"
		x                   = "0px"
		y                   = "0px"
		:width              = "width"
		:height             = "height"
		viewBox             = "0 0 22.688 18.647"
		:aria-labelledby    = "iconName | translate"
		role                = "presentation"
	>
		<title
			:id     = "iconName"
			lang    = "en"
		>
			{{iconName | translate}}
		</title>
        <g id="noun_1666571_cc" transform="translate(391 -80.015)">
            <g id="Group_5" transform="translate(-391 80.015)">
                <path id="Path_14" :fill=iconColor d="M7.563,0.04L0.5,2.561C0.202,2.675,0.004,2.959,0,3.278v14.617
                    c0.03,0.437,0.41,0.767,0.847,0.737c0.054-0.004,0.108-0.013,0.161-0.028l6.806-2.434l6.806,2.434c0.162,0.056,0.338,0.056,0.5,0
                    l7.058-2.521c0.295-0.113,0.493-0.393,0.5-0.709V0.757c-0.026-0.438-0.402-0.771-0.84-0.745c-0.057,0.003-0.113,0.013-0.168,0.028
                    l-6.806,2.434L8.058,0.04C7.897-0.011,7.724-0.011,7.563,0.04z M7.063,1.82v13.018l-5.546,1.977V3.805L7.063,1.82z M8.576,1.82
                    l5.546,1.985v13.017l-5.546-1.985V1.82z M21.176,1.828v13.01l-5.547,1.984V3.805L21.176,1.828z"/>
            </g>
        </g>
	</svg>
</template>
<script>

export default {
	props: {
		iconName: {
			type: String,
			default: 'maps'
		},
		width: {
			type: [Number, String],
			default: 23
		},
		height: {
			type: [Number, String],
			default: 19
		},
		iconColor: {
			type: String,
			default: '#FFFFFF'
		}
	}
}

</script>

<style scoped>

	svg {
		display           : inline-block;
		vertical-align    : middle;
	}

</style>
