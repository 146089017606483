// Map , View, Layer imports
import Map from 'ol/map';
import View from 'ol/view';
import Group from 'ol/layer/group';
import Fullscreen from 'ol/control/fullscreen';
import control from 'ol/control';
import coordinate from 'ol/coordinate';
import ScaleLine from 'ol/control/scaleline';
import WMSCapabilities from 'ol/format/wmscapabilities';

// imports for projections
import proj4 from 'proj4';
import proj from 'ol/proj';

import Geolocation from 'ol/geolocation';
import Feature from 'ol/feature';
import Vector from 'ol/layer/vector';
import positionFeature from './myPositionConstructor/my-position-positionFeature';
import Point from 'ol/geom/point';
import VectorSource from 'ol/source/vector';
// configs for layers
import spatialConfiguration from './spatial-constructor/spatial-config';
// satellites configuration
import satelliteLayersConfig from './satellite-layers.configs/config';
// base maps configs
import baseMapsConfig from './basemap-layers.configs/config';
//extent
import extent from 'ol/extent';
//base map layers
import baseMapLayers from './baseMapLayers';
// weather model layers
import weatherLayers from './weather-constructor/weatherModel';
import weatherConfigs from './weather-constructor/weather-config';
// spatial model layers
import spatialLayers from './spatial-constructor/spatialModel';
// keyStoneSpecies layers
import keyStoneSpeciesLayers from './keystoneSpecies';
import keyStoneSpeciesConfigs from './keystoneSpecies-layers.configs/configs';
// Navigational restriction layer
import navigationalModels from './navigationalRestrictionsLayers';
// National parks layers
import nationalModels from './nationalParksLayers';
// Naturreservat layers
import naturreservatModels from './naturreservatLayers';
import algaeBloomLayers from './algaeBloomLayers';
// reed areas layer
import reedAreasModelsLayers from './reedAreasModels';
// sela protected areas
import sealProtectedLayers from './sealProtectedModel';
//import axios API for wms/wfs layers
import wmsAxiosApi from '../axios-wms-conf/axiosWmsConf';
import wfsAxiosApi from '../axios-wms-conf/axiosWFSConf';
// api for creating wrecks loctions
import Wrecks_API from './spatial-constructor/wrecksLayersContructor';
import Swimming_API from './spatial-constructor/swimmingInfoConstructor';
import Boating_API from './spatial-constructor/boatingInfoConstructor';
// api for creating video loctions
import Video_API from './spatial-constructor/videosLayersConstructor';
// api for creating pictures loctions
import Pictures_API from './spatial-constructor/picturesLayersConstructor';
// api for creeating spatiels
import aisAPI from './spatial-constructor/aisLayersConstructor';
//api for sreating poi logic
import poiAPI from './spatial-constructor/polLayersConstructor';
// api for creating winds and waves info objects
import windWaveAPI from './weather-constructor/windWaveLayersConstructor';
// import satellites API
import satellitesApi from './satellites-constructor/satellitesLayersConstructor'
// import features popups methods
import featuresWindows from './mapFeatureLogic.js'
// water quality turbidity
import addWaterQualitiesTURBIDITY from './waterQualityConstructor';
// water surface temp
import addWaterSurface from './WaterSurfaceTempConstructor';
// layer for drawing features
import toolsLayers from './drawingToolsLayers';
import drawingToolsAPI from './drawingToolsLayersConstructor';
import Style from 'ol/style/style';
import Stroke from 'ol/style/stroke';
import CircleStyle from 'ol/style/circle';
import easeOut from 'ol/easing.js';
import Fill from 'ol/style/fill';
import Observable from 'ol/observable';
// router for translations
import router from '../router';

import moment from 'moment';

let mapApi = {
  initMap: initMap,

  parseWaterQualityWMS: parseWaterQualityWMS,
  addFeatureWindsInfoWindow: addFeatureWindsInfoWindow,
  setProjections: setProjections,
  followLayers: followLayers,
  setVisibility: setVisibility,

  // zooming logic
  zoomIn: zoomIn,
  zoomOut: zoomOut,
  zoomToCordinate: zoomToCordinate,
  returnToInitialZoomCenter: returnToInitialZoomCenter,
  zoomToSelectedPosition: zoomToSelectedPosition,
  zoomToUserLocation: zoomToUserLocation,
  viewCenterCoordinates: viewCenterCoordinates,

  // view center coordinates for URL
  vuewCenterURLCoordinates: vuewCenterURLCoordinates,
  parseSatelliteWMS: parseSatelliteWMS,
  getLastSatelliteLayersName: getLastSatelliteLayersName,
  parseSpatilWFSwrecks: parseSpatilWFSwrecks,
  parseSpatilWFSwrecks_AHV: parseSpatilWFSwrecks_AHV,
  findSpatialLayersGroup: findSpatialLayersGroup,

  // methods for adding spatiel points
  addAISPonts: addAISPonts,
  getSingleAISInfo: getSingleAISInfo,

  // methods for adding weather info objects
  addWindsPoints: addWindsPoints,

  asyncVectorRefresh: asyncVectorRefresh,
  addWrecksLocations: addWrecksLocations,
  // addWrecksLocations_AHV: addWrecksLocations_AHV,
  addAlgaeBloomLocations: addAlgaeBloomLocations,
  addBoatingInfoLocations: addBoatingInfoLocations,
  addSwimmingInfoLocations: addSwimmingInfoLocations,

  // method for adding waves points
  addWavesPoints: addWavesPoints,

  // method for adding temperature points
  addTemperaturesPoints: addTemperaturesPoints,

  // method for adding water temperature points
  addWaterTemperaturePoints: addWaterTemperaturePoints,

  // method for adding water height points
  addWaterHeightPoints: addWaterHeightPoints,

  // inner API references
  getPlannerGroup: getPlannerGroup,
  findValuesInMembers: findValuesInMembers,

  //parse satellites layers
  addSatellitesLayers: addSatellitesLayers,

  // parseWaterQualityWMS
  parseWaterQualityWMS: parseWaterQualityWMS,
  getLastWaterQualityLayersName: getLastWaterQualityLayersName,

  // parse WaterSurfaceTemp
  addWaterTurbidity: addWaterTurbidity,

  // water surface temp
  addWaterSurfaceLayers: addWaterSurfaceLayers,
  getLastTimeDimension: getLastTimeDimension,
  getLastTimesMap: getLastTimesMap,

  addPOIPoints: addPOIPoints,
  transformExtentsTo4326: transformExtentsTo4326,
  checkIfLayerVisible: checkIfLayerVisible,
  addNewPOIS: addNewPOIS,

  addDrawingLayer: addDrawingLayer,
  clearDrawingVectorSource: clearDrawingVectorSource,

  changeMapProjection: changeMapProjection,
  setDefaultProjection: setDefaultProjection,

  removeSatelliteLayers: removeSatelliteLayers,
  clearAllLayers: clearAllLayers,
  deleteMeasureOverlay: deleteMeasureOverlay,

  setZoomIn: setZoomIn,
  featureCentered: featureCentered,
  addVideosLocations: addVideosLocations,
  addPicturesLocations: addPicturesLocations,
  getNearestObservation: getNearestObservation
}

proj.setProj4(proj4);
// let accuracyFeature = new Feature();

/******************************/
/* Create Map point data vector layer */
/******************************/
async function addWrecksLocations(map) {
  await Wrecks_API.addLocations(map);
}

// async function addWrecksLocations_AHV(map) {
//   await Wrecks_API.addLocations_AHV(map);
// }

async function addAlgaeBloomLocations(map) {
  await AlgaeBloom_API.addLocations(map);
}

async function addBoatingInfoLocations(map) {
  await Boating_API.addLocations(map);
}

async function addSwimmingInfoLocations(map) {
  await Swimming_API.addLocations(map);
}

async function addVideosLocations(map) {
  await Video_API.addLocations(map);
}

async function addPicturesLocations(map) {
  await Pictures_API.addLocations(map);
}

/***************************/
/* Create AIS vector layer */
/***************************/
async function addAISPonts(map, params) {
  await aisAPI.getAISFeatures(map, params);
}

/***************************/
/* Get single AIS by mmsi  */
/***************************/
async function getSingleAISInfo(map, params) {
  let singleAIS = await aisAPI.getSingleAIS(map, params);
  return singleAIS;
}

/*****************************/
/* Create winds vector layer */
/*****************************/
async function addWindsPoints(map) {
  await windWaveAPI.getWindsFeatures(map);
}

/*****************************/
/* Create temperature vector layer */
/*****************************/
async function addTemperaturesPoints(map) {
  // tämä on alkuperäinen, jossa haetaan pintalämpötilat
  // kyselyllä STOREDQUERY_ID=fmi::observations::wave::multipointcoverage
  // TOIMII: 
  await windWaveAPI.getTemperaturefeatures(map);
  //ja tässä lisätään kaikki mareographien pintalämpötilat, saadaan kyselyllä
  // STOREDQUERY_ID=fmi::observations::mareograph::instant::multipointcoverage
  //await windWaveAPI.getWaterHeightTempFeatures(map);
  // ei toimi vielä oikein 20.5.2021
}

/*****************************/
/* Create Watertemperature vector layer */
/*****************************/
async function addWaterTemperaturePoints(map) {
  await windWaveAPI.getWaterTemperaturefeatures(map);
}

/*****************************/
/* Create waves vector layer */
/*****************************/
async function addWavesPoints(map) {
  await windWaveAPI.getWavesFeatures(map);
}
/*****************************/
/* Create water height layer */
/*****************************/
async function addWaterHeightPoints(map) {
  await windWaveAPI.getWaterHeightFeatures(map);
}

/*****************************/
/* Create POI vector layer   */
/*****************************/
async function addPOIPoints(mapPOI) {
  await poiAPI.getPOIFeatures(mapPOI.map, mapPOI.POI);
}


/*****************************/
/* Add POI according to BBOX */
/*****************************/
function addNewPOIS(map, extent) {
  let specifiedExtents = mapApi.transformExtentsTo4326(extent);
  console.log('specifiedExtents for POIS: ' + specifiedExtents);
  poiAPI.getPOIFeaturesToExtents(map, specifiedExtents);
}


/************************************/
/* Add Drawing tools layers to BBOX */
/************************************/
function addDrawingLayer(map) {
  drawingToolsAPI.createDrawingLayer(map);
}

function clearDrawingVectorSource(map) {
  let toolsGroup = getPlannerGroup(map, "Drawing tools");
  toolsGroup[0].getSource().clear();
  toolsGroup[0].getSource().refresh({
    force: true
  });
}

/********************************************/
/*   Create Satellites images WMS layer 	*/
/********************************************/
function addSatellitesLayers(map, item) {
  return new Promise((resolve, reject) => {
    let satellitesLayersGroup = getPlannerGroup(map, item.name);
    if (satellitesLayersGroup.length >= 1) {
      console.log('full group');
      asyncVectorRefresh(map);
      resolve();
    } else {
      satellitesApi.addLayersNames(map, item)
        .then((satelliteLayers) => {
          satelliteLayers.forEach((layer) => {
            satellitesLayersGroup.push(layer);
            satellitesLayersGroup[0].setVisible(true);
            asyncVectorRefresh(map)
          })
          resolve()
        })
    }
  })
}

/********************************************/
/* Create water quality turbidity WMS layer */
/********************************************/
function addWaterTurbidity(map, name) {
  return new Promise((resolve, reject) => {
    let waterTurbidityGroup = getPlannerGroup(map, name);
    if (waterTurbidityGroup.length > 1) {
      // always show first layers as visible
      waterTurbidityGroup[0].setVisible(true);
      asyncVectorRefresh(map);
      resolve();
    } else {
      addWaterQualitiesTURBIDITY(name)
        .then((turbidityWaterLayers) => {

          turbidityWaterLayers.forEach((layer) => {
            waterTurbidityGroup.push(layer);
            asyncVectorRefresh(map);
          })
          resolve();
        })
    }
  })
}

/********************************************/
/* 		Removing satellites layers 			*/
/********************************************/
function removeSatelliteLayers(map, name) {
  let removingGroup = getPlannerGroup(map, name);
  removingGroup.forEach((layer) => {
    layer.setVisible(false);
  })
}


/**********************************/
/* Create water surface WMS layer */
/**********************************/
function addWaterSurfaceLayers(map, name) {
  return new Promise((resolve, reject) => {
    // if any layers already exists in this group (if user click on the same layer in left menu more than one time)
    let waterSurfaceGroup = getPlannerGroup(map, name);
    // if found just refresh the layers group and do nothing
    if (waterSurfaceGroup.length >= 1) {
      waterSurfaceGroup[0].setVisible(true);
      asyncVectorRefresh(map);
      resolve();
    }
    /* add new layers group with layers in it to the store */
    else {
      addWaterSurface(name)
        .then((waterSurfaceLayers) => {
          waterSurfaceLayers.forEach((layer) => {
            waterSurfaceGroup.push(layer);
            asyncVectorRefresh(map);
          })
          resolve();
        })
    }
  })
}

// set projections of the map
function setProjections(layerGroup, map) {
  let extents = new extent.createEmpty();
  layerGroup.getLayers().forEach((layer) => {
    extent.extend(extents, layer.getSource().getExtent())
  });
  map.getView().fitExtent(extents, map.getSize());
}

// set lat/lon of the map center
function viewCenterCoordinates(map) {
  let center = proj.transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
  center = coordinate.format(center, 'N {y} \n E {x}', 5);
  return center;
}

// set lat / lon for URL
function vuewCenterURLCoordinates(map) {
  let center = proj.transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
  return center;
}

function featureCentered(map, featureCoords) {
  let view = map.getView();
  let featureCenter = proj.fromLonLat(featureCoords);
  view.animate({
    duration: 400,
    center: featureCenter
  })
}

function zoomIn(map) {
  let view = map.getView();
  let zoom = view.getZoom();
  view.animate({
    zoom: zoom + 1,
    duration: 200
  });
}

function setZoomIn(map) {
  let view = map.getView();
  let zoom = view.getZoom()
  view.animate({
    zoom: 7,
    duration: 200
  });
}

function zoomOut(map) {
  let view = map.getView();
  let zoom = view.getZoom()
  view.animate({
    zoom: zoom - 1,
    duration: 200
  });
}

function returnToInitialZoomCenter(map) {
  let view = map.getView();
  if (view.getProjection().getCode() == 'EPSG:3067') {
    let centerOf3067 = proj.transform([-12.52071, 52.00288], 'EPSG:4326', 'EPSG:3067');
    view.animate({
      zoom: 2,
      duration: 800,
      center: centerOf3067
    });
  } else {
    let finlandLonLat = [23.79199, 63.12915];
    let finlanLonLatMercator = proj.fromLonLat(finlandLonLat);
    view.animate({
      zoom: 2,
      duration: 800,
      center: finlanLonLatMercator
    });
  }
}

function zoomToSelectedPosition(map, selectedPosition, selectedZoom) {
  let view = map.getView();
  let finlandLonLat = selectedPosition;
  let finlanLonLatMercator = proj.fromLonLat(finlandLonLat);
  view.animate({
    zoom: selectedZoom,
    duration: 800,
    center: finlanLonLatMercator
  });
}

/************************************/
/* USER_GEOLOCATION ANIMATED: START */
/************************************/
let animationListener;
let duration = 3000;

function zoomToUserLocation(map) {
  let view = map.getView();
  navigator.geolocation.getCurrentPosition((pos) => {
    let coords;

    if (view.getProjection().getCode() == "EPSG:3067") {
      coords = proj.fromLonLat([pos.coords.longitude, pos.coords.latitude], 'EPSG:3067');
    } else {
      coords = proj.fromLonLat([pos.coords.longitude, pos.coords.latitude]);
    }

    view.animate({
      center: coords,
      zoom: 12
    })

    positionFeature.setGeometry(coords ? new Point(coords) : null);

    let posVector = getPosVector(map);
    if (posVector.getSource() && posVector.getSource().getFeatures().length > 0) {
      if (animationListener) {
        Observable.unByKey(animationListener);
      }
      posVector.getSource().clear();
      posVector.getSource().addFeatures([positionFeature]);
      posVector.getSource().refresh();
    } else {
      let posSource = new VectorSource({})

      posVector.setSource(posSource);
      posSource.on('addfeature', function (e) {
        flash(e.feature, map, posVector)
      })
      posSource.addFeatures([positionFeature]);
    }

  })
}

function getPosVector(map) {
  let v;
  map.getLayers().forEach(function (el) {
    if (el.get('title') === 'Position Vector') {
      console.log(el);
      v = el;
    }
  })
  return v;
}

function flash(feature, map, posVector) {
  let start = new Date().getTime();
  animationListener = map.on('postcompose', animate);

  function animate(event) {
    let vectorContext = event.vectorContext;
    let frameState = event.frameState;
    let flashGeom = feature.getGeometry().clone();
    let elapsed = frameState.time - start;
    let elapsedRatio = elapsed / duration;
    // radius will be 5 at start and 30 at end.
    let radius = easeOut.easeOut(elapsedRatio) * 25 + 5;
    let opacity = easeOut.easeOut(1 - elapsedRatio);
    let style = new Style({
      image: new CircleStyle({
        radius: radius,
        stroke: new Stroke({
          color: 'rgba(255, 255, 255, ' + opacity + ')',
          width: 0.5 + opacity,
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, ' + 0.4 + ')'
        })
      })
    });


    vectorContext.setStyle(style);
    vectorContext.drawGeometry(flashGeom);
    if (elapsed > duration) {
      start = new Date().getTime();
    }
    // tell OpenLayers to continue postcompose animation
    // TODO use map render only if it will we required, for now it's okay
    // map.render();
    frameState.animate = true
  }
}
/**********************************/
/* USER_GEOLOCATION ANIMATED: END */
/**********************************/

function zoomToCordinate(map, coords) {
  let view = map.getView();
  let zoom = view.getZoom();
  let center = view.getCenter();
  view.animate({
    zoom: zoom + 1,
    center: coords,
    duration: 200
  })
}

// request to WMS service for getting wrecks objects
function parseSpatilWFSwrecks() {
  return new Promise((resolve, reject) => {
    let axios = wfsAxiosApi.wfsGetFeatureRequest;
    axios
      .get()
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
  })
}

function parseSpatilWFSwrecks_AHV() {
  return new Promise((resolve, reject) => {
    let axios = wfsAxiosApi.wfsGetFeatureRequest_AHV;
    axios
      .get()
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
  })
}

// get 10 last pictures from satellite source
function getLastSatelliteLayersName(wmsInfo) {
  let layers = wmsInfo.Capability.Layer.Layer;
  let layerNamesContainer = []
  let endLayer = layers.length - 10;
  console.log('show me the end layer ====> ', endLayer);
  for (let i = layers.length - 1; i > endLayer - 1; i--) {
    let layerObj = layers[i]
    layerNamesContainer.push(layerObj);
  }
  if (layerNamesContainer.length == 10) {
    return layerNamesContainer;
  }
}

// get last 10 time dimensions for sea surface map
function getLastTimeDimension(timeDimension) {
  let timeContainer = [];
  let oldestTime = timeDimension.length - satelliteLayersConfig.waterSurfaceConfigs.layersPerGroup;
  for (let i = timeDimension.length - 1; i > oldestTime - 1; i--) {
    let timeObj = timeDimension[i]
    timeContainer.push(timeObj);
  }
  if (timeContainer.length == satelliteLayersConfig.waterSurfaceConfigs.layersPerGroup) {
    return timeContainer;
  }
}

// get last time dimensions with corresponding layers (2019-06-13: [layer1, layer2], 2019-06-12: [layer1, layer3], ...)
function getLastTimesMap(layerTimesMap) {
  let lastTimesMap = {};
  let layers = Object.keys(layerTimesMap);
  while (Object.keys(lastTimesMap).length < satelliteLayersConfig.waterSurfaceConfigs.layersPerGroup) {
    let lastTime = moment('2000', 'YYYY');
    let lastLayers = [];
    layers.forEach((layer) => {
      let layerTimes = layerTimesMap[layer];
      if (layerTimes.length > 0) {
        let time = layerTimes[layerTimes.length - 1]; // the newest time is always the last in array
        if (moment(time).isAfter(lastTime)) {
          lastTime = time;
          lastLayers = [];
          lastLayers.push(layer);
        }
        else if (moment(time).isSame(lastTime)) {
          lastLayers.push(layer);
        }
      }
    })
    lastTimesMap[lastTime] = lastLayers;
    lastLayers.forEach((layer) => { // remove the ones that were saved to lastTimesMap 
      layerTimesMap[layer].pop();
    })
  }
  return lastTimesMap;
}

// get 10 last pictures from satellite source
function getLastWaterQualityLayersName(layers) {
  let layerNamesContainer = []
  let endLayer = layers.length - 10;
  for (let i = layers.length - 1; i > endLayer - 1; i--) {
    let layerObj = layers[i]
    layerNamesContainer.push(layerObj);
  }
  if (layerNamesContainer.length == 10) {
    return layerNamesContainer;
  }
}


// transform coordinates form 3857 to 4326 accrording to POI API specification
function transformExtentsTo4326(extent) {
  let extents_4326 = proj.transformExtent(extent, proj.get("EPSG:3857"), proj.get("EPSG:4326"));
  let specifiedBBOX = [];
  specifiedBBOX[0] = extents_4326[3];
  specifiedBBOX[1] = extents_4326[1];
  specifiedBBOX[2] = extents_4326[2];
  specifiedBBOX[3] = extents_4326[0];
  return specifiedBBOX;
}

// parse satellite source
function parseSatelliteWMS({
  map,
  url
}) {
  let parser = new WMSCapabilities();
  return new Promise((resolve, reject) => {
    let axios = wmsAxiosApi.wmsCompabilitiesRequest(url);
    axios
      .get()
      .then(
        (response) => {
          let result = parser.read(response.data);
          if (result) {
            resolve(result)
          }
        }
      )
      .catch(function (error) {
        reject(error);
      })
  })
}

// water quality parser for WMS
function parseWaterQualityWMS({
  map,
  url
}) {
  let parser = new WMSCapabilities();
  return new Promise((resolve, reject) => {
    let axios = wmsAxiosApi.wmsCompabilitiesRequest(url);
    axios
      .get()
      .then(
        (response) => {
          let result = parser.read(response.data);
          if (result) {
            resolve(result)
          }
        }
      )
  })
}

function deleteMeasureOverlay(measureTooltip, map, feature) {
  let toolsGroup = getPlannerGroup(map, "Drawing tools");
  toolsGroup[0].getSource().removeFeature(feature);
  // console.log(toolsGroup[0].getSource().getFeatures());
  measureTooltip.remove();
}

function baseLayersVisibility(baseLayersGroup, layerName) {
  return new Promise((resolve, reject) => {
    baseLayersGroup.forEach((layer) => {
      if (layer.getProperties().default && layerName === layer.getProperties().name) {
        reject(router.app.$t('warningAtLeastOneMap'));
      } else if (!layer.getProperties().default && layerName === layer.getProperties().name) {
        let oldDefaultLayer = baseLayersGroup.find(element => element.getProperties().default);
        oldDefaultLayer.set('default', false);
        oldDefaultLayer.set('visible', false);
        baseLayersGroup.map((element) => {
          if (element.getProperties().name === layerName) {
            element.set('default', true);
            element.set('visible', true);
          }
        })
        resolve(baseLayersGroup);
      }
    })
  })
}

// set visibility of the layer
function setVisibility(map, layerName, layerGroupName) {
  if (layerGroupName === baseMapsConfig.baseMapsGroupTitle) {
    let baseMapGroup = getPlannerGroup(map, baseMapsConfig.baseMapsGroupTitle);
    return baseLayersVisibility(baseMapGroup, layerName)
  } else {
    return new Promise((resolve, reject) => {
      map.getLayers().forEach(function (layer) {
        if (layer instanceof Group) {
          layer.getLayers().forEach((sublayer) => {
            if (sublayer.values_.finnishName == layerName) {
              if (sublayer.getVisible() == true) {
                sublayer.setVisible(false);
                resolve(sublayer);
              } else if (sublayer.getVisible() == false) {
                sublayer.setVisible(true);
                resolve(sublayer);
              }
            }
          })
        }
      })
    })
  }
}

// checking if layer is visible
function checkIfLayerVisible(map, layerName) {
  let layerExist = false;
  map.getLayers().forEach(function (layer, id) {
    if (layer instanceof Group) {
      layer.getLayers().forEach((sublayer) => {
        if (sublayer.values_.finnishName == layerName) {
          if (sublayer.getVisible() == true) {
            layerExist = true;
          }
        }
      })
    }
  })
  return layerExist;
}

// Get nearest weather observation
function getNearestObservation(map, layerName) {
  let properties = {}
  let center = map.getView().getCenter();
  map.getLayers().forEach(function (layer) {
    if (layer instanceof Group) {
      return layer.getLayers().forEach((sublayer) => {
        if (sublayer.values_.finnishName == layerName) {
          if (sublayer.getSource()) {
            properties = sublayer.getSource().getClosestFeatureToCoordinate(center).getProperties();
          }
        }
      })
    }
  })
  return properties;
}

// Clear all layers
function clearAllLayers(map) {
  map.getLayers().forEach(function (layer) {
    if (layer instanceof Group) {
      layer.getLayers().forEach((sublayer) => {
        if (sublayer.values_.type != 'base' && sublayer.values_.visible === true && sublayer.values_.finnishName !== 'Tools') {
          // console.log('CLEARED: ', sublayer.values_.finnishName);
          sublayer.setVisible(false);
        }
      })
    }
  });
}

// change map projection
function changeMapProjection(map) {
  proj4.defs("EPSG:3067", "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
  let proj3067_ = proj.get("EPSG:3067");
  proj3067_.setExtent([-3669433.90, 4601644.86, 642319.78, 9362767.00])

  map.setView(new View({
    projection: 'EPSG:3067',
    center: proj.transform([-12.52071, 52.00288], 'EPSG:4326', 'EPSG:3067'),
    extent: [-3669433.90, 4601644.86, 642319.78, 9362767.00],
    zoom: 2,
    minZoom: 2,
  }));
}

function setDefaultProjection(map) {
  let defaultZoom = 2;
  let windowWidth = window.innerWidth;
  let zoomOnLoad = windowWidth > 768 ? 7 : 6;
  let center = [23.79199, 63.12915];
  let finlanLonLatMercator = proj.fromLonLat(center);

  map.setView(new View({
    projection: 'EPSG:3857',
    extent: [
      1957956.430880433,
      8313902.557947304,
      3464683.132437827,
      10674277.991393548
    ],
    center: finlanLonLatMercator,
    zoom: defaultZoom,
    minZoom: zoomOnLoad

  }))
}

// function for getting specified layers from selected layer group
function followLayers(map, groupName) {
  let menuLayers = [];
  let subjectiveLayer = null;

  map.getLayers().forEach(function (layer, id) {
    if (layer instanceof Group && layer.values_.title == groupName) {
      subjectiveLayer = layer;
      subjectiveLayer.getLayers().forEach(function (sublayer, sublayerIndex) {
        menuLayers.push(sublayer);
      })
    }
  })
  return menuLayers;
}

/*********************/
/* Get Planner Group */
/*********************/
function getPlannerGroup(map, title) {
  var layers = map.getLayers();
  var length = layers.getLength(),
    l;
  for (var i = 0; i < length; i++) {
    l = layers.item(i);
    var lt = l.get('title');
    // check for layers within groups
    if (lt === title) { // Title of Group
      if (l.getLayers) {
        var innerLayers = l.getLayers().getArray();
        return innerLayers;
      }
    }
  }
}

/******************************/
/* Recursively checking value */
/******************************/
function findValuesInMembers(object, key) {
  var value;
  Object.keys(object).some(function (k) {
    if (k === key) {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === 'object') {
      value = findValuesInMembers(object[k], key);
      return value !== undefined;
    }
  });
  return value;
}

// find only Spatials Group Layers
function findSpatialLayersGroup(map) {
  let spatiel;
  map.getLayers().forEach(function (layer, id) {
    if (layer instanceof Group && layer.values_.title == 'Spatial info') {
      spatiel = layer;
    }
  })
  return spatiel;
}

//rendering map asynchronously
function asyncVectorRefresh(map) {
  map.render()
}

// features info windows behavior
function addFeatureWindsInfoWindow(map, element) {
  return featuresWindows.windsFeature(map, element);
}

async function initMap(element, center = [23.75, 60.25], zoom = 7) {
  let windowWidth = window.innerWidth;
  let zoomOnLoad;
  let defaultZoom;
  if (zoom !== undefined) {
    defaultZoom = zoom;
  }
  zoomOnLoad = windowWidth > 768 ? 8 : 7;
  center = windowWidth > 768 ? center : [22.00, 60.78]
  return new Promise((resolve, reject) => {
    let layersSatellite;
    let finlandLonLat = center;
    // transforming finland poition
    let finlanLonLatMercator = proj.fromLonLat(finlandLonLat);
    // setting map borders to a certain position
    let mapBorder = proj.transformExtent(
      [1723141.8799883714, 8313902.557947304, 3699497.6833298886, 10674277.991393548],
      "EPSG:4326",
      "EPSG:3857",
      "EPSG:3067"
    );

    // constructing the map
    let FinnishMap = new Map({
      controls: control.defaults().extend([
        new Fullscreen({
          source: "fullscreen"
        }),
        new ScaleLine({
          target: "liner"
        }),
        // new Rotate({
        //   className: "ol-rotate-custom-compass",
        //   autoHide: false,
        //   label: document.getElementById("compass-icon"),
        //   tipLabel: "For rotate  Shift+Alt + left button mouse.",
        //   target: "compass-control"
        // })
      ]),
      target: element,
      layers: [
        new Group({
          title: baseMapsConfig.baseMapsGroupTitle,
          layers: baseMapLayers,
          id: 0
        }),
        new Group({
          title: keyStoneSpeciesConfigs.keyStoneGroupName,
          layers: keyStoneSpeciesLayers,
          id: 1
        }),
        new Group({
          title: "Seal protected areas",
          layers: [],
          id: 3
        }),
        new Group({
          title: spatialConfiguration.REED_AREAS_NAME,
          layers: reedAreasModelsLayers,
          id: 4
        }),
        new Group({
          title: spatialConfiguration.NAVIGATIIONAL_RESTRICTIONS_NAME,
          layers: navigationalModels,
          id: 5
        }),

        new Group({
          title: spatialConfiguration.NATIONAL_PARKS_NAME,
          layers: nationalModels,
          id: 6
        }),
        new Group({
          title: spatialConfiguration.SEAL_Protected_AreaS_NAME,
          layers: sealProtectedLayers,
          id: 13
        }),
        new Group({
          title: spatialConfiguration.ALGAEBLOOM_NAME,
          layers: algaeBloomLayers,
          id: 7
        }),

        new Group({
          title: spatialConfiguration.groupName,
          layers: spatialLayers,
          id: 8
        }),

        // Satellite layers
        new Group({
          title: satelliteLayersConfig.satelliteConfigs.satellites,
          layers: [],
          id: 9
        }),
        new Group({
          title: satelliteLayersConfig.landsatConfigs.satellites,
          layers: [],
          id: 10
        }),
        new Group({
          title: satelliteLayersConfig.sentinel2Configs.satellites,
          layers: [],
          id: 11
        }),
        new Group({
          title: satelliteLayersConfig.sentinel3Configs.satellites,
          layers: [],
          id: 12
        }),

        new Group({
          title: weatherConfigs.weatherGroupName,
          layers: weatherLayers
        }),

        // groups for water quality
        new Group({
          title: satelliteLayersConfig.waterQualityConfigs.turbidity,
          layers: []
        }),
        new Group({
          title: satelliteLayersConfig.waterQualityConfigs.cdom,
          layers: []
        }),
        new Group({
          title: satelliteLayersConfig.waterQualityConfigs.secchiDepth,
          layers: []
        }),

        // groups for water surface types
        new Group({
          title: satelliteLayersConfig.waterSurfaceConfigs.surfaceTemperature,
          layers: []
        }),
        new Group({
          title: satelliteLayersConfig.waterSurfaceConfigs.algalBlooms,
          layers: []
        }),
        new Group({
          title: satelliteLayersConfig.waterSurfaceConfigs.chlrophyll,
          layers: []
        }),

        // group for tools like Measure, Polygon
        new Group({
          title: "Drawing tools",
          layers: toolsLayers
        }),

        new Vector({
          title: "Position Vector"
        })

      ],
      view: new View({
        extent: [
          1957956.430880433,
          8313902.557947304,
          3464683.132437827,
          10674277.991393548
        ],
        center: finlanLonLatMercator,
        zoom: zoomOnLoad,
        minZoom: zoomOnLoad - 2,
        enableRotation: false
      })
    });
    resolve(FinnishMap)
  })
    .catch((error) => {
      reject(error);
    })
}

export default mapApi;
