import TileLayer from 'ol/layer/tile';
import TileWMS from 'ol/source/tilewms';
import spatialConfiguration from './spatial-constructor/spatial-config';

// syke_maanpeitesuotkosteikot:Maanpeite_soilla_ja_rantakosteikoilla
const reedAreas_URL = 'https://paikkatiedot.ymparisto.fi/geoserver/syke_maanpeitesuotkosteikot/wms?';

let reedAreasModelsLayers = [
  new TileLayer({
    id: 0,
    zIndex: 1,
    finnishName: spatialConfiguration.REED_AREAS_NAME,
    name: spatialConfiguration.REED_AREAS_NAME,
    dataType: 'JSON',
    visible: false,
    source: new TileWMS({
      url: reedAreas_URL,
      params: {
        'TILED': true,
        'LAYERS': 'syke_maanpeitesuotkosteikot:Maanpeite_soilla_ja_rantakosteikoilla'
      },
      transparent: 'true',
      projection: "EPSG:3857",
      crossOrigin: 'anonymous'
    }),
    opacity: 0.4
  }),
];

export default reedAreasModelsLayers;
