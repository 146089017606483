<template>
  <transition name="fadeHeight" mode="in-out">
    <div v-if="keystoneSpecies.active && keystoneSpecies.singleSpecies !== undefined" class="keystone-wrapper">
      <div class="keystone__header">
        <div @click="deactivateKeyStoneSpeciesInfoWindow()">
          <icon-tools-close-base :iconColor="'#000000'">
          </icon-tools-close-base>
        </div>
      </div>
      <div class="keystone__content">
        <div class="content__picture">
          <img :src="keystoneSpecies.singleSpecies.pictureUrl" alt="picture">
        </div>
        <div class="content__text">
          <div>
            <span> {{ keystoneSpecies.singleSpecies.systemName | translate }} </span>
            <span> {{ keystoneSpecies.singleSpecies.systemText | translate}} </span>
          </div>
          <span class="content__text--url">
            <a :href="keystoneSpecies.singleSpecies.systemURL | translate"> {{ 'seeMoreOnWikipedia' | translate }} </a>
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex';
  import IconToolsCloseBase from '../../svg-system-components/icons-tools/IconToolsCloseBase';
  export default {
    components: {
      IconToolsCloseBase
    },
    computed: {
      ...mapGetters({
        keystoneSpecies: 'getKeystoneSpeciesInfoWindow'
      }),
      text() {
        let text = this.keystoneSpecies.singleSpecies.finnishText;
        let splitedText = text.split(" ");
        return splitedText[0];
      }
    },
    methods: {
      ...mapMutations(['deactivateKeyStoneSpeciesInfoWindow'])
    },
    filters: {
      firstWordBold(value) {
        let words = value.split(" ");
        let firstWord = words[0];
        return value.replace(firstWord, "");
      }
    },
  }

</script>

<style scoped lang="scss">
  .keystone-wrapper {
    position: absolute;
    bottom: 0;
    left: 19rem;
    width: 30rem;
    background-color: white;
    z-index: 90;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.4), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 0px 1px 0 rgba(0, 0, 0, 0.12);

    @media screen and (max-width: 768px) {
      position: absolute;
      top: auto;
      bottom:0;
      left: 50%;
      width: 100%;
      background-color: white;
      transform: translate(-50%, 0);
      z-index: 90;
      -webkit-box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.4), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 0px 1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.4), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 0px 1px 0 rgba(0, 0, 0, 0.12);
    }

    .keystone__header {
      width: 100%;
      height: 2rem;
      display: flex;
      justify-content: flex-end;

      & div {
        cursor: pointer;
      }

      & div>* {
        height: 100%;
        margin-right: 10px;
      }
    }

    .keystone__content {
      display: flex;
      width: 100%;
      height: calc(100% - 2rem);

      & div:first-of-type {
        width: 40%
      }

      & div:last-of-type {
        width: 60%;
      }

      .content__picture {
        padding: 1rem;

        img {
          width: 100%;
          // height: 100%;
        }
      }

      .content__text {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: space-between;

        & div {
          width: 100%;
          word-wrap: break-word;

          & span:first-of-type {
            font-family: "Montserrat-Bold"
          }
        }

        .content__text--url {
          line-height: 1;
          font-size: 15px;

          a {
            text-decoration: none;
          }
        }
      }

      @media screen and (max-width: 768px) {
        overflow-y: auto;
      }
    }
  }

  .fadeHeight-enter-active,
  .fadeHeight-leave-active {
    transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
  }

  .fadeHeight-enter,
  .fadeHeight-leave-to {
    transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

</style>
